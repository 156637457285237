import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Box,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
const useStyles = makeStyles((theme) => ({

    paperFullScreen: {
        overflow: 'hidden',
        backgroundColor: 'black',
    },
    colorInherit: {
        color: 'white',
    },
    MainPicture: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'top',
        paddingBottom: "120%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.ColorC2 + "22",
        marginRight: theme.spacing(1),
        cursor: "pointer",
    },
    Frame: {
        cursor: "grab",
    },
    FullscreenMainPicture: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundSize: 'contain',
        maxHeight: '90vh',
        maxWidth: '90vw',
        height: '90vh',
        width: '75vw',
    },
    FullScreenThumbnails: {
        maxHeight: "90vh",
        overflowY: "auto",
        position: "absolute"
    },
    invisibleResetScaleButton: {
        visibility: "hidden",
        position: "absolute",
    },
    Thumbnail: {
        width: "100%",
        cursor: "pointer",
    },
    Toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    Tools: {
        position: "absolute",
        top: 0,
        display: "flex"
    },
    ArrowFooter: {
        height: "50px",
        width: "50px",
        color: "white"
    },
    Navigation: {
        display: "flex",
        justifyContent: "space-between",
        color: theme.ColorC1,
        margin: theme.spacing(1),
        paddingBottom: "4px",
        fontSize: "16px",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "3px",
            fontSize: "14px",
        },
    },
    NavigationButton: {
        flexGrow: 0,
        display: "flex",
        alignItems: "center",
        color: "white"
    },
    Carret: {
        display: "flex",
        justify: 'center',
        justifyContent: "space-around",
        alignItems: "center",
    }
}));

export const Gallery = ({ data }) => {

    const classes = useStyles();

    const [imageID, setImageID] = useState()
    useEffect(() => {
        setImageID(data[0])
    }, [data])
    const sharedMainPic = (item) => {
        setImageID(item)
    }

    const [open, setOpen] = React.useState(false);
    const handleClose = (item) => {
        setOpen(false);
    };
    const triggerOpen = () => {
        setOpen(true)
    }

    return (
        <>
            {open ?
                <Dialog
                    fullScreen
                    classes={{
                        paperFullScreen: classes.paperFullScreen
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <Box className={classes.Toolbar}>
                        <IconButton
                            classes={{
                                colorInherit: classes.colorInherit
                            }}
                            color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Grid container>
                        <Grid item xs={1} className={classes.Carret}>
                            <CarretLeft
                                data={data}
                                sharedMainPic={sharedMainPic}></CarretLeft>
                        </Grid>
                        <Grid item xs={9} >
                            <MainImage
                                data={data}
                                isFullScreen={true}
                                imageID={imageID}
                                triggerOpen={triggerOpen}
                            />
                        </Grid>
                        <Grid item xs={1} className={classes.Carret} >
                            <CarretRight
                                data={data}
                                sharedMainPic={sharedMainPic}></CarretRight>
                        </Grid>
                        <Grid item xs={1}>
                            <Box className={classes.FullScreenThumbnails}>
                                <Thumbnails
                                    data={data}
                                    sharedMainPic={sharedMainPic}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Dialog>
                :
                <Grid container>
                    <Grid item xs={10}>
                        <MainImage
                            data={data}
                            imageID={imageID}
                            triggerOpen={triggerOpen}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Thumbnails
                            data={data}
                            sharedMainPic={sharedMainPic}
                        />
                    </Grid>
                </Grid>
            }
        </>
    );
};

const MainImage = ({
    imageID, triggerOpen, isFullScreen,
}) => {

    const classes = useStyles()

    const handleClick = () => {
        triggerOpen(true)
    }
    useEffect(() => {
        handleReset()
    }, [imageID])

    const resetScale = useRef();

    const handleReset = () => {
        let el = document.getElementById("reset")
        el && el.click()
    }

    return (
        <>

            {isFullScreen ?
                <TransformWrapper
                    defaultScale={1}
                    options={{
                        minScale: 1,
                        maxScale: 6,
                        limitToBounds: true,
                        limitToWrapper: true,
                        centerContent: false,
                    }}
                    wheel={{ wheelEnabled: true, step: 10 }}
                >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <>
                            <Grid
                                container
                                justify="center"
                            >
                                <Grid item xs={12} className={classes.Tools} >
                                    <IconButton classes={{ colorInherit: classes.colorInherit }} color='inherit' onClick={zoomIn}><ZoomInIcon /></IconButton>
                                    <IconButton classes={{ colorInherit: classes.colorInherit }} color='inherit' onClick={zoomOut}><ZoomOutIcon /></IconButton>
                                    <IconButton className={classes.invisibleResetScaleButton} id="reset" ref={resetScale} onClick={resetTransform}></IconButton>
                                </Grid>
                                <TransformComponent>
                                    <Box className={classes.Frame}>
                                        <div
                                            className={classes.FullscreenMainPicture}
                                            style={{
                                                backgroundImage: imageID ? `url(/image/${imageID})` : null
                                            }}
                                        />
                                    </Box>
                                </TransformComponent>
                            </Grid>
                        </>
                    )}
                </TransformWrapper>
                :
                <div
                    className={classes.MainPicture}
                    style={{
                        backgroundImage: imageID ? `url(/image/${imageID})` : null,
                    }}
                    onClick={isFullScreen ? null : handleClick}
                />}
        </>
    )
}
let i = 0;
const CarretLeft = ({
    data,
    sharedMainPic
}) => {
    const classes = useStyles();

    const handleClick = (item, index) => {
        sharedMainPic(item, index)
    }
    return (
        <>
            <div className={classes.NavigationButton} onClick={() => { if (i > 0) { handleClick(data[i - 1], i - 1); i-- } else { handleClick(data[data.length - 1], data.length - 1); i = data.length - 1 } }}>
                <ChevronLeftIcon className={classes.ArrowFooter} />
            </div>
        </>
    )
}
const CarretRight = ({
    data,
    sharedMainPic
}) => {
    const classes = useStyles();

    const handleClick = (item, index) => {
        sharedMainPic(item, index)
    }
    return (
        <>
            <div className={classes.NavigationButton} onClick={() => { if (i < data.length - 1) { handleClick(data[i + 1], i + 1); i++ } else { handleClick(data[0], 0); i = 0 } }}>
                <ChevronRightIcon className={classes.ArrowFooter} />
            </div>
        </>
    )
}


const Thumbnails = ({
    data,
    sharedMainPic,
}) => {

    const classes = useStyles();

    const handleClick = (item, index) => {
        i = index;
        sharedMainPic(item, index)
    }

    return (
        <>
            {data ? data.map((img, index) => (
                <Grid item xs={12} key={img}>
                    <img
                        alt="gallery views"
                        className={classes.Thumbnail}
                        src={img ? `/image/thumbnail/${img}` : null}
                        type="button"
                        onClick={() => handleClick(img, index)}
                    />
                </Grid>
            )) : null}
        </>
    )
}