import React from "react";
import StoreRouter from "../routes/StoreRouter";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Footer from "../components/Store/Footer";
import StoreTheme from "../themes/StoreTheme";
import StoreAppBar from "../components/Store/StoreAppBar";
import { styled } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    minHeight: "100vh",
    background: theme.FooterColor,
  },
  content: {
    background: theme.BackgroundColor,
  },
  toolbar: theme.mixins.toolbar,
  fullContainer: {
    padding: "16px",
    [theme.breakpoints.down("xs")]: {
      padding: "6px",
    },
  },
}));

export const ToolbarPlaceHolder = () => {
  const classes = useStyles();
  return <div className={classes.toolbar} />;
};

export const FixedWidthStoreContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.fullContainer}>
      {children}
    </Container>
  );
};

export const ContentPlaceholder = styled("div")({
  minHeight: "100vh",
  width: "100%",
  background: "white",
});

export default function StoreLayout({ site, location }) {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const validated = useSelector((state) => state.auth.isValidated);
  const classes = useStyles();

  if (authenticated && !validated) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  
  return (
    <ThemeProvider
      theme={(theme) =>
        StoreTheme(
          theme,
          site === "auctions" ? theme.ColorGold : theme.ColorBlue
        )
      }
    >
      <div className={classes.grow}>
        <StoreAppBar site={site} />
        <div className={classes.content}>
          <StoreRouter />
        </div>
        <Footer site={site} />
      </div>
    </ThemeProvider>
  );
}
