import React from 'react'
import { Page, Document, StyleSheet, View, Text} from '@react-pdf/renderer'
import Title from "../ContentLayout/Common/Title";
import ListingTitle from "../ContentLayout/ObjectsListing/ListingTitle"
import { tableColumnStyle } from '../pdf_cst';
import ContactDetails from '../ContentLayout/Common/ContactDetails';
import { listing } from '../../../js/constants/miscellaneous_cst';
import ListingItemsTable from '../ContentLayout/ObjectsListing/ListingItemsTable';
import ListInformations from '../ContentLayout/ObjectsListing/ListInformations';
import TableBlankSpace from "../ContentLayout/Common/TableBlankSpace"

const styles = StyleSheet.create(tableColumnStyle);

export default function ObjectsResultPDF ({data}) {
    let type = listing

    const styles = StyleSheet.create({...tableColumnStyle,
        row: {
            flexDirection: 'row',
            marginBottom: 32
        },
        TitleContact : {
            flexDirection: 'column'

        },
        email : {
            textAlign: "center"
        }

    })

    return (
        <Document title={"Native-" + data.Title + "-Results.pdf"}>
            <Page 
                size="A4" // {[595.28, 841.89]} 
                style={styles.page}
            >
                <View style={styles.row}>
                    <View style={styles.TitleContact}>
                        <Title title={"Native"}/>
                        <ContactDetails data={data} type={type}/>
                    </View>
                </View>
                <View>
                    <ListInformations informations={data.Informations}/>
                    <ListingTitle data={data}/>
                    <ListingItemsTable items={data.Products} type="results" resultName={data.ResultType}/>
                </View>
                <Text
                    fixed
                    style={styles.BottomEmail}
                >
                    www.native-auctions.com
                </Text>
                <Text 
                    fixed
                    style={styles.PageNumber} 
                    render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} 
                />
            </Page>
        </Document>
    )
}