import React, { useRef, useState, useEffect } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import LoginForm from "./LoginForm";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChangePasswordIcon from '@material-ui/icons/Lock';

import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  loginMenu: {
    marginBottom: 8,
  },
  iconButton: {
    padding: "3px",
  },
  icon: {
    height: "36px",
    width: "36px",
  },
  welcomeA: {
    fontSize: "70%",
  },
  welcomeB: {
    fontWeight: 500,
    fontSize: "90%",
  },
  loggedUser: {
    textAlign: "right",
    marginRight: 5,
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      display:"none"
    }
  },
  loginBox: {
    background: theme.ColorBlue,
  },
  loginTextIcon: {
    display: "flex",
  },
  choiceIcons: {
    marginRight: "20px",
  },
}));

export default function AccountMenu() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const role = useSelector((state) => state.auth.role);
  const displayName = useSelector((state) => state.auth.displayName);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <div
          style={{ cursor: "pointer" }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          className={classes.loginTextIcon}
          onClick={handleToggle}
        >
          {authenticated && (
            <div className={classes.loggedUser}>
              <div className={classes.welcomeA}>Welcome</div>
              <div className={classes.welcomeB}>{displayName}</div>
            </div>
          )}
          <AccountCircleIcon className={classes.icon} />
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                {authenticated ? (
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={() => history.push("/profile")}>
                        <AccountBoxIcon className={classes.choiceIcons} />{" "}
                        My Profile
                      </MenuItem>
                      <MenuItem onClick={() => history.push("/change-password")}>
                        <ChangePasswordIcon className={classes.choiceIcons}/>
                        Change Password
                      </MenuItem>
                      {role === "Admin" && (
                        <MenuItem onClick={() => history.push("/admin")}>
                          <SupervisorAccountIcon
                            className={classes.choiceIcons}
                          />{" "}
                          Administration
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => history.push("/logout")}>
                        <ExitToAppIcon className={classes.choiceIcons} /> Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                ) : (
                  <Box
                    width={280}
                    boxShadow={3}
                    p={2}
                    className={[classes.NDropBox, classes.loginBox]} // put in an array to use several classes
                  >
                    <LoginForm />
                  </Box>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
