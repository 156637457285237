import { createMuiTheme } from '@material-ui/core/styles';


export default function ProfileTheme(theme, brandColor)
{
    return createMuiTheme({
        ...theme,
        ColorC1: brandColor,
        ColorC2: theme.ColorBlue,
        ButtonSpacing: "8px",
        ButtonDoubleSpacing: "16px",
        overrides: {
            MuiListItem: {
                root: {
                    "&$selected": {
                        backgroundColor: theme.ColorBlue,
                        color: "white",
                    },
                    "&:hover": {
                        color: theme.ColorBlue,
                    }, 
                },
            },
            MuiListItemIcon: {
                root: {
                    color: "inherit"
                }
            }
        }
    });
}