import React, {Fragment} from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36
    },

    row: {
        flexDirection: 'row',
    },
    ownThings: {
        width: "70%"
    },
    client: {
        width: "30%",

    }
});

const NativeContact = () => {
    return (
        <Text style={styles.ownThings}>
            Galerie de la rue Ruysbroeck 5{"\n"}
            1000 Brussels{"\n"}
            Belgium{"\n"}
            T +32 (0) 2 514 0442{"\n"}
            info@native-auctions.com{"\n"}
        </Text>
    )
}

const RecipientContact = ({data, type}) => {
    const { getName } = require('country-list');

    return (
        <Fragment>
            {type && type.name === "group" ? null :
                <Text style={styles.client}>    
                    {data.Name + "\n"}
                    {data.AddressLine1 + "\n"}
                    {data.AddressLine2 && data.AddressLine2 + "\n"}
                    {data.PostCode + "  "}
                    {data.City + "\n"}
                    {getName(data.Country) + "\n"}
                    {data.VATNumber && data.VATNumber + "\n"}
                </Text>
            }
        </Fragment>
        
    )
}


const ContactDetails = ({ data, type }) => {
    return (
        <View style={styles.row}>
            <NativeContact/>
            <RecipientContact data={data} type={type}/>
        </View>
    );
}
export default ContactDetails