import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { tableColumnStyle } from "../../pdf_cst"
import BillTableRow from './BillTableRow';
import BillTableHeader from './BillTableHeader';
import BillTableFooter from './BillTableFooter';


const styles = StyleSheet.create({...tableColumnStyle})

const InvoiceItemsTable = ({  hideVAT, bill, type }) => (
  <View style={styles.tableContainer}>
    <BillTableHeader  hideVAT={hideVAT} items={bill} type={type} />
    <BillTableRow  hideVAT={hideVAT} items={bill}></BillTableRow>
    <BillTableFooter items={bill} type={type} />
  </View>
);

export default InvoiceItemsTable