import React, { useEffect, useState } from "react";
import { Paper, Grid, Typography} from "@material-ui/core";
import { NDataLoading } from "../Widget/NAdminCustomComponents";

import Axios from "axios";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { useSelector, useDispatch } from "react-redux";
import MasonryListing from "../Widget/MasonryListing";
import { itemsUpdated, resetFollows } from "../../js/actions/items";

import ProductCell from "../Widget/ProductCell"

import * as cst from "../../js/constants/miscellaneous_cst";

const Follows = () => {
  const localStyle = makeStylesGlobal((theme) => ({
    Container: {
      position: "absolute",
      left: "8px",
      top: "8px",
      right: "8px",
      bottom: "8px",
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",

      border: "1px solid rgba(0, 0, 0, 0.3)",
      padding: "8px",
      textAlign: "center",
    },
    Title: {
      flexBasis: "2.5em",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    AuctionTitle: {
      flexBasis: "2.5em",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    Picture: {
      flexBasis: "50px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center center",
    },
    Estimation: {
      flexBasis: "2.5em",
    },
  }));

  const serverTimeDelta = useSelector((state) => state.itemCache.serverTimeDelta);
    const serverTime = () => {
      var t = new Date();
      return t.setSeconds(t.getSeconds() + serverTimeDelta);
    }

    const [currentTime, setCurrentTime] = useState(serverTime())

  useEffect(() => {
    setTimeout(() => {
      setCurrentTime(serverTime());
    }, 1000);
  });

  const classes = localStyle();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const [data, setData] = useState(null);

  function getData() {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    Axios.get(`view/follows`, config).then((response) => {
        const data = response.data;
        dispatch(resetFollows(response.data.Products.map(t => t.ID)));
        dispatch(itemsUpdated(data.Updates, false));
      setData(data);
    });
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Paper
        className={classes.NVisiblePaper}
      >
        <Grid item xs={10} style={{ verticalAlign: "middle" }}>
          <Typography variant="h6" style={{ padding: "12px" }}>
            Follows
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {data ? (
            <MasonryListing
              data={{ items: data.Products, separators: null }}
              component={ProductCell}
              cellWidth={300}
              cellHeight={500}
              itemProps={{
                currentTime: currentTime,
              }}
            />
          ) : (
            <>
              <NDataLoading />
            </>
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default Follows;
