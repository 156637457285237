import React, { useState, useEffect } from "react";
import ReactDataGrid from "react-data-grid";
import { Grid, Paper, Typography, Box } from "@material-ui/core";
import { makeStylesGlobal } from "../../../../themes/GlobalTheme";
import { ResponsiveContainer } from "recharts";
import CreatableSelect from "react-select/creatable";
import { reactSelectStyles } from '../../../Widget/NativeStyleds';
import { useReduxPrefsState } from "../../../../js/actions/prefs";
import { useSelector } from "react-redux";
import Axios from "axios";
import { NBottomActiveButton } from "../../../Widget/NAdminCustomComponents";
import SellerEditor from "./SellerEditor";
import NumericEditor from "./NumericEditor";
import { productPage } from "../../../../js/constants/miscellaneous_cst";

const initColumns = [
  {
    key: "SellerID",
    name: "Seller",
    editable: true,
    resizable: true,
    width: 100,
  },
  { key: "Title", name: "Title", editable: true, resizable: true, width: 200 },
  {
    key: "Description",
    name: "Description",
    editable: true,
    resizable: true,
    width: 300,
  },
  {
    key: "InternalNotes",
    name: "Notes",
    editable: true,
    resizable: true,
    width: 300,
  },
  {
    key: "StartPrice",
    name: "Start price",
    editable: true,
    resizable: true,
    width: 150,
  },
  {
    key: "EstimatedLow",
    name: "Estimate Low",
    editable: true,
    resizable: true,
    width: 150,
  },
  {
    key: "EstimatedHigh",
    name: "Estimate High",
    editable: true,
    resizable: true,
    width: 150,
  },
];

const initRows = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

const useStyles = makeStylesGlobal((theme) => ({
  Paper: {
    padding: "10px",
    height: `calc(100% - ${theme.mixins.toolbar.minHeight + 10}px)`,
  },
  Sizer: {
    height: "100%",
  },
  GridContainer: {
    height: "auto",
  },
  Select: {
    width: "auto",
    display: "inline",
  },
  RightButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ResponsiveReactDataGrid = (props) => {
  return <ReactDataGrid minHeight={Math.max(100, props.height)} {...props} />;
};

function BulkEncodeInternal({ candidates, history }) {
  const token = useSelector((state) => state.auth.token);
  const [rows, setRows] = useReduxPrefsState("bulkItems", initRows);
  const [inputValue, setInputValue] = useState("");
  const [customFields, setCustomFields] = useReduxPrefsState(
    "bulkCustomFields",
    []
  );
  const [columnsSaved, setColumnsSaved] = useReduxPrefsState(
    "bulkColumns",
    initColumns
  );

  const sellerEditor = <SellerEditor options={candidates} />;
  const sellerFormatter = ({ value }) => {
    return <div>{value && value.toString().split("|").slice(1).join(" ")}</div>;
  };

  const getColumnExtra = (column) => {
    switch (column.key) {
      case "SellerID":
        return { ...column, editor: sellerEditor, formatter: sellerFormatter };
      case "StartPrice":
      case "EstimatedLow":
      case "EstimatedHigh":
        return { ...column, editor: <NumericEditor /> };
      default:
        return column;
    }
  };
  const columns = columnsSaved.map((t) => getColumnExtra(t));

  useEffect(() => {
    let newColumns = columnsSaved.slice();

    newColumns = newColumns.filter(
      (t) =>
        customFields.some((k) => k.value === t.key) ||
        initColumns.some((k) => k.key === t.key)
    );

    let didAdd = false;
    customFields.forEach((c) => {
      if (!newColumns.some((k) => k.key === c.label)) {
        newColumns = newColumns.concat([
          {
            key: c.label,
            name: c.label,
            editable: true,
            resizable: true,
            width: 100,
          },
        ]);
        didAdd = true;
      }
    });
    if (newColumns.length !== columnsSaved.length || didAdd) {
      setColumnsSaved(newColumns);
    }
  });

  const classes = useStyles();

  const isEmpty = (o) =>
    !o || Object.keys(o).every((k) => o[k] === undefined || o[k] === "");

  const gridRowsUpdated = ({ fromRow, toRow, updated }) => {
    let newRows = rows.slice();
    for (let i = fromRow; i <= toRow; i++) {
      newRows[i] = { ...newRows[i], ...updated };
    }

    newRows = newRows.filter((t) => !isEmpty(t));
    newRows = newRows.concat(initRows);

    setRows(newRows);
  };

  const columnResized = (index, width) => {
    let newColumns = columnsSaved.slice();
    newColumns[index].width = width;
    setColumnsSaved(newColumns);
  };

  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleCreate = async () => {
    const objects = rows.filter((t) => !isEmpty(t));

    const formatted = objects.map((t) => {
      let extra = customFields
        .map((f) =>
          t[f.value] ? "<b>" + f.label.toString() + ":</b> " + t[f.value] : null
        )
        .filter((t) => t)
        .join("<br/>");
      if (extra.length > 0) {
        extra = "<br/><br/>" + extra;
      }

      const description = t.Description ? t.Description : "";
      return {
        SellerID: t && t.SellerID && t.SellerID.toString().split("|")[0],
        Title: t.Title,
        Description: description + extra,
        InternalNotes: t.InternalNotes,
        StartPrice: t.StartPrice && !isNaN(t.StartPrice) && parseInt(t.StartPrice),
        EstimatedLow: t.EstimatedLow && !isNaN(t.EstimatedLow) && parseInt(t.EstimatedLow),
        EstimatedHigh: t.EstimatedHigh && !isNaN(t.EstimatedHigh) && parseInt(t.EstimatedHigh)
      };
    });

    var config = {
      headers: { Authorization: "Bearer " + token },
    };
    let url = "/product/bulkadd";

    try {
      var response = await Axios.post(url, formatted, config);
      if (response && response.data === "OK") {
        setRows(initRows);
        history.push(productPage);
      }
    } catch {
      console.log("Try again later...");
    }
  };

  const handleChange = (value, actionMeta) => {
    !value && setColumnsSaved(initColumns);
    value ? setCustomFields(value) : setCustomFields([]);
  };
  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("");
        setCustomFields([...customFields, createOption(inputValue)]);
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  return (
    <Paper className={classes.Paper}>
      <Grid container spacing={1} className={classes.Sizer}>
        <Grid item xs={12}>
          <Typography variant="h6">Encode new objects</Typography>
        </Grid>
        <Grid item xs={12}>
          Custom features
          <CreatableSelect
            //className={classes.Select}
            styles={reactSelectStyles}
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="List features of objects you want to encode..."
            value={customFields}
          />
        </Grid>
        <Grid item xs={12} className={classes.GridContainer}>
          <ResponsiveContainer width="100%" height="100%">
            <ResponsiveReactDataGrid
              columns={columns}
              rowGetter={(i) => rows[i]}
              rowsCount={rows.length}
              onGridRowsUpdated={gridRowsUpdated}
              enableCellSelect={true}
              enableDragAndDrop={true}
              onColumnResize={columnResized}
            />
          </ResponsiveContainer>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Box className={classes.RightButtons}>
            <NBottomActiveButton type="submit" onClick={handleCreate}>
              Create items
            </NBottomActiveButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function BulkEncode(props) {
  const token = useSelector((state) => state.auth.token);
  const [candidates, setCandidates] = useState(null);

  useEffect(() => {
    var config = {
      headers: { Authorization: "Bearer " + token },
    };
    let url = "/user/simplechooser";
    Axios.get(url, config).then((response) => {
      setCandidates(response.data);
    });
  }, [token]);

  return (
    <>
      {candidates && <BulkEncodeInternal candidates={candidates} {...props} />}
    </>
  );
}
