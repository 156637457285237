import React from 'react'
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import NativeSelect from "@material-ui/core/NativeSelect";
import Box from '@material-ui/core/Box';
import {NAdminActiveButton, NAdminToolbarCancelButton} from '../Widget/NAdminCustomComponents'

const localStyle = makeStylesGlobal(theme => ({
    AuctionChooserBar: {
        display: 'flex',
        position: "relative",
        alignItems: 'flex-end',
    }
}));  

export const AuctionChooser = ({
    onCancel, 
    onSaveAuction,
    onValueChange,
    value, 
    selectedRows,
    auctionChoices,
    isDisabled, 
    actionLabel
}) =>  { 
    
    const classes = localStyle();
    const label = actionLabel ? actionLabel : "Assign";

    return (
        <>
            <Box className={classes.AuctionChooserBar}>
                <div className={classes.VComponentMargin}>
                    <FormControl error={false} style={{fullWidth: true, width: "100%", top:"-5px"}} disabled={isDisabled}>
                        <NativeSelect
                            value={value ? value : ""}
                            fullWidth={true}
                            onChange={(e) => {
                                onValueChange(e)
                            }}
                        >
                            <option value={""} label={""}></option>
                            {auctionChoices && auctionChoices.map((option, key) => (
                                <option key={key} value={option.value}>{option.label}</option>
                            ))}
                        </NativeSelect>

                    </FormControl>
                </div>
                <NAdminActiveButton
                    onClick={onSaveAuction}
                >
                    {label}
                </NAdminActiveButton>

                <NAdminToolbarCancelButton
                    onClick={onCancel} 
                >
                    Cancel
                </NAdminToolbarCancelButton>
            </Box>
        </>
    )
};