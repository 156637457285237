import { combineReducers } from 'redux';
import authReducer from "./auth";
import prefsReducer from "./prefs";
import itemsReducer from "./items";
import dashReducer from "./dashboard";
import synctabReducer from "./synctab";

const rootReducer = combineReducers({
    auth: authReducer,
    userprefs: prefsReducer,
    itemCache: itemsReducer, 
    dashboard: dashReducer,
    synctab: synctabReducer
});

export default rootReducer