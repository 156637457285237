import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

export const NativeDialog = ({ open, onClose, maxWidth='lg' , children }) => {

    return (
        <>
            <Dialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={maxWidth}
            >
                <DialogContent dividers>
                {open && React.cloneElement(children, { onClose: onClose })}
                </DialogContent>
            </Dialog>
        </>
    );
}