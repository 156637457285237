import React, { useRef, useEffect, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import Axios from 'axios'
import AuctionsEditPanel from "../../EditPanels/AuctionsEditPanel";
import EnteteEditPanels from "../../EditPanels/EnteteEditPanels";
import GroupEditPanel from "../../EditPanels/GroupEditPanel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useSelector } from "react-redux";
import { SECTIONSURL } from "../../../js/constants/model_cst";
import { fetchData, deleteModel } from "../../../js/utils/backend"; 
import { makeStylesGlobal } from "../../../themes/GlobalTheme"
import { NAdminToolBar, NAdminActiveButton } from '../../Widget/NAdminCustomComponents'
import SectionEditPanel from "../../EditPanels/SectionEditPanel"
import { scrollTopFunction } from "../../../js/constants/functions_cst";
import * as cst from "../../../js/constants/miscellaneous_cst"
import { useReduxPrefsState } from "../../../js/actions/prefs"
import {useHistoryState} from "../../../js/utils/customHooks";

const localStyle = makeStylesGlobal(theme => ({
}));

export default function Sections({ match, history, ...props }) {
    const classes = localStyle();
    const token = useSelector(state => state.auth.token);

    const tableRef = useRef();

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
    const [displayEditPanel, setDisplayEditPanel] = useState(true)
    const [operation, setOperation] = useState("none");
    const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn","BeforeLot");
    const [sortingDir, setSortingDir] = useHistoryState("sortingDir","asc");
    const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
  

    const auctionId = match && match.params && match.params.auctionId;
    const [auctionData, setAuctionData] = useState(null);

    function getAuctionData() {
        if (auctionId) {
            var config = {
                headers: { Authorization: "Bearer " + token }
            };
            let url = "/auction/" + auctionId;
            Axios.get(url, config)
                .then(response => {
                    setAuctionData(response.data);
                })
        }
    }

    useEffect(() => {
        getAuctionData();
    }, [token, auctionId]);

    const operationChange = newOperation => 
    {
        switch(newOperation)
        {
            case "none":
                setDisplayEditPanel(true)
            break;
            case "new":
            break;
            default: return null;
        }
        setOperation(newOperation);
    }

    function refreshTable() { 
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    if (!auctionData)
        return null;

    return (
        <>
            <EnteteEditPanels
                titleFormatter={(auction) => auction.Title}

                Data={auctionData}
                component={
                    auctionData.Status === "Group"
                        ?
                        <GroupEditPanel isNew={false} />
                        :
                        <AuctionsEditPanel isNew={false} />
                }
            />
            <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    detailPanel={
                        displayEditPanel ? (rowData) =>
                        {
                            return (
                                <div>
                                    <SectionEditPanel
                                        Data={rowData}
                                        onClose={refreshTable}
                                        isNew={false}
                                    />
                                </div>
                            );
                        } : null}
                    tableRef={tableRef}
                    columns={[
                        {
                            title: "Before Lot",
                            field: "BeforeLot",
                            cellStyle: { width: '20%', padding: 0 },
                            defaultSort: getSort("BeforeLot")
                        },
                        {
                            title: "Title",
                            field: "PrimaryText", 
                            defaultSort: getSort("Title"), customSort: (a, b) => null
                        },
                    ]}
                        data={query => fetchData(token, query, SECTIONSURL, "&auction="+auctionId)}
                    options={{
                        detailPanelType: 'single',
                        pageSize: rows,
                        pageSizeOptions: cst.itemsPerPage,
                        emptyRowsWhenPaging:false,
                        actionsColumnIndex: 50,
                    }}
                    editable={{
                        onRowDelete: oldData => deleteModel(oldData.ID, token, SECTIONSURL)
                    }}
                    onChangeRowsPerPage={(nbRows) => setRows(nbRows)}
                    title="Sections"
                    components={{
                        Toolbar: props => (
                            <div>
                                <div>   
                                    <MTableToolbar {...props} />
                                </div>
                                <div>
                                <NAdminToolBar invisible={operation==="new"}>
                                {operation === "none" && 
                                    <>
                                        <NAdminActiveButton
                                        startIcon={<AddIcon />}
                                        onClick={() => operationChange("new")}>
                                        New
                                        </NAdminActiveButton>
                                    </>
                                }
                                {operation === "new" && (
                                    <Paper className={classes.NVisiblePaper}>
                                        <SectionEditPanel 
                                            Data={{AuctionID: auctionId, BackgroundColor:"#FFFFFF", TextColor: "#000000", IsExact: true}}         
                                            onClose={() => {
                                                operationChange("none");
                                                refreshTable();
                                                scrollTopFunction();
                                            }} 
                                            isNew={true} 
                                        />
                                    </Paper>
                                )}
                                </NAdminToolBar>
                                </div>
                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
        </>
    );
}