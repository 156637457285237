import React from "react";
import LoginForm from "./LoginForm";
import ValidationForm from "./ValidationForm";
import { Box, useTheme } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { NFullGridCentered } from "../Widget/NativeStyleds";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const localStyle = makeStylesGlobal(theme => ({}));

const Login = () => {
  const classes = localStyle();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isValidated = useSelector(state => state.auth.isValidated);
  let originalPath = useSelector(state => state.auth.originalPath);

  if(originalPath === "/login")
  {
    originalPath = "/";
  }

  return (
    <NFullGridCentered>
      <ThemeProvider
        theme={theme =>
          createMuiTheme({
            ...theme,
            ActiveColor: "white",
            BackgroundColor: theme.ColorBlue
          })
        }
      >
        <Box
          width={300}
          boxShadow={3}
          className={classes.NDropBox}
          bgcolor={useTheme().ColorBlue}
        >
          {isAuthenticated ? <ValidationForm /> : <LoginForm /> }
          
        </Box>
      </ThemeProvider>
      
      {isAuthenticated && isValidated ? <Redirect to={originalPath} /> :  null}
    </NFullGridCentered>
  );
};

export default Login;
