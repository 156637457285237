import React from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux"; 
import { editModel} from "../../js/utils/backend";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { PARAMETERSURL } from "../../js/constants/model_cst";

import { 
    VTextField, 
    VDropzone, 
    VMuiTextEditor, 
    
} from '../Widget/CustomVInputs'
import { useForm, FormContext } from 'react-hook-form';
import * as cst from '../../js/constants/miscellaneous_cst'

import {NBottomActiveButton, NBottomCancelButton} from '../Widget/NAdminCustomComponents';

const ParameterEditPanel = ({Data, onClose}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange", 
        defaultValues: Data
    });

    const {
        handleSubmit
    } = methods;

    const localStyle = makeStylesGlobal(theme => ({}));
    const classes = localStyle();

    const token = useSelector(state => state.auth.token);


    const submitUpdate = data => { 
        const newData = {...Data, ...data }; 
        editModel(newData, Data.ID, token, PARAMETERSURL)
        .then(onClose)
    }
    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)}>
                <Grid container> 
                    {Data.ParamType === "String" ? 

                        <Grid item xs={12}>
                            <VTextField
                                label="Native VAT Parameter"
                                fieldName="Value"
                                fullWidth={true}
                                validation={{ required: false }}
                            />
                        </Grid>

                    : null }

                    {Data.ParamType === "Image" ? 
                    
                        <VDropzone
                            fieldName="Value"
                            label="Image Parameter"
                            validation={{required: false}} // if false, fail to create
                            fileType="img" 
                        />

                    : null }

                    {Data.ParamType === "RichText" ? 
                    
                    <Grid item xs={12}>
                        <VMuiTextEditor
                            fieldName="Value"
                            label="Terms and Conditions Parameter"
                            validation={{
                                required: cst.msgContentRequired,
                                pattern: {
                                    minLength: 12,
                                    message: cst.msgContentRequired
                                } 
                            }} 
                        />
                    </Grid>

                    
                    : null }
                    
                    <Grid item sm={12} xs={12}>
                        <Box className={classes.RightEndAlign}>
                            <NBottomCancelButton 
                                variant="outlined" 
                                onClick={onClose}
                            >
                            Cancel
                            </NBottomCancelButton>
                            <NBottomActiveButton 
                                type="submit"
                            >
                            Save
                            </NBottomActiveButton>
                        </Box>
                    </Grid>    

                </Grid>
            </form>
        </FormContext>
    ); 
} 

export default ParameterEditPanel