import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import {
    ToolbarPlaceHolder,
    FixedWidthStoreContainer
} from "../../layouts/StoreLayout";
import NBreadcrumb from '../Widget/NBreadcrumb'
import { TermsConditionsContent } from '../Account/TermsAndConditions/TermsConditionsContent';

const useStyles = makeStyles(theme => ({
    Headline: {
        fontSize: "200%",
        fontFamily: theme.PrimaryFont,
        color: theme.ColorC1,
        textTransform: "uppercase",
        paddingTop: "10px",
        paddingBottlm: "10px",
        borderBottom: "solid 1px",
        borderBottomColor: theme.ColorC1 + "44",
    },
    TermsOfSalesContent : {
        textAlign: "justify",
    }
}));


const TermsConditions = ({match}) => {

    const classes = useStyles();

    const breadCrumbs = [
        {
            Label: "Native",
            URL: "/",
            Arrow: true
        },
    ];

    return (
        <FixedWidthStoreContainer>

            <Helmet>
                <title>Terms of sales</title>
            </Helmet>

            <ToolbarPlaceHolder />
            <Grid container>
                <Grid item xs={12}> 
                    <NBreadcrumb data={breadCrumbs}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.Headline}>TERMS OF SALES</Typography>
                </Grid>
                <div className={classes.TermsOfSalesContent}>
                    <TermsConditionsContent />
                </div>
                
            </Grid>
        </FixedWidthStoreContainer>
    )
}

export default TermsConditions;