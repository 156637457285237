import React from 'react'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { editModel, addNewModel} from "../../js/utils/backend";
import { useSelector } from "react-redux"; 
import { makeStylesGlobal } from "../../themes/GlobalTheme";

import { GROUPURL, requestGROUP } from "../../js/constants/model_cst";
import { useForm, FormContext } from 'react-hook-form';

import {VTextField, VMultiLineEdit } from '../Widget/CustomVInputs'

import {NBottomActiveButton, NBottomCancelButton} from '../Widget/NAdminCustomComponents';

const GroupEditPanel = ({
    Data, onClose, isNew, onUpdate
}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: Data
    });

    const {
        handleSubmit
    } = methods;


    const localStyle = makeStylesGlobal(theme => ({}));

    const classes = localStyle();
    const token = useSelector(state => state.auth.token);

    const submitMethods = (newData) =>{
        if(onUpdate != null)onUpdate(newData);
        if(onClose != null)onClose();
    }

    const submitUpdate = data => {
        const newData = {...Data, ...data}; 
        if(isNew)
        {
            addNewModel(newData, token, GROUPURL)
            .then(()=>submitMethods(newData)) 
        }
        else
        {
            editModel(newData, Data.ID, token, requestGROUP)
            .then(()=>submitMethods(newData))
        }    
    }

    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)}>
                <Grid container>
                    <Grid item xs={12}>
                        <VTextField
                            label="Title"
                            fieldName="Title"
                            validation={{ required: true }}
                            fullWidth={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <VMultiLineEdit
                            label="Informations"
                            fieldName="Informations"
                            validation={{required: false}}
                        />
                    </Grid>

                    <Grid item sm={12} xs={12}>
                        <Box className={classes.RightEndAlign}>
                            <NBottomCancelButton
                                onClick={onClose}
                            >
                                Cancel
                            </NBottomCancelButton>
                            <NBottomActiveButton
                                type="submit"
                            >
                                Save
                            </NBottomActiveButton>
                        </Box>
                    </Grid>

                </Grid>
            </form>
        </FormContext>
    )

}

export default GroupEditPanel