import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Grid, Link, Box, Button, Typography } from "@material-ui/core";
import RouterLink from "../../routes/RouterLink";
import { useSelector, useDispatch } from "react-redux";
import {
  ToolbarPlaceHolder,
  ContentPlaceholder,
  FixedWidthStoreContainer,
} from "../../layouts/StoreLayout";
import { makeStyles } from "@material-ui/core/styles";
import * as cst from "../../js/constants/miscellaneous_cst";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { Redirect, useHistory } from "react-router-dom";
import { entry } from "../../js/actions/auth";
import {
  itemsUpdated,
  serverTimed,
  itemPending,
  followItem,
  unfollowItem,
} from "../../js/actions/items";
import { format } from 'date-fns'
import { BidChooser } from "../Widget/BidChooser";
import { Gallery } from "../Widget/Gallery"

import { NBottomCancelButton } from "../Widget/NAdminCustomComponents"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { Deadline, calculateTimeLeft } from "../Widget/Deadline"
import { getGoodBids } from "../../js/utils/biddingMethods";
import { Helmet } from 'react-helmet';

import {NModal, NDialog} from "../Widget/NAdminCustomComponents"
import ExternalOnlinePlatforms from "../Widget/ExternalOnlinePlatforms"
import { formatCurrency,priceEstimation } from "../../js/utils/formatter"


const useStyles = makeStyles((theme) => ({
  Headline: {
    display: "flex",
    alignItems: "baseline",
    paddingTop: "10px",
    paddingBottlm: "10px",
    borderBottom: "solid 1px",
    borderBottomColor: theme.ColorC1 + "44",
  },
  HeadlineText: {
    fontSize: "130%",
    fontFamily: theme.SecondaryFont,
    color: theme.ColorC2,
    fontWeight:"bold",
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: "130%",
    },
  },
  HeadlineOrder: {
    fontSize: "130%",
    fontFamily: theme.SecondaryFont,
    color: theme.ColorC1,
    fontStyle: "normal",
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      fontSize: "130%",
    },
  },
  Section: {
    fontSize: "160%",
    color: theme.ColorC2,
    fontFamily: theme.PrimaryFont,
    fontStyle: "normal",
    paddingTop: "20px",
    borderBottom: "solid 1px",
    borderBottomColor: theme.ColorC2 + "22",
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "140%",
      marginBottom: "6px",
    },
  },
  ColumnLeft: {
    color: theme.ColorC2,
    paddingBottom: "16px",
  },
  ColumnRight: {
    paddingLeft: "32px",
    color: theme.ColorC2,
    paddingBottom: "16px",
    minHeight: "100vh",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
  Footer: {
    paddingBottom: "10px",
    borderTop: "solid 1px",
    borderTopColor: theme.ColorC2 + "22",
  },
  Fact: {
    display: "flex",
    color: theme.ColorC2,
    alignItems: "baseline"
  },
  FactBold: {
    display: "flex",
    fontWeight: "bold",
    alignItems: "baseline",
    color: theme.ColorC2
  },
  ActionGroup: {
    background: theme.ColorC1 + "10",
    margin: "10px 0px",
    borderTop: "1px solid",
    borderTopColor: theme.ColorC1 + "22",
    borderBottom: "1px solid",
    borderBottomColor: theme.ColorC1 + "22",
    padding: "0px 3px",
  },

  AuctionGroupOnlinePlatforms : {
    background: theme.ColorC2 + "10",
    margin: "10px 0px",
    borderTop: "1px solid",
    borderTopColor: theme.ColorC2 + "22",
    borderBottom: "1px solid",
    borderBottomColor: theme.ColorC2 + "22",
    padding: "0px 3px",
  },

  Action: {
    padding: "3px",
    display: "flex",
    alignItems: "center",
  },
  ActionExp: {
    padding: "0px 3px",
    fontSize: "80%",
  },
  FactLabel: {
    fontFamily: theme.SecondaryFont,
    fontSize: "14px",
    flexGrow: 1,
  },
  FactValue: {
    fontSize: "14px",
    marginLeft: "10px",
    flexGrow: 0,
  },
  FactValue2: {
    fontSize: "14px",
    flexGrow: 0,
  },
  FactValue3: {
    fontWeight: "bold", 
    fontSize: "14px",
    flexGrow: 1,
    textAlign: "center"
  },
  ArrowFooter: {
    height: "16px",
    width: "16px",
  },
  MoreLabelFooter: {
    textTransform: "uppercase",
    lineHeight: "12px",
    fontSize: "14px",
    cursor: "pointer",
    textAlign: "left",
    margin: "10px"
  },
  Navigation: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.ColorC1,
    margin: "4px",
    paddingBottom: "16px",
    fontSize: "14px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "3px",
      fontSize: "14px",
    },
  },

  OnlinePlatformStatement: { 
    color: theme.ColorC2,
    padding: theme.spacing(2)
  },

  BidOnlineButton : { 
    margin: "5px 0px", 
    padding: "6px", 
    color: theme.ColorC2, 
  },

  NavigationButton: {
    flexGrow: 0,
    display: "flex",
    alignItems: "center",
  },
  NavigationSpacer: {
    flexGrow: 1,
  },
  PreHead: {
    display: "flex",
    alignItems: "center",
    color: theme.ColorC1,
    paddingTop: "5px",
  },
  PreHeadText: {
    flexGrow: 0,
    fontFamily: theme.PrimaryFont,
    textTransform: "uppercase",
    color: theme.ColorC1,
    fontSize: "20px",
    marginRight: "10px",
    paddingTop: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  BigPlacement: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  StoreButton: {
    color: theme.ColorC1,
    margin: "5px 0px",
    padding: "6px"
  },
  StoreTextField: {
    color: theme.ColorC1,
    margin: "3px 10px 0px 0px",
  },
  Highest: {
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "center",
    padding: "10px 3px",
  },
  BiddingHistory: {
    display: "flex",
    flexDirection: "column"
  },
  BidItem: {
    display: "flex",
    marginLeft: "10px",
    marginTop: "10px",
    paddingTop: "0px",
    paddingBottom: "10px",
    borderTop: "1px solid",
    borderTopColor: theme.ColorC1 + "22",
  },
  BidHeader: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column"
  },
  Bidder: {
    display: "flex",
    fontSize: "14px"
  },
  BidTime: {
    fontSize: "10px",
  },
  BidValue: {
    fontWeight: "normal",
    fontSize: "16px",
    color: theme.ColorC2,
    flexGrow: 0,
  },

  FakePreviousSpace:{width: "77px"}, 
  FakeNextSpace:{width: "50px"},

  RightEndAlign: {
    display: "flex",
    justifyContent: "flex-end",
    margin: '8px'
  },


}));



function StoreAuthenticatedButton({ onClick, children, ...props }) {
  const [clicked, setClicked] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  return (
    <>
      <Button
        variant="outlined"
        className={classes.StoreButton}
        {...props}
        onClick={() => {
          setClicked(true);
          !token && dispatch(entry(document.location.pathname));
          token && onClick(token);
        }}
      >
        {children}
      </Button>
      {clicked && (token ? null : <Redirect to={"/login"} />)}
    </>
  );
}

function Product({ match, width }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState();
  const siteId = match.params.siteId;
  const auctionId = match.params.auctionId;
  const productId = match.params.productId;
  const token = useSelector((state) => state.auth.token);
  const stateItem = useSelector((state) => state.itemCache.items[productId]);
  const allBids = stateItem ? stateItem.vbids : null;
  const currentBid = stateItem ? stateItem.current : null;
  const myId = useSelector((state) => state.auth.bidderId);
  const currentPrice = currentBid ? currentBid.Value : null;
  const deadline = currentBid ? currentBid.DeadLine : null;
  const iAmBest = currentBid ? currentBid.BidderRef === myId && myId > 0 : false;
  const isFirstBid = currentBid ? currentBid.BidderRef === 0 : true;
  const isPending = stateItem ? stateItem.pending : false;
  const isFinal = stateItem ? stateItem.final : false;

  const [wasPending, setWasPending] = useState(isPending);
  const [openOutbidWarning, setOpenOutbidWarning] = useState(false);

  useEffect(() => {
    if(isPending) {
      setWasPending(true);
    }
  }, [isPending]);

  useEffect(() => {
    if(wasPending && !isPending)
    {
      setWasPending(false);
      if(!iAmBest)
      {
        setOpenOutbidWarning(true);
      }
    }
  }, [wasPending, isPending, iAmBest]);


  const iFollow = useSelector((state) => state.itemCache.follows.includes(productId));

  const absenteeBidCondition = (iAmBest && (currentBid.MaxValue > currentBid.Value));

  const compact = isWidthDown("xs", width);

  const handleFollow = (token) => {
    let url = `/follow/track/${productId}`;
    var config = {
      headers: { Authorization: "Bearer " + token },
    };
    dispatch(followItem(productId));
    Axios.get(url, config)
      .then(function (response) { })
      .catch(function (error) { });
  };

  const handleUnfollow = (token) => {
    let url = `/follow/untrack/${productId}`;
    var config = {
      headers: { Authorization: "Bearer " + token },
    };
    dispatch(unfollowItem(productId));
    Axios.get(url, config)
      .then(function (response) { })
      .catch(function (error) { });
  };

  function formatTime(time) {
    if (!time)
      return "";

    const date = time.endsWith("Z") ? new Date(time) : new Date(time + "Z");
    return format(date, "yyyy-MM-dd HH:mm:ss");
  }

  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {

    let url = `/view/product/${productId}`;
    var config = {
      headers: { Authorization: "Bearer " + token },
    };
    Axios.get(url, config)
      .then(function (response) {
        const data = response.data;
        dispatch(itemsUpdated(data.Updates, false));
        const serverTime = data.ServerTime.endsWith("Z") ? new Date(data.ServerTime) : new Date(data.ServerTime + "Z");
        dispatch(serverTimed(serverTime, new Date()));
        setData(data);
      })
      .catch(function (error) {
        setData(null);
        setShouldRedirect(true)

      });
    
  }, [productId, dispatch, token]);




  if (!data || !currentBid) {
    if (shouldRedirect) {
      return <Redirect to={`/${siteId}/detail/${auctionId}`}/>
    }
    return <ContentPlaceholder />;
  }

  const AuctionParams = () => {

    let nextBid = getGoodBids(currentBid.Value, 2 );
    const byDefaultNextBid = nextBid[1].value;

    const [bidValue, setBidValue] = useState(iAmBest ? currentBid.MaxValue : (isFirstBid ? currentBid.Value : byDefaultNextBid)); 
    const [showMore, setShowMore] = useState(false)

    const serverTimeDelta = useSelector((state) => state.itemCache.serverTimeDelta);
    const serverTime = () => {
      var t = new Date();
      return t.setSeconds(t.getSeconds() + serverTimeDelta);
    }

    const [currentTime, setCurrentTime] = useState(serverTime())
    const tooLate = calculateTimeLeft(deadline, currentTime) ? false : true; 
    const active = !iAmBest && !isPending && !tooLate; 
    const displayCurrentValue = (data.IsOnline && currentPrice && !data.FinalPrice && !isFirstBid); 
    const displayStartingPrice = (data.IsOnline && isFirstBid);



    const auctionEnded = data.IsOnline && deadline && tooLate; 

    const handleMore = () => {
      setShowMore((prev) => !prev);
    };

    const handleBid = (token) => {
      dispatch(itemPending(productId));
      let url = `/bid/${productId}/${bidValue}`;
      var config = {
        headers: { Authorization: "Bearer " + token },
      };

      Axios.get(url, config)
        .then(function (response) {
          dispatch(itemsUpdated(response.data, false));
        })
        .catch(function (error) { });
    };

    function displayFrom(allBids) {
      let showTop = 5
      if (showMore) {
        return (allBids.length)
      } else {
        return showTop
      }
    }


    useEffect(() => {
      setTimeout(() => {
        setCurrentTime(serverTime());
      }, 1000);
    });

    const [openBidConfirm, setOpenBidConfirm] = useState(false);
    const handleClickOpenBidConfirm = () => {
      setOpenBidConfirm(true);
    };
    const handleCloseBidConfirm = () => {
      setOpenBidConfirm(false);
    };
    
    const handleCloseOutbidWarning = () => {
      setOpenOutbidWarning(false)
    };

    const [showOnlinePlatforms, setShowOnlinePlatforms] = useState(false)
    const toggleOnlinePlatforms = () => setShowOnlinePlatforms(state => !state)
  


    return (<>

      {auctionEnded ? (
        <div className={classes.Fact}>
          <div className={classes.FactValue3}>
          {isFinal ? "the sale has ended." : "validating latest bids..." }
          </div>
        </div>
      ) : null}
      

      <div className={data.FinalPrice ? classes.Fact : classes.FactBold}>
        <div className={classes.FactLabel}>estimate: </div>
        <div className={classes.FactValue2}>
          &nbsp;&nbsp;
          {priceEstimation(data)}
        </div>
      </div>

      {displayStartingPrice ? (
        <div className={classes.FactBold}>
          <div className={classes.FactLabel}>start: </div>
          <div className={classes.FactValue}>
            {formatCurrency(currentPrice)}
          </div>
        </div>
      ) : null}

      {displayCurrentValue ? (
        <div className={classes.FactBold}>
          <div className={classes.FactLabel}>{auctionEnded ? "result: " : "current: "}</div>
          <div className={classes.FactValue}>
            {formatCurrency(currentPrice)} {stateItem.current.BidCount ? "(" + stateItem.current.BidCount + ")" :""}
          </div>
        </div>
      ) : null}

      {data.IsOnline && deadline && !tooLate ? (
        <div className={classes.FactBold}>
          <div className={classes.FactLabel}>time: </div>
          <div className={classes.FactValue2}>
            <Deadline 
              deadline={deadline} 
              currentTime={currentTime && currentTime}
            />
          </div>
        </div>
      ) : null}

      
      {data.FinalPrice ? (
        <div className={classes.FactBold}>
          <div className={classes.FactLabel}>result: </div>
          <div className={classes.FactValue}>
            {formatCurrency(data.FinalPrice)}
          </div>
        </div>
      ) : null}

      {data.IsOnline ? (
        <div className={classes.ActionGroup}>
          {iAmBest && 
            <>
              <div className={classes.Highest}>You are the highest bidder!</div>
              {(!tooLate) ? 
                <>
                {absenteeBidCondition ? 
                  <div className={classes.ActionExp}>
                  You entered an absentee bid, meaning we'll only bid as much as needed to keep you in the lead.
                  </div> : null}
                  <div className={classes.Action}>
                    <BidChooser
                      currentBid={currentBid.MaxValue-1}
                      defaultValue={currentBid.MaxValue}
                      onChange={setBidValue}
                    />
                    {(bidValue !== currentBid.MaxValue) &&
                    <Button
                      onClick={handleClickOpenBidConfirm}
                      style={{ marginLeft: "4px" }}
                      variant="outlined"
                      className={classes.StoreButton}
                    >
                      Increase&nbsp;my bid
                    </Button>}
                  </div>
                </> : null} 
            </>
          }

          {isPending ? (
            <div className={classes.ActionExp}>Please wait...</div>
          ) : null}

          {active ? (
            <div className={classes.Action}>
              <BidChooser 
                currentBid={isFirstBid ? currentBid.Value - 1 : currentBid.Value} 
                defaultValue={isFirstBid ? currentBid.Value : byDefaultNextBid}
                onChange={setBidValue} 
              />
              <Button
                onClick={handleClickOpenBidConfirm}
                style={{ marginLeft: "4px" }}
                variant="outlined"
                className={classes.StoreButton}
              >
                Place&nbsp;bid
              </Button>
            </div>
          ) : null}

          <Dialog open={openBidConfirm} onClose={handleCloseBidConfirm} aria-labelledby="form-dialog-title" >
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  Please confirm your bid ({formatCurrency(bidValue)}). We remind you that all bids are final.
                </Grid>
                <Grid item xs={12} className={classes.RightEndAlign}>
                  <Box>
                    <NBottomCancelButton onClick={handleCloseBidConfirm}>
                      Cancel
                    </NBottomCancelButton>
                    <StoreAuthenticatedButton onClick={handleBid} style={{ marginLeft: "4px" }}>
                      Place bid
                    </StoreAuthenticatedButton>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <Dialog open={openOutbidWarning} onClose={handleCloseOutbidWarning} aria-labelledby="form-dialog-title" >
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  Someone has outbid you. Do you want to place a higher bid ?
                </Grid>
                <Grid item xs={12} className={classes.RightEndAlign}>
                  <Box>
                    <NBottomCancelButton onClick={handleCloseOutbidWarning}>
                      Close
                    </NBottomCancelButton>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <div className={classes.Action}>
            {iFollow ? (
              <StoreAuthenticatedButton
                fullWidth={true}
                onClick={handleUnfollow}
              >
                Unfollow
              </StoreAuthenticatedButton>
            ) : (
                <StoreAuthenticatedButton fullWidth={true} onClick={handleFollow}>
                  Follow this object
                </StoreAuthenticatedButton>
              )}
          </div>
        </div>
      ) : null}

      {data.OnlinePlatforms.length !== 0 ? 
      <div className={classes.AuctionGroupOnlinePlatforms}>
        <div className={classes.ActionExp}>
            {cst.onlinePlatformStatementMsg}
          
        </div>
        <div className={classes.Action}>
          <Button variant="outlined" onClick={toggleOnlinePlatforms} className={classes.BidOnlineButton} fullWidth={true}>
            Bid Online
          </Button> 
        </div>
        <div className={classes.ActionExp}>
            Request telephone line for bidding: <Link href="mailto:info@native-auctions.com" color="inherit">info@native-auctions.com</Link>
        </div>
      </div>
      : null}

      <NModal size="small" showModal={showOnlinePlatforms} updateModalState={toggleOnlinePlatforms}>
        <ExternalOnlinePlatforms 
          OnlinePlatforms={data.OnlinePlatforms}
          onClose={toggleOnlinePlatforms}
        />
      </NModal>

      {data.IsOnline && allBids ? (
        <div className={classes.BiddingHistory}>
          <div className={classes.FactLabel}>Bidding history</div>

          {Object.keys(allBids)
            .sort(function (a, b) {
              let result = allBids[b].Value > allBids[a].Value ? 1 :
                (allBids[a].Value === allBids[b].Value ? parseInt(b) - parseInt(a) : -1);
              return result;
            })
            .slice(0, displayFrom(allBids))
            .map((item, i) => {
              return (<BidItem key={i} item={allBids[item]} />)
            })
          }

          {(Object.keys(allBids).length > 5) ?
            <div className={classes.MoreLabelFooter} onClick={handleMore}>
              {showMore ? 'Less' : 'More'}
            </div> : null}

        </div>
      ) : null}
    </>);
  }

  const Description = () => (
    <>
      {data.Description ? (
        <div
          className="styleContainer"
          dangerouslySetInnerHTML={{ __html: data.Description }}
        />
      ) : (
          ""
        )}
    </>
  );

  const BidItem = ({ item }) => {
    return (<div className={classes.BidItem}>
      {item.BidderRef ?
        (<div className={classes.BidHeader}>
          <div className={classes.Bidder}>
            Bidder&nbsp;{item.BidderRef} {item.BidderRef === myId ? " (You) " : null}
          </div>
          <div className={classes.BidTime}>
            {formatTime(item.Time)}
          </div>
        </div>) :
        (<div className={classes.BidHeader}>
          <div className={classes.Bidder}>
            Starting Price
          </div>
        </div>)}
      <div className={classes.BidValue}>{formatCurrency(item.Value)}</div>
    </div>)
  };

  return (
    <>
      <Helmet>
        <title>{`Lot n°${data.Order} \u202F ${data.Title}`}</title>

      </Helmet>

      <FixedWidthStoreContainer>
        <ToolbarPlaceHolder />
        <Grid container>
          <Grid item xs={12}>
            <Link
              component={RouterLink}
              to={{pathname:"/" + siteId + "/detail/" + auctionId, state:{scrollback:history.location.state && history.location.state.scrollforward}}}
              color="inherit"
              underline="none"
            >
              <div className={classes.PreHead}>
                <div className={classes.PreHeadText}>{data.AuctionTitle}</div>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} className={classes.Headline}>
            <div className={classes.HeadlineText}>{data.Title}</div>
            <div className={classes.HeadlineOrder}>
              <b>{data.Order}</b>
              <div className={classes.BigPlacement}>
                {"\u202F"}/{"\u202F"}
                {data.Total}
              </div>
            </div>
          </Grid>


          <Grid item xs={12} className={classes.Navigation}>
            
            <>
              {data.PreviousID ? (
                <Link
                  color="inherit"
                  underline="none"
                  component={RouterLink}
                  to={{pathname:"/" +
                  siteId +
                  "/detail/" +
                  auctionId +
                  "/product/" +
                  data.PreviousID, state:{scrollforward:history.location.state && history.location.state.scrollforward}}}
                >
                  <div className={classes.NavigationButton}>
                    <ArrowBackIcon className={classes.ArrowFooter} />
                    <div className={classes.NavigationText}>previous</div>
                  </div>
                </Link>
              ) : <div className={classes.FakePreviousSpace}>{"\u00A0"}</div>}
            </>

                <>
            <Link
                color="inherit"
                underline="none"
                component={RouterLink}
                to={{pathname:"/" + siteId + "/detail/" + auctionId, state:{scrollback:history.location.state && history.location.state.scrollforward}}}
            >
              <div className={classes.NavigationButton}>
                  back to list
              </div>
            </Link>
            </>

            <>
              {data.NextID ? (
                <Link
                  color="inherit"
                  underline="none"
                  component={RouterLink}
                  to={{pathname:"/" +
                  siteId +
                  "/detail/" +
                  auctionId +
                  "/product/" +
                  data.NextID, state:{scrollforward:history.location.state && history.location.state.scrollforward}}}
                >
                  <div className={classes.NavigationButton}>
                    <div className={classes.NavigationText}>next</div>
                    <ArrowForwardIcon className={classes.ArrowFooter} />
                  </div>
                </Link>
              ) :  <div className={classes.FakeNextSpace}>{"\u00A0"}</div>}
            </>

          </Grid>

          <Grid item container xs={12}>
            {compact ? (
              <>
                <Grid item xs={12} className={classes.ColumnLeft}>
                  <AuctionParams/>
                  <Gallery data={data.Images} />
                  <Description />
                </Grid>
              </>
            ) : (
                <>
                  <Grid item xs={12} sm={4} className={classes.ColumnLeft}>
                    <AuctionParams/>
                    <Description />
                  </Grid>
                  <Grid item xs={12} sm={8} className={classes.ColumnRight}>
                    <Gallery data={data.Images} />
                  </Grid>
                </>
              )}
          </Grid>
        </Grid>
      </FixedWidthStoreContainer>
    </>
  );
}

export default withWidth()(Product);