import * as actionTypes from "../constants/action_types";
import { saveDashboard } from "../actions/dashboard";
import { itemsUpdated } from "../actions/items";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { tabID } from "../actions/synctab";

let hubDashboardConnect = null;
let hubProductsConnect = null;

const setupDashboardHub = async (dispatch,keepGoing, getState) => {
    const token = getState().auth.token;
    hubDashboardConnect = new HubConnectionBuilder()
    .withUrl("/signalr/dashboard", { accessTokenFactory: () => token })
    .build();

    hubDashboardConnect.on("Update", (variable) => {
        dispatch(saveDashboard(variable.Key, variable.Value));
    });

    hubDashboardConnect.onclose(async () => {
        await loopstart(hubDashboardConnect, 2000,keepGoing,getState, onReconnectDashboard);
    });
    loopstart(hubDashboardConnect, 500, keepGoing, getState, onReconnectDashboard);
};

const onReconnectDashboard = (getState) =>
{
    hubDashboardConnect.invoke("Refresh");
}

const setupProductHub = async (dispatch,keepGoing,getState) => {
    hubProductsConnect = new HubConnectionBuilder()
    .withUrl("/signalr/product")
    .build();

    hubProductsConnect.on("Update", (items) => {
        dispatch(itemsUpdated(items,true));
    });

    hubProductsConnect.onclose(async () => {
        await loopstart(hubProductsConnect, 4000,keepGoing,getState, onReconnectProduct);
    });
    loopstart(hubProductsConnect, 4000, keepGoing, getState, onReconnectProduct);
};

const onReconnectProduct = (getState) =>
{
    const itemsCache = getState().itemCache;
    const serial = itemsCache && itemsCache.items && itemsCache.items.liveserial;
    const auth = getState().auth;
    const userId = auth && auth.userId;
    console.log("Connected to products, refreshing... " + serial + " " + userId);
    hubProductsConnect.invoke("Refresh", serial ? serial : 0, userId ? userId : null);
}

async function loopstart(hub, currentDelay, keepGoing, getState, whenDone) {
  if (keepGoing()) {
    try {
      await hub.start();
      whenDone(getState);
    } catch (err) {
      console.log(err);
      const newDelay = currentDelay < 60000 ? currentDelay * 2 : currentDelay;
      setTimeout(() => loopstart(hub, newDelay, keepGoing, getState,whenDone), currentDelay);
    }
  }
}

const transition = async (keepGoing, setHub, getHub, setupHub) => {
  if (getHub() !== null) {
    try {
      await getHub().stop();
    } catch {}
    setHub(null)
  }
  if (keepGoing()) {
      setupHub(keepGoing);
  }
};

let shouldConnect = false;
let shouldConnectDashboard = false;
const signalRMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  if (
    action.type === actionTypes.TABALIVE ||
    action.type === actionTypes.AUTHENTICATED ||
    action.type === actionTypes.UNAUTHENTICATED 
  ) {
    const isMain = getState().synctab.maintab === tabID;
    const isMainDashboard = isMain && getState().auth.role === "Admin";
    if (isMain !== shouldConnect) {
      shouldConnect = isMain;
      transition(() => shouldConnect, newHub => hubProductsConnect = newHub, () => hubProductsConnect, (keepGoing) => setupProductHub(dispatch,keepGoing, getState));
    }
    if(isMainDashboard !== shouldConnectDashboard) {
        shouldConnectDashboard = isMainDashboard;
        transition(() => shouldConnectDashboard, newHub => hubDashboardConnect = newHub, () => hubDashboardConnect, (keepGoing) => setupDashboardHub(dispatch,keepGoing, getState) );
    }
  }

  return next(action);
};

export default signalRMiddleware;
