import React from "react";
import { Link } from "@material-ui/core";
import RouterLink from "../../routes/RouterLink";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Crumbcontainer: {
    fontSize: "80%",
    fontFamily: theme.SecondaryFont,
    color: theme.ColorC2,
    display: "flex",
    alignItems: "center"
  },
  FullCrumb: {
    display: "flex",
    alignItems: "center"
  },
  Crumb: {
      display:"block"
  },
  Arrow: { // n/a
    display:"block",
    marginLeft: "10px",
    marginRight: "10px",
    height: "14px",
    width: "14px",
    color: theme.ColorC1,
  },
}));

export default function NBreadcrumb({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.Crumbcontainer}>
      {data.map((item, index) => (
          <div  key={"bread"+index}  className={classes.FullCrumb}>
        <div className={classes.Crumb}>
          <Link
            component={RouterLink}
            to={item.URL}
            color="inherit"
            underline="none"
          >
            {item.Label}
          </Link>
        </div>
        {item.Arrow ? `\u00A0 / \u00A0` : null}
        </div>
      ))}
    </div>
  );
}
