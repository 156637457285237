import React from 'react';

import { Editors } from "react-data-grid-addons"

const { SimpleTextEditor } = Editors;


export default class NumericEditor extends SimpleTextEditor {
  setInputRef = (input) => {
    this.input = input;
  };

  render() {
    return (<input ref={this.setInputRef} type="number" onBlur={this.props.onBlur} className="form-control" defaultValue={this.props.value} />);
  }
}