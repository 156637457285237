import "babel-polyfill";
import * as actionTypes from "../constants/action_types";
import { useDispatch, useSelector } from 'react-redux';

const variablesaved = (name, value) => ({
  type: actionTypes.VARIABLESAVED,
  payload: {[name]: value}
});

export const saveVariable = (name, value) => {
  return dispatch => {
    dispatch(variablesaved(name, value));
  };
};

export const useReduxPrefsState = (label, initValue) => 
{
  const dispatch = useDispatch();
  if(!useSelector(state => state.userprefs[label]))
  {
    dispatch(saveVariable(label,initValue));
  }

  return [useSelector(state => state.userprefs[label]),
          t=>dispatch(saveVariable(label,t))];
}


