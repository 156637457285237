import React, { useState } from 'react'
import { NModal } from './NAdminCustomComponents'
import { Paper, Grid, Link, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({


    PlatformPicture : {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: '60%',
    },

    PlatformBox : {
        height: "10vh",
        padding: theme.spacing(1/2),
        boxSizing: "border-box",
    },

    PlatformName : {
        padding : theme.spacing(1)
    },
    OnlinePlatformLink: {
        cursor: "pointer"
    },
    NoUrlAlertPaper : {
        padding: "24px", 
        width: "80%", 
        background: "rgba(255,255,255, 0.9)", 
        margin: "16px auto"
    },
    NoUrlAlertMsg : {
        padding: "0px 3px",
        fontSize: "90%",
        color: theme.ColorC2,
        textAlign: "center"
    },
    ChooseYourPlatform : {
        fontSize: "20px",
        fontWeight: "600",
        textAlign: "center",
        color: theme.ColorC2,
        padding: "8px 3px",
        margin: theme.spacing(1)
    },
    PaperStyle: {
        color: theme.ColorC2,
    },
}));

export default function ExternalOnlinePlatform ({OnlinePlatforms}) { 

    const classes = useStyles();
    const [showAlertNoURL, setShowAlertNoURL] = useState(false)
    const togglAlertNoURL = () => setShowAlertNoURL(state => !state)

    return (
        <>
            <Paper className={classes.PaperStyle}>
                    <div className={classes.ChooseYourPlatform}>
                        Choose your platform
                    </div>
                <Grid 
                    container 
                    justify="space-between"
                    direction="row"
                    alignItems="center"
                >   
                    
                    {OnlinePlatforms ? OnlinePlatforms.map((item, key) => (
                        <Grid item md={4} sm={3} xs={12} key={key} className={classes.PlatformBox}>  
                                <Link 
                                    href={item.URL}
                                    color="inherit"
                                    onClick={() => {!item.URL && togglAlertNoURL() }}
                                    className={classes.OnlinePlatformLink}
                                > 
                                
                                    <div 
                                        className={classes.PlatformPicture}
                                        alt={item.PlatformName}
                                        style={{
                                            backgroundImage: item.LogoURL ? `url(${item.LogoURL})` : null,
                                        }}
                                    />
                                    <Typography noWrap align="center" variant="body2" className={classes.PlatformName}>{item.PlatformName}</Typography>
                                </Link>
                        </Grid>
                    )) : null }
                </Grid>
            </Paper>

            <NModal size="small" showModal={showAlertNoURL} updateModalState={togglAlertNoURL}>
                <Paper className={classes.NoUrlAlertPaper}>
                        <div className={classes.NoUrlAlertMsg}>
                            The auction will be live on this platform in the coming days.
                            <br/>Please check back later.
                        </div>
                </Paper>
            </NModal>
        </>
    )
}