import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";

const DelayedScroll = (props) => {
  const scroll = props.scroll;

  
  
  useEffect(() => {
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function check() {
      let done = false;
      for (let i = 0; i < 10 && !done; i++) {
        await sleep(200);
        if(scroll < document.body.scrollHeight)
        {
          window.scrollTo(0,scroll);
          done = true;
        }
      }
    }
    check();
  }, [scroll]);
  

  
  return props.children;
};

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = { scroll: 0 };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      if (this.props.location.state && this.props.location.state.scrollback) {
        this.setState({
          scroll: this.props.location.state.scrollback,
        });
      } 
    }
  }
  
  render() {
    return <DelayedScroll scroll={this.state.scroll}>{this.props.children}</DelayedScroll>;
  }
}

export default withRouter(ScrollToTop);
