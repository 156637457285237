import React, { useRef, useState, useEffect } from "react";
import MaterialTable, { MTableToolbar, MTableBodyRow, MTableEditRow } from "material-table";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import BulkAddIcon from '@material-ui/icons/TableChart';
import AuctionPreviewIcon from '@material-ui/icons/DesktopMac';
import AuctionsIcon from "@material-ui/icons/Gavel";
import SectionsIcon from "@material-ui/icons/FormatListNumbered";
import { makeStylesGlobal } from "../../../themes/GlobalTheme";
import {
  NAdminToolBar,
  NAdminActiveButton,
  NAdminFilterBar
} from "../../Widget/NAdminCustomComponents";
import { fetchData, deleteModel } from "../../../js/utils/backend";
import { PRODUCTURL } from "../../../js/constants/model_cst";
import ProductsEditPanel from "../../EditPanels/ProductsEditPanel";
import { productThumbnail, StatusRenderer } from "../../Widget/TableEditors";
import Unassign from '@material-ui/icons/RemoveCircleOutline';
import Transfer from '@material-ui/icons/SwapHoriz';
import SetStatus from "@material-ui/icons/LocalOffer";
import * as cst from "../../../js/constants/miscellaneous_cst";
import { scrollTopFunction } from "../../../js/constants/functions_cst"


import Axios from "axios";
import { AuctionChooser } from "../../Widget/AuctionChooser";
import StatusFilter from "../../Widget/StatusFilter";
import { StatusChooser } from "../../Widget/StatusChooser";
import ReorderIcon from '@material-ui/icons/UnfoldMore';
import UnassignChooser from "../../Widget/UnassignChooser";
import { TablePagination } from "@material-ui/core";
import { useReduxPrefsState } from "../../../js/actions/prefs";
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { priceEstimation} from "../../../js/utils/formatter";
import {useHistoryState} from "../../../js/utils/customHooks";


const localStyle = makeStylesGlobal(theme => ({

}));



export default function Products({ auctionId, componentRefresher, auctionData, history, store }) {
  const classes = localStyle();
  const token = useSelector(state => state.auth.token);

  const tableRef = useRef();

  const [onSelect, setOnSelect] = useState(false);
  const [configActionDelete, setConfigActionDelete] = useState(true);
  const [displayEditPanel, setDisplayEditPanel] = useState(true);
  const [operation, setOperation] = useState("none");
  const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
  const [isTableEditing, setIsTableEditing] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [statusFilter, setStatusFilter] = useHistoryState("statusFilter",{
    label: "All",
    value: "All"
  });
  const [searchText, setSearchText] = useHistoryState("searchText","");
  const [currentPage, setCurrentPage] = useHistoryState("currentPage",0);
  const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn",auctionId ? "AuctionNumber" : "SerialNumber");
  const [sortingDir, setSortingDir] = useHistoryState("sortingDir",auctionId ? "asc" : "desc");
  const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
  const [isInit,setIsInit] = useState(true);

  const [auctionChoices, setAuctionChoices] = useState([]);


  const isGroup = (auctionData && auctionData.Status) ? ( (auctionData.Status === "Group") ? true : false ) : false;  

  const isAuctionBinded = auctionId ? true : false;

  const options = setOptions();

  function refreshTable() {
    if (tableRef && tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }

  const operationChange = newOperation => {
    switch (newOperation) {
      case "none":
        setConfigActionDelete(true);
        setDisplayEditPanel(true);
        setOnSelect(false);
        tableRef.current.onAllSelected(false);
        statusFilterChange({ value: "All", label: "All" });
        break;
      case "assign":
        setOnSelect(true);
        setConfigActionDelete(false);
        tableRef.current.onAllSelected(false);
        break;
      case "unassign":
        setOnSelect(true)
        setConfigActionDelete(false);
        tableRef.current.onAllSelected(false)
        break;
      case "transfer":
        setOnSelect(true)
        setConfigActionDelete(false);
        tableRef.current.onAllSelected(false)
        break;
      case "new":
        setConfigActionDelete(false);
        break;
      case "productStatus":
        setOnSelect(true);
        setConfigActionDelete(false);
        tableRef.current.onAllSelected(false);
        break;
      case "deleteRow":
        break;
      default:
        return null;
    }
    setOperation(newOperation);
  };

  function setOptions() {
    return [{ label: "All", value: "All" }, ...cst.productStatusses];
  }

  const handleAuction = event => {
    const choosenAuction = event.target.value;
    setSelectedAuction(choosenAuction);
  };
  const handleSubmitToAuction = () => {
    let url = cst.assignProductToAuction;
    let data = {
      AuctionId: selectedAuction,
      ProductIds: selectedRows,
      OriginalAuctionId: auctionId
    };
    var config = {
      headers: { Authorization: "Bearer " + token }
    };

    Axios.post(url, data, config).then();
  };

  const handleTransferToAuction = () => {
    let url = cst.transferProductToAuction;
    let data = { 
      AuctionId: selectedAuction,
      ProductIds: selectedRows,
      OriginalAuctionId: auctionId
    };
    var config = {
      headers: { Authorization: "Bearer " + token }
    };

    Axios.post(url, data, config).then(refreshTable());
  };
  

  useEffect(() => {
    let cancelToken = Axios.CancelToken.source();
    const fetchData = () => {
      let url = cst.getAuctionChooser;
      var config = {
        cancelToken: cancelToken.token,
        headers: { Authorization: "Bearer " + token }
      };
      Axios.get(url, config).then(response => {
        let array = response.data;

        let convertArray = array.map(option => ({
          value: option.ID,
          label: option.Title + ( option.Status === "Group" ? ` [GROUP] ` : ` (${parseInt(option.EndDate)}) ` ) 
        }));
        setAuctionChoices(convertArray);
      });
    };
    fetchData();

    return () => {
      cancelToken.cancel();
    };
  }, [token]);

  function statusFilterChange(status) {
    setStatusFilter(status);
    tableRef.current.onAllSelected(false);
    refreshTable();
  }

  function makeName(user) {
    if (user) {
      const first = user.FirstName ? user.FirstName : "";
      const last = user.LastName ? " " + user.LastName : "";
      return first + last;
    }
  }



  
  
  var currentQuery = null;
  const exportCSV = () => {
    let orderedQuery = currentQuery ? `&orderBy=${currentQuery.orderBy.field}&dir=${currentQuery.orderDirection}` : "&orderBy=AuctionNumber&dir=asc"
    let type = 'text/csv;charset=utf-8;'
    let url = `/product/csv?filter[auction]=${auctionId}${orderedQuery}`
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.get(url, config)
    .then((response) => {
      let fileName = response.headers["content-disposition"].split("filename")[1]
      fileName = fileName.slice(1, fileName.length - 2)
      var encodedUri = window.URL.createObjectURL(new Blob([response.data], { type: type }))
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click()
      document.body.removeChild(link);
    })
  }  


  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialTable
          detailPanel={
            displayEditPanel
              ? rowData => {
                return (
                  <div>
                    <ProductsEditPanel
                      Data={
                        rowData.Description === null
                          ? { ...rowData, Description: "" }
                          : rowData
                      }
                      onClose={() => {
                        refreshTable();
                        componentRefresher();
                      }}
                      isNew={false}
                      isAuctionBinded={isAuctionBinded}
                      isOnline={store === 2}
                      isGroup={isGroup}
                    />
                  </div>
                );
              }
              : null
          }
          tableRef={tableRef}
          columns={[
            {
              title: auctionId ? "Lot" : "#",
              field: auctionId ? "AuctionNumber" : "SerialNumber",
              defaultSort: getSort(auctionId ? "AuctionNumber" : "SerialNumber")
            },
            {
              title: "Image",
              field: "ImageID",
              cellStyle: { width: "10%", padding: 0 },
              sorting: false,
              render: productThumbnail
            },
            {
              title: "Title",
              field: "Title",
              defaultSort: getSort("Title"), customSort: (a, b) => null
            },
            {
              title: "Seller",
              field: "OriginalSeller",
              render: rowData => makeName(rowData && rowData.OriginalSeller),
              defaultSort: getSort("OriginalSeller"), customSort: (a, b) => null
            },
            {
              title: "Estimated",
              field: "EstimatedLow",
              render: priceEstimation,
              defaultSort: getSort("EstimatedLow")
            },
            {
              title: "Buyer",
              field: "OriginalBuyer",
              render: rowData => makeName(rowData && rowData.OriginalBuyer),
              sorting: false,
              hidden: auctionId && !isGroup ? false : true
            },
            {
              title: "Status",
              field: "Status",
              sorting: false,
              headerStyle: { textAlign: "center" },
              render: rowData =>
                <div style={{ textAlign: "center" }}> {StatusRenderer(rowData.Status, cst.productStatusses)} </div>
            }
          ]}
          actions={[
            (rowData) => (auctionId ? {
              icon: () => <><AuctionPreviewIcon /></>,
              onClick: (event, rowData) => { history.push("/auctions/detail/" + auctionId + "/product/" + rowData.CorrespondingAssignment) },
              tooltip: 'Preview final result'
            } : null)
          ]}
          data={query => {
            currentQuery = query
            let url = PRODUCTURL;
            let fetchOptions =
              statusFilter.label === "All"
                ? ""
                : "&filter[status]=" + statusFilter.value;
            fetchOptions += auctionId ? "&filter[auction]=" + auctionId : "";

            const effectivePage = isInit ? currentPage : query.page;
            setSearchText(query.search,true);
            setSortingColumn(query.orderBy && query.orderBy.field);
            setSortingDir(query.orderDirection);
            setCurrentPage(effectivePage);
            setIsInit(false)
            return fetchData(token, {...query,page: effectivePage}, url, fetchOptions);
          }}
          options={{
            emptyRowsWhenPaging: false,
            detailPanelType: "single",
            sorting: true,
            draggable: false,
            pageSize: rows,
            pageSizeOptions: cst.itemsPerPage,
            actionsColumnIndex: 50,
            addRowPosition: "first",
            selection: onSelect,
            showTextRowsSelected: false,
            searchText: searchText,
            selectionProps: rowData => ({
              color: "primary"
            })
          }}
          onChangeRowsPerPage={(nbRows) => setRows(nbRows)}
          editable={{
            onRowDelete: configActionDelete && !auctionId
              ? oldData => deleteModel(oldData.ID, token, PRODUCTURL)
              : null
          }}
          onSelectionChange={rowData => {
            let guids;
            guids = rowData.map(row => row.ID);
            setSelectedRows(guids);
          }}
          title="Objects"
          components={{
            Row: props => { setIsTableEditing(props.hasAnyEditingRow); return (<MTableBodyRow {...props} />) },
            EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />) },
            Pagination: props => (<TablePagination {...props} className={isTableEditing || selectedRows.length ? classes.disabled : null} />),
            Toolbar: props => (
              <div>
                <div className={isTableEditing || selectedRows.length ? classes.disabled : null}>
                  <MTableToolbar {...props} />
                </div>
                <div className={isTableEditing ? classes.disabled : null}>
                  <NAdminToolBar invisible={operation === "new"}>
                    {operation === "none" && (
                      <>
                        {!auctionId && (
                          <>
                            <NAdminActiveButton
                              startIcon={<AddIcon />}
                              onClick={() => {
                                operationChange("new");
                              }}
                            >
                              New
                          </NAdminActiveButton>
                            <NAdminActiveButton
                              startIcon={<BulkAddIcon />}
                              onClick={() => {
                                history.push("/admin/products/bulk")
                              }}
                            >
                              Bulk encode
                          </NAdminActiveButton>
                            <NAdminActiveButton
                              startIcon={<AuctionsIcon />}
                              onClick={() => {
                                operationChange("assign");
                              }}
                            >
                              Assign
                          </NAdminActiveButton>
                          </>
                        )}

                        {auctionId && (
                          <>
                            <NAdminActiveButton
                              startIcon={<ReorderIcon />}
                              onClick={() => {
                                operationChange("reorder");
                                history.push(`/admin/auction/${auctionId}/products/reorder`)
                              }}
                            >
                              Reorder
                            </NAdminActiveButton>

                            

                            <NAdminActiveButton
                              startIcon={<Unassign />}
                              onClick={() => {
                                operationChange("unassign")
                              }}
                            >
                              Unassign
                            </NAdminActiveButton>

                            {isGroup ? 
                              <><NAdminActiveButton
                                startIcon={<AuctionsIcon />}
                                onClick={() => {
                                  operationChange("assign")
                                }}
                              >
                                Assign
                              </NAdminActiveButton>
                              <NAdminActiveButton
                              startIcon={<Transfer />}
                              onClick={() => {
                                operationChange("transfer")
                              }}
                            >
                              Transfer
                            </NAdminActiveButton></>

                              : 
                              <NAdminActiveButton
                                startIcon={<SectionsIcon />}
                                onClick={() => {
                                  operationChange("reorder");
                                  history.push(`/admin/auction/${auctionId}/sections`)
                                }}
                              >
                                Sections
                              </NAdminActiveButton>
                            }

                            <NAdminActiveButton
                              onClick={exportCSV}
                              startIcon={<SaveAltIcon/>}
                            >
                              Export selection to CSV
                            </NAdminActiveButton>
                          </>
                        )}
                        <NAdminActiveButton
                          startIcon={<SetStatus />}
                          onClick={() => {
                            operationChange("productStatus");
                          }}
                        >
                          Status
                      </NAdminActiveButton>
                      </>
                    )}

                    {
                      operation === "unassign" && (
                        <UnassignChooser
                          onSubmit={() => {
                            refreshTable()
                            setSelectedRows([])
                          }}
                          onCancel={() => {
                            operationChange("none");
                          }}
                          selectedRows={selectedRows}
                          auctionId={auctionId}
                        />
                      )
                    }

                    {operation === "productStatus" && (
                      <>
                        <StatusChooser
                          statusCategories={cst.manualProductStatusses}
                          onCancel={() => {
                            operationChange("none");
                          }}
                          statusUrl={cst.setProductStatus}
                          selectedRows={selectedRows}
                          onSubmit={() => {
                            refreshTable();
                            setSelectedRows([])
                          }}
                        />
                      </>
                    )}

                    {operation === "assign" && (
                      <AuctionChooser
                        value={selectedAuction}
                        onCancel={() => {
                          operationChange("none");
                          tableRef.current.onAllSelected(false);
                        }}
                        auctionChoices={auctionChoices}
                        selectedRows={selectedRows}
                        onSaveAuction={
                          selectedAuction !== "" && selectedRows.length > 0
                            ? () => {
                              operationChange("none");
                              handleSubmitToAuction();
                              refreshTable();
                            }
                            : null
                        }
                        onValueChange={handleAuction}
                        isDisabled={false}
                      />
                    )}
                    {operation === "transfer" && (
                      <AuctionChooser
                        value={selectedAuction}
                        onCancel={() => {
                          operationChange("none");
                          tableRef.current.onAllSelected(false);
                        }}
                        auctionChoices={auctionChoices}
                        selectedRows={selectedRows}
                        actionLabel={"Transfer"}
                        onSaveAuction={
                          selectedAuction !== "" && selectedRows.length > 0
                            ? () => {
                              operationChange("none");
                              handleTransferToAuction();
                              refreshTable();
                            }
                            : null
                        }
                        onValueChange={handleAuction}
                        isDisabled={false}
                      />
                    )}

                    {operation === "new" && (
                      <Paper className={classes.NVisiblePaper}>
                        <ProductsEditPanel
                          Data={{
                            Description: "",
                            OfficialImagesID: [],
                            InternalImagesID: []
                          }}
                          onClose={() => {
                            operationChange("none");
                            refreshTable();
                            scrollTopFunction();

                          }}
                          isNew={true}
                          isAuctionBinded={isAuctionBinded}
                        />
                      </Paper>
                    )}
                  </NAdminToolBar>
                </div>
                <NAdminFilterBar>
                  <StatusFilter
                    value={statusFilter.label}
                    onChange={statusFilterChange}
                    options={options}
                    className={isTableEditing || selectedRows.length ? classes.disabled : null}
                  />
                </NAdminFilterBar>
              </div>
            )
          }}
        />
      </Grid>
    </Grid>
  );
}
