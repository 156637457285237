import React from 'react';
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { tableColumnStyle } from "../../pdf_cst"

const styles = StyleSheet.create({ ...tableColumnStyle });

const PhoneRequestTableHeader = () => (
    <View style={styles.headerContainer}>
        <Text style={styles.item15}>Operator</Text>
        <Text style={styles.item5}>Lot</Text>
        <Text style={styles.item30}>Title</Text>
        <Text style={styles.item20}>Bidder</Text>
        <Text style={styles.itemPhoneH}>Official Phone</Text>
        <Text style={styles.itemPhoneH}>Requested Phone</Text>
    </View>
)

export default PhoneRequestTableHeader;