import React from 'react';
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { tableColumnStyle } from "../../pdf_cst"

const styles = StyleSheet.create({ ...tableColumnStyle });

const ListingTableHeader = ({ type, resultName }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.auctionNumber}></Text>
        <Text style={styles.thumbnailImage}></Text>
        <Text style={type === "results" ? styles.listingItemDescriptionShort : styles.listingItemDescription}>Description</Text>
        <Text style={styles.estimate}>Estimates</Text>
        {type === "results" ?
            <Text style={styles.result}>{resultName}</Text>
            : null}
    </View>
)

export default ListingTableHeader;