import React from 'react'
import OwnBillings from './OwnBillings'
import * as cst from '../../../js/constants/miscellaneous_cst';


function Billing({ history }) {
    return (
        <>
            <OwnBillings type={cst.invoice} history={history} />
            <OwnBillings type={cst.settlement} history={history} />
        </>
    )
}

export default Billing
