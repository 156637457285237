import React from 'react'
import { Page, Document, StyleSheet, View, Text } from '@react-pdf/renderer';
import Title from '../ContentLayout/Common/Title';
import BillDetails from '../ContentLayout/Billing/BillDetails';
import ContactDetails from '../ContentLayout/Common/ContactDetails';
import PayInformations from '../ContentLayout/Common/PayInformations';
import BillItemsTable from '../ContentLayout/Billing/BillItemsTable';
import { invoice } from '../../../js/constants/miscellaneous_cst';
import { tableColumnStyle } from '../pdf_cst';

const styles = StyleSheet.create(tableColumnStyle);

export default function InvoicePDF({ billData }) {
    let type = invoice
    let hideVAT = !billData.IsWithVATonCommission && billData.VATTotal === 0
    return (
        <Document title={'Native-' + billData.Number + ".pdf"}>
            <Page 
                size="A4" // {[595.28, 841.89]}  
                style={styles.page}
            >
                <View>
                    <Title title={"Native"} />
                    <ContactDetails data={billData} />
                    <BillDetails bill={billData} type={type} />
                    <BillItemsTable hideVAT={hideVAT} bill={billData} type={type} />

                    {hideVAT ?
                    <Text style={styles.IsArticle44}>
                            Invoice Margin scheme (art. 5, 10°ter AR n° 1). No documents specifying VAT will be issued. {"\n"}
                            Premium all taxes included.
                    </Text>
                        : null}
                    {billData.IsArticle44 && !hideVAT ?
                        <Text style={styles.IsArticle44}>
                            AUCUNE TVA BELGE - ARTICLE 21§2 CODE TVA / ARTICLE 44 DIRECTIVE 2006/12/CEE.
                        </Text>
                        : null}
                </View>
                <View fixed style={styles.PayInformationsBlock}>
                    <PayInformations />
                </View>
                <Text 
                    fixed
                    style={styles.PageNumber} 
                    render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} 
                />
            </Page>
        </Document >
    )
}
