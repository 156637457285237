import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStylesGlobal } from "../../../themes/GlobalTheme";
import { Paper, Grid, Typography, ButtonBase } from "@material-ui/core";
import MasonryListing from "../../Widget/MasonryListing";
import Axios from "axios";
import { NDataLoading, NAdminToolbarCancelButton,NAdminActiveButton } from "../../Widget/NAdminCustomComponents"
import { AUCTIONSURL } from "../../../js/constants/model_cst.js"

const gutter = 3;
const useStyles = makeStylesGlobal((theme) => ({
  Paper: {
    padding: "10px",
  },
  Image: {
    position: "absolute",
    left: gutter,
    right: gutter,
    top: gutter,
    bottom: gutter,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    border: "1px solid rgba(224, 224, 224, 0.9)",
  },
  Banner: {
    position: "absolute",
    left: gutter,
    right: gutter,
    top: gutter,
    bottom: "auto",
    background: "rgba(224, 224, 224, 0.5)",
    fontSize: "110%"
  },
  Blurred: {
    position: "absolute",
    left: gutter,
    right: gutter,
    top: gutter,
    bottom: gutter,
    background: "rgba(255, 255, 255, 0.8)"
  },
  InnerBorder: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    left: 3,
    right: 3,
    top: 3,
    bottom: 3,
    border: "3px dashed rgba(70, 70, 70, 0.7)",
    borderRadius: "5px",
    fontSize: "200%",
  },
  FullButton: {
    padding: 0,
    margin: 0,
    width: "100%",
    height: "100%"
  },
  backButton:{
    textAlign: "right"
  }
}));

export default function AuctionReorder({ match, history, ...props }) {
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  const auctionId = match && match.params && match.params.auctionId;

  const [data, setData] = useState(null);
  const [selectedCellIdx, setSelectedCellIdx] = useState(-1);

  function getData(t, aId) {
    let config = {
      headers: {
        Authorization: "Bearer " + t,
      },
    };
    Axios.get(`/assignment/auction/${aId}`, config).then((response) => {
      setData(response.data);
      setSelectedCellIdx(-1);
    });
  };

  useEffect(() => {
    getData(token, auctionId);
  }, [token, auctionId]);


  const ReorderMassonryCell = ({ item, selectedCellIdx, index }) => {
    if (!item)
      return null;
    const url = item.ImageID ? `url(/Image/thumbnail/${item.ImageID})` : "none";
    const selected = selectedCellIdx === index;
    const blurred = !selected && selectedCellIdx !== -1;

    const handleClick = (e) => {
      if (selectedCellIdx === index) {
        setSelectedCellIdx(-1)
      }
      else {
        if (selectedCellIdx === -1) {
          setSelectedCellIdx(index);
        }
        else {
          moveCell(data[selectedCellIdx].ID, data[index].Order);
        }
      }
    };

    return (

      <ButtonBase focusRipple className={classes.FullButton} onClick={handleClick}>
        <span style={{ backgroundImage: url }} className={classes.Image} ></span>
        {blurred ?
          <span className={classes.Blurred}>
            <span className={classes.InnerBorder}>
              {item.Order}
            </span>
          </span>
          :

          <span className={classes.Banner}>{item.Order}</span>

        }
      </ButtonBase>

    );
  };

  const moveCell = (id, order) => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    Axios.get(`/assignment/reorder/${id}/${order}`, config).then(() => {
      getData(token, auctionId);
    });
  };

  const shuffle = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    Axios.get(`/assignment/shuffle/${auctionId}`, config).then(() => {
      getData(token, auctionId);
    });
  };

  return (
    <>
      <Paper className={classes.Paper}>
        <Grid container spacing={1}>
          <Grid item xs={8} style={{verticalAlign: "middle"}}>
            <Typography variant="h6">Assign Object Order in Auction</Typography>
          </Grid>
          <Grid item xs={4} className={classes.backButton} >
            <NAdminActiveButton className={""} onClick={shuffle}> SHUFFLE </NAdminActiveButton>
            &nbsp;
            <NAdminToolbarCancelButton className={""} onClick={() => history.push(`/admin${AUCTIONSURL}/${auctionId}/products`)}> DONE </NAdminToolbarCancelButton>
          </Grid>
          <Grid item xs={12}>
            {data ? (
              <MasonryListing
                data={{items: data, separators: null}}
                component={ReorderMassonryCell}
                itemProps={{ selectedCellIdx: selectedCellIdx }}
                cellWidth={100}
                cellHeight={140}
              />
            ) : (
                <NDataLoading />
              )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
