import React from "react";
import { Paper, Link, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RouterLink from "../../routes/RouterLink";
import { FixedWidthStoreContainer } from "../../layouts/StoreLayout";
import * as cst from "../../js/constants/miscellaneous_cst"

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles((theme) => ({
  Footer: {
    paddingLeft:0,
    paddingRight:0,
    paddingTop:"8px",
    paddingBottom:"8px",
    flexGrow: 1,
    background: theme.FooterColor,
    color: theme.FooterText,
    border: "none",
    fontSize: "90%",
  },
  ItemHead: {
    fontWeight: 600,
  },
  ItemLinkData: {},
  ItemLink: {
    display: "flex",
    alignItems: "center",
  },
  ItemGroup: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  Frame: {
    borderTop: "solid 1px",
    borderBottom: "solid 1px",
    borderTopColor: "#FFF3",
    borderBottomColor: "#FFF3",
  },
  Copyright: {
    textAlign: "right",
  },
  DisplaySocialMedia : {
    display: "flex",
  },
  SocialMediaPosition : {
    marginTop: theme.spacing(1), 
    marginRight: theme.spacing(1)
  }
}));

export default function Footer({ site }) {
  const classes = useStyles();

  const ItemHead = ({ children }) => {
    return <div className={classes.ItemHead}>{children}</div>;
  };

  const ItemLink = ({ to, children }) => {
    return (
      <Link component={RouterLink} color="inherit" underline="hover" to={to}>
        <div className={classes.ItemLink}>
          <div className={classes.ItemLinkData}>{children}</div>
        </div>
      </Link>
    );
  };

  return (
    <Paper className={classes.Footer} elevation={0}>
      <FixedWidthStoreContainer>
        <Grid container className={classes.Frame}>
            <Grid item xs={12} sm={6} md={4}>
              <div className={classes.ItemGroup}>
                <ItemHead>Contact</ItemHead>
                Native SPRL/BVBA
                <br/>
                Galerie de la rue de Ruysbroeck 5<br />
                1000 Brussels
                <br/>
                Belgium
                <br/>
                <br/>
                <Link href="mailto:info@native-auctions.com" color="inherit">info@native-auctions.com</Link>
                <br/>
                +32 (0) 2 514 04 42
                <br/>
                TVA BE0835325693
              </div>
            </Grid>
            
            {site === "upcoming" ? null : 
            
            <Grid item xs={12} sm={6} md={3}>
            <div className={classes.ItemGroup}>
              <ItemHead>How we work</ItemHead>
              <ItemLink to={`/${site}/terms`}>Terms and Conditions</ItemLink>
              <ItemLink to={`/${site}/faq`}>FAQ</ItemLink>
            </div>
            </Grid>}

            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.ItemGroup}>
                <ItemHead>My account</ItemHead>
                <ItemLink to={`/profile/account`}>Profile</ItemLink>
                <ItemLink to={`/profile/bid`}>Bids</ItemLink>
                <ItemLink to={`/profile/follow`}>Follows</ItemLink>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <div className={classes.ItemGroup}>
                  <ItemHead>Social Media</ItemHead>
                  <Box className={classes.DisplaySocialMedia}>
                    <Link 
                      href={cst.facebookLink}
                      color="inherit"
                      underline="none"
                      className={classes.SocialMediaPosition}
                    >
                      <FacebookIcon fontSize="large"/>
                    </Link>
                    <Link
                      href={cst.instagramLink}
                      color="inherit"
                      underline="none"
                      className={classes.SocialMediaPosition}
                    >
                      <InstagramIcon fontSize="large"/>
                    </Link>
                  </Box>
                </div>
              </Grid>
          </Grid>

        <div className={classes.Copyright}>
          © {new Date().getFullYear()} Native SPRL
        </div>
      </FixedWidthStoreContainer>
    </Paper>
  );
}
