import React, { useState } from "react";
import { NativeGeneric } from "../components/Widget/NativeStyleds";
import { makeStyles, useTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  Grid,
  Hidden,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ProfileRouter from "../routes/ProfileRouter";
import ProfileMenu from "../components/Profile/ProfileMenu";
import AccountMenu from "../components/Account/AccountMenu";

import ProfileTheme from "../themes/ProfileTheme";

const drawerWidth = 150;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFF",
    borderBottomStyle: "solid",
    borderBottomColor: theme.ColorGold,
    borderBottomWidth: "1px",
  },
  toolbarBox: {
    padding: "8px 10px 3px 10px",
    color: theme.ColorBlue,
  },
  icon: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    height: "30px",
    width: "30px",
    marginRight: "10px",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    background:"white",
    padding: 9,
    width: "100%",
    minHeight: "100vh",
  },
  burger: {
    height: "36px",
    marginRight: "30px",
  },
  logo: {
    color: theme.ColorGold,
    fontFamily: theme.PrimaryFont,
    fontSize: "30px",
    lineHeight: "36px",
    marginRight: "30px",
  },
  mojo: {
    textTransform: "uppercase",
    fontSize: "18px",
    lineHeight: "36px",
    flexGrow: 1,
  },
}));

export default function ProfileLayout({ container }) {
  const classes = useStyles();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={(theme) =>
      ProfileTheme(
        theme,
        theme.ColorBlue
      )}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar} elevation={0}>
          <Toolbar className={classes.toolbarBox}>
            <Grid item container alignItems="center">
              <Grid item style={{ cursor: "pointer" }}>
                <MenuIcon
                  className={classes.icon}
                  onClick={handleDrawerToggle}
                />
              </Grid>
              <Grid item className={classes.logo}>
                <NativeGeneric />
              </Grid>
              <Grid item className={classes.mojo}>
                &nbsp;
              </Grid>
              <AccountMenu />
            </Grid>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <ProfileMenu />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <div className={classes.toolbar} />
              <ProfileMenu />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Box height="100%">
            <div className={classes.toolbar} />
            <ProfileRouter />
          </Box>
        </main>
      </div>
    </ThemeProvider>
  );
}
