//Fundamentals
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';

//Visuals
import './index.css';
import './fonts/bauerbodonistd-bold.otf';
import './fonts/bauerbodonistd-bolditalic.otf';
import './fonts/bauerbodonistd-italic.otf';
import './fonts/bauerbodonistd-roman.otf';

//Redux
import { Provider } from "react-redux";
import { store, persistor } from './js/store/index';


ReactDOM.render(
<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <App />
  </PersistGate>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
