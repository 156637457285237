import React, {useState} from 'react'
import RouterLink from '../../routes/RouterLink'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Box, useTheme, Typography, Link } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStylesGlobal } from "../../themes/GlobalTheme";

import { Redirect } from 'react-router-dom';
import { NError } from '../Widget/NativeStyleds';

import { NTypoSmallTitle, NFullGridCentered } from "../Widget/NativeStyleds";
import { VTextField } from '../Widget/CustomVInputs';

import { useDispatch } from 'react-redux';
import { authenticated } from '../../js/actions/auth';

import { emailRegex, msgEmailInvalid, msgPasswordInvalid, msgPasswordUnmatching, passwordRegex } from '../../js/constants/miscellaneous_cst'

import { useForm, FormContext } from 'react-hook-form';
import Axios from 'axios';

const localStyle = makeStylesGlobal(theme => ({
}));

const ChangePassword = () => { 

    const classes = localStyle();
    const varUseTheme = useTheme().ColorBlue;

    const dispatch = useDispatch();

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })
    const {watch, handleSubmit} = methods;


    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [submitError, setSubmitError] = useState(null);

    const onSubmit = data => {

        const url = `/user/password`
        const passwordData = {
            "username" : data.username ,
            "oldPassword" : data.oldPassword ,
            "password": data.password
        }
        
        Axios.post(url, passwordData) 
            .then(response => {    
                if (response.data.IsLogged === true) {
                    dispatch(authenticated(
                        response.data.IsValidated,
                        response.data.Token,
                        response.data.Role, 
                        response.data.DisplayName,
                        response.data.BidderId, 
                        response.data.UserId
                    ));
                }
                
                setRedirectToLogin(true)

            })
            .catch(error => {
                if(error.response && error.response.data && error.response.data.message) {
                    setSubmitError(error.response.data.message);
                } else {
                    setSubmitError("Impossible to change password now, please try again later.")
                }
            })
    }

    if(redirectToLogin === true) {
        return <Redirect component={RouterLink} to="/login" />
    }

    return (
        <NFullGridCentered>
            <ThemeProvider
                    theme={theme =>
                        createMuiTheme({
                            ...theme,
                            ActiveColor: "white",
                            BackgroundColor: theme.ColorBlue
                        })
                    }
            >
                <Box
                    width={300}
                    boxShadow={3}
                    className={classes.NDropBox}
                    bgcolor={varUseTheme}
                >
                    <FormContext {...methods}>
                        <Grid container item spacing={1}
                        >
                            <Grid item xs={12}>
                                <NTypoSmallTitle className={classes.NSecHeader}>Change your password</NTypoSmallTitle>
                            </Grid>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12}>
                                        <VTextField
                                            autoComplete="new-password"                                    
                                            placeholder="E-mail"
                                            fieldName="username"
                                            validation={{
                                                required: true,
                                                pattern: {
                                                    value: emailRegex,
                                                    message: msgEmailInvalid
                                                }}
                                            }
                                            className={classes.NTextField}
                                            InputProps={{
                                            className: classes.NTextFieldInput
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VTextField
                                            placeholder="Old Password"
                                            fieldName="oldPassword"
                                            type="password"
                                            validation={{
                                                required: 'Type here your old password',
                                                pattern: {
                                                    message: msgPasswordInvalid
                                                }
                                            }}
                                            
                                            className={classes.NTextField}
                                            InputProps={{
                                                className: classes.NTextFieldInput
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Box mx={1} >
                                        <Typography variant="caption">
                                            <Link component={RouterLink} to="/reset-password">Forgot Password ?</Link>
                                        </Typography>
                                    </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VTextField
                                            placeholder="New Password"
                                            fieldName="password"
                                            type="password"
                                            validation={{
                                                required: 'Type here your new password',
                                                pattern: {
                                                    value: passwordRegex,
                                                    message: msgPasswordInvalid
                                                }
                                            }}
                                            
                                            className={classes.NTextField}
                                            InputProps={{
                                                className: classes.NTextFieldInput
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <VTextField
                                            placeholder="Confirm New Password"
                                            fieldName="ConfirmPassword"
                                            type="password"
                                            validation={{
                                                required: true,
                                                validate: (value) => {return value === watch('password') ? true : msgPasswordUnmatching ;},
                                                pattern: {
                                                    message: msgPasswordUnmatching
                                                }}
                                            }
                                            className={classes.NTextField}
                                            InputProps={{
                                                className: classes.NTextFieldInput
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {submitError ? <NError >{submitError}</NError> : null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box  >
                                            <Button 
                                                fullWidth={true} 
                                                className={classes.NButton}                                        
                                                type="submit"
                                            >
                                                Change my password
                                            </Button>     
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </FormContext>
                </Box>
            </ThemeProvider>
        </NFullGridCentered>
    );
}

export default ChangePassword;