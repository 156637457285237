import React from "react";
import Faq from "../components/Store/Faq";
import { Switch, Route, Redirect } from "react-router-dom";
import Upcoming from "../components/Store/Upcoming";
import Product from "../components/Store/Product";
import Auction from "../components/Store/Auction";
import TermsConditions from "../components/Store/TermsConditions";



const StoreRouter = () => {

    return (
        <Switch>
            <Route exact path={`/:siteId/faq`} component={Faq} />
            <Route exact path={`/:siteId/terms`} component={TermsConditions} />
            <Route exact path={`/:siteId/detail/:auctionId`} component={Auction}/>
            <Route exact path={`/:siteId/detail/:auctionId/product/:productId`} component={Product}/>
            <Route exact path="/upcoming" component={Upcoming} /> 
            <Route exact path="/draft" render={(props) => <Upcoming {...props} draft={true} />}/>
            <Redirect from="/" to="/upcoming"/>
        </Switch>
    )
}

export default StoreRouter