import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Typography } from "@material-ui/core";
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {TermsConditionsContent} from './TermsConditionsContent';
import Link from "@material-ui/core/Link";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  Headline: {
    fontSize: "200%",
    fontFamily: theme.PrimaryFont,
    color: theme.ColorC1,
    textTransform: "uppercase",
    paddingTop: "10px",
    paddingBottlm: "10px",
    borderBottom: "solid 1px",
    borderBottomColor: theme.ColorC1 + "44",
  },
  TermsSentence : {
    cursor: "pointer",
  }

}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function TermsConditionsModal(){
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography className={classes.TermsSentence}  onClick={handleClickOpen}> I have read and agree to the Native's <Link underline={'always'}>Terms and Conditions</Link></Typography>
      <Dialog onClose={handleClose} open={open}>
        <DialogContent >
          <Grid item xs={12}>
            <Typography className={classes.Headline}>TERMS OF SALES</Typography>
          </Grid>
          <TermsConditionsContent/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}