import React, { useEffect } from "react";
import { TextField, Button, Typography, Grid } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { useDispatch, useSelector } from 'react-redux';
import { authorize, reseterror } from "../../js/actions/auth";
import { NError } from "../Widget/NativeStyleds";
import RouterLink from "../../routes/RouterLink";
import Link from "@material-ui/core/Link";

const localStyle = makeStylesGlobal(theme => ({

}));

export default function LoginForm() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const classes = localStyle();
  const dispatch = useDispatch();

  const lastError = useSelector(state => state.auth.errorMessage);

  const handleSubmit = () => {
    dispatch(authorize(email, password));
  };

  useEffect(() => {
    dispatch(reseterror());
}, [])

  return (
    <React.Fragment>
      <Grid container item spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.NSecHeader}>Please sign-in</Typography>
        </Grid>
        {
          lastError ?
            <Grid item xs={12}>
              <NError>
                {lastError}
              </NError>
            </Grid>
            : null
        }
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            className={classes.NTextField}
            InputProps={{
              className: classes.NTextFieldInput
            }}
            placeholder="E-mail"
            onChange={event => setEmail(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            className={classes.NTextField}
            InputProps={{
              className: classes.NTextFieldInput
            }}
            onKeyPress={ (e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
            type="password"
            placeholder="Password"
            onChange={event => setPassword(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box my={1}>
            <Typography variant="caption">
              <Link component={RouterLink} to="/reset-password">Forgot Password ?</Link>
            </Typography>
          </Box>
        </Grid>
        <Grid item container justify="flex-end" xs={12}>
          <Button className={classes.NButton} onClick={handleSubmit} >Sign in</Button>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.NSecHeader}>
            First time here?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth={true} className={classes.NButton} component={RouterLink} to="/register">
            Create new account
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
