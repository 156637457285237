import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import Axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as cst from '../../js/constants/miscellaneous_cst'
import { NError } from './NativeStyleds'
import { useSelector } from "react-redux";
import clsx from 'clsx';

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {resolve(reader.result)};
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
    })
}

const Dropzone = ({onChange, label, value, fileType, isMulti}) => { 
    var url = value ? `url(${cst.thumbnailImage}/${value})` : "none";
    const useStyles= makeStyles(theme => ({
        dropzoneImg: {
            height: '200px',
            backgroundRepeat: 'no-repeat',
            width: '100%',     
            backgroundImage: url,
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            border: "1px solid rgba(224, 224, 224, 0.9)", 
        },
        dropzonePdf: {
            height: '200px',
            backgroundRepeat: 'no-repeat',
            width: '100%',     
            backgroundImage: value && "url(https://upload.wikimedia.org/wikipedia/commons/8/8f/Adobe-24943.svg)",
            backgroundSize: '100px',
            backgroundPosition: 'center center',
            border: "1px solid rgba(224, 224, 224, 0.9)", 
        },
        dropzoneError: {
            height: '12rem',
            backgroundRepeat: 'no-repeat',
            width: '100%',     
            backgroundImage: url,
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            border: "2px solid red", 
        },
        dropzoneMsg: {
            textAlign: 'center',
        },
        Icon: {
            width: '80px', 
            height: "80px",
        },
    }))

    const classes = useStyles();
    const token = useSelector(state => state.auth.token);

    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const onUploaded = useCallback( (newGuidImg) => { // a callback to send props to the parent
        onChange(newGuidImg)
    }, [onChange])

    const onDrop = useCallback(async (acceptedFiles) => {        
        let sortedArray = null;
        sortedArray = acceptedFiles.sort((a, b) => a.name.toString().localeCompare(b.name) )
        setLoading(true);
        for(const file of sortedArray)  {
            try {
                const binaryFile = await readFileAsync(file);
                let fileName = file.name;
                let url = cst.postUploadImage + fileName 
                let data = binaryFile
                let config = {
                    headers: {'Content-Type': 'application/octet-stream', Authorization: "Bearer " + token }
                }
                let response = await Axios.post(url, data, config);
                onUploaded(response.data);
            }
            catch {
                setSubmitError("Impossible to upload, try later")
                setLoading(false) 
            }
        }
        setLoading(false) ;
    },[onUploaded,token])

    function fileTypeAcceptation(fileType){
        if(fileType === "img"){
            return ['image/jpeg', 'image/png']
        } else if (fileType === "pdf") {
            return ['application/pdf']
        }
    }
    
    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
        onDrop, 
        onUploaded,
        label: label,
        multiple: isMulti, 
        accept: fileTypeAcceptation(fileType) // accept a MIME type
    })
    let imgIsPresent = value

    return (
        <> 
            {submitError ? <NError >{submitError}</NError> : null}
            <div  {...getRootProps({})} 
                className={clsx({
                    [classes.dropzoneImg]: fileType === "img",
                    [classes.dropzonePdf]: fileType === "pdf"
                })}
            >
                <input  
                    {...getInputProps()}  
                /> 
                <div className={classes.dropzoneMsg}>
                    {!isDragActive && !loading && !imgIsPresent  && `Drop a file to upload`}
                    {isDragActive && !isDragReject && "Drop file here"}
                    {isDragReject && `Sorry, file type not accepted (allowed : ${fileTypeAcceptation(fileType)}) `}
                    {loading && <LinearProgress variant="query" />}
                </div>
            </div>     
        </>
    )
}

export default Dropzone