import React, { useState, useEffect } from "react";
import Select from 'react-select'
import {reactSelectStyles} from './NativeStyleds';
import {getGoodBids} from "../../js/utils/biddingMethods"
import { formatCurrency } from "../../js/utils/formatter";

export const BidChooser = ({
    currentBid,
    onChange,
    defaultValue,
    fieldName,
    inputRef,
    isStartPrice = false
}) => {

    const [options, setOptions] = useState([])

    const value = { 
        value: defaultValue ? defaultValue : "", 
        label: `${defaultValue ? formatCurrency(defaultValue) : "" }`
    }

    useEffect(() => {
        getOptions(currentBid + 1, currentBid, defaultValue)
    }, [currentBid, defaultValue])

    function getOptions(input, currentBid, defaultValue) {
        let inputValue = Number(input || defaultValue || currentBid + 1)
        if(isNaN(inputValue)) inputValue = 0
        if (currentBid) inputValue = inputValue > currentBid ? inputValue : currentBid + 1
        if(isStartPrice)
        {
            Number.isInteger(inputValue) && setOptions(getGoodBids(5, 200))
        }
        else
        {
            Number.isInteger(inputValue) && setOptions(getGoodBids(inputValue, 200))
        }
    }

    return (
        <>
            
            <Select
                styles={reactSelectStyles}
                defaultValue={value}
                options={options} 
                placeholder=""
                filterOption={() => true}
                onChange={price =>  onChange(price.value)}
                onInputChange={(value) => { getOptions(value, currentBid,defaultValue) }}
                onFocus={(input) => getOptions(input.value, value)}
                name={fieldName}
                ref={inputRef}
            />
            
        </>
    )
}