import React, { useRef, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { Box, Paper } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { fetchData } from "../../../js/utils/backend";
import format from 'date-fns/format';
import { makeStylesGlobal } from "../../../themes/GlobalTheme"
import { greyBackgroundColor } from "../../../themes/GlobalTheme";
import { CurrencyRenderer } from "../../Widget/TableEditors"
import DownloadIcon from '@material-ui/icons/SaveAlt';
import { downloadPdf } from "../../Widget/DownloadButton";

const localStyle = makeStylesGlobal((theme) => ({
}));

export default function OwnBillings({ type, history }) {
    const token = useSelector(state => state.auth.token);
    const tableRef = useRef();
    const classes = localStyle();

    const [billings, setBillings] = useState(0);

    return (
        <>
            <Box hidden={type.name === "Settlement" && billings === 0}>
                <MaterialTable
                    style={{ backgroundColor: greyBackgroundColor }}
                    tableRef={tableRef}
                    columns={[
                        {
                            title: "Number",
                            field: "Number",
                            headerStyle: { textAlign: "left" },
                            defaultSort: "desc"
                        },
                        {
                            title: "Total",
                            field: "Total",
                            headerStyle: { textAlign: "right", display: "flex", flexDirection: "row-reverse"},
                            cellStyle: { textAlign: "right" },
                            render: rowData => <>{CurrencyRenderer(rowData.Total)}</> 
                        },
                        {
                            title: "Date",
                            field: "Date",
                            cellStyle: { textAlign: "center" },
                            render: rowData => { return format(new Date(rowData.Date), 'dd/MM/yyyy') }
                        }

                    ]}
                    data={query => {
                        fetchData(token, query, type.ownBills).then((data) => { setBillings(data.totalCount); })
                        return fetchData(token, query, type.ownBills)
                    }}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 5,
                        pageSizeOptions: [5],
                        showTextRowsSelected: false,
                        search: false,
                        headerStyle: { textAlign: "center", backgroundColor: greyBackgroundColor },
                        selectionProps: rowData => ({
                            color: 'primary'
                        })
                    }}
                    actions={[
                        (rowData) => ({
                            icon: () => <><DownloadIcon /></>,
                            onClick: (event, rowData) => { downloadPdf(type.name.toLocaleLowerCase(), rowData.ID) },
                            tooltip: `Download ${type.name} pdf`
                        }),
                    ]}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No invoices to display'
                        },
                    }}
                    title={type.name + "s"}
                    components={{
                        Toolbar: props => (
                            <div>
                                <div>
                                    <MTableToolbar {...props} />
                                </div>
                            </div>
                        ),
                        Container: props => <Paper {...props} className={classes.NVisiblePaper}/>

                    }}
                />
            </Box>
        </>
    )
}
