import React from 'react'
import { makeStyles } from '@material-ui/core';
import { NTypoSmallTitle } from '../Widget/NativeStyleds';
import {DownloadButton, DownloadIcon} from "../Widget/DownloadButton.js"
import { ReactComponent as LogoPDF } from '../../images/wikimedia_icon_Adobe-24943.svg';


const useStyles = makeStyles((theme) => ({
    fullscreen: {
        textAlign: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh"
    },
    Icon: {
        width: '80px', 
        height: "80px",
    },
    pdfPage: {
        margin: theme.spacing(1),
    },
    pdfView: {
        textAlign: "center",
        alignItems: "center",
    },
    pageName: {
        textAlign: "left",
        fontFamily: theme.SecondaryFont,
        fontSize: "100%"
    },
    Button : {
        marginTop: theme.spacing(1),
    }
}));

function DocumentDownload(props) {
    const { match } = props
    let documentType = match && match.params && match.params.doctype
    let documentID = match && match.params && match.params.docID
    let fileName = match && match.params && match.params.filename
    const classes = useStyles()
    return (
        <>

            <div className={classes.fullscreen}>
                    <NTypoSmallTitle 
                        className={classes.pageName}
                    >{fileName}
                    </NTypoSmallTitle>
                    <DownloadIcon documentType={documentType} documentID={documentID}>
                        <LogoPDF className={classes.Icon}/>
                    </DownloadIcon>
                    <DownloadButton className={classes.Button} documentType={documentType} documentID={documentID} >
                        Download 
                    </DownloadButton>
            </div>
        </>
    )
}

export default DocumentDownload
