import React from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux"; 
import { editModel, addNewModel} from "../../js/utils/backend";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { AUCTIONSURL } from "../../js/constants/model_cst";
import { 
    VTextField, 
    VStoreChooser, 
    VDropzone, 
    VNumeric,
    VMuiTextEditor, 
    VDateTimePicker,
    } from '../Widget/CustomVInputs'
import { useForm, FormContext } from 'react-hook-form';
import * as cst from '../../js/constants/miscellaneous_cst'
import {NBottomActiveButton, NBottomCancelButton} from '../Widget/NAdminCustomComponents';

const AuctionsEditPanel = ({Data, isNew, onClose, onUpdate, hideCancel}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange", 
        defaultValues: Data
    });

    const {
        watch, 
        triggerValidation,
        handleSubmit
    } = methods;

    const localStyle = makeStylesGlobal(theme => ({}));
    const classes = localStyle();

    const token = useSelector(state => state.auth.token);

    const submitMethods = (newData) =>{
        if(onUpdate != null)onUpdate(newData, newData.Title);
        if(onClose != null)onClose();
    }

    const submitUpdate = data => { 
        const newData = {...Data, ...data }; 
        if(onUpdate != null)onUpdate(newData, newData.Title);
        if(isNew === true)
        {
            addNewModel(newData, token, AUCTIONSURL)
            .then(()=>submitMethods(newData))
        }
        else
        {
            editModel(newData, Data.ID, token, AUCTIONSURL)
            .then(()=>submitMethods(newData))
        }
    }
    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)}>
                <Grid container> 
                    <Grid item sm={2} xs={12}>
                        <VDropzone
                            fieldName="MainImageID"
                            label="Wide Image"
                            validation={{required: false}} // if false, fail to create
                            fileType="img" 
                        />
                        <VDropzone
                            fieldName="SmallImageID"
                            label="News Image"
                            validation={{required: false}} // if false, fail to create
                            fileType="img" 
                        />
                    </Grid>
                    <Grid container item sm={10} xs={12}> 
                            <Grid item md={9} sm={8} xs={12}>
                                <VTextField
                                    label="Title" 
                                    fieldName="Title"
                                    validation={{required: true}}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                                <Box className={classes.RightEndAlign}>
                                    <VStoreChooser
                                        fieldName="Store"
                                        label="Store"
                                        toggle={true}
                                        validation={{
                                            required: true,
                                            validate: (value) => {
                                            if (value === 0) {
                                                return false
                                            } else if (value === 3) { 
                                                return false
                                            } else {
                                                return true
                                            }
                                            }
                                        }}
                                    /> 
                                </Box>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <VDateTimePicker
                                    fieldName="StartDate"
                                    label="Start Date"
                                    validation={{
                                        required: cst.msgRequired,
                                        validate: (value) => {
                                            return  (watch('StartDate') > watch('EndDate')) ? cst.msgStartDateInvalid : true ;
                                        }
                                    }}
                                    onBlur={async () => {
                                        triggerValidation("EndDate");
                                    }} 
                                    isNew={isNew} 
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <VDateTimePicker
                                    fieldName="EndDate"
                                    label="End Date"
                                    validation={{
                                        required: cst.msgRequired,
                                        validate: (value) => {
                                            return (watch('EndDate') < watch('StartDate')) ? cst.msgEndDateInvalid : true ;
                                        }
                                    }}
                                    onBlur={async () => {
                                        triggerValidation("StartDate");
                                    }} 
                                    isNew={isNew}
                                />
                        </Grid>
                        <Grid item xs={12} sm={4}  >
                <VNumeric
                  label="Comission"
                  fieldName="BaseComissionPercent"
                  fullWidth={true}
                  validation={{ required: true }}
                  unit="%"
                  inputProps={{
                    style: { textAlign: "right" }
                  }}
                />
              </Grid>
                            <Grid item sm={12} xs={12}>
                                <VMuiTextEditor
                                    fieldName="ShortDescription"
                                    label="Short Description"
                                    isNew={isNew}
                                    isShort={true}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <VMuiTextEditor
                                    fieldName="Informations"
                                    label="Detailed Informations"
                                    isNew={isNew}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box className={classes.RightEndAlign}>
                                    {hideCancel ? null : <NBottomCancelButton 
                                        variant="outlined" 
                                        onClick={onClose}
                                    >
                                    Cancel
                                    </NBottomCancelButton>}
                                    <NBottomActiveButton 
                                        type="submit"
                                    >
                                    Save
                                    </NBottomActiveButton>
                                </Box>
                            </Grid>    
                        </Grid>
                    </Grid>
            </form>
        </FormContext>
    ); 
} 

export default AuctionsEditPanel