import React, { useRef, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/AddCircleOutline";

import { fetchData } from "../../../js/utils/backend";
import { PRODUCTURL } from "../../../js/constants/model_cst";
import { productThumbnail, StatusRenderer } from "../../Widget/TableEditors";
import * as cst from '../../../js/constants/miscellaneous_cst'
import { withStyles } from '@material-ui/core/styles';

const StyledMTableToolbar = withStyles({
    root: {
        display: 'grid',
        paddingLeft: '0px'
    },
})(MTableToolbar);


export default function Products({ choosenProduct, handleClose }) {
    const token = useSelector(state => state.auth.token);

    const tableRef = useRef();

    const [pageSize, setPageSize] = useState(cst.defaultPageSize);



    const handleAddItem = (event, rowData) => {

        choosenProduct(rowData)
        handleClose()
    }
    
    return (
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable

                    tableRef={tableRef}
                    columns={[
                        {
                            title: "Image",
                            field: "ImageID",
                            cellStyle: { width: "10%", padding: 0 },
                            sorting: false,
                            render: productThumbnail
                        },
                        {
                            title: "Title",
                            field: "Title"
                        },
                        {
                            title: "Status",
                            field: "Status",
                            sorting: false,
                            cellStyle: { paddingLeft: "0" },
                            render: (rowData) => StatusRenderer(rowData.Status, cst.productStatusses)
                        }
                    ]}

                    data={query => {
                        setPageSize(query.pageSize);
                        let url = PRODUCTURL;

                        return fetchData(
                            token, query, url
                        );
                    }}

                    options={{
                        paging: true,
                        sorting: true,
                        draggable: false,
                        pageSize: pageSize,
                        pageSizeOptions: [5, 10, 50, 100],
                        actionsColumnIndex: 0,
                        addRowPosition: "first",
                        searchFieldAlignment: 'left',
                        searchFieldStyle: {
                            marginRight: '8px'
                        }
                    }}
                    actions={[
                        rowData => ({
                            icon: () => <AddIcon />,
                            onClick: (event, rowData) => handleAddItem(event, rowData),
                            tooltip: 'Add Object',
                        })
                    ]}

                    title=""
                    components={{
                        Toolbar: props => (

                            <StyledMTableToolbar
                                {...props}

                            />
                        )
                    }}
                />
            </Grid>
        </Grid>
    );
}