
import React from 'react'



export const calculateTimeLeft = (deadline, currentTime) => {
  const date = new Date(currentTime);
  const inputDate = deadline && (deadline.endsWith("Z") ? new Date(deadline) : new Date(deadline + "Z"));
  const difference = inputDate - date;
  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } 
};

export const Deadline = ({ 
  deadline, 
  currentTime 
}) => {   

  const timeLeft = calculateTimeLeft(deadline, currentTime);

  return (
    <>
      {timeLeft ?
        (<>
          {timeLeft.days.toString()}d&nbsp;
          {timeLeft.hours.toString().padStart(2, "0")}h&nbsp;
          {timeLeft.minutes.toString().padStart(2, "0")}m&nbsp;
          {timeLeft.seconds.toString().padStart(2, "0")}s
        </>) : null}
        
    </>
  );
};