import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatCurrencyInvoice } from '../../../../js/utils/formatter';
import { APPCURRENCY } from '../../../../js/constants/miscellaneous_cst';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomWidth: 0.2,
        borderBottomColor: 'gray',
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    lastRow: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '47%',
        textAlign: 'right',
        paddingRight: 8,
    },
    percentage:{
        width: '11%',
        textAlign: 'right',
    },
    total: {
        width: '42%',
        textAlign: 'right',
        paddingRight: 8,
    },
    shorterBorderLines: {
        paddingLeft: 335, // sur 595.28 (largeur A4)
    },
});

const BillTableFooter = ({ items, type }) => {

    return (
        <View wrap={false} style={styles.shorterBorderLines}>
            {items.IsWithVATonCommission || items.VATTotal > 0 ?
            <View style={styles.row} wrap={false}>
                <Text style={styles.description}>VAT</Text>
                <Text style={styles.percentage} >{items.VATRate}%</Text>
                <Text style={styles.total}>{formatCurrencyInvoice(items.VATTotal, APPCURRENCY, 2, " ")}</Text>
            </View> : null}
            <View style={styles.lastRow} wrap={false}>
                <Text style={styles.description}>Total all taxes incl.</Text>
                <Text style={styles.percentage} ></Text>
                <Text style={styles.total}>{formatCurrencyInvoice(items.Total, APPCURRENCY, 2, " ")}</Text>
            </View>
        </View>
    )
};

export default BillTableFooter