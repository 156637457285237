import React, { useState, useEffect } from "react";
import { Typography, Paper, Table, TableRow, TableCell,TableBody } from "@material-ui/core";
import { makeStylesGlobal } from "../../../../themes/GlobalTheme";
import { useDashboardState } from "../../../../js/actions/dashboard";

const localStyle = makeStylesGlobal((theme) => ({
  WidgetContainer: {
    height: theme.NDashBoardWidgetHeight, //'200px',
  },
}));


export default function ConnectedUsersWidget() {
  const classes = localStyle();
  const connectedUsers = useDashboardState("connectedusers", []);
  const userCount = useDashboardState("usercount", 0);


  return (
    <>
      <Paper className={classes.NPaperWidget}>
        <Typography variant="h6">Connected ({userCount})</Typography>
        <Table className={classes.table} size="small" aria-label="Connected users">
        <TableBody>
          {connectedUsers.map((row,i) => (
            <TableRow key={i}>
              <TableCell >{row}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
          
      </Paper>
    </>
  );
}
