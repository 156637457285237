import React from "react";
import TextField from "@material-ui/core/TextField";
import Box from '@material-ui/core/Box';
import Store from "./Store";
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Chip } from "@material-ui/core";
import { APPCURRENCY, thumbnailImage } from "../../js/constants/miscellaneous_cst";
import  { formatCurrency } from "../../js/utils/formatter";

export const FullWidthEdit = ({ value, onChange }) => (
  <TextField
    fullWidth={true}
    value={value}
    onChange={e => onChange(e.target.value)}
  />
);

export const MultilineEdit = ({ value, onChange }) => (
  <TextField
    fullWidth={true}
    multiline={true}
    value={value}
    onChange={e => onChange(e.target.value)}
  />
);


// --- Store

export const StoreRenderer = rowData => (
  <Store value={rowData.Store} editable={false}></Store>
);

export const StoreEdit = ({ value, onChange }) => (
  <Store
    value={value}
    toggle={false}
    editable={true}
    onChange={e => {
      onChange(e);
    }}
  ></Store>
);

export const StoreToggleEdit = ({ value, onChange }) => (
  <Store
    value={value}
    toggle={true}
    editable={true}
    onChange={e => {
      onChange(e);
    }}
  ></Store>
);

export function EllipsedTextRenderer(maxWidth) {
  return rowData => (
    <div style={{ width: maxWidth, whiteSpace: "nowrap" }}>
      <Box component="div" fullWidth={true} textOverflow="ellipsis" overflow="hidden">
        {rowData.Content}
      </Box>
    </div>
  );
}

export const productThumbnail = rowData => {
  let imagesTable;
  if (rowData.OfficialImagesID.length > 0) {
    imagesTable = rowData.OfficialImagesID;
  } else {
    imagesTable = rowData.InternalImagesID;
  }
  return Thumbnail(80, imagesTable[0]);
};

export function Thumbnail(maxWidth, imgName) {
  const image = imgName ? `url(${thumbnailImage}/${imgName})` : ''
  return (
    <div style={{
      width: maxWidth, height: maxWidth,
      backgroundImage: image,
      backgroundRepeat: 'no-repeat', backgroundSize: "contain",
      backgroundPosition: "center center"
    }}>

    </div>
  );
}



// HTML

export function HtmlTextRenderer(value) {
  function createMarkup(content) {
    return { __html: content };
  }
  return (
    <div dangerouslySetInnerHTML={createMarkup(value)} />
  );
}


// Date & Time

export function DateTimeRenderer(maxWidth, value) {
  let content = (value)?format(new Date(value), 'dd/MM/yyyy'): "N/A" 
  return (
    <div style={{ width: maxWidth, whiteSpace: "nowrap" }}>
      {content}
    </div>
  );
}

export function DateTimeHourRenderer(maxWidth, value) {
  let content = (value)?format(new Date(value+'Z'), 'dd/MM/yyyy HH:mm:ss'): "N/A" 
  return (
    <div style={{ width: maxWidth, whiteSpace: "nowrap" }}>
      {content}
    </div>
  );
}

export function DateTimeEdit(maxWidth) {
  return ({ value, onChange }) => (
    <div style={{ width: maxWidth }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          variant="inline"
          ampm={false}
          value={value}
          onChange={date => {
            onChange(date);
          }}
          format="dd/MM/yyyy HH:mm:ss"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}


export const CurrencyRenderer = (value, isRound) => {
    if(value) {
        return (
            <div style={{textAlign: "right", whiteSpace: "nowrap"}}>
                {`${formatCurrency(value, APPCURRENCY, isRound ? 0 : 2)}`}
            </div>
        )
    }
    return null;
}


export const StatusRenderer = (rowStatus, statusArray) => {
  for (let i = 0; i < statusArray.length; i++) {
    const element = statusArray[i];
    if (element.value === rowStatus) {
      return (
      <div style={{ textAlign: "center" }}>
        <Chip style={{ color: "white", background: element.color }} label={element.label} />
      </div>)
    }
  }
}

