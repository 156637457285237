import React from 'react'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { editModel, addNewModel} from "../../js/utils/backend";
import { useSelector } from "react-redux"; 
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { SECTIONSURL } from "../../js/constants/model_cst";
import { 
    VCheckBox,
    VColorChooser,
    VDatePicker,
    VDropzone, 
    VNumeric, 
    VTextField
} from '../Widget/CustomVInputs'

import { useForm, FormContext } from 'react-hook-form';
import * as cst from '../../js/constants/miscellaneous_cst'

import {NBottomActiveButton, NBottomCancelButton} from '../Widget/NAdminCustomComponents';

const SectionEditPanel = ({
    Data, 
    onClose, isNew}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange", 
        defaultValues: Data
    });

    const {
        handleSubmit
    } = methods;

    const localStyle = makeStylesGlobal(theme => ({}));
    
    const classes = localStyle();
    const token = useSelector(state => state.auth.token);


    const submitUpdate = data => { 
        const newData = {...Data, ...data}; 
        if(isNew)
        {
            addNewModel(newData, token, SECTIONSURL)
            .then(onClose)
        }
        else
        {
            editModel(newData, Data.ID, token, SECTIONSURL)
            .then(onClose)
        }
    }

    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)}>
                <Grid container>
                <Grid item sm={3} xs={12}>
                            <VNumeric
                                label="Before Lot"
                            fieldName="BeforeLot"
                            validation={{
                                required: cst.msgRequired,
                            }}
                            fullWidth={true}
                            />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                            <VCheckBox
                                label="Add space to start at new line"
                            fieldName="IsExact"
                            fullWidth={true}
                            />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                            <VTextField
                                label="Title"
                                fieldName="PrimaryText"
                                fullWidth={true}
                            />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                            <VNumeric
                                label="Linked to Lot (optional)"
                            fieldName="ReferToLot"
                            fullWidth={true}
                            />
                    </Grid>
                    <Grid item sm={9} xs={12}>
                            <VTextField
                                label="Subtitle"
                                fieldName="SecondaryText"
                                fullWidth={true}
                            />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <VDropzone
                            fieldName="ImageID"
                            label="Background Image (optional)"
                            fileType="img" 
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <VColorChooser
                            fieldName="BackgroundColor"
                            label="Background Color"
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <VColorChooser
                            fieldName="TextColor"
                            label="Text Color"
                        />
                    </Grid>
                    
                    <Grid item sm={12} xs={12}>
                        <Box className={classes.RightEndAlign}>
                            <NBottomCancelButton 
                                variant="outlined" 
                                onClick={onClose}
                            >
                            Cancel
                            </NBottomCancelButton>
                            <NBottomActiveButton 
                                type="submit"
                            >
                            Save
                            </NBottomActiveButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    ); 
} 

export default SectionEditPanel