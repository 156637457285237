import React from 'react';
import { Text, StyleSheet, View } from '@react-pdf/renderer';
import { smallFontSize } from '../../pdf_cst';

const styles = StyleSheet.create({
    text: {
        fontSize: smallFontSize,
        left: 0,
        right: 0,
        color: 'gray',
        marginTop: "10px",
        fontStyle: "bold"
        
    },
    container: {
        width: "100%",
        flex: "1 1 auto",
    },

});

const PayInformations = () => (
    <View style={styles.container} wrap={false}>
        <Text style={styles.text} wrap={false} >
            Native SPRL/BVBA {"\n"}
            KBC Brussels{"\n"}
            IBAN BE13 7360 4462 7239{"\n"}
            BIC/SWIFT KREDBEBB{"\n"}
            BTW/TVA BE0835325693</Text>
    </View>
);
export default PayInformations