
import React, {useEffect, useState} from 'react'
import { Box } from '@material-ui/core';
import {makeStylesGlobal} from "../../../themes/GlobalTheme";
import Axios from "axios";
import {itemsUpdated, resetFollows} from "../../../js/actions/items";
import {useSelector} from "react-redux";
import {TreeView, TreeItem} from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useHistory} from "react-router-dom";
import UserModal from "../../Modals/UserModal";

const localStyle = makeStylesGlobal(theme => ({
    Box : {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        background: "#e3ebec"
    }
}));


export const ObjectHistory = ({ 
  ID
}) => {
    const [data, setData] = useState(null);
    const token = useSelector((state) => state.auth.token);
    const history = useHistory();
    const [rowUserID, setRowUserId] = useState()

    const [showUserModal, updateShowUserModal] = useState(false)

    useEffect(() => {
        let config = {
            headers: {
                Authorization: "Bearer " + token,
            },
        };
        Axios.get(`product/history/`+ID, config).then((response) => {
            console.log(response.data);
            setData(response.data);
        });
    }, [ID,token]);
    
    const classes = localStyle();
    
    const manage = url => {
        if(url.startsWith("user:"))
        {
            setRowUserId(url.split(':')[1]);
            updateShowUserModal(true);
        }
        else
        {
            history.push(url)
        }
    }
    
    if(!data)
        return null;
    return (
        <Box className={classes.Box}>
            <UserModal isShown={showUserModal} userId={rowUserID} onClose={() => updateShowUserModal(false)}/>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
            >
                <TreeItem nodeId={"root"} label={"History and links"}>
                {data && data.Sections.map((t,i) => {
                    return (<TreeItem nodeId={"node" + i} label={t.Label}>
                        {t.Items.map((u,j) => {
                            return (<TreeItem nodeId={"item"+i+"-" +j} label={u.Label} onLabelClick={() => manage(u.URL)}>
                            </TreeItem>)
                    })}
                    </TreeItem>);
            })}
                </TreeItem>
            </TreeView>
        </Box>
    );
};