import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { tableColumnStyle } from "../../pdf_cst"

const styles = StyleSheet.create({...tableColumnStyle});

const BillTableHeader = ({ hideVAT, type, items }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.lot}>Lot</Text>
        <Text style={styles.label}>Description</Text>
        {hideVAT ? <Text style={styles.VAT}></Text> : null}
        <Text style={styles.hammer}>Hammer Price</Text>
        <Text style={styles.commission}>{hideVAT ? "Premium" : type.commission}</Text>
        {hideVAT ? null :
            <Text style={styles.VAT}>{!items.IsWithVATonCommission && items.VATTotal === 0 ? "" : "VAT"}</Text>}
        <Text style={styles.total}>Total</Text>
    </View>
);

export default BillTableHeader