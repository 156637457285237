import React, { useState, useEffect } from 'react'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { useForm, FormContext } from 'react-hook-form';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { NError } from '../Widget/NativeStyleds';
import { useSelector } from "react-redux";
import { VSelectInput, VTextField, VTextFieldMaskedInput } from '../Widget/CustomVInputs'
import * as cst from '../../js/constants/miscellaneous_cst'
import { tokenAuthenticated, authenticated } from "../../js/actions/auth";

import { NBottomActiveButton, NBottomCancelButton, NDataLoading } from '../Widget/NAdminCustomComponents';

import { computeCountriesArray } from '../../js/utils/computedPackages'


const ProfileForm = ({ 
    history, match, inStore, 
    isPopUp, rowUserID, onClose 
}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    })

    const { reset, handleSubmit } = methods;

    const localStyle = makeStylesGlobal(theme => ({

    }));

    const classes = localStyle();

    const token = useSelector(state => state.auth.token);

    const [isFetching, setIsFetching] = useState(true);
    const [submitError, setSubmitError] = useState(null);
    const [originalData, setOriginalData] = useState({});

    const [countriesList, setCountriesList] = useState([])

    const isOtherProfile = match && match.params && match.params.userId;
    const paramsID = match && match.params && match.params.userId;
    const [hasChanged, setHasChanged] = useState(false);

    const dispatch = useDispatch();


    useEffect(() => {
        const fetchData = () => {
            let userIdByRoute = isOtherProfile ? cst.getUserProfile + paramsID : cst.getUserProfile;
            let userIdByRow = cst.getUserProfile+rowUserID

            let url = isPopUp ? userIdByRow : userIdByRoute
            var config = {
                headers: { Authorization: "Bearer " + token }
            };
            Axios.get(url, config)
                .then(function (response) {
                    setOriginalData(response.data);
                    reset(response.data);
                    setIsFetching(false);
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.message) {
                        setSubmitError(error.response.data.message);
                    } else {
                        setSubmitError("Impossible to fetch you profile account, please try again later.")
                    }
                })
        }
        setCountriesList(computeCountriesArray())
        
        fetchData();

    }, [isOtherProfile, paramsID, isPopUp, rowUserID, token, reset]);

    const submitUpdate = data => {
        setHasChanged(false);
        const updatedProfile = { ...originalData, ...data };
        let config = {
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'

            }
        };
        let url = cst.getUserProfile;

        Axios.put(url, updatedProfile, config)
            .then(response => {
                if (response.data.IsLogged === true) {
                    dispatch(authenticated(
                        response.data.IsValidated,
                        response.data.Token,
                        response.data.Role
                    ));
                }
                if(originalData.GSM !== response.data.GSM){
                    dispatch(tokenAuthenticated(token));
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    setSubmitError(error.response.data.message);
                } else {
                    setSubmitError("Impossible to update your profile account, please try again later.")
                }
            })
        
        if(isPopUp) {return onClose()}
    }


    if (isFetching) {
        return <NDataLoading />
    }

    return (
        <FormContext {...methods}>
                    <Paper className={classes.NVisiblePaper}>
                        <form onSubmit={handleSubmit(submitUpdate)} onChange={s => {setHasChanged(true) }}>
                            <Grid container >
                                <Grid item sm={2} xs={4} >
                                    <VSelectInput
                                        label="Title"
                                        fullWidth={true}
                                        fieldName="Title"
                                        validation={{ required: true }}
                                        allvalues={cst.titleCategory}
                                        onClose={() =>setHasChanged(true)}
                                    />
                                </Grid>
                                <Grid item sm={5} xs={8}>
                                    <VTextField
                                        label="First Name"
                                        fieldName="FirstName"
                                        validation={{ required: true }}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item sm={5} xs={12}>
                                    <VTextField
                                        label="Last Name"
                                        fieldName="LastName"
                                        validation={{ required: true }}
                                        fullWidth={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item sm={6} xs={12}>
                                    <VTextField
                                        label="Email"
                                        fieldName="EMail"
                                        validation={{
                                            required: true,
                                            pattern: {
                                                value: cst.emailRegex,
                                                message: cst.msgEmailInvalid
                                            }
                                        }}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <VTextField
                                        label="Company"
                                        fieldName="Company"
                                        fullWidth={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item sm={6} xs={12}>
                                    <VTextField
                                        label="Mobile number"
                                        fieldName="GSM"
                                        validation={{
                                            required: true,
                                            pattern: {
                                                value: cst.phoneRegex,
                                                message: ''
                                            }
                                        }}
                                        fullWidth={true}
                                        helperText={cst.msgMobileNumber}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <VTextFieldMaskedInput
                                        label="VAT Number"
                                        fieldName="VATNumber"
                                        fullWidth={true}
                                        placeholder={"e.g. BE123456789"}
                                        formatChars={cst.VATnumberChars}
                                        mask={cst.VATnumberMask}
                                        emptyMaskChar={""}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <VTextField
                                        label="Address"
                                        fieldName="AddressLine1"
                                        validation={{
                                            required: true,
                                            pattern: {}
                                        }}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <VTextField
                                        label="Address complement"
                                        fieldName="AddressLine2"
                                        validation={{
                                            required: false,
                                            pattern: {}
                                        }}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} >
                                <Grid item sm={3} xs={6}>
                                    <VTextField
                                        label="Postal Code"
                                        fieldName="PostCode"
                                        validation={{
                                            required: true,
                                            pattern: {}
                                        }}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={6}>
                                    <VTextField
                                        label="City"
                                        fieldName="City"
                                        validation={{
                                            required: true,
                                            pattern: {}
                                        }}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item sm={5} xs={12}>
                                    <VSelectInput
                                        label="Country"
                                        fullWidth={true}
                                        fieldName="Country"
                                        validation={{ required: true }}
                                        allvalues={countriesList}
                                        onClose={() =>setHasChanged(true)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {submitError ? <NError >{submitError}</NError> : null}
                            </Grid>
                            <Grid item xs={12}>
                                {hasChanged ? 
                                <Box className={classes.RightEndAlign}>
                                    {inStore ? null : 
                                        (isPopUp ? 
                                            <NBottomCancelButton
                                                color="default"
                                                variant="outlined"
                                                type="button"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </NBottomCancelButton>
                                        :
                                            <NBottomCancelButton
                                                color="default"
                                                variant="outlined"
                                                type="button"
                                                onClick={history.goBack}
                                            >
                                                Cancel
                                            </NBottomCancelButton>)
                                    }
                                    <NBottomActiveButton
                                        type="submit"
                                    >
                                        Update
                                    </NBottomActiveButton>
                                </Box>
                                :
                                <></>
                                }
                            </Grid>
                        </form>
                    </Paper>
        </FormContext>
    )
}

export default ProfileForm;