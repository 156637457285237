export const MOVEUP = "up";
export const MOVEDOWN = "down";

export const USERSURL = "/user";
export const AUCTIONSURL = "/auction";
export const PRODUCTURL = "/product";
export const FAQURL = "/faq";
export const NEWSURL = "/news";
export const INVOICEURL = "/invoice"
export const INVOICELINEURL = "/invoiceline"
export const MAILINGLISTURL = "/user/mailinglist"
export const SETTLEMENTURL = "/settlement"
export const PARAMETERSURL = "/parameter"
export const CATALOGURL = "/catalog"
export const BIDSURL = "/bid"
export const PHONEREQUESTURL = "/phonerequest"
export const SECTIONSURL = "/auctionseparator"

export const GROUPURL = "/auction/group";
export const requestGROUP = "/auction";


