import { Font } from '@react-pdf/renderer';
import font from "../../fonts/bauerbodonistd-roman.ttf";
import defaultFont from "../../fonts/OpenSans-Regular.ttf";
import defaultBold from "../../fonts/OpenSans-SemiBold.ttf";
Font.register({
    family: "opensans", fonts: [
        { src: defaultFont, fontStyle: 'normal' },
        { src: defaultBold, fontStyle: "bold" },
    ]
})
Font.register({ family: "BauerBodoni", src: font, fontStyle: 'normal' })

export const titleColor = '#607d8b';

export const smallFontSize = 10;

export function formatDate(dateString) {
    if (!dateString) return "";
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let date = new Date(dateString);
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
}

const truncatedDescription = (text) => text.length > 600 ? `${text.substring(0, 600)}...` : text;    
export function htmlToString (text) {
    if ((text===null) || (text==='')) {
        return false; 
    } else {
        text = text.toString(); 
        return truncatedDescription(text).replace( /(<([^>]+)>)|(&nbsp;)/ig, ''); 
    }
}


export const tableColumnStyle = {
    
    // overall structure 
    page: {
        fontSize: 10,
        paddingTop: 30,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 100,
        lineHeight: 1.5,
        flexDirection: 'column',
        fontFamily: "opensans"
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        borderTopWidth: 0.2,
        borderColor: 'gray'
    },
    row: {
        flexDirection: 'row',
    },

    border : {
        borderBottomColor: 'gray', 
        borderBottomWidth: 0.2,
    },


    // common / ! \
    headerContainer: {
        flexDirection: 'row',
        borderBottomColor: 'gray',
        borderBottomWidth: 0.2,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
    },
    label: {
        width: '25%',
        textAlign: 'left',
        padding: 8,
        paddingRight: 8,
    },
    objDescription : {
        fontSize: 7,
        textAlign: 'left',
        paddingLeft: 47,
        paddingRight: 8,
        paddingBottom: 8,
        width: "100%",
    },
    percent:{
        fontSize:smallFontSize,
        paddingTop: 4
    },

    // listing table 
    thumbnailImage : { 
        width: '9%', //'28%',
        textAlign: 'left',
        padding: 8
    },
    itemPhoneH: {
        fontSize: 7,
        width: '15%',
        textAlign: 'left',
        padding: 8,
    },
    itemPhone: {
        fontSize: 8,
        width: '15%',
        textAlign: 'left',
        padding: 8,
    },
    item20: {
        fontSize: 7,
        width: '20%',
        textAlign: 'left',
        padding: 8,
    },
    item30: {
        fontSize: 7,
        width: '30%',
        textAlign: 'left',
        padding: 8,
    },
    item10: {
        fontSize: 7,
        width: '10%',
        textAlign: 'left',
        padding: 8,
    },
    item15: {
        fontSize: 7,
        width: '15%',
        textAlign: 'left',
        padding: 8,
    },
    item5: {
        fontSize: 7,
        width: '5%',
        textAlign: 'left',
        padding: 8,
    },
    listingItemDescription : {
        width: '69%',
        textAlign: 'left',
        padding: 8,
    },
    listingItemDescriptionShort : {
        width: '54%',
        textAlign: 'left',
        padding: 8,
    },

    auctionNumber : {
        width: '2%',
        textAlign: 'left',
        padding: 8,
    },

    estimate : {
        width: '20%',
        textAlign: 'right',
        padding: 8,
    },
    result : {
        width: '15%',
        textAlign: 'right',
        padding: 8,
    },


    // invoice || settlement table 
    lot: {
        width: '7%',
        textAlign: 'left',
        padding: 8,
    },
    hammer: {
        width: '16%',
        textAlign: 'right',
        padding: 8,
    },
    commission: {
        width: '16%',
        textAlign: 'right',
        padding: 8,
    },
    VAT: {
        width: '15%',
        textAlign: 'right',
        padding: 8,
    },
    total: {
        width: '21%',
        textAlign: 'right',
        padding: 8,
    },

    qty: {
        width: '10%',
        textAlign: 'right',
        padding: 8,
    },

    amount: {
        width: '15%',
        textAlign: 'right',
        padding: 8,
    },
    //


    // Fixed footer views
    PayInformationsBlock : {
        position: 'absolute', 
        bottom: 20, 
        left: 20, 
        right: 0,
    },
    PageNumber: {
        position: 'absolute',
        fontSize: smallFontSize,
        bottom: 20,
        left: 0,
        right: 20,
        textAlign: 'right',
        color: 'grey',
    },
    BottomEmail : {
        textAlign: 'center',
        position: 'absolute',
        bottom: 20,
        marginLeft: 50,
        color: 'gray',

    },
    IsArticle44: {
        textAlign: "center",
        padding: "10px"
    }
    //


}