import React from "react";

import { Link, Grid, Container } from "@material-ui/core";

import RouterLink from "../../routes/RouterLink";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    SeparationCell: {
        borderLeft: "solid 1px",
        borderColor: theme.ColorC1 + "22",
        position: "absolute",
        top: "16px",
        left: "0px",
        bottom: "16px", 
        zIndex: 10
    },
    SeparationCellInterior: {
        position: "absolute",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        bottom: "0px",
        top: "0px",
        left: "0px",
        right: "0px"
    }, 
      FeaturedVisualContainer: {
        minHeight: "100%",
        width: "100%"
      },
      Headline: {
        fontSize: "200%",
          padding: "6px",
        fontFamily: theme.PrimaryFont
      },
      SubLine: {
        fontSize: "120%",
        padding: "6px",
        display: "flex",
        alignItems: "center",
          fontFamily: theme.SecondaryFont,
        
    },
    HeadLineBlockBlocker: {
        position: "absolute",
        left: "0px",
        bottom: "0px",
        right: "0px",
        top: "0px",
        overflow: "hidden",
      },
    HeadLineBlock: {
        position: "absolute",
        backdropFilter: "blur(6px)",
        WebkitBackdropFilter: "blur(6px)",
        padding: 0,
        left: "10px",
        bottom: "10px",
        fontWeight: 300
      },
      SublineA: {
          display: "inline-block",
      },
}));


const SeparationCell = ({
    item,
    index,
    currentTime,
    totalWidth
}) => {
    const classes = useStyles();
    const history = useHistory();
    const totalWidthIn = totalWidth - 32;

    if (item.referTo) {
        const siteId = item.referTo.IsOnline ? "online" : "auctions"
        const isOnline = item.referTo.IsOnline;
        
        return (
            <div className={classes.SeparationCell} style={{ width: totalWidthIn }}>
                <Link
                    color="inherit"
                    underline="none"
                    component={RouterLink}
                    onClick={() => {
                        history.replace({ ...history.location, state: { scrollback: window.scrollY } });
                    }}
                    to={{ pathname: "/" + siteId + "/detail/" + item.referTo.AuctionID + "/product/" + item.referTo.ID, state: { scrollforward: window.scrollY } }}
                >
                    <SeparationCellBase item={item} index={index} currentTime={currentTime} totalWidth={totalWidthIn} />
                </Link>
            </div>
        );
    }

    return (
        <div className={classes.SeparationCell} style={{ width: totalWidthIn }}>
            <SeparationCellBase item={item} index={index} currentTime={currentTime} totalWidth={totalWidthIn} />
        </div>
    );
};

    const SeparationCellBase = ({ 
        item, 
        index, 
        currentTime,
        totalWidth
    }) => { 
        const classes = useStyles();
        const history = useHistory();
        const url = item.ImageID ? `url(/Image/${item.ImageID})` : "none";
        const extraStyle = item.ImageID ? { maxWidth:"66%"} : {};

        return (
            <div className={classes.SeparationCellInterior} style={{ backgroundColor: item.BackgroundColor, backgroundImage: url, color: item.TextColor,  width: totalWidth }}>
            
            <div className={classes.HeadLineBlockBlocker}>
                     <div className={classes.HeadLineBlock}  style={{...extraStyle, backgroundColor: item.BackgroundColor + "99", color: item.TextColor }}>
                            <Grid item className={classes.Headline} xs={12}>
                                <div>{item.PrimaryText}</div>
                            </Grid>
                        <Grid item className={classes.SubLine} xs={12}>
                            <div className={classes.SublineA}>{item.SecondaryText}</div>
                        </Grid>
                        </div>
                
                </div>
                </div>
        );
        };

    export default SeparationCell;