import React, { useRef, useState } from "react";
import MaterialTable, { MTableToolbar, MTableBodyRow, MTableEditRow } from "material-table";
import { Grid, TablePagination } from '@material-ui/core'
import {Paper} from "@material-ui/core";
import { fetchData, deleteModel } from '../../../js/utils/backend'
import { useSelector } from 'react-redux'
import { makeStylesGlobal } from "../../../themes/GlobalTheme";
import * as cst from '../../../js/constants/miscellaneous_cst';
import Axios from 'axios'
import BillingEditPanel from '../../EditPanels/BillingEditPanel'
import { NAdminToolBar, NAdminActiveButton, NAdminToolbarCancelButton, NAdminFilterBar } from '../../Widget/NAdminCustomComponents'
import AddIcon from "@material-ui/icons/AddCircleOutline";
import AddItemLine from '@material-ui/icons/QueueOutlined';
import EmailIcon from '@material-ui/icons/Email';
import { StatusRenderer, CurrencyRenderer } from "../../Widget/TableEditors";
import StatusFilter from "../../Widget/StatusFilter";
import  {scrollTopFunction} from "../../../js/constants/functions_cst"


import SetStatus from '@material-ui/icons/LocalOffer';
import MergeIcon from '@material-ui/icons/MergeType';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import AssignmentIcon from '@material-ui/icons/PictureAsPdf';

import { StatusChooser } from "../../Widget/StatusChooser";
import { useReduxPrefsState } from "../../../js/actions/prefs";
import { downloadPdf } from "../../Widget/DownloadButton";
import {useHistoryState} from "../../../js/utils/customHooks";


const localStyle = makeStylesGlobal(theme => ({
    IconInsideCell : {
        display: "flex", 
        alignItems: "center"
    }
}));

export default function Billings({ history, type }) {

    const token = useSelector(state => state.auth.token);
    const classes = localStyle();

    const tableRef = useRef();
    const [isTableEditing, setIsTableEditing] = useState(false);
    const [displayEditPanel, setDisplayEditPanel] = useState(true)

    const [onSelect, setOnSelect] = useState(false)
    const [configActionDelete, setConfigActionDelete] = useState(true)

    const [selectedRows, setSelectedRows] = useState([])
    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
    const [operation, setOperation] = useState("none");
    const [statusFilter, setStatusFilter] = useHistoryState("statusFilter",{ value: 'All', label: 'All' })
    const options = [{ value: 'All', label: 'All' }, ...type.status];
    const [searchText, setSearchText] = useHistoryState("searchText","");
    const [currentPage, setCurrentPage] = useHistoryState("currentPage",0);
    const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn","Number");
    const [sortingDir, setSortingDir] = useHistoryState("sortingDir","desc");
    const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
    const [isInit,setIsInit] = useState(true);
    function refreshTable() {
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const merge = () => {
        let url = type.modelUrl + "/merge";
        let data = selectedRows
        var config = {
            headers: { Authorization: "Bearer " + token }
        };
        Axios.post(url, data, config)
            .then((response) => {
                history.push(`/admin${type.modelUrl}/${response.data}`)
            })

    }

    const operationChange = newOperation => {
        switch (newOperation) {
            case "none":
                setConfigActionDelete(true)
                setDisplayEditPanel(true)
                tableRef.current.onAllSelected(false);
                setOnSelect(false)
                break;
            case "new":
                break;
            case "productStatus":
                setOnSelect(true)
                setConfigActionDelete(false);
                tableRef.current.onAllSelected(false)
                break;
            case "merge":
                setOnSelect(true)
                setConfigActionDelete(false);
                tableRef.current.onAllSelected(false)
                break;
            case "bulkDownload":
                setOnSelect(true)
                setConfigActionDelete(false);
                tableRef.current.onAllSelected(false)
                break;
            default:
                return null;
        }
        setOperation(newOperation);
    }


    const bulkDownload = async () => {
        for (const file of selectedRows) {
            await  downloadPdf(type.name.toLocaleLowerCase(), file)
        }
    }

    const handleEmailSend = () => {
        let url = type.modelUrl +  "/sendallopen";
        var config = {
            headers: { Authorization: "Bearer " + token }
        };
    
        Axios.get(url, config).then(() => refreshTable());
    };

    function statusFilterChange(status) {
        refreshTable();
        setStatusFilter(status);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    detailPanel={
                        displayEditPanel ? rowData => {
                            return (
                                <div>
                                    <BillingEditPanel
                                        Data={rowData}
                                        onClose={refreshTable}
                                        isNew={false}
                                        type={type}
                                    />
                                </div>
                            )
                        } : null}

                    tableRef={tableRef}
                    columns={[
                        {
                            title: "Number",
                            field: "Number",
                            defaultSort: getSort("Number")
                        },
                        {
                            title: "Name",
                            field: "Name",
                        defaultSort: getSort("Name")
                        },
                        {
                            title: "Auction",
                            field: "OriginalAuction.Title",
                            defaultSort: getSort("OriginalAuction.Title")
                        },
                        {
                            title: "Total",
                            field: "Total",
                            headerStyle: { textAlign: "right", display: "flex", flexDirection: "row-reverse"},
                            cellStyle: { textAlign: "right" },
                            render: ({ Total }) => <>{CurrencyRenderer(Total)}</>,
                            defaultSort: getSort("Total")
                        },
                        {
                            title: "Status",
                            field: "Status",
                            sorting: false,
                            headerStyle: { textAlign: "center" },
                            render: (rowData) => <>{StatusRenderer(rowData.Status, type.status)}</>
                        }
                    ]}
                    data={query => {
                        let fetchOptions = (statusFilter.label === "All") ? "" : "&filter[status]=" + statusFilter.label;
                        const effectivePage = isInit ? currentPage : query.page;
                        setSearchText(query.search,true);
                        setSortingColumn(query.orderBy && query.orderBy.field);
                        setSortingDir(query.orderDirection);
                        setCurrentPage(effectivePage);
                        setIsInit(false)
                        return fetchData(token, {...query,page: effectivePage}, type.modelUrl, fetchOptions)
                    }}
                    options={{
                        emptyRowsWhenPaging:false,
                        detailPanelType: "single",
                        actionsColumnIndex: -1,
                        pageSize: rows,
                        pageSizeOptions: cst.itemsPerPage,
                        selection: onSelect,      //!!! to unselect all rows, pass this :  tableRef.current.onAllSelected(false)
                        showTextRowsSelected: false,
                        searchText: searchText,
                        selectionProps: rowData => ({
                            color: 'primary'
                        })
                    }}
                    onSelectionChange={
                        rowData => {
                            let guids;
                            guids = rowData.map(row => row.ID)
                            setSelectedRows(guids)
                        }
                    }
                    editable={{
                        onRowDelete: configActionDelete ? oldData => deleteModel(oldData.ID, token, type.modelUrl) : null
                    }}
                    onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
                    actions={[
                        (rowData) => (operation !== "none"?null:{
                            icon: () => <><AddItemLine /></>,
                            onClick: (event, rowData) => { history.push(`/admin${type.modelUrl}/${rowData.ID}`) },
                            tooltip: `${type.name} details`
                        }),
                        (rowData) => (operation !== "none"?null:{
                            icon: () => <><DownloadIcon /></>,
                            onClick: (event, rowData) => { downloadPdf(type.name.toLocaleLowerCase(), rowData.ID) },
                            tooltip: `Download ${type.name} pdf`
                        }),
                        (rowData) => (operation !== "none"?null:{
                            icon: () => <><AssignmentIcon /></>,
                            onClick: (event, rowData) => { copyToClipboard(`${window.location.origin}/document/${type.name.toLowerCase()}/${rowData.ID}/Native-${rowData.Number}.pdf`) },
                            tooltip: `Copy ${type.name} pdf link `
                        })
                    ]}

                    title={type.name + "s"}
                    components={{
                        Row: props => { setIsTableEditing(props.hasAnyEditingRow); return (<MTableBodyRow {...props} />) },
                        EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />) },
                        Pagination: props=>(<TablePagination {...props} className={isTableEditing || selectedRows.length ?classes.disabled:null} />) ,
                        Toolbar: props => (
                            <div>
                                <div className={isTableEditing || selectedRows.length ? classes.disabled : null}>
                                    <MTableToolbar {...props} />
                                </div>
                                <div className={isTableEditing?classes.disabled:null}>
                                <NAdminToolBar invisible={operation === "new"}>

                                    {operation === "none" && (
                                        <>
                                            <NAdminActiveButton
                                                startIcon={<AddIcon />}
                                                onClick={() => {
                                                    operationChange("new")
                                                }}
                                            >
                                                New
                                            </NAdminActiveButton>
                                            <NAdminActiveButton
                                                startIcon={<MergeIcon />}
                                                onClick={() => {
                                                    operationChange("merge")
                                                }}
                                            >
                                                Merge
                                            </NAdminActiveButton>
                                            <NAdminActiveButton
                                                startIcon={<SetStatus />}
                                                onClick={() => {
                                                    operationChange("productStatus")
                                                }}
                                            >
                                                Status
                                            </NAdminActiveButton>
                                            <NAdminActiveButton
                                                startIcon={<EmailIcon />}
                                                onClick={() => {
                                                    handleEmailSend()
                                                }}
                                            >
                                                Send Open by E-mail
                                            </NAdminActiveButton>
                                            <NAdminActiveButton
                                                startIcon={<DownloadIcon />}
                                                onClick={() => {
                                                    operationChange("bulkDownload");
                                                }}
                                            >
                                                Bulk {`${type.name}s`} download
                                            </NAdminActiveButton>
                                        </>
                                    )}

                                    {operation === "new" && (
                                        <Paper className={classes.NVisiblePaper}>
                                            <BillingEditPanel
                                                Data={cst.defaultBillingData}
                                                onClose={() => {
                                                    operationChange("none");
                                                    refreshTable();
                                                    scrollTopFunction();
                                                }}
                                                isNew={true}
                                                type={type}
                                            />
                                        </Paper>
                                    )}
                                    {operation === "productStatus" && (
                                        <StatusChooser
                                            onCancel={() =>{
                                                operationChange("none")
                                            }}
                                            statusCategories={type.status}
                                            statusUrl={type.setLineStatus}
                                            selectedRows={selectedRows}
                                            onSubmit={() => {
                                                refreshTable()
                                                setSelectedRows([])
                                            }}
                                        />
                                    )}

                                    {operation === "merge" && (
                                        <>
                                            <NAdminActiveButton
                                                onClick={(selectedRows.length > 1) ? () => {
                                                    operationChange("none");
                                                    merge();
                                                } : null}
                                            >
                                                Merge
                                            </NAdminActiveButton>
                                            <NAdminToolbarCancelButton
                                                color="default"
                                                onClick={() => {
                                                    operationChange("none");
                                                    refreshTable();
                                                }}
                                            >
                                                Done
                                            </NAdminToolbarCancelButton>
                                        </>
                                    )}

                                    {operation === "bulkDownload" &&
                                        <>
                                            <NAdminActiveButton
                                                disabled={selectedRows.length > 1 ? false : true}
                                                onClick={(selectedRows.length > 1) ? () => {
                                                    bulkDownload(); 
                                                    operationChange("none");
                                                } : null}
                                            >
                                                Download {`${type.name}s`} 
                                            </NAdminActiveButton>
                                            <NAdminToolbarCancelButton
                                                color="default"
                                                onClick={() => {
                                                    operationChange("none");
                                                    refreshTable();
                                                }}
                                            >
                                                Cancel
                                            </NAdminToolbarCancelButton>
                                        </>
                                    }

                                </NAdminToolBar>
                                </div>
                                <NAdminFilterBar>
                                    <StatusFilter
                                        value={statusFilter.label}
                                        onChange={statusFilterChange}
                                        options={options}
                                        className={isTableEditing || selectedRows.length ? classes.disabled : null}
                                    />
                                </NAdminFilterBar>
                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
    )
}
