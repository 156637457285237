import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { tableColumnStyle } from "../../pdf_cst"

const styles = StyleSheet.create({...tableColumnStyle,
    empty: {
        width: "100%",
        height: 40
    }
});

const TableBlankSpace = ({ rowsCount }) => {
    if (rowsCount < 0) return <></>
    const blankRows = Array(rowsCount).fill(0)
    const rows = blankRows.map((x, i) =>
        <View style={styles.row} key={`BR${i}`} wrap={false}>
            <Text style={styles.empty}></Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default TableBlankSpace