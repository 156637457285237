import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    title : {
        flexDirection: 'row',
        fontSize: 12,
        marginTop: 56,
        marginBottom: 16
    },
})

const ListingTitle = ({data}) => {
    
    return ( // dangerouslySetInnerHTML won't work in react-pdf since it requires to render a <div/> ... 
        <Fragment>
            <View style={styles.title}>
                <Text>
                    {data.Title}
                </Text>
            </View>
        </Fragment>
    );
}

export default ListingTitle 
