import React from 'react'
import { Box, Grid } from '@material-ui/core';
import { useSelector } from "react-redux"; 
import { editModel, addNewModel } from "../../js/utils/backend";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { VTextField, VStoreChooser, VMuiTextEditor } from '../Widget/CustomVInputs'
import { useForm, FormContext } from 'react-hook-form';
import { FAQURL } from "../../js/constants/model_cst";
import * as cst from '../../js/constants/miscellaneous_cst'

import {NBottomActiveButton, NBottomCancelButton} from '../Widget/NAdminCustomComponents';


const FaqEditPanel = ({Data, onClose, isNew}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange", 
        defaultValues: Data
    })
    const {handleSubmit} = methods;

    const localStyle = makeStylesGlobal(theme => ({}));
    const classes = localStyle();

    const token = useSelector(state => state.auth.token);

    const submitUpdate = data => { 
        const newData = {...Data, ...data}; 
        if(isNew)
        {
            addNewModel(newData, token, FAQURL)
            .then(onClose)
        }
        else
        {
            editModel(newData, Data.ID, token, FAQURL)
            .then(onClose)
        }
    }

    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)}>
                <Grid container>  
                    <Grid item sm={12} xs={12}> 
                        <Grid container>
                            <Grid item sm={8} xs={12} >
                                <VTextField
                                    label="Title" 
                                    fieldName="Title"
                                    validation={{required: true}}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <Box className={classes.RightEndAlign}>
                                    <VStoreChooser
                                        fieldName="Store"
                                        label="Store"
                                        validation={{
                                            required: true,
                                            validate: (value) => {
                                            if (value === 0) {
                                                return false
                                            } else { 
                                                return true
                                            }
                                            }
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <VMuiTextEditor
                            fieldName="Content"
                            label="Content"
                            validation={{
                                required: cst.msgContentRequired,
                                pattern: {
                                    minLength: 12,
                                    message: cst.msgContentRequired
                                } 
                            }} 
                        />
                        <Box className={classes.RightEndAlign}>
                            <NBottomCancelButton 
                                variant="outlined" 
                                onClick={onClose}
                            >
                            Cancel
                            </NBottomCancelButton>
                            <NBottomActiveButton 
                                type="submit"
                            >
                            Save
                            </NBottomActiveButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    ); 
} 

export default FaqEditPanel