import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { tableColumnStyle } from "../../pdf_cst"
import ListingTableHeader from "./ListingTableHeader";
import ListingTableRow from "./ListingTableRow";

const styles = StyleSheet.create({...tableColumnStyle})

const ListingItemsTable = ({ items, type, resultName }) => (
    <View style={styles.tableContainer}>
        <ListingTableHeader type={type} resultName={resultName}/>
        <ListingTableRow items={items} type={type}/>
    </View>
);

export default ListingItemsTable