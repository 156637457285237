import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Checkbox,
  InputBase,
  Select,
  MenuItem,
  InputAdornment,
  Box,
  IconButton,
} from "@material-ui/core";
import Store from "./Store";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { DateTimePicker } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import Dropzone from "./Dropzone";
import MultiDropzone from "./MultiDropzone";
import MuiTextEditor from "./MuiTextEditor";
import { useFormContext, Controller } from "react-hook-form";
import UserChooser from "./UserChooser";
import RefreshIcon from "@material-ui/icons/Refresh";
import InputMask from "react-input-mask";
import { BidChooser } from "./BidChooser";
import GetAppIcon from '@material-ui/icons/GetApp';
import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined';
import { SketchPicker as ColorPicker } from 'react-color';

import ProfileForm from "../Profile/ProfileForm"
import { NModal } from "../Widget/NAdminCustomComponents"


import { Link } from "react-router-dom";

import * as cst from "../../js/constants/miscellaneous_cst";
import { useSelector } from "react-redux";
import Axios from "axios";

import MuiPhoneNumber from "material-ui-phone-number";

const localStyle = makeStylesGlobal((theme) => ({
  VMultiLineEdit: {
    border: "1px solid rgba(224, 224, 224, 0.9)",
    padding: "16px 20px 8px 20px",
  },
}));


export const VPhoneNumber = ({fieldName, label, validation, helperText}) => {

  const methods = useFormContext();
  const { watch, register, setValue, errors } = methods;
  const localStyle = makeStylesGlobal((theme) => ({
  }))

  useEffect(() => {
    register({ name: fieldName }, validation);
  }, [register, fieldName, validation]);

  const isError = errors[fieldName] ? true : false;
  const classes = localStyle();

  return (
    <>
      <div className={classes.VComponentMargin}>
        <InputLabel error={isError} shrink={true}>
          {label}
        </InputLabel>
        <MuiPhoneNumber
            defaultCountry={'be'}
            value={watch(fieldName)}
            autoFormat={false}
            countryCodeEditable={true}
            fullWidth={true}
            onChange={(value) => {
              setValue(fieldName, value, true);
            }}
        />
        <FormHelperText error={isError}>{helperText}</FormHelperText>
      </div>
    </>
  )
}

export const VDropzone = ({ fieldName, label, validation, fileType }) => {

  const methods = useFormContext();
  const { watch, register, setValue, errors } = methods;

  const value = watch(fieldName);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    register({ name: fieldName }, validation);
    setIsInitialized(true);
  }, [register, fieldName, validation]);

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";


  const localStyle = makeStylesGlobal((theme) => ({
    IconBackground: {
        backgroundColor: `rgba(255, 255, 255, 0.4)`, 
        '&:hover': {
          backgroundColor: `rgba(255, 255, 255, 0.8)`
        },
    },
    DropZoneIconButton : {
      display: 'flex',
      marginTop: '-56px',
      justifyContent: "center",
      opacity: 0,
      '&:hover': {
        opacity: 1,
      }
    }
  }));
  const classes = localStyle();

  //voluntarily connecting to isInitialized to make sure we load after hook forms init
  return (
    <>
      <div
        className={classes.VComponentMargin}
        initialized={isInitialized.toString()}
      >
        <InputLabel error={isError} shrink={true}>
          {label}
        </InputLabel>

        <Dropzone
          isMulti={false}
          value={value}
          onChange={(guid) => {
            setValue(fieldName, guid, true);
          }}
          fileType={fileType}
        />


        {value && <Box className={classes.DropZoneIconButton}>
          <Link 
            to={"/image/" + value}
            download 
            target="_blank"
            underline="none"
          >
            <IconButton 
              classes={{root: classes.IconBackground}}
            >
              <GetAppIcon/>
            </IconButton>
          </Link>
        </Box>}

        {value === undefined ? (
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        ) : null}
      </div>
    </>
  );
};

export const VMultiDropzone = ({ fieldName, label, validation, fileType }) => {
  const methods = useFormContext();
  const { watch, register, setValue, errors } = methods;

  const value = watch(fieldName, []);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    register({ name: fieldName }, validation);
    setIsInitialized(true);
  }, [register, fieldName, validation]);

  const classes = localStyle();

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";

  //voluntarily connecting to isInitialized to make sure we load after hook forms init
  return (
    <>
      <div
        className={classes.VComponentMargin}
        initialized={isInitialized.toString()}
      >
        <InputLabel error={isError} shrink={true}>
          {label}
        </InputLabel>
        <MultiDropzone
          value={value}
          onChange={(index, guid) => {
            var current = watch(fieldName, []);
            var newArray = [...current];

            if (index >= 0) {
              if (guid == null) {
                newArray.splice(index, 1);
              } else {
                newArray[index] = guid;
              }
            } else {
              newArray.push(guid);
            }

            setValue(fieldName, newArray);
          }}
          fileType={fileType}
        />

        {value === undefined ? (
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        ) : null}
      </div>
    </>
  );
};

export const VDatePicker = ({ fieldName, label, validation }) => {
  const methods = useFormContext();
  const { register, errors, watch, setValue } = methods;

  let value = watch(fieldName);
  function defaultDate() {
    let todayDate = new Date();
    let year = todayDate.getFullYear();
    let month = (1 + todayDate.getMonth()).toString().padStart(2, "0");
    let day = todayDate.getDate().toString().padStart(2, "0");
    let date = `${year}-${month}-${day}T00:00`;
    value = date;
    return setValue(fieldName, value, true);
  }

  useEffect(() => {
    register({ name: fieldName }, validation);
  }, [register, fieldName, validation]);

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";
  const classes = localStyle();

  return (
    <>
      <div className={classes.VComponentMargin}>
        <InputLabel error={isError} shrink={true}>
          {label}
        </InputLabel>
        <DatePicker
          openTo="date"
          value={value ? value : defaultDate()}
          onChange={(e) => {
            let year = e.getFullYear();
            let month = (1 + e.getMonth()).toString().padStart(2, "0");
            let day = e.getDate().toString().padStart(2, "0");
            let date = `${year}-${month}-${day}T00:00`;
            setValue(fieldName, date, true);
          }}
          fullWidth={true}
          ampm={false}
          views={["year", "month", "date"]}
          format={"dd/MM/yyyy"}
        />
        {isError ? (
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        ) : null}
      </div>
    </>
  );
};

export const VDateTimePicker = ({ fieldName, label, validation, onBlur }) => {
  const methods = useFormContext();
  const { register, errors, watch, setValue } = methods;

  const rawValue = watch(fieldName) + "Z";
  const value = Date.parse(rawValue);

  useEffect(() => {
    register({ name: fieldName }, validation);
  }, [register, fieldName, validation]);

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";
  const classes = localStyle();

  let initialDate = new Date();

  return (
    <>
      <div className={classes.VComponentMargin}>
        <InputLabel error={isError} shrink={true}>
          {label}
        </InputLabel>
        <DateTimePicker
          value={value ? value : null}
          onChange={(e) => {
            setValue(fieldName, e.toISOString().substring(0, 16), true);
          }}
          initialFocusedDate={initialDate.setMinutes(0)}
          onBlur={onBlur}
          fullWidth={true}
          ampm={false}
          views={["date", "hours"]}
          format={"dd/MM/yyyy HH:mm"}
        />
        {isError ? (
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        ) : null}
      </div>
    </>
  );
};

export const VMuiTextEditor = ({ isShort, fieldName, label, validation, isNew }) => {
  const methods = useFormContext();
  const { register, errors, watch, setValue } = methods;

  const value = watch(fieldName);

  useEffect(() => {
    register({ name: fieldName }, validation);
  }, [register, fieldName, validation]);

  const classes = localStyle();

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";

  return (
    <>
      <div className={classes.VComponentMargin}>
        <InputLabel error={isError} shrink={true}>
          {label}
        </InputLabel>
        <MuiTextEditor
          value={value}
          onChange={(html) => {
            setValue(fieldName, html);
          }}
          isNew={isNew}
          isShort={isShort}
        />

        {isError ? (
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        ) : null}
      </div>
    </>
  );
};

export const VStoreChooser = ({ fieldName, validation, toggle }) => {
  const methods = useFormContext();
  const { watch, setValue, register, errors } = methods;

  const classes = localStyle();

  const value = watch(fieldName);

  useEffect(() => {
    register({ name: fieldName }, validation);
  }, [register, fieldName, validation]);

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? "Set a store" : "";

  return (
    <>
      <div className={classes.VComponentMargin}>
        <Store
          value={value}
          toggle={!!toggle}
          editable={true}
          onChange={(e) => {
            setValue(fieldName, e);
          }}
        />
        {isError ? (
          <FormHelperText style={{ textAlign: "center" }} error={isError}>
            {errorMessage}
          </FormHelperText>
        ) : null}
      </div>
    </>
  );
};

export const VCheckBox = ({ fieldName, label, validation }) => {

  const methods = useFormContext();
  const {
    control,
    errors
  } = methods;

  const localStyle = makeStylesGlobal((theme) => ({
    LabelVCheckBox: {
      marginTop: "10px", 
      marginLeft: "-16px", 
      cursor: "pointer",
    },
    LabelAndCheckBoxGroup: {
      flexDirection: "row", 
      flexWrap: "nowrap"
    }
  }));

  const classes = localStyle();
  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";

  return (
    <div 
      className={classes.VComponentMargin}
    >
      <FormControl error={isError}>
        <FormGroup className={classes.LabelAndCheckBoxGroup}> 
          <FormControlLabel
            control={
              <Controller
                  name={fieldName}
                  type="checkbox"
                  control={control}
                  rules={validation}
                  as={<Checkbox color="primary"/>}
              />
            }
          />
          <div className={classes.LabelVCheckBox}>{label}</div>
        </FormGroup>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    </div>
  );
};

export const VColorChooser = ({
  fieldName,
  label,
  validation
}) => {
  const methods = useFormContext();
  const { setValue, register, watch, control } = methods;
  const [ stringValue, setStringValue] = useState(watch(fieldName) ?watch(fieldName) : "#FFF" );
  
  const localStyle = makeStylesGlobal((theme) => ({
    Label: {
    },
    LabelAndCheckBoxGroup: {
      flexDirection: "row", 
      flexWrap: "nowrap"
    }
  }));

  const classes = localStyle();

  const handleChangeComplete = (color) => {
    setValue(fieldName, color.hex, true);
    setStringValue(color.hex);
  };

  useEffect(() => {
    register({ name: fieldName }, validation);
  }, [register, fieldName, validation]);

  return (
    <div className={classes.VComponentMargin}>
      <div className={classes.Label}>{label}</div>
      <ColorPicker
        color={stringValue}
        onChangeComplete={handleChangeComplete}
        presetColors={[]}
        disableAlpha
      />
    </div>
  );
};

export const VNumeric = ({
  fieldName,
  label,
  validation,
  unit,
  integer,
  ...props // onBlur, InputProps, fullwidth, type, helperText ( for mobile number), multiline
}) => {
  const convert = (value) => {
    if (!value || value === "") return null;
    return integer ? parseInt(Number(value)) : Number(value);
  };

  const cleanString = (value) => {
    if (!value || value === "") return null;
    const trimmed = value.trim();
    return integer ? parseInt(trimmed).toString() : trimmed;
  }

  const methods = useFormContext();
  const { errors, setValue, register, watch, control } = methods;
  const [stringValue, setStringValue] = useState(watch(fieldName));
  const classes = localStyle();

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";

  const handleChange = (event) => {
    const value = event.target.value;
    const converted = convert(value);

    if (converted === null) {
      setStringValue("");
      setValue(fieldName, null,true);
    } else {
      const finalString =
        isNaN(converted) ? stringValue : cleanString(value);
      const finalValue = convert(finalString);
      setStringValue(finalString);
      setValue(fieldName, finalValue,true);
    }
  };

  useEffect(() => {
    register({ name: fieldName }, validation);
  }, [register, fieldName, validation]);

  return (
    <div className={classes.VComponentMargin}>
      <TextField
        value={stringValue}
        error={isError}
        helperText={errorMessage}
        label={label}
        onBlur={control.onBlur}
        onFocus={control.onFocus}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{style: { textAlign: "right" }}}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
};

export const VMultiLineEdit = ({
  fieldName,
  label,
  validation,
  ...props // onBlur, InputProps, fullwidth, type, helperText ( for mobile number), multiline
}) => {
  const methods = useFormContext();
  const { errors, control } = methods;

  const classes = localStyle();

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";

  return (
    <div className={classes.VComponentMargin}>
      <InputLabel shrink>{label}</InputLabel>
      <Controller
        control={control}
        name={fieldName}
        rules={validation}
        as={
          <InputBase
            className={classes.VMultiLineEdit}
            error={isError}
            helperText={errorMessage}
            fullWidth={true}
            multiline
            rows={4}
            rowsMax={8}
          />
        }
      />
    </div>
  );
};

export const VTextField = ({
  fieldName,
  label,
  validation,
  ...props // onBlur, InputProps, fullwidth, type, helperText ( for mobile number), multiline
}) => {
  const methods = useFormContext();
  const { errors, control } = methods;

  const classes = localStyle();

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";

  return (
    <div className={classes.VComponentMargin}>
      <Controller
        control={control}
        name={fieldName}
        rules={validation}
        as={
          <TextField error={isError} helperText={errorMessage} label={label} />
        }
        {...props}
      />
    </div>
  );
};

export const VUserChooser = ({
  onUserSelected,
  value,
  label,
  fieldName,
  validation,
  showRefresh
}) => {
  const methods = useFormContext();
  const { register, setValue } = methods;

  const [currentUser, setCurrentUser] = useState(value);
  const classes = localStyle();

  const handleUserSelected = (data) => {
    setCurrentUser(data);
    onUserSelected && onUserSelected(data);
    setValue(fieldName, data.ID);
  };

  const isRefreshDisplayed = !!(!currentUser && onUserSelected) || showRefresh


  const [showModal, updateShowModal] = useState(false)
  const toggleModal = () => updateShowModal(state => !state)


  return (
    <div className={classes.VComponentMargin}>
      <InputLabel shrink={true}> {label} </InputLabel>
      <Box display="flex" flexWrap="nowrap" alignItems="center" width="100%">
        <Box width="100%">
          <UserChooser
            fieldName={fieldName}
            onUserSelected={handleUserSelected}
            inputRef={() => register({ name: fieldName, validation })}
            value={currentUser}
          />
        </Box>
        
        
        <Box style={{ float: "right", display: "flex" }} 
          //hidden={!!(!currentUser && onUserSelected) || hideRefresh}
        >
          { isRefreshDisplayed ?
            <IconButton
              onClick={() => onUserSelected(currentUser)}
              style={{ alignSelf: "center" }}
            >
              <RefreshIcon />
            </IconButton>
          : null}

          {currentUser ? 
          <>
            <IconButton 
                onClick={toggleModal}
            >
              <AccountBoxIcon/>
            </IconButton> 
            <NModal showModal={showModal} updateModalState={toggleModal}>
              <ProfileForm rowUserID={currentUser.ID} isPopUp={true} onClose={toggleModal}/>
            </NModal>
          </>

          : null}
        </Box>


      </Box>


    </div>
  );
};

export const VSelectInput = ({
  fieldName,
  label,
  allvalues,
  validation,
  onOpen,
  onClose,
}) => {
  const methods = useFormContext();
  const { errors, control } = methods;

  const classes = localStyle();
  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";
  return (
    <div className={classes.VComponentMargin}>
      <FormControl error={isError} style={{ fullWidth: true, width: "100%" }}>
        <InputLabel>{label}</InputLabel>
        <Controller
          name={fieldName}
          control={control}
          rules={validation}
          as={
            <Select label={label} onOpen={onOpen} onClose={onClose}>
              {allvalues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          }
        />
        <FormHelperText hidden={!isError}>{errorMessage}</FormHelperText>
      </FormControl>
    </div>
  );
};

export const VTextFieldMaskedInput = ({
  fieldName,
  label,
  validation,
  formatChars,
  mask,
  onBlur,
  emptyMaskChar = "_",
  ...props
}) => {
  const methods = useFormContext();
  const { errors, control } = methods;

  const classes = localStyle();

  const isError = errors[fieldName] ? true : false;
  const errorMessage = isError ? errors[fieldName].message : "";

  const [value, setValue] = useState("");

  return (
    <div className={classes.VComponentMargin}>
      <Controller
        control={control}
        name={fieldName}
        rules={validation}
        onBlur={onBlur}
        as={
          <InputMask
            value={value}
            onChange={(e) => setValue(e.target.value)}
            mask={mask}
            formatChars={formatChars}
            alwaysShowMask
            maskChar={emptyMaskChar}
          >
            {() => (
              <TextField
                variant="standard"
                helperText={errorMessage}
                error={isError}
                label={label}
                fullWidth
                {...props}
              />
            )}
          </InputMask>
        }
      />
    </div>
  );
};

export const VBidChooser = ({
  defaultValue,
  label,
  fieldName,
  validation,
}) => {
  const classes = localStyle();
  const methods = useFormContext();
  const [price, setPrice] = useState(defaultValue)
  const { register,setValue } = methods;
  const onChange = (value) =>{
    setPrice(value)
    setValue(fieldName, value)
  }
  return (
    <div className={classes.VComponentMargin} >
      <InputLabel shrink={true}> {label} </InputLabel>
      <Box display="flex" flexWrap="nowrap" alignItems="center" width="100%">
        <Box width="100%">
          <BidChooser
            fieldName={fieldName}
            inputRef={() => register({ name: fieldName, validation })}
            defaultValue={price}
            onChange={onChange}
            isStartPrice={true}
          />
        </Box>
      </Box>
    </div>
  );
};


export const VAuctionChooser = ({
  label,
  fieldName,
  validation,

  onOpen,
  onClose,
}) => {


  const methods = useFormContext();
  const { control } = methods;

  const token = useSelector(state => state.auth.token);

  const [auctionChoices, setAuctionChoices] = useState([]);

  const classes = localStyle(); 


  useEffect(() => {
    let cancelToken = Axios.CancelToken.source();
    const fetchData = () => {
      let url = cst.getAuctionChooser;
      var config = {
        cancelToken: cancelToken.token,
        headers: { Authorization: "Bearer " + token }
      };
      Axios.get(url, config).then(response => {
        let array = response.data;
        let convertArray = array.map(option => ({
          value: option.ID,
          label: `${option.Title} (${parseInt(option.EndDate)})` 
        }));

        setAuctionChoices(convertArray);
      });
    };
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [token]);

  return (
    <>
      <div className={classes.VComponentMargin}>
        <FormControl 
          error={false} 
          style={{fullWidth: true, width: "100%",}} 
        >
            <InputLabel>{label}</InputLabel>
            <Controller
              name={fieldName}
              control={control}
              rules={validation}
              as={
                <Select label={label} onOpen={onOpen} onClose={onClose}>
                  <MenuItem  value={null}><span style={{textAlign: "center"}}>None</span></MenuItem>
                  {auctionChoices && auctionChoices.map((option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              }
            />
        </FormControl>
      </div>
    </>
  )
}