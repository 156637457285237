const countryList = require('country-list');
let namesArray = countryList.getNames();
let codesArray = countryList.getCodes();

var labelNames = namesArray.map(function(values, index){
    return values
})
var valueCodes = codesArray.map(function(values, index){
    return values
})

export const computeCountriesArray = () => {
    var labelCountry;
    var valueCountry;
    var result = [];
    for (let i = 0; i < labelNames.length; i++) {
        
        labelCountry = labelNames[i];
        valueCountry = valueCodes[i];
        

        result[i] ={label: labelCountry, value: valueCountry}
    };
    result = result.sort(function compareFn(a, b) { return a.label > b.label ? 1 : (a.label < b.label ? -1 : 0) })
    return result;
}