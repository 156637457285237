import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { blueGrey }  from "@material-ui/core/colors"

const colorBlue = "#123741";
const colorGold = "#8d7f41";
const primaryFont = "BauerBodoni";
const secondaryFont = "Open Sans";
export const greyBackgroundColor = "#F8F8F8";
export const ColorBrightBlueDashboard = "rgba(61, 112, 253, 0.8)";

export const GlobalTheme = createMuiTheme({
  PrimaryFont: primaryFont,
  SecondaryFont: secondaryFont,
  ColorBlue: colorBlue,
  ColorGold: colorGold,
  FooterColor: "#333",
  FooterText: "#CCC",
  LightGrey: "#FAFAFA",
  ActiveColor: "#EEE",
  BackgroundColor: "white",
  LightLineWidth: "1px",
  typography: {
    fontFamily: "Open Sans",
  },
  palette: {
    primary: blueGrey,
  },
  NDashBoardChartHeight: "428px",
  NDashBoardWidgetHeight: "200px",
  overrides: {
    MuiButton: {
        root: {
            borderRadius: 0,
            border: "1px solid #DDD",
            textTransform: "none",
            textDecoration: "none",
            textAlign: "center",
          }
    },
  },

  // dashboard admin
  ColorBrightBlueDashboard: "rgba(61, 112, 253, 1)",
  FontSizeDashboardValue: "300%",
  FontSizeDashBoardDelta: "200%",
});

const GlobalStylesAppend = (newStyle,theme) => ({

  NSecHeader: {
    fontFamily: theme.PrimaryFont,
    fontSize: "200%",
    textDecoration: "none",
    color: theme.ActiveColor
  },
  NDropBox: {
    padding: "20px",
    border: "1px solid #DDD",
    lineHeight: "110%",
    color: theme.ActiveColor
  },
  NTextField: {
    color: theme.ActiveColor
  },
  NTextFieldInput: {
    color: theme.ActiveColor
  },
  NTextFieldInputHuge: {
    fontSize: "150%",
    backgroundColor: "#164D5A",
    border: "2px inset white",
    color: theme.ActiveColor,
  },
  NButton: {
    color: theme.ActiveColor,
    borderWidth: theme.LightLineWidth,
    borderColor: theme.ActiveColor,
    borderStyle: "solid",
    //margin: "1px",
    fontFamily: secondaryFont
  },
  NTable: {
  },
  NPaperWidget: {
    padding: "10px",
  },
  NVisiblePaper: {
    padding: "10px",
    margin: "10px 2px",
    backgroundColor: greyBackgroundColor
  },
  Debug: {
    backgroundColor: "red"
  },
  NHeight70 : {
    height: "70vh",
  },


  // margin & positionements
  LeftEndAlign : {
    display: "flex",
    justifyContent: "flex-start",
    margin: '8px'
  },
  RightEndAlign : {
    display: "flex",
    justifyContent: "flex-end",
    margin: '8px'
  },
  ButtonMargin: {
    margin: '10px',
  },
  VComponentMargin: {
    margin: '8px 8px 4px 8px',
  },
  disabled:{
    pointerEvents: "none", opacity: 0.4
  },
  NLabel:{
    color:"white",
    opacity: 0.4
  },
  ScrollableModalPaper : {
    display: "block",
    overflowY: "auto",
    height: "90%",
  },
  ...newStyle(theme)
});



export const makeStylesGlobal = newStyle => makeStyles(theme => GlobalStylesAppend(newStyle,theme));
