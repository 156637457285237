import { INVOICEURL, SETTLEMENTURL } from "./model_cst";

//Const variables for tables
export const defaultPageSize = 10
export const itemsPerPage = [10,50,100, 500, 1000]


// links 
export const facebookLink = "https://www.facebook.com/nativeauctionsbrussels/"
export const instagramLink = "https://www.instagram.com/nativeauctions/?hl=en"

//app currency

export const APPCURRENCY = "€"
//ProductStatus
export const manualProductStatusses = [
    { label: 'Inventory', value: 'Inventory', color: "rgba(1, 48, 143, 1)" },
    { label: 'In House', value: 'InHouse', color: "rgba(141, 143, 1, 1)" },
    { label: 'Returned', value: 'Returned', color: "rgba(143, 1, 44, 1)" },
    { label: 'Sold', value: 'Sold', color: "rgba(102, 201, 56, 1)" },
    { label: 'Sent', value: 'Sent', color: "rgba(1, 140, 110, 1)" }
];

export const productStatusses = [
    { label: 'In Auction', value: 'InAuction', color: "rgba(235, 135, 5, 1)" }, ...manualProductStatusses
];

//SettlementStatus

export const settlementStatus = [
    { value: 'Draft', label: 'Draft', color: "rgba(179, 179, 179)" },
    { value: 'Open', label: 'Open', color: "rgba(235, 135, 5, 1)" },
    { value: 'Paid', label: 'Paid', color: "rgba(102, 201, 56, 1)" },
]

//InvoiceStatus

export const invoiceStatus = [
    ...settlementStatus,
    { value: "Delivered", label: "Delivered", color: "rgb(0, 128, 26)" },
];

//AuctionStatus
export const auctionVisible = { value: 'Visible', label: 'Visible', color: "rgba(102, 201, 56, 1)" };
export const auctionStatus = [
    { value: 'Draft', label: 'Draft', color: "rgba(209, 217, 222, 1)" },
    { value: 'Archived', label: 'Archived', color: "rgba(44, 78, 145, 1)" },
    { value: 'Past', label: 'Past', color: "rgba(141, 143, 1, 1)"}
];

// NewsStatus

export const newsStatus = [
    { value: 'Draft', label: 'Draft', color: "rgba(209, 217, 222, 1)" },
    { value: 'Visible', label: 'Visible', color: "rgba(102, 201, 56, 1)" },
    { value: 'Archived', label: 'Archived', color: "rgba(44, 78, 145, 1)" }
];

// Payment Status (Invoice / Settlement details )
export const paymentStatus = [
    { value: true, label: 'Paid', color: "rgba(102, 201, 56, 1)" },
    { value: false, label: 'Open', color: "rgba(237, 38, 12)" },
]

//Userchooser default Data

export const defaultBillingData = {
    VATRate: 21,
    BaseComissionPercent: 25,
    Status: "Draft"
}


// regex
export const phoneRegex = /^(\+)?([ 0-9]){10,16}$/;
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const passwordRegex = /^.{5,}$/;
export const min12Chars = /.{20,}/s;
export const numberWithOptionalDecimal = /^[0-9]*[,.]{0,1}[0-9]{0,2}?$/; // /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/  // /^[0-9]\d*(\.\d+)?$/;
export const cannotStartwithBE = /^[^BE].*/ // or ^{BE} ??


//for VAT number


// helper text messages for validation purposes
export const msgEmailInvalid = 'E-mail is not valid';
export const msgEmailUnmatching = 'E-mail confirmation is not matching';
export const msgPasswordInvalid = 'Use a minimum password length of 5 or more characters';
export const msgPasswordUnmatching = 'Password Confirmation is not matching';
export const msgMobileNumber = "We'll send you a verification code on your mobile. Please type a valid mobile number.";
export const msgImageRequired = 'Picture is required';
export const msgPDFRequired = 'PDF file is required';
export const msgContentRequired = 'Content is required'
export const msgRequired = 'Required'
export const msgStartDateInvalid = "Start date is greater than end date."
export const msgEndDateInvalid = "End date is less than start date."
export const msgEstimatedLowInvalid = "Estimated low should be less than estimated high."
export const msgEstimatedHighInvalid = "Estimated high should be higher than estimated low."
export const msgArt44Required = "Article 44 applies, VAT number is required"
export const msgArt44ButVATIsBE = "For Article 44 to apply, VAT number should be from outside Belgium"
export const msgVATRate0 = "Article 44 applies, VAT rate should be 0%"
export const msgEmailNeeded = "A valid E-mail is required"

// statements
export const onlinePlatformStatementMsg = "For this auction we partner with renowned online platforms, where you can place absentee bids or bid in real time during the sale. "



// url for API requests
export const postUploadImage = `/image/upload/`;
export const getUserProfile = `/user/profile/`;
export const getAuctionChooser = `/auction/chooser`
export const assignProductToAuction = `/product/assign` 
export const transferProductToAuction = `/product/transfer`
export const unassignProductFromAuction = `/product/unassign`
export const getAuctionData = `/auction/`
export const postNewInvoiceLine = `/invoiceline`
export const invoiceLines = `/invoiceline/invoice`
export const setProductStatus = `/product/status`
export const setInvoiceStatus = `/invoice/status` 
export const setAuctionStatus = `/auction/status`
export const publishAuction = `/auction/publish`
export const testAuction = `/auction/test`
export const setNewsStatus = `/news/status/`
export const setSettlementStatus = `/settlement/status`
export const mergeInvoices = `/invoice/merge`
export const thumbnailImage = `/Image/thumbnail`
export const mailing = `/user/mailing`  
export const bulkmailing = `/user/mailingset`
export const unfollowObject = `/follow/untrack` 
export const generateBillings = `/auction/invoices`
export const sendNotifications = `/auction/close`
//Api Request Profile
export const myBids = "/bid/view" 
export const viewTermsConditions = `/view/parameter/terms-conditions`

//Site URLS
export const productPage = "/admin/products"

// mask logic for price input 
export const priceMask = `999999999`;
export const priceChars = {'9' : numberWithOptionalDecimal } 

const typeMask = "a"
const invoiceCode = "inv";
const yearMask = "9999";
const numMask = "999";

const countryMask = "aa"
const numberMask = "******************************"
export const VATnumberMask = countryMask + numberMask
export const VATnumberChars = {
    '9': '[0-9]',
    'a': '[A-Z]',
    '*': `[0-9A-Z\` ´/'".,]`
}


export const phoneNumberMask = "+999999999999999"
export const phoneNumberChars = {
    '9': '[0-9]',
}


export const listing = {
    name: "group"
}

export const settlement = {
    name: "Settlement",
    id: "settlementId",
    status: settlementStatus,
    modelUrl: SETTLEMENTURL,
    modelLines: "/settlementline/settlement",
    modelLine: "/settlementline",
    ownBills: "/settlement/view",
    userType: "Seller",
    nextnumber: "/settlement/nextnumber",
    commission: "Margin",
    setLineStatus: setSettlementStatus,
    
    //mask logic for invoice number
    customMask: `${typeMask}set-${yearMask}-${numMask}`,
    NumberChars: {
        '9': '[0-9]',
        'a': '[CIP]',
        '*': '[A-Za-z0-9]'
    }
}

export const invoice = {
    name: "Invoice",
    id: "invoiceId",
    status: invoiceStatus,
    modelUrl: INVOICEURL,
    modelLines: invoiceLines,
    modelLine: postNewInvoiceLine,
    userType: "Customer",
    commission: "Commission",
    setLineStatus: setInvoiceStatus,
    ownBills: "/invoice/view",
    nextnumber: "/invoice/nextnumber",

    //mask logic for invoice number
    customMask: `${typeMask}${invoiceCode}-${yearMask}-${numMask}`,
    NumberChars: {
        '9': '[0-9]',
        'a': '[CIP]',
        '*': '[A-Za-z0-9]'
    }

}

export const biddingValues= [
    {value: 50, bid: 5},
    {value: 100 , bid: 10 },
    {value: 500 , bid: 20 },
    {value: 1000 , bid: 50 },
    {value: 3000 , bid: 100 },
    {value: 5000 , bid: 200 },
    {value: 8000 , bid: 250 },
    {value: 10000 , bid: 500 },
    {value: 25000 , bid: 500 },
    {value: 50000 , bid: 1000 },
    {value: Infinity, bid: 2000}
]

export const titleCategory = [
    { value: "", label: "" },
    { value: "Mr", label: " Mr." },
    { value: "Mrs", label: " Mrs." },
    { value: "Ms", label: " Ms." },
    { value: "Other", label: " Other" },
    { value: "MrMrs", label: " Mr. & Mrs." },
]
