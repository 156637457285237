import React, { useState } from "react";
import { useSelector } from "react-redux";
import Axios from 'axios'
import AuctionsEditPanel from "../../EditPanels/AuctionsEditPanel";
import { NDataLoading, NBottomCancelButton, NBottomActiveButton } from "../../Widget/NAdminCustomComponents";
import WronglyEncodedProducts from "../Products/WronlyEncodedProducts";
import { Box, Chip, Grid } from "@material-ui/core";
import { makeStylesGlobal } from "../../../themes/GlobalTheme";
import { NTypoLargeTitle, NTypoSmallTitle } from "../../Widget/NativeStyleds";
import * as cst from "../../../js/constants/miscellaneous_cst";
import { AUCTIONSURL } from "../../../js/constants/model_cst";

const localStyle = makeStylesGlobal(theme => ({
    chips: {
        margin: 1,
        color: "white",
        background: "rgba(143, 1, 44, 1)"
    },
}));

export default function AuctionWithProblems({ auctionId, history, onClose, products, auction, ...props }) {
    const token = useSelector(state => state.auth.token);
    const [publishErrorData, setPublishErrorData] = useState(products);
    const [auctionData, setAuctionData] = useState(auction)
    const classes = localStyle();
    const fixProductText = "Please fix the following products"
    const title = setTitle()

    const backendGet = (url) => {
        var config = {
            headers: { Authorization: "Bearer " + token }
        };
        return Axios.get(url, config)
    }

    function setTitle() {
        let temp = fixProductText;
        let auctionName = auctionData ? auctionData.Title : ""
        if (publishErrorData && publishErrorData.IsOk) temp = `The Auction Is Ready To Be Published`;
        if (publishErrorData && publishErrorData.AuctionErrors.length) temp = "Please fix the following auction details"
        return { auctionName: auctionName, title: temp };
    }
    function getPublishError() {
        let url = `${cst.testAuction}/${auctionId}`;
        backendGet(url).then(response => {
            setPublishErrorData(response.data);
        })
    }

    function getAuctionData() {
        let url = AUCTIONSURL + "/" + auctionId;
        backendGet(url)
            .then(response => {
                setAuctionData(response.data);
            })
    }

    function publishAgain() {
        let url = `${cst.publishAuction}/${auctionId}`;
        backendGet(url).then((response) => {
            if (response.data.IsOk) {
                onClose();
            }
        })
    }

    function problemStatus(chips) {
        return chips.map((chip,index) => {
            return (<Chip className={classes.chips} key={index} label={chip} />)
        })
    }
    if (publishErrorData == null || auctionData == null) { getAuctionData(); getPublishError(); return <NDataLoading /> }
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={6}>
                <NTypoLargeTitle>{title.auctionName}</NTypoLargeTitle>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box className={classes.RightEndAlign} >
                        <NBottomCancelButton
                            variant="outlined"
                            onClick={onClose}
                        >
                            Back
                    </NBottomCancelButton>
                        {publishErrorData.IsOk && <NBottomActiveButton
                            onClick={publishAgain}
                        >
                            Publish Again
                                    </NBottomActiveButton>}
                    </Box>
                </Grid>
            </Grid>
            <NTypoSmallTitle>{title.title}</NTypoSmallTitle>
            {!!publishErrorData.AuctionErrors.length &&
                <><Box>{problemStatus(publishErrorData.AuctionErrors)}</Box>
                    <AuctionsEditPanel
                        Data={auctionData}
                        isNew={false}
                        hideCancel
                        onUpdate={(data) => { getAuctionData(); getPublishError(); }}
                    />
                </>
            }
            {!!publishErrorData.ProductProblems.length && !!publishErrorData.AuctionErrors.length && <NTypoSmallTitle>{fixProductText}</NTypoSmallTitle>}
            {!!publishErrorData.ProductProblems.length && <WronglyEncodedProducts auctionId={auctionId} products={products.ProductProblems} {...props} onUpdate={getPublishError} />}
        </>
    );
}