import React, {useState, useEffect} from "react";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    Warning : {
        marginLeft: "30px",
    },
}));

export const WarningTestWebsite = ({currentURL}) => {

  const classes = useStyles();

  const [isTest, setIsTest] = useState(false)

  useEffect(() => {
    if (currentURL.includes("preprod")){
      setIsTest(true)
    }
  }, [currentURL])

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <>
      {isTest && 
        <Grid item className={classes.Warning}>
          <Alert severity="warning">This is a test website, all changes will be lost</Alert>
        </Grid>
      }
    </>
  )
}
