import React, { useState, useEffect } from "react";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import Chip from "@material-ui/core/Chip";

const localStyle = makeStylesGlobal(theme => ({
  chips: {
    margin: theme.spacing(0.5)
  },
  shopStyle: {
    width: "200"
  }
}));

export default function Store(props) {
  const classes = localStyle();

  const [currentValue, setCurrentValue] = useState(props.value);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  const shoplabels = ["Auctions", "Online"];

  const handleClick = (item, flag) => {
    if (props.editable) {
      let res = 0;
      if (props.toggle) 
      {
        res = item;
      }
      else
      {
        if (flag) {
          res = currentValue - item;
        } else {  
          res = currentValue + item;
        }
      }
      setCurrentValue(res);
      props.onChange(res);
    }
  };

  let shops = [];

  for (let shopid = 1; shopid <= shoplabels.length; shopid++) {
    if (currentValue & shopid) {
      shops.push(
        <Chip
          key={shopid}
          color="primary"
          clickable={props.editable}
          onClick={() => handleClick(shopid, true)}
          label={shoplabels[shopid - 1]}
        ></Chip>
      );
    } else {
      shops.push(
        <Chip
        key={shopid}
          variant="outlined"
          clickable={props.editable}
          onClick={() => handleClick(shopid, false)}
          label={shoplabels[shopid - 1]}
        ></Chip>
      );
    }
  }

  return <div className={classes.shopStyle}> {shops} </div>;
}
