import React from "react";
import {
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import RouterLink from "../../routes/RouterLink";

import { makeStyles } from "@material-ui/core/styles";


const MenuItem = (title, path) => 
{
  const effectivePath = "/profile/"+path;
  const isActive = document.location.pathname.startsWith(effectivePath);
  return (
  <ListItem button key={title} component={RouterLink} to={effectivePath} selected={isActive}>
    <ListItemText primary={"My " + title} />
  </ListItem>
)};

const useStyles = makeStyles((theme) => ({
  Online: {
    fontFamily: theme.PrimaryFont,
    color:theme.ColorBlue,
    fontStyle:"normal",
    fontSize:"120%"
  },
  Auction: {
    fontFamily: theme.PrimaryFont,
    color:theme.ColorGold,
    fontStyle:"normal",
    fontSize:"120%"
  }
}));

export default function ProfileMenu() {
  const classes = useStyles();
  return (
    <>
      <List>
          {MenuItem("Account", "account")}
          {MenuItem("Bids", "bid")}
          {MenuItem("Follows", "follow")}
          {MenuItem("Invoices", "invoice")}
          {MenuItem("Mailings", "mailing")}
        </List>
    </>
  );
}
