import React, { useRef, useState } from "react";
import MaterialTable, { MTableToolbar, MTableEditRow, MTableBodyRow } from "material-table";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { MAILINGLISTURL } from '../../js/constants/model_cst'
import Axios from 'axios';
import { DateTimeRenderer } from "../Widget/TableEditors"
import { NAdminToolBar, NAdminActiveButton, NCatalogAndTypo, NMailingAndTypo, NOnlineAndTypo, NAdminToolbarCancelButton, NAdminLabeledButtonGroup } from '../Widget/NAdminCustomComponents'

import ContactMailIcon from '@material-ui/icons/ContactMail';

import { ButtonGroup, TablePagination } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { fetchData, downloadFile } from "../../js/utils/backend";
import * as cst from '../../js/constants/miscellaneous_cst'
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { useReduxPrefsState } from "../../js/actions/prefs";
import {useHistoryState} from "../../js/utils/customHooks";

const localStyle = makeStylesGlobal(theme => ({
}));

export default function Mailing(props) {
    const classes = localStyle();

    const token = useSelector(state => state.auth.token);

    const tableRef = useRef();

    const [selectedRows, setSelectedRows] = useState([])

    const [onSelect, setOnSelect] = useState(false)

    const [isTableEditing, setIsTableEditing] = useState(false);
    const [searchText, setSearchText] = useHistoryState("searchText","");
    const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn","LastName");
    const [sortingDir, setSortingDir] = useHistoryState("sortingDir","asc");
    const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
    const [currentPage, setCurrentPage] = useHistoryState("currentPage",0);
    const [isInit,setIsInit] = useState(true);

    const [operation, setOperation] = useState("none");
    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
    function refreshTable() {
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    const submitPref = (mailing) => {
        let url = cst.bulkmailing;
        let data = {
            "UserIds": selectedRows,
        }
        data = { ...data, ...mailing }
        var config = {
            headers: { Authorization: "Bearer " + token }
        };
        Axios.post(url, data, config)
            .then(response => {
                refreshTable()
                setSelectedRows([])
            })
    }

    const operationChange = newOperation => {
        switch (newOperation) {
            case "none":
                setOnSelect(false);
                tableRef.current.onAllSelected(false);
                break;
            case "bulkMailing":
                setOnSelect(true);
                break;
            default: return null;
        }
        setOperation(newOperation);
    }

    var currentQuery = null;
    const exportCSV = () => {
        let url = "/user/mailingcsv";
        let type = 'text/csv;charset=utf-8;'
        downloadFile(token, currentQuery, url,type);
    }   

    return (
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    tableRef={tableRef}
                    columns={[
                        { title: "Bidder #", field: "SerialNumber", editable: "never",
                defaultSort: getSort("SerialNumber") },
                        {
                            title: "First Name",
                            field: "FirstName",
                            editable: "never",
                            defaultSort: getSort("FirstName"), customSort: (a, b) => null
                        },
                        {
                            title: "Last Name",
                            field: "LastName",
                            editable: "never",
                        defaultSort: getSort("LastName"), customSort: (a, b) => null
                        },
                        {
                            title: "Company",
                            field: "Company",
                            editable: "never",
                            defaultSort: getSort("Company"), customSort: (a, b) => null
                        },
                        {
                            title: "E-Mail",
                            field: "EMail",
                            editable: "never",
                            defaultSort: getSort("EMail"), customSort: (a, b) => null
                        },
                        {
                            title: "Mailing Catalog",
                            field: "MailingCatalog",
                            defaultSort: getSort("MailingCatalog")

                        },
                        {
                            title: "Mailing Online",
                            field: "MailingOnline",
                            defaultSort: getSort("MailingOnline")

                        },
                        {
                            title: "Mailing List",
                            field: "MailingList",
                            defaultSort: getSort("MailingList")

                        },
                        {
                            title: "Last Change",
                            field: "MailingStatusChange",
                            render: rowData => DateTimeRenderer(70, rowData.MailingStatusChange),
                            editable: "never",
                        defaultSort: getSort("MailingStatusChange")
                        }
                    ]}

                    data={query => {
                        const effectivePage = isInit ? currentPage : query.page;
                        setSearchText(query.search,true);
                        setSortingColumn(query.orderBy && query.orderBy.field);
                        setSortingDir(query.orderDirection);
                        setCurrentPage(effectivePage);
                        setIsInit(false);
                        const effectiveQuery={...query,page: effectivePage};
                        currentQuery = effectiveQuery
                        
                        return fetchData(token, effectiveQuery, MAILINGLISTURL)
                    }}
                    options={{
                        pageSize: rows,
                        selection: onSelect,
                        pageSizeOptions: cst.itemsPerPage,
                        actionsColumnIndex: 50,
                        showTextRowsSelected: false,
                        emptyRowsWhenPaging:false,
                        searchText: searchText,
                        selectionProps: rowData => ({
                            color: 'primary'
                        })
                    }}

                    onSelectionChange={
                        rowData => {
                            let guids;
                            guids = rowData.map(row => row.ID)
                            setSelectedRows(guids)
                        }
                    }

                    title="Mailing"
                    onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
                    components={{
                        EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />)},
                        Row: props => { setIsTableEditing(props.hasAnyEditingRow); return (<MTableBodyRow {...props} />)},
                        Pagination: props=>(<TablePagination {...props} className={selectedRows.length ?classes.disabled:null} />) ,
                        Toolbar: props => (
                            <div>
                                <div className={selectedRows.length ? classes.disabled : null}>
                                    <MTableToolbar {...props} />
                                </div>
                                <NAdminToolBar>

                                    {operation === "none" &&
                                        <>
                                            <NAdminActiveButton
                                                startIcon={<ContactMailIcon />}
                                                onClick={() => operationChange("bulkMailing")}
                                            >
                                                Change preferences
                                            </NAdminActiveButton>
                                            <NAdminActiveButton
                                                startIcon={<SaveAltIcon />}
                                                onClick={exportCSV}
                                            >
                                                Export selection to CSV
                                            </NAdminActiveButton>
                                        </>
                                    }

                                    {operation === "bulkMailing" &&
                                        <>
                                            <NAdminLabeledButtonGroup>
                                                <NCatalogAndTypo />
                                                <ButtonGroup variant="contained" color="primary" fullwidth style={{ margin: '8px' }}>
                                                    <NAdminActiveButton
                                                        onClick={() => {
                                                            let choice = { "Kind": "Catalog", "Pref": "Yes" }; submitPref(choice);
                                                        }}
                                                    >
                                                        Yes
                                        </NAdminActiveButton>
                                                    <NAdminActiveButton
                                                        onClick={() => {
                                                            let choice = { "Kind": "Catalog", "Pref": "No" }; submitPref(choice);
                                                        }}
                                                    >
                                                        No
                                        </NAdminActiveButton>
                                                </ButtonGroup>
                                            </NAdminLabeledButtonGroup>

                                            <NAdminLabeledButtonGroup>
                                                <NOnlineAndTypo />
                                                <ButtonGroup variant="contained" color="primary" fullwidth style={{ margin: '8px' }}>
                                                    <NAdminActiveButton
                                                        onClick={() => {
                                                            let choice = { "Kind": "Online", "Pref": "Yes" }; submitPref(choice);
                                                        }}
                                                    >
                                                        Yes
                                        </NAdminActiveButton>
                                                    <NAdminActiveButton
                                                        onClick={() => {
                                                            let choice = { "Kind": "Online", "Pref": "No" }; submitPref(choice);
                                                        }}
                                                    >
                                                        No
                                        </NAdminActiveButton>
                                                </ButtonGroup>
                                        </NAdminLabeledButtonGroup>
                                        <NAdminLabeledButtonGroup>
                                                <NMailingAndTypo />
                                                <ButtonGroup variant="contained" color="primary" fullwidth style={{ margin: '8px' }}>
                                                    <NAdminActiveButton
                                                        onClick={() => {
                                                            let choice = { "Kind": "List", "Pref": "Yes" }; submitPref(choice);
                                                        }}
                                                    >
                                                        Yes
                                        </NAdminActiveButton>
                                                    <NAdminActiveButton
                                                        onClick={() => {
                                                            let choice = { "Kind": "List", "Pref": "No" }; submitPref(choice);
                                                        }}
                                                    >
                                                        No
                                        </NAdminActiveButton>
                                                </ButtonGroup>
                                            </NAdminLabeledButtonGroup>

                                            <NAdminToolbarCancelButton
                                                style={{ margin: "0px" }}
                                                onClick={() => {
                                                    operationChange("none");
                                                }}
                                            >
                                                Done
                                </NAdminToolbarCancelButton>

                                        </>
                                    }
                                </NAdminToolBar>
                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
    );
}