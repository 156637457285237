import "babel-polyfill";
import * as actionTypes from "../constants/action_types";
import { useDispatch, useSelector } from "react-redux";

const dashboardsaved = (name, value) => ({
  type: actionTypes.DASHBOARDSAVED,
  payload: { [name]: value },
});

export const saveDashboard = (name, value) => {
  return (dispatch) => {
    dispatch(dashboardsaved(name, value));
  };
};

export const useDashboardState = (label, initValue) => {
  const dispatch = useDispatch();
  if (!useSelector((state) => state.dashboard[label])) {
    dispatch(saveDashboard(label, initValue));
  }

  return useSelector((state) => state.dashboard[label]);
};
