import React, { useRef } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useReduxPrefsState } from "../../js/actions/prefs"
import { PARAMETERSURL } from '../../js/constants/model_cst'
import { NAdminToolBar } from '../Widget/NAdminCustomComponents'


import { TablePagination } from '@material-ui/core';
import { fetchData } from "../../js/utils/backend";
import * as cst from '../../js/constants/miscellaneous_cst'
import ParameterEditPanel from "../EditPanels/ParameterEditPanel.js"

export default function Parameters(props) {
    const token = useSelector(state => state.auth.token);

    const tableRef = useRef();
    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);


    function refreshTable() {
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    detailPanel={
                        rowData => {
                            return (
                                <div >
                                    <ParameterEditPanel
                                        Data={rowData}
                                        onClose={refreshTable}
                                    />
                                </div>
                            );
                        }
                    }
                    tableRef={tableRef}
                    columns={[
                        {
                            title: "Parameter",
                            field: "Description",
                            editable: "never",
                            defaultSort: "asc"
                        },
                        {
                            title: "Type",
                            field: "ParamType",
                        },
                    ]}

                    data={query => {
                        return fetchData(token, query, PARAMETERSURL)
                    }}
                    options={{
                        pageSize: rows,
                        search: false,
                        pageSizeOptions: cst.itemsPerPage,
                        actionsColumnIndex: 50,
                        emptyRowsWhenPaging:false,
                        selectionProps: rowData => ({
                            color: 'primary'
                        })
                    }}
                    onChangeRowsPerPage={(nbRows) => setRows(nbRows)}
                    title="Parameters"
                    components={{
                        Pagination: props => (<TablePagination {...props} />),
                        Toolbar: props => (
                            <div>
                                <div>
                                    <MTableToolbar {...props} />
                                </div>

                                <NAdminToolBar>
                                </NAdminToolBar>
                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
    );
}