import React, { Fragment } from 'react'
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { tableColumnStyle, smallFontSize } from '../../pdf_cst';
import { formatCurrency } from '../../../../js/utils/formatter';
import { thumbnailImage, APPCURRENCY } from "../../../../js/constants/miscellaneous_cst"

const styles = StyleSheet.create({ ...tableColumnStyle,
    picture : {
        width: 30,
    },

})

const ListingTableRow = ({ items, type }) => {
    const rows = items.map((item, index) => 
        <View style={styles.row} key={index} wrap={false}>
            <Text style={styles.auctionNumber}>{item.AuctionNumber === 0 ? null : item.AuctionNumber}</Text>
            <View style={styles.thumbnailImage}>
                <Image
                    style={styles.picture}
                    src={`${thumbnailImage}/${item.OfficialImagesID[0] ? item.OfficialImagesID[0] : item.InternalImagesID[0]}`}
                />
            </View>
            <Text style={type === "results" ? styles.listingItemDescriptionShort :  styles.listingItemDescription}>{item.Title}</Text>
            <Text style={styles.estimate}>
                {formatCurrency(item.EstimatedLow, APPCURRENCY, 0, " ")} - {formatCurrency(item.EstimatedHigh, APPCURRENCY, 0, " ")}
            </Text>
            {type === "results" ?
                <Text style={styles.result}>
                {formatCurrency(item.DisplayFinalPrice, APPCURRENCY, 0, " ")} 
                </Text>: null}
        </View>
    ) 

    return (<Fragment>{ rows }</Fragment>)
};

export default ListingTableRow;