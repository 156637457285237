import React from 'react'
import { NAdminActiveButton, NAdminToolbarCancelButton } from './NAdminCustomComponents'
import Axios from 'axios'
import { useSelector } from 'react-redux';
import { unassignProductFromAuction } from '../../js/constants/miscellaneous_cst';

function UnassignChooser({ onCancel, onSubmit, selectedRows, auctionId }) {
    const token = useSelector(state => state.auth.token);


    function submit() {
        const config = {
            headers: { Authorization: "Bearer " + token }
        }
        Axios.post(unassignProductFromAuction, {AuctionId: auctionId ,ProductIds: selectedRows}, config)
        .then(response => {
            onSubmit()
        }).catch((e)=>console.log(e))
    }

    return (
        <>
            <NAdminActiveButton
                onClick={submit}
            >
                Unassign
            </NAdminActiveButton>
            <NAdminToolbarCancelButton
                onClick={onCancel}
            >
                Done
            </NAdminToolbarCancelButton>
        </>
    )
}


export default UnassignChooser
