import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Axios from 'axios'
import AuctionsEditPanel from "../../EditPanels/AuctionsEditPanel";
import EnteteEditPanels from "../../EditPanels/EnteteEditPanels";
import Products from "../Products/Products"
import { NDataLoading, NAdminWorkflowPanel, NAdminActiveButton } from "../../Widget/NAdminCustomComponents";
import GroupEditPanel from "../../EditPanels/GroupEditPanel";
import AuctionPreviewIcon from '@material-ui/icons/DesktopMac';
import PhoneRequestIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import EuroIcon from '@material-ui/icons/Euro';
import { Grid } from '@material-ui/core';
import * as cst from "../../../js/constants/miscellaneous_cst";
import GenerateBilling from "../Products/GenerateBilling";
import { NativeDialog } from "../../Widget/NativeDialog";
import AuctionWithProblems from "./AuctionWithProblems";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DownloadIcon from '@material-ui/icons/DescriptionOutlined';
import { downloadPdf } from "../../Widget/DownloadButton";


function storeType(store) {
    if (store === 1) {
        return "/auctions/detail/"
    } else if (store === 2) {
        return "/online/detail/"
    }
}

export default function AuctionWithProducts({ match, history, ...props }) {

    const token = useSelector(state => state.auth.token);

    const [invoicePopup, setInvoicePopup] = useState(false);
    const [auctionData, setAuctionData] = useState(null);

    const [publishFeedBack, setPublishFeedBack] = useState();

    const auctionId = match && match.params && match.params.auctionId;
    const isOnline = auctionData && auctionData.Store === 2;
    const isInProgress = auctionData && auctionData.InProgress;

    function getAuctionData() {
        if (auctionId) {
            var config = {
                headers: { Authorization: "Bearer " + token }
            };
            let url = "/auction/" + auctionId;
            Axios.get(url, config)
                .then(response => {
                    setAuctionData(response.data);
                })
        }
    }

    const handleSendNotifications = () => {
        let url = cst.sendNotifications + "/" + auctionId;
        var config = {
          headers: { Authorization: "Bearer " + token }
        };
    
        Axios.get(url, config).then(() => getAuctionData());
    };
    
    function publishAuction(auctionData) {
        
        let url = `${cst.publishAuction}/${auctionData.ID}`;
        var config = {
            headers: { Authorization: "Bearer " + token }
        };
        Axios.get(url, config)
            .then((response) => {
                setPublishFeedBack({ ...response.data, auction: auctionData })
                if (response.data.IsOk) {
                    getAuctionData();
                }
            }).catch(() => { })
    }
    
      function setStatus(guid, status) {
        let data = {
          "Status": status,
          "Items": [guid]
        }
        var config = {
          headers: { Authorization: "Bearer " + token }
        }
        Axios.post(cst.setAuctionStatus, data, config)
          .then(function (response) {
            getAuctionData();
          })
      }

    useEffect(() => {
        getAuctionData();
    }, [token, auctionId]);

    if (auctionData == null && auctionId) { return <NDataLoading /> }
    return (
        <>
            {invoicePopup &&
                      <NativeDialog
                        onClose={
            () => {
              getAuctionData();
                              setInvoicePopup(false);
                              
                          }
                        }
                        open
                        maxWidth={'sm'}
                      >
                        <GenerateBilling
                          auctionId={auctionId}
                        />
                      </NativeDialog>
            }
            {publishFeedBack && !publishFeedBack.IsOk &&
                          <NativeDialog
                            auctionId={publishFeedBack.auction.ID}
                            open
                            onClose={() => {
                                getAuctionData();
                              setPublishFeedBack(null);
                            }}
                          >
                            <AuctionWithProblems
                              auctionId={auctionId}
                              products={publishFeedBack}
                              auction={publishFeedBack.auction}
                              {...props} />
                          </NativeDialog>
                        }
            <EnteteEditPanels
                titleFormatter={(auction) => auction.Title}

                Data={auctionData}
                component={
                    auctionData.Status === "Group"
                        ?
                        <GroupEditPanel isNew={false} />
                        :
                        <AuctionsEditPanel isNew={false} />
                }
            />
            <NAdminWorkflowPanel>
                <Grid container>
                    {auctionData && (auctionData.Status === "Draft" || auctionData.Status === "Archived") ? <>
                        <Grid container item xs={12} sm={2} alignItems="center">
                            Auction preparation
                    </Grid>
                        <Grid itm xs={12} sm={10}>
                            <NAdminActiveButton
                                startIcon={<AuctionPreviewIcon />}
                                onClick={() =>
                                    history.push(storeType(auctionData.Store) + auctionId)
                                }
                            >
                                Preview
                            </NAdminActiveButton>
                            
                        </Grid> </> : null}
                    {auctionData && (auctionData.Status === "Draft" || auctionData.Status === "Archived") ? <>
                        <Grid container item xs={12} sm={2} alignItems="center">
                            Auction launch
                    </Grid>
                        <Grid itm xs={12} sm={10}>
                            <NAdminActiveButton
                                startIcon={<VisibilityIcon />}
                                onClick={() =>
                                    publishAuction(auctionData)
                                }
                            >
                                Publish
                                </NAdminActiveButton>
              </Grid></> : null}
            {isInProgress ? <>
                    <Grid container item xs={12} sm={2} alignItems="center">
                        After auction
              </Grid>
              <Grid itm xs={12} sm={10}>
                {auctionData && auctionData.Status === "Visible" && !isOnline ?
                  <NAdminActiveButton
                    startIcon={<VisibilityOffIcon />}
                    onClick={() =>
                      setStatus(auctionData.ID, "Past")
                    }
                  >
                    Hide unsold objects
                                </NAdminActiveButton> : null}
                {isOnline ?
                  <NAdminActiveButton
                    startIcon={<EmailIcon />}
                    onClick={handleSendNotifications}
                  >
                    Notify Winners/Loosers
                        </NAdminActiveButton> : null}
                        <NAdminActiveButton
                            onClick={() => setInvoicePopup(true)}
                            startIcon={<EuroIcon />}
                        >
                            Generate Invoices
                        </NAdminActiveButton>
                    </Grid></> : null}
                    { auctionData && (auctionData.Status=== "Visible" ||auctionData.Status=== "Past")   ? <>
                    <Grid container item xs={12} sm={2} alignItems="center">
                        Auction state
                    </Grid>
                    <Grid itm xs={12} sm={10}>
                            <NAdminActiveButton
                                  startIcon={<VisibilityOffIcon />}
                                  onClick={() =>
                                    setStatus(auctionData.ID, "Archived")
                                  }
                                >
                                  Archive
                                </NAdminActiveButton>
                    </Grid></> : null
                    }
                    <Grid container item xs={12} sm={2} alignItems="center">
                        PDF Reports
                    </Grid>
                    <Grid itm xs={12} sm={10}>
                    <NAdminActiveButton
                              startIcon={<DownloadIcon />}
                              onClick={()=>downloadPdf("group", auctionId, token)}
                            > 
                Estimates
                            </NAdminActiveButton>
              {isInProgress ?
                <NAdminActiveButton
                  startIcon={<DownloadIcon />}
                  onClick={() => downloadPdf("results", auctionId, token)}
                >
                  Results
                </NAdminActiveButton> : null}
                {isInProgress ?
                <NAdminActiveButton
                  startIcon={<DownloadIcon />}
                  onClick={() => downloadPdf("resultsNoPremium", auctionId, token)}
                >
                  Results without Premium
                </NAdminActiveButton> : null}
                <NAdminActiveButton
                                startIcon={<PhoneRequestIcon />}
                                onClick={() =>
                                  history.push(`/admin/auction/${auctionId}/phonerequest`)
                                }
                            >
                                Phone Requests
                                </NAdminActiveButton>
                    </Grid>
                </Grid>

            </NAdminWorkflowPanel>
            <Products
                auctionData={auctionData}
              auctionId={auctionId}
              store={auctionData.Store}
                componentRefresher={getAuctionData}
              history={history}
                {...props}
            />
        </>
    );
}