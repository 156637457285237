import React from "react";
import { Paper, Box } from "@material-ui/core"
import Typography from "@material-ui/core/Typography";
import { makeStylesGlobal, ColorBrightBlueDashboard } from "../../../../themes/GlobalTheme";
import { useDashboardState } from "../../../../js/actions/dashboard";
import {
    BarChart, Bar, 
    CartesianGrid, XAxis, Tooltip, Line,
    ResponsiveContainer,
} from 'recharts';

export default function BiddingChartWidget () {

    const localStyle = makeStylesGlobal(theme => ({
        ChartContainer: {
            boxSizing: "border-box",
            width: "100%",
            height: theme.NDashBoardChartHeight, //"428px",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
        },
    }));
    const classes = localStyle();


    const recentBids = useDashboardState("auctionbids", null);

    const timestamp = recentBids && recentBids.map((item, index) => {
        const wholeDate = new Date(item.Time)
        const hours = wholeDate.getHours() 
        const minutes = wholeDate.getMinutes() 
        return `${hours}h${minutes ? minutes : "00"}`
    })

    const roundedHour = timestamp && timestamp.map((item, index) => {
        if (item.includes("00")) {
            return item.substring(0, item.length - 2).padStart(3, '0') 
        } else {
            return null
        }
    }) 

    const roundedHourBidArray = recentBids && recentBids.map(function (item, index) {
        return { 
            UTCtime: item.Time, 
            Timestamp: roundedHour[index],
            Bids: item.Count 
        };
    });

    const CustomTooltip = ({ active, payload, label }) => {
        const wholeDate = payload ? new Date(payload[0] && payload[0].payload && payload[0].payload.UTCtime) : null
        const day = wholeDate.getUTCDate()
        const month = (wholeDate.getUTCMonth() + 1).toString().padStart(2, '0')
        const hours = wholeDate.getHours() 
        const minutes = wholeDate.getMinutes()

        const ddMM = `${day}/${month}`
        const tooltipTimestamp = `${hours}h${minutes ? minutes : "00"}`
        
        if (active) {
            return (
                <div>
                    <p>{`${payload[0].value} Bids`}</p>
                    <p>{`${tooltipTimestamp}`}</p>
                    <p>{`${ddMM}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <Paper 
                className={classes.NPaperWidget}
            >
                <Box className={classes.ChartContainer}>
                    <Typography variant="h6">Bid Flow</Typography>
                    <ResponsiveContainer>
                        <BarChart
                            data={roundedHourBidArray}
                            barGap={0} barCategoryGap={0}
                            margin={{left: 10, right: 10}}
                        >
                            <Bar
                                dataKey="Bids"
                                fill={ColorBrightBlueDashboard}
                                background={{ fill: 'white' }}
                            />
                            <CartesianGrid
                                horizontal={true}
                                vertical={false}
                            />
                            <XAxis
                                dataKey="Timestamp" 
                                name = 'Time'
                                tick={{fontSize: 10}}
                                interval={4}
                            />
                            <Line dataKey="Bids"  
                            stroke='red'/>
                            <Tooltip 
                                content={<CustomTooltip />}
                                wrapperStyle={{backgroundColor: "rgba(255,255,255, 0.7)"}}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Paper>
        </>
    )
}