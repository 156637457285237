import React, {useEffect} from "react";
import AdminLayout from "../layouts/AdminLayout";
import StoreLayout from "../layouts/StoreLayout";
import ProfileLayout from "../layouts/ProfileLayout";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Logout from "../components/Account/Logout";
import Login from "../components/Account/Login";
import { useSelector, useDispatch } from "react-redux";
import { tokenAuthenticated, entry } from "../js/actions/auth";
import { updateTab } from "../js/actions/synctab";
import RegistrationForm from "../components/Account/RegistrationForm";
import ResetPassword from "../components/Account/ResetPassword";
import ChangePassword from "../components/Account/ChangePassword";
import ScrollToTop from "./ScrollToTop";
import DocumentDownload from "../components/Documents/DocumentDownload";

import { Helmet } from 'react-helmet';

function PrivateRoute({ component: Component, restrictedTo, ...rest }) {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const validated = useSelector((state) => state.auth.isValidated);
  const ok = authenticated && validated;
  const activeRole = useSelector((state) => state.auth.role);
  const appropriate = restrictedTo ? activeRole === restrictedTo : true;
  const dispatch = useDispatch();

  if (!ok) {
    dispatch(entry(document.location.pathname));
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        ok === true ? (
          appropriate === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: "/" },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

const AuthRouter = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  let lastAuthentication = useSelector((state) => state.auth.since);

  useEffect(() => {
    setInterval(() => {
      dispatch(updateTab());
    }, 5000);
  });

  if (token) {
    lastAuthentication = lastAuthentication
      ? new Date(lastAuthentication)
      : new Date(2000, 1, 1);
    const numberHours =
      (new Date().getTime() - lastAuthentication.getTime()) / (1000 * 3600);
    if (numberHours < 1) {
      console.log("Recent authentication found.");
    } else {
      dispatch(tokenAuthenticated(token));
    }
  }

  return (
    <BrowserRouter> 
      <Helmet>
      <script>Router</script>
        <title></title>
      </Helmet>
      <ScrollToTop>
        <Switch>
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={RegistrationForm} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/document/:doctype/:docID/:filename" component={DocumentDownload} />
          <PrivateRoute
            path="/admin"
            restrictedTo="Admin"
            component={AdminLayout}
          />
          <PrivateRoute path="/profile" component={ProfileLayout} />

          <Route
            path="/online"
            render={(props) => <StoreLayout {...props} site="online" />}
          />
          <Route
            path="/auctions"
            render={(props) => <StoreLayout {...props} site="auctions" />}
          /> 
          <Route 
            path="/upcoming" 
            render={(props) => <StoreLayout {...props} site="upcoming" />}
          />
          <Route 
            path="/draft" 
            render={(props) => <StoreLayout {...props} site="upcoming" />}
          />
          <Redirect  from="/" to="/upcoming" />
          {/*if remove exact then redirect to default route */}
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default AuthRouter;
