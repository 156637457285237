import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import MailingPreferences from "../components/Profile/MailingPreferences";
import ProfileForm from "../components/Profile/ProfileForm";
import Follows from "../components/Profile/Follows";
import Billing from "../components/Profile/Billing/Billing";
import { Container } from "@material-ui/core";
import Bids from "../components/Profile/Bids";
import { Helmet } from 'react-helmet';

export default function ProfileRouter() {
        return (
                <>
                <Container maxWidth={"md"} disableGutters>
                        <Helmet>
                                <title>Native Auctions - Profile</title>
                        </Helmet>
                        <Switch>
                                <Route path="/profile/account" component={props => <ProfileForm {...props} inStore />} />
                                <Route path="/profile/mailing" component={MailingPreferences} />
                                <Route path="/profile/invoice" component={Billing} />
                                <Route path="/profile/bid" component={Bids} />
                                <Redirect exact from="/profile" to="/profile/account" />
                        </Switch>
                </Container>
                <Container maxWidth={false} disableGutters>
                        <Switch>
                                <Route path="/profile/follow" component={Follows} />
                        </Switch>
                </Container>
                </>
        );
}
