import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { titleColor, formatDate } from '../../pdf_cst';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        marginTop:5
    },
    invoiceDate: {
        fontStyle: 'bold',
    },
    boldTitleColor: {
        fontStyle: 'bold',
        color: titleColor
    },
    auctionName: {
        marginTop: 24,
        textAlign: "center",
    },
    left:{
        width:"20%"
    }

});

const BillDetails = ({ bill, type }) => {
    return (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text style={styles.left}>{`${type.name} Number `}</Text>
                <Text style={styles.boldTitleColor}>{bill.Number}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.left}>{`${type.name} Date `}</Text>
                <Text style={styles.boldTitleColor}>{formatDate(bill.InvoiceDate)}</Text>
            </View >
            {bill.AuctionTitle ? <View>
                <Text style={styles.auctionName}>{bill.AuctionTitle} - {formatDate(bill.AuctionDate)} </Text>
            </View>
                :
                null
            }
        </Fragment>
    );
}

export default BillDetails