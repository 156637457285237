import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { tableColumnStyle, smallFontSize, htmlToString } from '../../pdf_cst';
import { formatCurrencyInvoice } from '../../../../js/utils/formatter';
import { APPCURRENCY } from '../../../../js/constants/miscellaneous_cst';

const styles = StyleSheet.create({...tableColumnStyle});

const BillTableRow = ({hideVAT, items }) => {
    const rows = items.Lines.map((item, index) =>
        <View>
            <View style={styles.row} key={index} wrap={true}>
                <Text style={styles.lot} fixed> {item.Lot || ""}</Text>
                <Text style={styles.label}>{item.Label} {'\n'}</Text>
                {hideVAT ? <Text style={styles.VAT}></Text> : null}
                <Text style={styles.hammer}>{formatCurrencyInvoice(item.Price, APPCURRENCY, 2, " ")}</Text>
                <View style={styles.commission}>
                <Text >{formatCurrencyInvoice(item.CommissionValue, APPCURRENCY, 2, " ")}</ Text>
                <Text style={styles.percent}>{item.CommissionPercent}%</Text>
                </View>
                {hideVAT ? null :
                    <Text style={styles.VAT}>{!items.IsWithVATonCommission && item.VATValue === 0 ? "" : formatCurrencyInvoice(item.VATValue, APPCURRENCY, 2, " ")}</Text>}
                <Text style={styles.total}>{formatCurrencyInvoice(item.Total , APPCURRENCY, 2, " ")}  </Text>
                
            </View>
            <Text style={styles.objDescription}>
                {htmlToString(item.Description)}
            </Text>
            <View style={styles.border}></View>
        </View>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default BillTableRow