import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import Axios from "axios";
import {
  Grid,
  Typography,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import RouterLink from "../../routes/RouterLink";
import ImageFadeIn from "react-image-fade-in";
import clsx from 'clsx';
import { CollapsibleFadedContent } from "../Widget/NStoreCustomComponents"
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";


import { ContentPlaceholder, FixedWidthStoreContainer } from "../../layouts/StoreLayout";
import { Deadline } from "../Widget/Deadline";

const useStyles = makeStyles((theme) => ({
  FeaturedVisual: {
    backgroundSize: "cover",
    backgroundPosition: "right bottom",
    flexGrow: 1,
    flexBasis: "30vh"
  },
  FeaturedVisualDouble: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    flexGrow: 1,
    flexBasis: "30vh"
  },
  FeaturedVisualContainer: {
    minHeight: "80vh",
    width: "100%",
  },
  FeaturedVisualContainerDouble: {
    minHeight: "45vh",
    width: "100%",
  },
  Headline: {
    fontSize: "200%",
    [theme.breakpoints.up('lg')]: {
      fontSize: "3vw"
    },
    padding: "10px",
    fontFamily: theme.PrimaryFont
  },
  HeadlineDouble1: {
    fontSize: `calc(max(30px, max(1vh,min(2vw,3vh))))`,
    textAlign: "left",
    padding: "5px",
    fontFamily: theme.PrimaryFont
  },
  HeadlineDouble2: {
    fontSize: `calc(max(30px, max(1vh,min(2vw,3vh))))`,
    textAlign: "right",
    padding: "5px",
    fontFamily: theme.PrimaryFont
  },
  SubLine: {
    fontSize: "140%",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    fontFamily: theme.SecondaryFont,
    color: theme.ColorC2,
  },
  HeadLineBlock: {
    background: "#FFFFFF99",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    padding: 10,
    margin: 10,
    marginLeft: 30,
    marginBottom:30,
    color: theme.ColorC1,
    textTransform: "uppercase",
    fontWeight: 300,
  },
  HeadLineBlock1: {
    background: "#FFFFFF99",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    padding: 10,
    margin: 10,
    marginLeft: 30,
    marginTop:80,
    color: theme.ColorC1,
    textTransform: "uppercase",
    fontWeight: 300,
  },
  HeadLineBlock2: {
    background: "#FFFFFF99",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    padding: 10,
    margin: 10,
    marginRight: 30,
    marginBottom:30,
    color: theme.ColorC1,
    textTransform: "uppercase",
    fontWeight: 300,
  },
  SublineA: {
    flexGrow: 1,
    display: "inline-block",
  },
  SublineA1: {
    fontSize: "80%",
    flexGrow: 1,
    display: "inline-block",
    textAlign: "left",
  },
  SublineA2: {
    flexGrow: 1,
    fontSize: "80%",
    display: "inline-block",
    textAlign: "right",
  },
  SublineB: {
    marginTop: "2px",
    height: "20px",
    display: "inline-block",
  },
  ArrowFooter: {
    height: "16px",
    width: "16px",
  },
  BlockAuctions: {
    paddingTop: "10px",
    paddingBottom: "30px",
    marginTop: "10px",
    borderTop: "solid 1px",
    borderTopColor: theme.ColorGold + "44"
  },
  BlockOnline: {
    paddingTop: "10px",
    paddingBottom: "30px",
    marginTop: "10px",
    borderTop: "solid 1px",
    borderTopColor: theme.ColorBlue + "44"
  },

  BlockText: {
    paddingLeft: "20px",
    [theme.breakpoints.down('xs')]: {
      paddingLeft: "8px",
    }
  },

  BlockHeadlineAuctions: {
    lineHeight: "normal",
    color: theme.ColorGold,
    textTransform: "uppercase",
    fontFamily: theme.PrimaryFont,
    fontSize: "170%",
    marginTop: "-2px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "150%"
    }
  },
  BlockHeadlineOnline: {
    lineHeight: "normal",
    color: theme.ColorBlue,
    textTransform: "uppercase",
    fontFamily: theme.PrimaryFont,
    fontSize: "170%",
    marginTop: "-2px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "150%"
    }
  },


  BlockSubHeadlineAuctions: {
    color: theme.ColorC2,
    textTransform: "uppercase",
    fontFamily: theme.SecondaryFont,
    fontSize: "110%",
    paddingBottom: "6px",
    borderBottom: "solid 1px",
    borderBottomColor: theme.ColorGold + "22",
  },
  BlockSubHeadlineOnline: {
    color: theme.ColorC2,
    textTransform: "uppercase",
    fontFamily: theme.SecondaryFont,
    fontSize: "110%",
    paddingBottom: "6px",
    borderBottom: "solid 1px",
    borderBottomColor: theme.ColorBlue + "22",
  },


  BlockFooterAuctions: {
    borderBottom: "solid 1px",
    borderBottomColor: theme.ColorGold + "22",
    display: "flex",
    color: theme.ColorGold,
    alignItems: "center",
    paddingBottom: "6px"
  },
  BlockFooterOnline: {
    borderBottom: "solid 1px",
    borderBottomColor: theme.ColorBlue + "22",
    display: "flex",
    color: theme.ColorBlue,
    alignItems: "center",
    paddingBottom: "6px"
  },
  CountdownBlock: {
    width: "100%",
    position: "absolute",
    padding: 0,
    paddingTop: "3px",
    paddingBottom: "3px",
    marginTop: "59px",
    [theme.breakpoints.down('xs')]: {
      marginTop: "50px",
    },
    border: "0px",
    color: "white",
    textAlign: "center",
    backgroundColor: theme.ColorBlue,
    zIndex: 1000,
  },
  LabelFooter: {
    flexGrow: 1,
    textTransform: "uppercase",
    textAlign: "left",
    fontSize: "14px",
  },
}));


const BlockImageNews = styled("img")({
  width: "100%",
  margin: 0,
  padding: 0,
  border: "solid 1px",
  borderColor: "#00000011",
}); 

const CountdownInternals = ({ block }) => {
  const classes = useStyles();
  const serverTimeDelta = useSelector((state) => state.itemCache.serverTimeDelta);
  const serverTime = () => {
    var t = new Date();
    return t.setSeconds(t.getSeconds() + serverTimeDelta);
  }
  const [currentTime, setCurrentTime] = useState(serverTime());
  useEffect(() => {
    setTimeout(() => {
      setCurrentTime(serverTime());
    }, 1000);
  });

  
  const deadline = block.Deadline;
  const inputDate = deadline && (deadline.endsWith("Z") ? new Date(deadline) : new Date(deadline + "Z"));
  const deadlineFinal = inputDate > currentTime ? block.Deadline : null;
  return (
    <Link
    component={RouterLink}
    to={block.URL}
    color="inherit"
    underline="none"
    
  >
    <div className={classes.CountdownBlock} >
      Online auction "{block.Title}" {deadlineFinal ? <>in  <Deadline deadline={deadlineFinal} currentTime={currentTime} />.</> : "is here!"}
      </div>
      </Link>
  )
};

const Countdown = ({ block }) => {
  if (block)
  {
    return (<CountdownInternals block={block} />);
  }
  return null;
}

export default function Upcoming({ draft }) {
  const classes = useStyles();
  const [data, setData] = useState();

  const BlockContent = ({ data }) => {

    const htmlContentRef = useRef();
    const [heightDimension, setHeightDimension] = useState();
    const isNews = (data.Type === "news");

    useLayoutEffect(() => {
      if(isNews && htmlContentRef.current){
        setHeightDimension(htmlContentRef.current.offsetHeight)
      }
    }, [])

    const visibleHeight = (heightDimension > 0) ? 150 : 0; // HEIGHT TO SHOW 

    return (
      <>
      <Helmet>
          <title>Native Auctions - Upcoming</title>
      </Helmet>

      <Grid container spacing={0} 
        className={clsx({
          [classes.BlockAuctions]: data.Store === "auctions",
          [classes.BlockOnline]: data.Store === "online"
        })}
      >
        <Grid item xs={4} sm={3}>
          <BlockImageNews src={"image/thumbnail/" + data.Image} />
        </Grid>
        <Grid item xs={8} sm={9}
          className={classes.BlockText}
        >
          <Typography 
            className={clsx({
              [classes.BlockHeadlineAuctions]: data.Store === "auctions",
              [classes.BlockHeadlineOnline]: data.Store === "online",
            })}
          >
            {data.Title}
          </Typography>
          <Typography
            className={clsx({
              [classes.BlockSubHeadlineAuctions]: data.Store === "auctions",
              [classes.BlockSubHeadlineOnline]: data.Store === "online"
            })}
          >
            {data.SubTitle}
          </Typography>

          {isNews ?
            (
              (heightDimension < 200) ? 
                <div className="styleContainer" dangerouslySetInnerHTML={{ __html: data.HTMLContent }}/> 
                :
                <CollapsibleFadedContent  fixedHeight={visibleHeight}>
                  <div ref={htmlContentRef} className="styleContainer" dangerouslySetInnerHTML={{ __html: data.HTMLContent }}/> 
                </CollapsibleFadedContent>
            )
            : 
            <div className="styleContainer" dangerouslySetInnerHTML={{ __html: data.HTMLContent }}/>
          }

          {data.URL ?
            <div
              className={clsx({
                [classes.BlockFooterAuctions]: data.Store === "auctions",
                [classes.BlockFooterOnline]: data.Store === "online"
              })}
            >
              <div
                className={classes.LabelFooter}
              >
                More</div>
            </div> : null}
        </Grid>
      </Grid>

    </>
    );
  };

  const BlockView = ({ data }) => {
    return (
      <>
        {data.URL ? (
          <Link
            component={RouterLink}
            to={data.URL}
            color="inherit"
            underline="none"
          >
            <BlockContent data={data} />
            </Link>
        ) : (
            <BlockContent data={data} />
          )}
      </>
    );
  };

  useEffect(() => {
    let url = draft ? `/view/draftpage` : `/view/mainpage`;

    Axios.get(url)
      .then(function (response) {
        const data = response.data;

        setData(data);
        console.log("Data", data);
      })
      .catch(function (error) {
        setData(null);
      });
  }, []);

  if (!data) {
    return <ContentPlaceholder />;
  }
  const headline = data.Headline;
  const subline = data.SubLine;
  const headline2 = data.Headline2;
  const subline2 = data.SubLine2;
  const mainImage = data.Image;

  const FeaturedBlock = () => (
    <div className={data.IsDouble ? classes.HeadLineBlock1 : classes.HeadLineBlock}>
      <Grid item className={data.IsDouble ? classes.HeadlineDouble1 : classes.Headline} xs={12}>
        <div>{headline}</div>
      </Grid>
      <Grid item className={classes.SubLine} xs={12}>
        <div className={data.IsDouble ? classes.SublineA1 : classes.SublineA}>{subline}</div>
      </Grid>
    </div>);
  
  const FeaturedBlock2 = () => (
    <div className={classes.HeadLineBlock2}>
      <Grid item className={classes.HeadlineDouble2} xs={12}>
        <div>{headline2}</div>
      </Grid>
      <Grid item className={classes.SubLine} xs={12}>
        <div className={classes.SublineA2}>{subline2}</div>
      </Grid>
    </div>);

  if (data.IsDouble)
  {
    return (
      <>
          <Countdown block={data.Countdown} />
          <ImageFadeIn
            className={classes.FeaturedVisualDouble}
            src={"image/" + mainImage}
            loadAsBackgroundImage={true}
            opacityTransition={2}
        >
            <Grid 
              container
              direction="column"
              alignItems="flex-start"
              justify="flex-start"
              className={classes.FeaturedVisualContainerDouble}
            >
            <Grid item container xs={8} sm={7} lg={5} xl={4} >
            <Link
    component={RouterLink}
    to={data.URL}
    color="inherit"
    underline="none"
    
  >
                <FeaturedBlock /></Link>
              </Grid>
          </Grid>
          <Grid 
              container
              direction="column"
              alignItems="flex-end"
              justify="flex-end"
              className={classes.FeaturedVisualContainerDouble}
          >
            
              <Grid item container xs={8} sm={7} lg={5} xl={4} >
              <Link
    component={RouterLink}
    to={data.URL2}
    color="inherit"
    underline="none"
    
  >
              <FeaturedBlock2 />
              </Link>
              </Grid>
            </Grid>
          </ImageFadeIn>
  
        <FixedWidthStoreContainer>
          {data.Blocks.map((block, index) => (
            <BlockView data={block} key={index} />
          ))}
        </FixedWidthStoreContainer>
      </>
    );
  }
  return (
    <>
      <Countdown block={data.Countdown} />
      <Link
    component={RouterLink}
    to={data.URL}
    color="inherit"
    underline="none"
    
  >
        <ImageFadeIn
          className={classes.FeaturedVisual}
          src={"image/" + mainImage}
          loadAsBackgroundImage={true}
          opacityTransition={2}
        >
          <Grid 
            container
            direction="column"
            alignItems="flex-start"
            justify="flex-end"
            className={classes.FeaturedVisualContainer}
          >
            <Grid item container xs={8} sm={7} lg={5} >
              <FeaturedBlock />
            </Grid>
          </Grid>
        </ImageFadeIn>
        </Link>

      <FixedWidthStoreContainer>
        {data.Blocks.map((block, index) => (
          <BlockView data={block} key={index} />
        ))}
      </FixedWidthStoreContainer>
    </>
  );
}
