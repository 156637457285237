import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';




const localStyle = makeStyles(theme => ({
    panel: {
        marginBottom: "1%",
        disabled: {
            color: "rgba(0, 0, 0, 0)"
        }
    },
    BlockDisplay : {
        display: "block"
    }
}));


export default function EnteteEditPanels({ component, titleFormatter, Data, match, onUpdate,defaultOpen }) {
    const [isOpen, setIsOpen] = useState(defaultOpen ? true : false);
    const [data, setData] = useState(Data)
    let classes = localStyle();
    useEffect(() => {
            setData(Data)
    }, [Data])
    function onFormCancel() {
        setIsOpen(false);
    }
    const clickSummary = event => {
        setIsOpen(true)
    };

    const handleUpdate = (data) =>{
        onUpdate && onUpdate(data)
        setData(data)
    }

    return (
        <ExpansionPanel
            className={classes.panel}
            expanded={isOpen}>
            <ExpansionPanelSummary onClick={clickSummary}
                expandIcon={
                    (isOpen ? null : <CreateIcon />)}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >

                <Typography variant="h6">
                    {titleFormatter(data)}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.BlockDisplay}>
                {isOpen && React.cloneElement(component, { onClose: onFormCancel, isOpen: isOpen, Data: data, onUpdate: handleUpdate, match })}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}
