import React from 'react'
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import Box from '@material-ui/core/Box';
import { NAdminActiveButton, NAdminToolbarCancelButton } from '../Widget/NAdminCustomComponents'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Axios from 'axios';
import { useSelector } from 'react-redux';


const localStyle = makeStylesGlobal(theme => ({
    AdminToolBarButtonSpacing: {
        marginTop: '20px'
    },
    StatusChooserBar: {
        display: 'flex',
    }
}));




export const StatusChooser = ({
    onCancel,
    statusCategories,
    statusUrl,
    selectedRows,
    onSubmit
    
    
}) => {

    const token = useSelector(state => state.auth.token);
    const statusValues = statusCategories.map((status, index) => {
        return {
            Label: status.label,
            Icon: <FiberManualRecordIcon style={{ color: status.color }} />,
            Status: status.value
        }
    })

    const handleSubmitStatus = (status) => {
        
        let data = {
            "Status": status,
            "Items": selectedRows
        }
        var config = {
            headers: { Authorization: "Bearer " + token }
        }
        if (selectedRows.length > 0) {
            Axios.post(statusUrl, data, config)
                .then(function (response) {
                    onSubmit()
                })
        } else {
            return null
        }
    }

    const classes = localStyle();
    return (
        <>
            <Box className={classes.StatusChooserBar}>
                    <>
                        {statusValues.map((status, index) => (
                            <NAdminActiveButton
                                type="button"
                                key={index}
                                startIcon={status.Icon}
                                onClick={() => {
                                    handleSubmitStatus(status.Status)
                                }}
                            >
                                {status.Label}
                            </NAdminActiveButton>
                        ))}
                    </>
                    <NAdminToolbarCancelButton
                        onClick={onCancel}
                    >
                        Done
                    </NAdminToolbarCancelButton>
            </Box>
        </>
    )
};