import React, { useState, useEffect } from 'react'
import { Grid, Box, Typography } from "@material-ui/core";
import { NTypoSmallTitle } from '../../Widget/NativeStyleds';
import { VTextFieldMaskedInput } from '../../Widget/CustomVInputs';
import { NBottomCancelButton, NBottomActiveButton } from '../../Widget/NAdminCustomComponents';
import * as cst from "../../../js/constants/miscellaneous_cst";
import { FormContext, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { addNewModel } from '../../../js/utils/backend';
import { makeStylesGlobal } from '../../../themes/GlobalTheme';
import Axios from 'axios';

const localStyle = makeStylesGlobal(theme => ({
    hidden: {
        visibility: "hidden"
    },
    half: {
        position: "absolute",
        bottom: 4,
        left: 0,
    },
    displayFlex: {
        display: "flex"
    },
    label: {
        width: "50%",
        position: "relative",
    }
}));

const numberIndexes = [{ name: "InvoiceStartNumber", type: cst.invoice },
{ name: "SettlementStartNumber", type: cst.settlement }]

export default function BillingsNumbers({ onClose, auctionId }) {
    const [invoiceNumber, setInvoiceNumber] = useState()
    const [settlementNumber, setSettlementNumber] = useState()
    const classes = localStyle();
    const token = useSelector(state => state.auth.token);
    const test = { InvoiceStartNumber: invoiceNumber, SettlementStartNumber: settlementNumber }
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: test
    });
    const { handleSubmit, setValue } = methods;



    useEffect(() => {
        const fetchBillingNumbers = (token, type, fieldName, callback, ) => {
            var config = {
                headers: { Authorization: "Bearer " + token }
            };
            let url = type.nextnumber + "?category=P";
            Axios.get(url, config)
                .then(response => {
                    callback(response.data)
                    setValue(fieldName, response.data)
                })
        }
        numberIndexes.forEach((el, index) => {
            fetchBillingNumbers(token, el.type, el.name, index === 0 ? setInvoiceNumber : setSettlementNumber)
        })
    },[])

    const submitUpdate = param => {
        addNewModel({ ...param, AuctionID: auctionId }, token, cst.generateBillings).then(onClose);
    };

    return (
        <>
            <FormContext {...methods}>
                <form onSubmit={handleSubmit(submitUpdate)}>
                    <Grid container
                        className={!invoiceNumber && !settlementNumber && classes.hidden}>
                        <Grid item xs={12}>
                            <NTypoSmallTitle> Generate all invoices and settlement for the auction </NTypoSmallTitle>
                        </Grid>
                        <Grid item xs={12} className={classes.displayFlex}>
                            <div className={classes.label}>
                                <Typography className={classes.half} >Invoice starting at: </Typography>
                            </div>
                            <VTextFieldMaskedInput
                                label="Invoice Number"
                                fieldName={numberIndexes[0].name}
                                validation={{
                                    required: cst.msgRequired,
                                    validate: value => {
                                        if (value.includes("_")) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }
                                }}
                                formatChars={cst.invoice.NumberChars}
                                mask={cst.invoice.customMask}
                            />
                        </Grid>
                        <Grid item xs={12}  className={classes.displayFlex}>
                            <div className={classes.label}>
                                <Typography className={classes.half} >Settlement starting at: </Typography>
                            </div>
                            <VTextFieldMaskedInput
                                label="Settlement Number"
                                fieldName={numberIndexes[1].name}
                                validation={{
                                    required: cst.msgRequired,
                                    validate: value => {
                                        if (value.includes("_")) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }
                                }}
                                formatChars={cst.settlement.NumberChars}
                                mask={cst.settlement.customMask}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.RightEndAlign}>
                                <NBottomCancelButton variant="outlined"
                                    onClick={onClose}>
                                    Cancel
                            </NBottomCancelButton>
                                <NBottomActiveButton type="submit">Generate</NBottomActiveButton>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </FormContext>
        </>
    )
}