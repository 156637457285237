import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { tableColumnStyle } from "../../pdf_cst"
import PhoneRequestTableHeader from "./PhoneRequestTableHeader";
import PhoneRequestTableRow from "./PhoneRequestTableRow";

const styles = StyleSheet.create({...tableColumnStyle})

const PhoneRequestTable = ({ items }) => (
    <View style={styles.tableContainer}>
        <PhoneRequestTableHeader/>
        <PhoneRequestTableRow items={items}/>
    </View>
);

export default PhoneRequestTable