import React, { useState, useRef } from "react";
import { FixedSizeGrid as Grid } from "react-window";
import { WindowScroller, AutoSizer } from "react-virtualized";

const processData = ({ data, columns }) => {
    
  if (data == null)
    return [null, 1];
  
  //copy the items in data into a new array
  let items = [...data.items];
  const separators = data.separators;

  if (data.separators && data.separators.length) {
    //loop on the separators
    for (let i = 0; i < data.separators.length; i++) {
      const baseSeparator = data.separators[i];
      //find the item whose Order is equal to baseSeparator.ReferToLot
      const referItem = items.find((item) => item && item.Order && item.Order == baseSeparator.ReferToLot);
      const separator = {...baseSeparator, isSeparator: true, referTo: referItem};
      //find the index of the item that has the Order greater than the separator BeforeLot
      let index = items.findIndex((item) => item && item.Order && item.Order >= separator.BeforeLot);

      while (separator.IsExact && (index % columns > 0))
      {
        //insert a null item at index and increment it
        items.splice(index, 0, null);
        index++;
      }

      //go to the first index in that row
      index = index - (index % columns);
      //insert the separator in that index
      items.splice(index, 0, separator);
      //add column-1 null items after the separator
      for (let j = 0; j < columns - 1; j++) {
        items.splice(index + 1, 0, null);
      }
    }
  }

  return [items, Math.ceil(items.length/columns)];
};

export default function MasonryListing({
  data,
  component,
  itemProps,
  cellWidth,
  cellHeight
}) {
  const overScan = cellHeight;
  const [columnCount, setColumnCount] = useState(2);
  const [effectiveWidth, setEffectiveWidth] = useState(cellWidth);
  const [effectiveHeight, setEffectiveHeight] = useState(cellHeight);
  const gridRef = useRef(null);
  if (!data)
    return null;
  const [effectiveData, rowCount] = processData({ data, columns: columnCount });
  const totalHeight = rowCount * effectiveHeight;

  

  const Cell = ({ columnIndex, rowIndex, style }) => {
    const idx = rowIndex * columnCount + columnIndex;
    if (effectiveData == null || idx >= effectiveData.length) {
      return null;
    }
    return (
      <div style={style}>
        {component({ 
          item: effectiveData[idx], 
          index: idx, 
          totalWidth: effectiveWidth*columnCount,
          ...itemProps 
        })}
      </div>
    );
  };

  

  const onResize = ({ width }) => {
    const _columns = Math.max(Math.floor(width / cellWidth),2);
    const _cellWidth = Math.floor(width / _columns);
    const _cellHeight = Math.round((cellHeight * _cellWidth) / cellWidth);


    setColumnCount(_columns);
    setEffectiveWidth(_cellWidth);
    setEffectiveHeight(_cellHeight);
  };

  const handleScroll = ({ scrollTop }) => {
    if (gridRef.current) {
      gridRef.current.scrollTo({ scrollLeft: 0, scrollTop });
    }
  };

  const renderGrid = ({ width, height, scrollTop }) => {
    const effectiveTop = Math.min(scrollTop, Math.max(0, totalHeight - height));
    const maxHeight = totalHeight - effectiveTop;
    const overscannedHeight = Math.min(height + overScan, maxHeight);
    return (
      <>
        <Grid
          ref={gridRef}
          style={{
            position: "absolute",
            top: effectiveTop,
            overflow: "hidden",
          }}
          columnCount={columnCount}
          columnWidth={effectiveWidth}
          height={overscannedHeight}
          rowCount={rowCount}
          rowHeight={effectiveHeight}
          width={width}
          overscanRowCount={1}
        >
          {Cell}
        </Grid>
        <div style={{ minHeight: totalHeight, height: totalHeight }} />
      </>
    );
  };

  return (
    <>
      <WindowScroller onScroll={handleScroll}>
        {({ height, scrollTop }) => (
          <AutoSizer disableHeight onResize={onResize} height={height}>
            {({ width }) => renderGrid({ width, height, scrollTop })}
          </AutoSizer>
        )}
      </WindowScroller>
    </>
  );
}