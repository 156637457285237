import React, { useRef, useState,useEffect } from "react";
import MaterialTable, { MTableToolbar, MTableBodyRow, MTableEditRow } from "material-table";
import { Grid, Paper, TablePagination } from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import { AUCTIONSURL } from "../../../js/constants/model_cst";
import { makeStylesGlobal } from "../../../themes/GlobalTheme";
import { NAdminToolBar, NAdminActiveButton, NAdminFilterBar } from '../../Widget/NAdminCustomComponents'
import { fetchData, deleteModel } from "../../../js/utils/backend";
import {StoreRenderer, DateTimeRenderer, Thumbnail, StatusRenderer, CurrencyRenderer} from "../../Widget/TableEditors";
import AuctionsEditPanel from "../../EditPanels/AuctionsEditPanel";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import * as cst from '../../../js/constants/miscellaneous_cst';
import {scrollTopFunction} from "../../../js/constants/functions_cst"
import SetStatus from '@material-ui/icons/LocalOffer';
import StatusFilter from "../../Widget/StatusFilter";
import ProductsIcon from '@material-ui/icons/ShoppingBasketOutlined';
import { StatusChooser } from "../../Widget/StatusChooser";
import {saveVariable, useReduxPrefsState} from "../../../js/actions/prefs";
import { priceEstimation} from "../../../js/utils/formatter";
import { useHistory,Prompt } from "react-router-dom";
import Axios from "axios";
import { useHistoryState} from "../../../js/utils/customHooks";

const localStyle = makeStylesGlobal(theme => ({
}));

export default function Auction({ history, auctionId, ...props }) {
  const token = useSelector(state => state.auth.token);
  const classes = localStyle();

  const tableRef = useRef();

  const [displayEditPanel, setDisplayEditPanel] = useState(true)
  const [onSelect, setOnSelect] = useState(false)
  const [configActionDelete, setConfigActionDelete] = useState(true)
  const [isTableEditing, setIsTableEditing] = useState(false);
  const [operation, setOperation] = useState("none");
  const [statusFilter, setStatusFilter] = useHistoryState("statusFilter",{ value: 'All', label: 'All' })
  const [selectedRows, setSelectedRows] = useState([])
  const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
  const options = [{ value: 'All', label: 'All' }, ...cst.auctionStatus, cst.auctionVisible];
  const [searchText, setSearchText] = useHistoryState("searchText","");
  const [currentPage, setCurrentPage] = useHistoryState("currentPage",0);
  const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn","StartDate");
  const [sortingDir, setSortingDir] = useHistoryState("sortingDir","desc");
  const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
  const [isInit,setIsInit] = useState(true);

  function statusFilterChange(status) {
    setStatusFilter(status);
    refreshTable();
  }

  function refreshTable() {
    if (tableRef && tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }

  const operationChange = newOperation => {
    switch (newOperation) {
      case "none":
        setDisplayEditPanel(true)
        setOnSelect(false)
        setConfigActionDelete(true)
        tableRef.current.onAllSelected(false);
        break;
      case "new":
        break;
      case "auctionStatus":
        setOnSelect(true)
        setConfigActionDelete(false);
        tableRef.current.onAllSelected(false)
        break;
      default:
        return null;
    }
    setOperation(newOperation);
  }
  
  

  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialTable
          detailPanel={
            displayEditPanel ? rowData => {
              return (
                <AuctionsEditPanel
                  Data={(rowData.Informations === null) ? ({ ...rowData, Informations: '' }) : rowData}
                  onClose={refreshTable}
                  isNew={false}
                />
              )
            } : null}

          tableRef={tableRef}
          columns={[
            {
              title: "Image",
              field: "MainImageID",
              cellStyle: { width: '70px', padding: 0 },
              sorting: false,
              render: rowData => Thumbnail(70, rowData.MainImageID)
            },
            {
              title: "Title",
              field: "Title",
              defaultSort: getSort("Title"), customSort: (a, b) => null
            },
            {
              title: "Estimated",
              field: "EstimatedLow",
              cellStyle: { width: "15%" },
              render: priceEstimation,
              defaultSort: getSort("EstimatedLow")
            },
            {
              title: "Lots",
              field: "Lots",
              headerStyle: { textAlign: "right"},
              cellStyle: { textAlign: "right"},
              defaultSort: getSort("Lots")
            },
            {
              title: "Result",
              field: "Result",
              headerStyle: { textAlign: "right"},
              defaultSort: getSort("Result"),
              render: rowData => <>{CurrencyRenderer(rowData.Result,true)}</>
            },
            {
              title: "From",
              field: "StartDate",
              defaultSort: getSort("StartDate"),
              render: rowData => DateTimeRenderer(70, rowData.StartDate)
            },
            {
              title: "Status",
              field: "Status",
              sorting: false,
              headerStyle: { textAlign: "center" },
              render: (rowData) =>
                <div style={{ textAlign: "center" }}> {StatusRenderer(rowData.Status, [...cst.auctionStatus, cst.auctionVisible])} </div>
            },

            {
              title: "Store",
              field: "Store",
              cellStyle: { width: "15%" },
              render: StoreRenderer,
              defaultSort: getSort("Store")
            },
          ]}

          onSelectionChange={
            rowData => {
              let guids;
              guids = rowData.map(row => row.ID)
              setSelectedRows(guids)
            }
          }

          data={query => {
            let url = AUCTIONSURL;
            let fetchOptions = (statusFilter.value === "All") ? "" : "&filter[status]=" + statusFilter.value;
            fetchOptions += (auctionId) ? "&filter[auction]=" + auctionId : "";

            const effectivePage = isInit ? currentPage : query.page;
            setSearchText(query.search,true);
            setSortingColumn(query.orderBy && query.orderBy.field);
            setSortingDir(query.orderDirection);
            setCurrentPage(effectivePage);
            setIsInit(false)
            return fetchData(
              token, {...query,page: effectivePage}, url, fetchOptions
            );
          }}

          options={{
            sorting: true,
            detailPanelType: 'single',
            pageSize: rows,
            pageSizeOptions: cst.itemsPerPage,
            actionsColumnIndex: 50,
            selection: onSelect,
            showTextRowsSelected: false,
            emptyRowsWhenPaging:false,
            searchText: searchText
          }}
          actions={[
            (rowData) => (operation !== "none" ? null : {
              icon: () => <><ProductsIcon /></>,
              onClick: (event, rowData) => { history.push("/admin/auction/" + rowData.ID + "/products") },
              tooltip: 'See related Objects'
            })
          ]}

          editable={{
            onRowDelete: configActionDelete ? oldData => deleteModel(oldData.ID, token, AUCTIONSURL) : null
          }}
          onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
          title="Auctions"
          components={{
            EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />) },
            Row: props => { setIsTableEditing(props.hasAnyEditingRow); return (<MTableBodyRow {...props} />) },
            Pagination: props => (<TablePagination {...props} className={isTableEditing || selectedRows.length ? classes.disabled : null} />),
            Toolbar: props => (
              <div>
                <div className={isTableEditing || selectedRows.length ? classes.disabled : null}>
                  <MTableToolbar {...props} />
                </div>
                <div className={isTableEditing ? classes.disabled : null}>
                  <NAdminToolBar invisible={operation === "new"}>
                    {operation === "none" &&
                      <>
                        <NAdminActiveButton
                          startIcon={<AddIcon />}
                          onClick={() => {
                            operationChange("new")
                          }}
                        >
                          New
                      </NAdminActiveButton>
                        <NAdminActiveButton
                          startIcon={<SetStatus />}
                          onClick={() => {
                            operationChange("auctionStatus")
                          }}
                        >
                          Status
                  </NAdminActiveButton>
                      </>
                    }

                    {operation === "auctionStatus" && (
                      <StatusChooser
                        onCancel={() => {
                          operationChange("none")
                        }}
                        statusCategories={cst.auctionStatus}
                        statusUrl={cst.setAuctionStatus}
                        selectedRows={selectedRows}
                        onSubmit={() => {
                          refreshTable()
                          setSelectedRows([])
                        }}
                      />
                    )}

                    {operation === "new" && (
                      <Paper className={classes.NVisiblePaper}>
                        <AuctionsEditPanel
                          Data={{ Store: 0, Status: "Draft", BaseComissionPercent: 30 }}
                          onClose={() => {
                            operationChange("none");
                            refreshTable();
                            scrollTopFunction()
                          }}
                          isNew={true}
                        />
                      </Paper>
                    )}
                  </NAdminToolBar>
                </div>
                <NAdminFilterBar>
                  <StatusFilter
                    value={statusFilter.label}
                    onChange={statusFilterChange}
                    options={options}
                    className={isTableEditing || selectedRows.length ? classes.disabled : null}
                  />
                </NAdminFilterBar>

              </div>
            )
          }}
        />
      </Grid>
    </Grid>
  );
}