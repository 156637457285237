import React, { useRef, useState } from "react";
import MaterialTable, { MTableToolbar, MTableBodyRow, MTableEditRow } from "material-table";
import { TablePagination } from '@material-ui/core';
import { makeStylesGlobal } from "../../../themes/GlobalTheme";
import { Grid, Paper, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, IconButton } from "@material-ui/core";
import { Delete } from '@material-ui/icons';
import { useSelector } from "react-redux";
import PhoneRequestEditPanel from "../../EditPanels/PhoneRequestEditPanel";
import { fetchData, deleteModel} from "../../../js/utils/backend"; 
import { downloadPdf } from "../../Widget/DownloadButton";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useReduxPrefsState } from "../../../js/actions/prefs"
import * as cst from "../../../js/constants/miscellaneous_cst";
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { NAdminToolBar, NAdminActiveButton, NBottomActiveButton, NBottomCancelButton } from "../../Widget/NAdminCustomComponents";


import {useHistoryState} from "../../../js/utils/customHooks";

const localStyle = makeStylesGlobal(theme => ({

}));

const URL = "/phonerequest";

function EditStringDialog(props) {
    const [value, setValue] = useReduxPrefsState("OperatorsJSON", [{Name: "Nicolas", EN: true, FR: true, NL: true}, {Name: "Sébastien", EN: true, FR: true, NL: true}, {Name: "Gilles", EN: true, FR: true, NL: true}])
    const expanded = [...value, {Name: "", EN: true, FR: true, NL: true}];

    const handleCheckboxChange = (event, index, key) => {
        const newValue = [...value];
        newValue[index][key] = event.target.checked;
        setValue(newValue);
    };
    
    const handleNameChange = (event, index) => {
        if (event.target.value)
        {
            const newValue = index === value.length ? [...expanded] : [...value];
            newValue[index].Name = event.target.value;
            setValue(newValue);
        }
        else
        {
            const newValue = [...value];
            newValue.splice(index, 1);
            setValue(newValue);
        }
    };

    const handleSave = () => {
        downloadPdf("phonerequest", { auctionId: props.auction, operators: value }, props.token);
        props.onClose();
    };

    const handleRemoveClick = (index) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        setValue(newValue);
      };
  
    
    return (
      <Dialog open={props.open} onClose={props.onClose} maxWidth="xl" fullWidth>
        <DialogTitle>Generate schedule</DialogTitle>
            <DialogContent>
                <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Languages</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expanded.map((operator, index) => (
              <TableRow key={index}>
                    <TableCell>
                        
                            <TextField value={operator.Name} onChange={(event) => handleNameChange(event, index)} />
                </TableCell>
                    <TableCell>
                    {operator.Name && <>
                    <Checkbox checked={operator.EN} color="primary" onChange={(event) => handleCheckboxChange(event, index, 'EN')} />
                  <span>EN</span>
                  <Checkbox checked={operator.FR} color="primary" onChange={(event) => handleCheckboxChange(event, index, 'FR')} />
                  <span>FR</span>
                  <Checkbox checked={operator.NL} color="primary" onChange={(event) => handleCheckboxChange(event, index, 'NL')} />
                  <span>NL</span></>}
                    </TableCell>
                    <TableCell>
                        {operator.Name && <>
                        <IconButton onClick={() => handleRemoveClick(index)}>
                            <Delete />
                            </IconButton>
                            </>}
                    </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </DialogContent>
        <DialogActions>
        
                                    <NBottomCancelButton 
                                        variant="outlined" 
                                        onClick={props.onClose}
                                    >
                                    Cancel
                                    </NBottomCancelButton>
                                    <NBottomActiveButton 
                                        onClick={handleSave}    
                                    >
                                    Download Schedule
                                    </NBottomActiveButton>

        </DialogActions>
      </Dialog>
    );
  }

export default function PhoneRequest({ match, history, ...props }) {
    const classes = localStyle();

    const token = useSelector(state => state.auth.token);
    const auctionId = match && match.params && match.params.auctionId;

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);

    const [displayEditPanel, setDisplayEditPanel] = useState(true);
    const [operation, setOperation] = useState("none");
    const [isTableEditing, setIsTableEditing] = useState(false);
    const [searchText, setSearchText] = useHistoryState("searchText","");
    const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn","OriginalUser.LastName");
    const [sortingDir, setSortingDir] = useHistoryState("sortingDir","asc");
    const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
    const [currentPage, setCurrentPage] = useHistoryState("currentPage",0);
    const [isInit, setIsInit] = useState(true);
    
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleEditClick = () => {
    setDialogOpen(true);
  };


  const handleClose = () => {
    setDialogOpen(false);
  };


    function refreshTable() {
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    const operationChange = newOperation => {
        switch (newOperation) {
            case "none":
                setDisplayEditPanel(true);
                break;
            case "new":
                break;
            default:
                return null;
        }
        setOperation(newOperation);
    };

    const tableRef = useRef();

    return (
        <Grid container>
            <EditStringDialog open={dialogOpen} onClose={handleClose} token={token} auction={auctionId} />
            <Grid item xs={12}>
                <MaterialTable
                    detailPanel={
                        displayEditPanel ? 
                            rowData => {
                                return (
                                    <div>
                                        <PhoneRequestEditPanel
                                            Data={rowData}
                                            onClose={() => {
                                                refreshTable();
                                            }}
                                            isNew={false}
                                        />
                                    </div>
                                ) 
                            } : null
                    }
                    tableRef={tableRef}
                    columns={[
                        { title: "First Name", cellStyle: { width: "15%" }, field: "OriginalUser.FirstName", editable: "never",
                            defaultSort: getSort("OriginalUser.FirstName"), customSort: (a, b) => null},
                        { title: "Last Name", cellStyle: { width: "15%" }, field: "OriginalUser.LastName", editable: "never",
                            defaultSort: getSort("OriginalUser.LastName"), customSort: (a, b) => null
                        }, 
                        { title: "Lots", field: "LotsNumbers", editable: "never", sorting: false }
                    ]}
                    data={query => {
                        let fetchOptions = "&filter[auction]="+auctionId
                        const effectivePage = isInit ? currentPage : query.page;
                        setSearchText(query.search,true);
                        setSortingColumn(query.orderBy && query.orderBy.field);
                        setSortingDir(query.orderDirection);
                        setCurrentPage(effectivePage);
                        setIsInit(false)
                        return  fetchData(token, {...query,page: effectivePage}, URL, fetchOptions)
                    }}

                    options={{
                        pageSize: rows,
                        pageSizeOptions: cst.itemsPerPage,
                        emptyRowsWhenPaging:false,
                        actionsColumnIndex: 50,
                        searchText:searchText
                    }}

                    
                    onChangeRowsPerPage={(nbRows) => setRows(nbRows)}

                    editable={{
                        onRowDelete: 
                            oldData => deleteModel(oldData.ID, token, URL)
                    }}

                    title="Phonecall Requests"


                    components={{
                        Row: props => { setIsTableEditing(props.hasAnyEditingRow); return (<MTableBodyRow {...props} />) },
                        EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />) },
                        Pagination: props => (<TablePagination {...props} />),

                        Toolbar: props => (
                            <div>
                                <div className={isTableEditing ? classes.disabled : null}>
                                    <MTableToolbar {...props} />
                                </div>

                                <NAdminToolBar invisible={operation === "new"}>
                                    {operation === "none" && (
                                        <>
                                        <NAdminActiveButton
                                            startIcon={<AddIcon />}
                                            onClick={() => {
                                                operationChange("new");
                                            }}
                                            >
                                            New
                                            </NAdminActiveButton>
                                            <NAdminActiveButton
                                            startIcon={<SaveAltIcon />}
                                            onClick={handleEditClick}
                                            >
                                            Generate schedule
                                        </NAdminActiveButton>
                                        </>
                                    )}

                                    {operation === "new" && (
                                    <Paper className={classes.NVisiblePaper}>
                                            <PhoneRequestEditPanel
                                                Data={{
                                                    AuctionID: auctionId,
                                                    OriginalUser: null, 
                                                    EN: true,
                                                    FR: true,
                                                    NL: true
                                                  }}
                                            onClose={() => {
                                                operationChange("none");
                                                refreshTable();
                                            }}
                                            isNew={true}
                                        />
                                    </Paper>
                                    )}
                                </NAdminToolBar>
                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
    )
}