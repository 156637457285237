import * as cst from '../constants/miscellaneous_cst'
import { formatCurrency } from './formatter';

const findBidIndex = (value) =>{
  return cst.biddingValues.findIndex(t => value < t.value);
}

export const beginValue= (index, bid) => {
  let currentBid = cst.biddingValues[index].bid
    return (bid > 0)? Math.ceil(bid / currentBid)*currentBid : 0;

}

export const getGoodBids = (bid, nbBids) => {
  let goodBids = [];
  let bidIndex = findBidIndex(bid)
  let value = beginValue(bidIndex,bid)
  goodBids.push({value: value, label: formatCurrency(value,cst.APPCURRENCY)})
  while (goodBids.length <= nbBids) {
    if(value >= cst.biddingValues[bidIndex].value){
      bidIndex++
    }
    let bidValue = cst.biddingValues[bidIndex].bid;
    value += bidValue;
    goodBids.push({ value: value, label: `${formatCurrency(value,cst.APPCURRENCY)}` })
    if (goodBids.length === nbBids) return goodBids
  }

  // return bid + values[values.length-1].bid;
}