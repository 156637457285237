import React, { useRef, useState } from "react";
import MaterialTable, { MTableToolbar, MTableBodyRow, MTableEditRow } from "material-table";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { makeStylesGlobal } from "../../themes/GlobalTheme";

import { fetchData, deleteModel } from "../../js/utils/backend"; 
import { NEWSURL } from "../../js/constants/model_cst";
import { DateTimeRenderer, Thumbnail, StatusRenderer } from "../Widget/TableEditors";
import NewsEditPanel from "../EditPanels/NewsEditPanel";

import {NAdminToolBar, NAdminActiveButton, NAdminFilterBar} from '../Widget/NAdminCustomComponents'
import { defaultPageSize, newsStatus } from "../../js/constants/miscellaneous_cst";
import { StatusChooser } from "../Widget/StatusChooser"
import StatusFilter from "../Widget/StatusFilter"
import * as cst from "../../js/constants/miscellaneous_cst"
import SetStatus from '@material-ui/icons/LocalOffer';
import { useReduxPrefsState } from "../../js/actions/prefs";
import { scrollTopFunction } from "../../js/constants/functions_cst";
import {useHistoryState} from "../../js/utils/customHooks";

const localStyle = makeStylesGlobal(theme => ({
}));

export default function News() {

  const classes = localStyle();

  const token = useSelector(state => state.auth.token);

  const tableRef = useRef();

  const [configSearch, setConfigSearch] = useState(true)
  const [configActionDelete, setConfigActionDelete] = useState(true)
  const [displayEditPanel, setDisplayEditPanel] = useState(true)
  const [isTableEditing, setIsTableEditing] = useState(false);
  const [operation, setOperation] = useState("none");
  const [selectedRows, setSelectedRows] = useState([]);
  const [onSelect, setOnSelect] = useState(false)
  const [rows, setRows] = useReduxPrefsState("nbRows", defaultPageSize);
  const [searchText, setSearchText] = useHistoryState("searchText","");
  const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn","PublicationDate");
  const [sortingDir, setSortingDir] = useHistoryState("sortingDir","desc");
  const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
  const [currentPage, setCurrentPage] = useHistoryState("currentPage",0);
  const [isInit,setIsInit] = useState(true);


  const [statusFilter, setStatusFilter] = useHistoryState("statusFilter",{label: "All", value: "All"});

  const options = [{ value: 'All', label: 'All' }, ...newsStatus];

  function statusFilterChange(status) {
    refreshTable();
    setStatusFilter(status);
  }
  
  const operationChange = newOperation =>
  {
    switch(newOperation)
    {
      case "none":
        setConfigSearch(true)
        setConfigActionDelete(true)
        setDisplayEditPanel(true)
        setOnSelect(false)
        break;
      case "new":
        setConfigSearch(true)       
        setConfigActionDelete(false)
        break;
      case "newsStatus":
        setOnSelect(true)
        setConfigActionDelete(false);
        tableRef.current.onAllSelected(false)
        break;
      default: return null;
    }
    setOperation(newOperation);
  }

  function refreshTable() {
    if (tableRef && tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialTable
          detailPanel= {
            displayEditPanel ? rowData => {
              return ( 
                <div >
                  <NewsEditPanel 
                    Data={rowData} 
                    onClose={refreshTable}
                    isNew={false}
                  />
                </div>
              );
            }: null}
          tableRef={tableRef}
          columns={[  
            {
              title: "Image",
              field: "ImageID",
              cellStyle: { width: '10%', padding: 0 },
              sorting: false,
              render: rowData => Thumbnail(80, rowData.ImageID)
            },
            { 
              title: "Title", 
              field: "Title", 
              sorting: false,
              editable: "always"
            },
            {
              title: "Date",
              field: "PublicationDate",
              defaultSort: getSort("PublicationDate"),
              render: rowData => DateTimeRenderer(70, rowData.PublicationDate)
            },
            {
              title: "Status",
              field: "Status",
              sorting: false,
              headerStyle: { textAlign: "center" },
              render: (rowData) =>
                <div style={{ textAlign: "center" }}> {StatusRenderer(rowData.Status, newsStatus)} </div>
            },
          ]}
        
          data={query =>
            {
              let fetchOptions = (statusFilter.value === "All") ? "" : "&filter[status]=" + statusFilter.value + "&orderBy=PublicationDate&dir=desc";
              const effectivePage = isInit ? currentPage : query.page;
              setSearchText(query.search,true);
              setSortingColumn(query.orderBy && query.orderBy.field);
              setSortingDir(query.orderDirection);
              setCurrentPage(effectivePage);
              setIsInit(false)
              return fetchData(token, {...query,page: effectivePage}, NEWSURL, fetchOptions)
            }
          }
        
          options={{
            draggable: false,
            detailPanelType: 'single',
            pageSize: rows,
            pageSizeOptions: cst.itemsPerPage,
            actionsColumnIndex: 50,
            addRowPosition: "first",
            search:  configSearch,
            selection: onSelect,
            showTextRowsSelected: false,
            emptyRowsWhenPaging:false,
            paging: selectedRows.length || isTableEditing ? false : true,
            searchText: searchText
          }}
          onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
          editable={{
            onRowDelete: configActionDelete ? oldData => deleteModel(oldData.ID,token, NEWSURL) : null
          }}
          onSelectionChange={rowData => {
            let guids;
            guids = rowData.map(row => row.ID);
            setSelectedRows(guids);
          }}
          
          title="News"
          components={{
            Row: props => { setIsTableEditing(props.hasAnyEditingRow); return (<MTableBodyRow {...props} />)},
            EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />)},
            Toolbar: props => (
              <div>
                <div className={isTableEditing || operation === "reorder" ?classes.disabled:null}> 
                  <MTableToolbar {...props} /> 
                  </div>
                  <div className={isTableEditing?classes.disabled:null}>
                  <NAdminToolBar invisible={operation === "new"}>

                    {operation === "none" && 
                      <>
                        <NAdminActiveButton
                          startIcon={<AddIcon />}
                          onClick={() => operationChange("new")}
                        >
                          New
                        </NAdminActiveButton>
                        <NAdminActiveButton
                          startIcon={<SetStatus />}
                          onClick={() => {
                            operationChange("newsStatus")
                          }}
                        >
                          Status
                        </NAdminActiveButton>

                      </>
                    }


                    {operation === "newsStatus" && (
                    <StatusChooser
                      onCancel={() => {
                        operationChange("none")
                    }}
                      statusCategories={cst.newsStatus}
                      statusUrl={cst.setNewsStatus}
                      selectedRows={selectedRows}
                      onSubmit={() => {
                        refreshTable()
                        setSelectedRows([])
                      }}
                    />
                  )}

                    
                    {operation === "new" && (
                      <Paper className={classes.NVisiblePaper}>
                        <NewsEditPanel 
                          Data={{ Content: ''}}
                          onClose={() => {
                            operationChange("none");
                            refreshTable();
                            scrollTopFunction();
                          }} 
                          isNew={true} 
                        />
                      </Paper>
                    )}
                  </NAdminToolBar>
                  </div>

                  <NAdminFilterBar>
                    <StatusFilter 
                    value={statusFilter.label} 
                    onChange={statusFilterChange} 
                    options={options}
                    className={isTableEditing || selectedRows.length?classes.disabled:null}
                    />
                  </NAdminFilterBar>
                  
              </div>
            )
          }}
        />
      </Grid>
    </Grid>
  );
}