import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Paper, Box} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { IconButton } from '@material-ui/core';
import SplitIcon from '@material-ui/icons/CallSplit';
import BillingEditPanel from '../../EditPanels/BillingEditPanel';
import ProductsEditPanel from "../../EditPanels/ProductsEditPanel";
import { DialogProductChooser } from '../../Widget/DialogProductChooser'
import EnteteEditPanels from "../../EditPanels/EnteteEditPanels";
import Axios from "axios";
import { addNewModel, deleteModel, editModel } from '../../../js/utils/backend'
import LineModification from './LineModification';
import { NAdminActiveButton, NAdminToolbarCancelButton, NDataLoading, NModal } from '../../Widget/NAdminCustomComponents';
import { Thumbnail, StatusRenderer } from '../../Widget/TableEditors';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import { downloadPdf } from '../../Widget/DownloadButton';
import  { formatCurrency } from "../../../js/utils/formatter";
import * as cst from "../../../js/constants/miscellaneous_cst";
import CatalogIcon from '@material-ui/icons/MenuBook';
import { makeStylesGlobal } from "../../../themes/GlobalTheme"
import SettlementsIcon from "@material-ui/icons/Receipt";
import InvoicesIcon from "@material-ui/icons/Description";



const useStyles = makeStylesGlobal(theme => ({
  table: {
    tableLayout: "auto",
  },
  imageCell: {
    width: "10vw",
    alignItems: "center",
    textAlign: "center"
  },
  actionsCell: {
    width: "15vw",
  },
  splitCell: {
    width: "5vw",
  },
  paper: {
    padding: "5px"
  }
}));

export default function BillingWithProducts({ history, match, type }) {
  const token = useSelector(state => state.auth.token);

  const [products, setProducts] = useState([]);
  const [billingData, setBillingData] = useState(null);
  const [toSplit, setToSplit] = useState([]);
  const [operation, setOperation] = useState("none");
  const cancelToken = Axios.CancelToken.source()



  const billingId = match && match.params && match.params[type.id];

  function editData(rowData) {
    editModel(rowData, rowData.ID, token, type.modelLine).then(() => getProductsData());
    setOperation("justTriggerStateToRerenderDialog")
    setOperation("none")
  }
  
  function getBillingData() {
    if (billingId) {
      var config = {
        cancelToken: cancelToken.token,
        headers: { Authorization: "Bearer " + token }
      };
      let url = type.modelUrl+ "/" + billingId;
      Axios.get(url, config)
        .then(response => {
          setBillingData(response.data);
        })
    }
  }

  const getProductsData = () => {
    let url = `${type.modelLines}/${billingId}`
    let config = {
      cancelToken: cancelToken.token,
      headers: { Authorization: "Bearer " + token }
    };
    Axios.get(url, config)
      .then(function (response) {
        setProducts(response.data)
        setOperation("justTriggerStateToRerenderDialog")
        setOperation("none")
      })
  }

  useEffect(() => {
    getProductsData();
    getBillingData();
    return () => {
      cancelToken.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []) 


  
  
  const toggleSplittingMode = () => {
    const op = (operation === "split") ? "none" : "split";
    setOperation(op);
    setToSplit([]);
  }


  const [showProductModal, updateShowProductModal] = useState(false)
  const toggleProductModal = () => updateShowProductModal(state => !state)

  const [productData, setProductData] = useState(undefined)

  const fetchProductData = (rowProductID) => {  
    const url = '/product/'+rowProductID
    const config = {headers: { Authorization: "Bearer " + token }};
    Axios.get(url, config)
      .then(response => {
        setProductData(response.data)
        toggleProductModal()

      })
  }

  const choosenProduct = (rowData) => {
    const item = {
      [type.name + "ID"]: billingData.ID,
      ProductID: rowData.ID,
      Label: rowData.Title,
      Price: 0,
      CustomCommissionPercent: false, 
      CommissionPercent: 0,
    }
    addItemToModel(item)
  }

  const addItemToModel = (item) => {
    let url = type.modelLine + '/'
    let newData = item
    addNewModel(newData, token, url)
      .then(function (response) {
        getProductsData();
      })
  }
  const handleProductclick = (row) => {
    history.push(row.URL) 
  }
  const deleteProductFromView = (row) => {
    let url = type.modelLine
    let rowid = row.ID
    deleteModel(rowid, token, url)
      .then((response) => {
        getProductsData()
      })
  }

  const toggleToSplit = (lineId) => {
    const result = toSplit.filter((id) => id !== lineId);
    if (result.length === toSplit.length) {
      setToSplit([...toSplit, lineId])
    } else {
      setToSplit(result)
    }
  }

  const split = () => {
    let url = type.modelLine + "/split"
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.post(url, toSplit, config)
      .then(
        (response) => {
          history.push(`/admin${type.modelUrl}/` + response.data)
          getProductsData();
        })
    setOperation("none")
  }

  const twoPartTransactionSide = type.name === "Invoice" ? "Corresp. Settlement" : "Corresp. Invoice";


  const classes = useStyles();
  if (billingData === null && billingId) { getBillingData(); return <NDataLoading/> }
  return (
    <>
      <EnteteEditPanels
        titleFormatter={(billing) => type.name+ " " + billing.Number}
        Data={billingData}
        onUpdate={()=>getProductsData()}
        defaultOpen={true}
        component={
          <BillingEditPanel
            isNew={false}
            type = {type}
          />
        }
        match={match}
      />
      {(operation === "none") && 
      <>
        <DialogProductChooser
          choosenProduct={choosenProduct}
          billingId={billingId}
          products={products}
        />

        <LineModification
          dTitle={`Add ${type.name} line`} 
          earningType={type.commission} 
          rowData={{
            [type.name + "ID"]: billingData.ID,
            ProductID: undefined,
            ImagesID: undefined,
            Label: "",
            Price: "",
            FreeFormItem: true
          }}
          onClose={addItemToModel} 
          isNew={true}
        />

        <NAdminActiveButton
          startIcon={<DownloadIcon />}
          onClick={()=>downloadPdf(type.name.toLowerCase(),billingId)}
        > 
          Download 
        </NAdminActiveButton>

      </>}

      {(operation === "split" && toSplit.length > 0) && <NAdminActiveButton startIcon={<SplitIcon />} onClick={split}> Split </NAdminActiveButton>}
      {(operation === "split") ? 
      <NAdminToolbarCancelButton onClick={toggleSplittingMode}>Cancel</NAdminToolbarCancelButton> 
      : 
      <NAdminActiveButton  startIcon={<SplitIcon />} onClick={toggleSplittingMode}>Split</NAdminActiveButton>      
      }
      <TableContainer component={Paper} className={classes.table} >
        <Table aria-label="sticky table" stickyHeader={true}>
          <TableHead style={{ position: "sticky", zIndex: 10 }}>
            <TableRow>
              {(operation === "split") && <TableCell align="left" > {"  "} </TableCell>}
              <TableCell align="left" > Objects </TableCell>
              <TableCell align="left" > Lot </TableCell>
              <TableCell colSpan={2} align="left" >  </TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="center"> {twoPartTransactionSide}</TableCell>

              {(operation === "none") && <TableCell align="center">Actions</TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {products && products.Data &&
              products.Data.map((row, index) => (
                <TableRow key={index}>
                  {(operation === "split") && <TableCell className={classes.splitCell}> <Checkbox color="secondary" onChange={() => toggleToSplit(row.ID)}></Checkbox> </TableCell>}
                  {row.ImagesID ?
                    <TableCell component="th" className={classes.imageCell} onClick={() => handleProductclick(row)}>
                      {Thumbnail("5vh",row.ImagesID[0])}
                    </TableCell>
                    :
                    <TableCell component="th" className={classes.imageCell}>
                      {Thumbnail("5vh",null)}
                    </TableCell>
                  }
                  <TableCell component="th" scope="row">
                    {row.Lot}
                  </TableCell>
                  <TableCell colSpan={2} component="th" scope="row">
                    {row.Label}
                  </TableCell>
                  <TableCell align="right">{formatCurrency(row.Price, cst.APPCURRENCY, 2)}</TableCell>
                  <TableCell align="center">
                    {type.name === 'Settlement' &&
                      (!row.FreeFormItem && 
                        <>{StatusRenderer(row.IsInvoicePaid, cst.paymentStatus)}</>
                      )
                    }
                    {type.name === 'Invoice' &&
                      (!row.FreeFormItem && 
                        <>{StatusRenderer(row.IsSettlementPaid, cst.paymentStatus)}</>
                      )
                    }
                  </TableCell>

                  {(operation === "none") && // display of Actions Buttons 
                    <TableCell align="right" className={classes.actionsCell}>

                      {row.CorrespSettlementID && 
                        <IconButton onClick={() => history.push(`/admin/settlement/${row.CorrespSettlementID}`)}>
                          <SettlementsIcon/>
                        </IconButton>
                      }

                      {row.CorrespInvoiceID && 
                        <IconButton onClick={() => history.push(`/admin/invoice/${row.CorrespInvoiceID}`)}>
                          <InvoicesIcon/>
                        </IconButton>
                      }

                      {row.ProductID ? 
                        <>
                          <IconButton onClick={() => {
                            fetchProductData(row.ProductID)
                          }}>
                            <CatalogIcon/>
                          </IconButton>
                          
                        </>
                        : null
                      }

                      <LineModification dTitle={`Edit ${type.name} line`} earningType={type.commission} key={row.ID} isNew={false} rowData={row} onClose={editData} baseCommission={billingData.BaseComissionPercent} />

                      <IconButton aria-label="delete" row={row}  onClick={() => deleteProductFromView(row)}>
                        <DeleteOutlinedIcon />
                      </IconButton>
                    
                    </TableCell>
                  }
                </TableRow>
              ))
            }

            {products && products.Data &&
              <>
                <TableRow>
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                  {(operation === "split") && <TableCell rowSpan={4} />}
                  <TableCell colSpan={2}>Subtotal</TableCell>
                  <TableCell align="right">{formatCurrency(products.SubTotal,  cst.APPCURRENCY, 2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>{type.commission}</TableCell>
                  <TableCell align="right">{formatCurrency(products.Commission,  cst.APPCURRENCY, 2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >VAT</TableCell>
                  <TableCell align="right">{products.VATRate} %</TableCell>
                  <TableCell align="right">{formatCurrency(products.VAT, cst.APPCURRENCY, 2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell align="right">{formatCurrency(products.Total, cst.APPCURRENCY, 2)}</TableCell>
                </TableRow>
              </>
            }

            <NModal showModal={showProductModal} updateModalState={toggleProductModal}>
              <Paper className={classes.ScrollableModalPaper}>
              {productData ?
                <ProductsEditPanel
                    Data={productData}
                    onClose={() => {toggleProductModal(); getProductsData()}}
                    isNew={false}
                    isPopUp={true}
                /> 
              : null}
              </Paper>
            </NModal>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}