import React, { useState, useEffect } from 'react'
import Select, { components } from 'react-select';
import { reactSelectStyles } from './NativeStyleds';

import { useSelector } from 'react-redux';
import Axios from 'axios';
import { Typography } from '@material-ui/core';



export default function UserChooser({ onUserSelected, value, fieldName, inputRef }) {

  const token = useSelector(state => state.auth.token);

  const [options, setOptions] = useState();

  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value])

  const Option = props => {
    return (
      <div content={'Customise your option component!'} >
        <components.Option {...props} >
          <Typography style={{ fontWeight: 'Bold' }}> {`${props.label}`}</Typography>
          <Typography style={{ fontStyle: 'normal' }}>{props.data.Adress}</Typography>
        </components.Option>
      </div>
    );
  };

  function firstValue(value) {
    if (value == null) {
      return null;
    } else if (value.ID) {
      return {
        label: makeName(value),
        Adress: makeAddress(value),
        value: value.ID,
        Data: value
      }
    }
    return value
  }

  function makeName(suggestion) {
    const first = (suggestion.FirstName) ? suggestion.FirstName : "";
    const last = (suggestion.LastName) ? " " + suggestion.LastName : "";
    return first + last;
  }

  function makeAddress(suggestion) {
    const adr1 = (suggestion.AddressLine1) ? suggestion.AddressLine1 : "";
    const adr2 = (suggestion.AddressLine2) ? suggestion.AddressLine2 : "";
    return adr1 + " " + adr2
  }

  function getUsers(name) {
    let url = "/user/chooser?search=";

    if (name) url += name;
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.get(url, config)
      .then(response => {
        let temp = [];
        response.data.map(suggestion => {
          const address = makeAddress(suggestion);
          const names = makeName(suggestion);
          return temp.push({
            value: suggestion.ID,
            label: names,
            Data: suggestion,
            Adress: address,
          })
        })
        setOptions(temp);
      })
  }


  function giveData(value) {
    setVal(value.Data)
    onUserSelected && onUserSelected(value.Data);
  }

  return (
    <Select
      placeholder={"Select a user"}
      name={fieldName}
      value={firstValue(val)}
      ref={inputRef}
      components={{ Option }}
      styles={reactSelectStyles}
      options={options}
      onInputChange={getUsers}
      onFocus={() => getUsers()}
      onChange={giveData}
    />
  )
}


