import React from 'react'
import { useEffect, useState } from 'react'

import Axios from 'axios';
import * as cst from "../../../js/constants/miscellaneous_cst"

export const TermsConditionsContent = () => {

    const [termsContent, setTermsContent] = useState()

    useEffect(() => {
        Axios.get(cst.viewTermsConditions)
            .then(function(response){
                setTermsContent(response.data)
            })
    }, [])

    return (
            <div
                className="styleContainer"
                dangerouslySetInnerHTML={{ __html: termsContent }}
            />
    )
}
