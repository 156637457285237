import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, DialogTitle, Grid, Box } from '@material-ui/core';
import { VTextField, VNumeric } from "../../Widget/CustomVInputs"
import { useForm, FormContext } from 'react-hook-form';
import * as cst from '../../../js/constants/miscellaneous_cst';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { NBottomActiveButton, NBottomCancelButton, NAdminActiveButton } from "../../Widget/NAdminCustomComponents"
import { makeStylesGlobal } from "../../../themes/GlobalTheme"

import AddExtraIcon from '@material-ui/icons/QueueOutlined';

const localStyle = makeStylesGlobal(theme => ({
  VATswitcher: {
    margin: "16px 0px 0px 12px "
  }
}))

export default function LineModification({
  dTitle,
  onClose,
  isNew,
  baseCommission,
  earningType,
  rowData
}) {

  const [open, setOpen] = useState(false);

  const classes = localStyle();


  const [checkedCommission, setCheckedCommission] = useState({ checked: rowData ? rowData.CustomCommissionPercent : undefined });
  const toggleCommission = (event) => {
    setCheckedCommission({ ...checkedCommission, [event.target.name]: event.target.checked });
  };


  const [checkedVAT, setCheckedVAT] = useState({ checked: rowData ? !rowData.NoVAT : undefined }); // inversed switch direction, based on {...rowData, NoVAT: false}
  const toggleVAT = (event) => {
    setCheckedVAT({ ...checkedVAT, [event.target.name]: event.target.checked });
  }

  const [isFreeFormItem] = useState(isNew ? true : rowData.FreeFormItem)

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: rowData
  });

  const {
    handleSubmit,
  } = methods;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (newData) => {
    setOpen(false);
  };

  const verifylabel = (label) => {
    if (label) {
      return label;
    } else {
      return rowData.Label;
    }
  }


  const resetCommission = (newcommission) => {
    if (checkedCommission.checked === true) {
      return newcommission
    } else {
      return baseCommission
    }
  }

  const submitUpdate = data => {
    const CustomCommissionPercent = checkedCommission.checked;
    const CommissionPercent = resetCommission(data.CommissionPercent)

    const NoVAT = !checkedVAT.checked; // submit inversed switch direction (cf. state)

    const newData = { ...rowData, ...data, CustomCommissionPercent, CommissionPercent, NoVAT };

    rowData["Label"] = verifylabel(newData.Label);
    rowData["Price"] = parseFloat(newData.Price);
    rowData["CustomCommissionPercent"] = newData.CustomCommissionPercent;
    rowData["CommissionPercent"] = parseFloat(CommissionPercent);
    onClose(newData);
    handleClose(newData)
  }

  return (
    <>
      {isNew ?
        <NAdminActiveButton
          onClick={handleClickOpen}
          startIcon={<AddExtraIcon />}
        >
          Add extra
            </NAdminActiveButton>
        :
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      }


      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(submitUpdate)}>
            <DialogTitle id="form-dialog-title">{dTitle}</DialogTitle>

            {open &&
              <DialogContent>

                <Grid container>
                  <Grid item xs={12}>
                    <VTextField
                      label="Label"
                      fieldName="Label"
                      fullWidth
                      validation={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <VNumeric
                      label="Price"
                      fieldName="Price"
                      fullWidth
                      unit={cst.APPCURRENCY}
                      validation={{ required: false }}
                    />
                  </Grid>

                  {isFreeFormItem ?

                    <>
                      <Grid
                        item container xs={12} sm={5}
                        justify="flex-start"
                        className={classes.VATswitcher}
                      >
                        <FormGroup row fieldName="NoVAT">
                          <FormControlLabel
                            control={
                              <Switch
                                size="small"
                                checked={checkedVAT.checked}
                                onChange={toggleVAT}
                                name="checked"
                                color="primary"
                              />
                            }
                            label={"VAT"}
                          />
                        </FormGroup>
                      </Grid>
                    </>

                    :

                    <>
                      <Grid
                        item container xs={12} sm={5}
                        justify="center"
                        alignItems="flex-end"
                        alignContent="center"
                      >
                        <FormGroup row
                          fieldName="CustomCommissionPercent"
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                size="small"
                                checked={checkedCommission.checked}
                                onChange={toggleCommission}
                                name="checked"
                                color="primary"
                              />
                            }
                            label={`Custom ${earningType}`}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        {(checkedCommission.checked === true) ?
                          <VNumeric
                            label={earningType}
                            fieldName="CommissionPercent"
                            fullWidth
                            unit="%"
                            validation={{ required: false }}
                          />
                          : <VNumeric
                            label={earningType}
                            fieldName="CommissionPercent"
                            fullWidth
                            value={baseCommission}
                            unit="%"
                            validation={{ required: false }}
                            disabled
                          />
                        }
                      </Grid>
                    </>
                  }
                </Grid>

                <Grid item xs={12}>
                  <Box className={classes.RightEndAlign}>
                    <NBottomCancelButton
                      onClick={handleClose}
                    >
                      Cancel
                    </NBottomCancelButton>
                    <NBottomActiveButton
                      type="submit"
                    >
                      Save
                    </NBottomActiveButton>
                  </Box>
                </Grid>

              </DialogContent>
            }
          </form>
        </FormContext>
      </Dialog>
    </>
  );
}