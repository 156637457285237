import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core";
import Axios from 'axios';
import {
    ToolbarPlaceHolder,
    FixedWidthStoreContainer
} from "../../layouts/StoreLayout";
import { NDataLoading } from "../Widget/NAdminCustomComponents"
import NBreadcrumb from '../Widget/NBreadcrumb';
import { Helmet } from 'react-helmet';


const useStyles = makeStyles(theme => ({
    QAItem : {
        borderLeft: '1px solid',
        borderColor: 'rgba(141, 127, 65, 0.25)',
        textAlign: "justify",
        width: '100%',
        margin: 'auto',
        padding: '0px 8px',
        marginTop: '2em',
    },
    Question : {
        fontFamily: theme.PrimaryFont,
        color: theme.ColorC1, 
        fontSize: "120%"
    },
    Answer : {
        fontFamily: theme.SecondaryFont,
        color: theme.ColorC2
    },
    Headline: {
        fontSize: "200%",
        fontFamily: theme.PrimaryFont,
        color: theme.ColorC1,
        textTransform: "uppercase",
        paddingTop: "10px",
        paddingBottlm: "10px",
        borderBottom: "solid 1px",
        borderBottomColor: theme.ColorC1 + "44",
    },
    fullContainer: {
        padding: "16px",
        [theme.breakpoints.down("xs")]: {
            padding: "6px",
            },
    },
}));

const Faq = ({match}) => {

    const classes = useStyles();
    
    const [isFetching, setIsFetching] = useState(true)
    const [faqData, setFaqData] = useState([])
    
    const store = match.params.siteId;


    useEffect ( () => {
        const source = Axios.CancelToken.source();
        let url = `/faq/store/${store}`;
        Axios.get(url)
            .then(function(response) {
                const data = response.data
                data.sort(function(a, b) {
                    return a.Order - b.Order;
                });
                setFaqData(data)
            })
            .catch(function (error) {
                console.error(error);
                })
        setIsFetching(false)
        return () => {
            source.cancel();
        };
    }, [store])

    if (isFetching) {
        return <NDataLoading/>
    } 

    const breadCrumbs = [
        {
            Label: "Native",
            URL: "/",
            Arrow: true
        },
    ];


    const FAQIndex = faqData;
    return (
        <FixedWidthStoreContainer>
            <Helmet>
                <title>FAQ</title>
            </Helmet>
            <ToolbarPlaceHolder />
            <Grid container >
                <Grid item xs={12}> 
                    <NBreadcrumb data={breadCrumbs}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.Headline}>Frequently Asked Questions</Typography>
                </Grid>
                <Grid container>
                        {FAQIndex.map((faq, key) => {
                            return (
                                <Grid item key={key} className={classes.QAItem}>
                                    <Typography className={classes.Question} paragraph={true}>
                                        {faq.Title}
                                    </Typography>
                                    <Typography className={classes.Answer}>
                                        <div
                                            className="styleContainer"
                                            dangerouslySetInnerHTML={{ __html: faq.Content }}
                                        />
                                    </Typography>
                                </Grid>
                            )
                        })
                        }
                </Grid>
            </Grid>
        </FixedWidthStoreContainer>
    );
}

export default Faq;

