import {useRef, useEffect, useState} from "react";

export function usePreviousState(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]); 

    return ref.current;
}


export const useHistoryState = (label, initValue) =>
{
    const [state,setState] = useState(window.history.state && window.history.state[label] ? window.history.state[label] : initValue);

    const updateState = (t,silent) => {
        const existingState = window.history.state ? window.history.state : {[label]: undefined};
        if(!silent && state !== t) {
            setState(t);
        }
        const newState = {...existingState, [label]: t};
        window.history.replaceState(newState,"");
        console.log("HistoryState",window.history.state);
    };

    return [state,(t,silent) => updateState(t,silent)];
}