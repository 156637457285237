import Axios from "axios";

export function editModel(newObject, id, token, url) {
  return new Promise((resolve, reject) => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.put(url + "/" + id, newObject, config)
      .then(function (response) {
        resolve();
      })
      .catch(function (error) {
        console.error(error);
        console.log(newObject);
        reject();
      });
  });
}

export function addNewModel(newObject, token, url) {
  return new Promise((resolve, reject) => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.post(url, newObject, config)
      .then(function (response) {
        resolve();
      })
      .catch(function (error) {
        console.error(error);
        reject();
      });
  });
}


export function deleteModel(rowid, token, url) {
  return new Promise((resolve, reject) => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.delete(url + "/" + rowid, config)
      .then(function (response) {
        resolve();
      })
      .catch(function (error) {
        console.error(error);
        reject();
      });
  });
}

export function changeOrder(url, token, rowid, direction) {
  return new Promise((resolve, reject) => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };

    Axios.get(url + "/reorder/" + rowid + "/" + direction, config)
      .then(function (response) {
        resolve();
      })
      .catch(function (error) {
        console.error(error);
        reject();
      });
  });
}

export function fetchData(
  token,
  query,
  baseURL,
  optionalFetchOptions
) {
  return new Promise((resolve, reject) => {

    let url = baseURL + "?";
    url += "pageSize=" + query.pageSize;
    url += "&pageNumber=" + (query.page + 1);
    url += "&search=" + query.search;
    if (optionalFetchOptions) {
      url += optionalFetchOptions
    }

    if (query.orderBy) {
      url += "&orderBy=" + query.orderBy.field;
      url += "&dir=" + query.orderDirection;
    }
    var config = {
      headers: { Authorization: "Bearer " + token }
    };

    Axios.get(url, config)
      .then(function (response) {
        resolve({
          data: response.data.Data,
          page: response.data.PageIndex - 1,
          totalCount: response.data.TotalCount
        });
      })
      .catch(function (error) {
        resolve({ data: [], page: 0, totalCount: 0 });
      });
  });
}
export const downloadFile = (
  token,
  query,
  baseURL,
  type,
  optionalFetchOptions,
  
) => {
  var config = {
    headers: { Authorization: "Bearer " + token }
  };
  let url = baseURL + "?"
  if (query && query.search) {
    url += "&search=" + query.search;
  }
  
  if (optionalFetchOptions) {
    url += optionalFetchOptions
  }
  if (query && query.orderBy) { 
    url += "&orderBy=" + query.orderBy.field;
    url += "&dir=" + query.orderDirection;
  }
  Axios.get(url, config)
    .then((response) => {
      let fileName = response.headers["content-disposition"].split("filename")[1]
      fileName = fileName.slice(1, fileName.length - 2)
      var encodedUri = window.URL.createObjectURL(new Blob([response.data], { type: type }))
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF
      link.click()
      document.body.removeChild(link);
    })
}

