import React, { useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { NDataLoading } from "../../Widget/NAdminCustomComponents";
import ProductsEditPanel from "../../EditPanels/ProductsEditPanel";
import { productThumbnail } from "../../Widget/TableEditors";
import * as cst from "../../../js/constants/miscellaneous_cst";
import Axios from "axios";
import { Chip, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { makeStylesGlobal } from "../../../themes/GlobalTheme";

const localStyle = makeStylesGlobal(theme => ({
    chips:{
        margin: 1,
        color: "white", 
        background: "rgba(143, 1, 44, 1)"
    },
}));

export default function WronglyEncodedProducts({ auctionId, products, onUpdate }) {
    const token = useSelector(state => state.auth.token);
    const classes = localStyle();
    const tableRef = useRef();

    const [wrongProducts, setWrongProducts] = useState(products)

    function refreshTable() {
        if (tableRef && tableRef.current) {
            getWrongProducts(token, auctionId)
        }
    }

    function getWrongProducts(token, auctionId) {
        let url = `${cst.testAuction}/${auctionId}`;
        var config = {
            headers: { Authorization: "Bearer " + token }
        };
        Axios.get(url, config)
            .then((response) => {
                setWrongProducts(response.data.ProductProblems)
            })
    }

    function problemStatus(chips) {
        return chips.map((chip, index) => {
            return (<Chip className={classes.chips} key={index} label={chip} />)
        })
    }
    if (!(wrongProducts && wrongProducts.length)) return <NDataLoading />
    return (
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    detailPanel={
                        rowData => {
                                return (
                                    <div>
                                        <ProductsEditPanel
                                            Data={
                                                rowData.Description === null
                                                    ? { ...rowData, Description: "", ValidationChips: null}
                                                    : {...rowData,ValidationChips: null}
                                            }
                                            onClose={() => {
                                                refreshTable();
                                                onUpdate();
                                            }}
                                            isNew={false}
                                        />
                                    </div>
                                );
                            }
                        }
                    tableRef={tableRef}
                    columns={[
                        {
                            title: "Lot",
                            field: "AuctionNumber",
                        },
                        {
                            title: "Image",
                            field: "ImageID",
                            cellStyle: { width: "10%", padding: 0 },
                            sorting: false,
                            render: productThumbnail
                        },
                        {
                            title: "Title",
                            field: "Title"
                        },
                        {
                            title: "Problem(s) to be fixed",
                            render: rowData => problemStatus(rowData.ValidationChips)
                        },
                    ]}
                    data={wrongProducts}
                    options={{
                        detailPanelType: "single",
                        sorting: false,
                        draggable: false,
                        pageSize: wrongProducts.length,
                        actionsColumnIndex: 50,
                        paging: false,
                        addRowPosition: "first",
                        showTextRowsSelected: false,
                        search: false,
                        selectionProps: rowData => ({
                            color: "primary"
                        })
                    }}
                    title=""
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>,
                        Toolbar: props=> null
                   }}
                />
            </Grid>
        </Grid>
    );
}
