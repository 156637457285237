import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/InsertChart";
import UsersIcon from "@material-ui/icons/PermContactCalendar";
import AuctionsIcon from "@material-ui/icons/Gavel";
import FAQIcon from "@material-ui/icons/LiveHelp";
import InvoicesIcon from "@material-ui/icons/Description";
import NewsIcon from "@material-ui/icons/RecordVoiceOver";
import RouterLink from "../../routes/RouterLink";
import ProductsIcon from "@material-ui/icons/ShoppingBasket";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import SettlementsIcon from "@material-ui/icons/Receipt";
import BuildIcon from '@material-ui/icons/Build';
import CatalogIcon from '@material-ui/icons/MenuBook';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import GroupsIcon from '@material-ui/icons/ListAlt';


const MenuItem = (title, icon, path) => {
  const effectivePath = "/admin/" + path;
  const isActive = document.location.pathname.startsWith(effectivePath);
  return (
    <ListItem
      button
      key={title}
      component={RouterLink}
      to={effectivePath}
      selected={isActive}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default function AdminMenu() {
  return (
    <>
      <List>
        {MenuItem("Dashboard", <DashboardIcon />, "dashboard")}
        {MenuItem("Users", <UsersIcon />, "users")}
        {MenuItem("News", <NewsIcon />, "news")}
        {MenuItem("Auctions", <AuctionsIcon />, "auctions")}
        {MenuItem("Groups", <GroupsIcon/>, "groups")}
        {MenuItem("Objects", <ProductsIcon />, "products")}
        {MenuItem("Bids", <MonetizationOnIcon />, "bids")}
        {MenuItem("Invoices", <InvoicesIcon />, "invoices")}
        {MenuItem("Settlements", <SettlementsIcon />, "settlements")}
        {MenuItem("FAQ", <FAQIcon />, "faq")}
        {MenuItem("Catalog", <CatalogIcon/>, "catalog")}
        {MenuItem("Mailing", <ContactMailIcon />, "mailing")}
        {MenuItem("Parameters", <BuildIcon />, "parameters")}
      </List>
    </>
  );
}
