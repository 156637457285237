import React, { useState, useRef } from "react";
import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { USERSURL } from '../../js/constants/model_cst'
import AddIcon from "@material-ui/icons/AddCircleOutline";

import { editModel, deleteModel, fetchData } from "../../js/utils/backend";
import { NAdminToolBar, NAdminActiveButton, NModal } from "../Widget/NAdminCustomComponents"
import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined';
import { defaultPageSize, itemsPerPage } from "../../js/constants/miscellaneous_cst";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { TablePagination } from "@material-ui/core";
import { useReduxPrefsState } from "../../js/actions/prefs";
import RegistrationForm from "../Account/RegistrationForm";
import {scrollTopFunction} from "../../js/constants/functions_cst"
import ProfileForm from "../../components/Profile/ProfileForm"
import {useHistoryState} from "../../js/utils/customHooks";




const localStyle = makeStylesGlobal(theme => ({
  
}));

export default function Users(props) { 
  const [isTableEditing, setIsTableEditing] = useState(false);
  const token = useSelector(state => state.auth.token);
  const [rows, setRows] = useReduxPrefsState("nbRows", defaultPageSize);
  const classes= localStyle();
  const tableRef = useRef();
    const [searchText, setSearchText] = useHistoryState("searchText","");
    const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn","LastName");
    const [sortingDir, setSortingDir] = useHistoryState("sortingDir","asc");
  const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
    const [currentPage, setCurrentPage] = useHistoryState("currentPage",0);
    const [isInit,setIsInit] = useState(true);

  const [operation, setOperation] = useState("none");
  const operationChange = newOperation => 
    {
        switch(newOperation)
        {
            case "none":
            break;
            case "addUser":
            break;
            default: return null;
        }
        setOperation(newOperation);
    }

    function refreshTable() { 
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    const [rowUserID, setRowUserId] = useState()
    const [showModal, updateShowModal] = useState(false)
    const toggleModal = () => updateShowModal(state => !state)
  

  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialTable
          tableRef={tableRef}
          columns={[
            { title: "Bidder #", field: "SerialNumber", editable: "never",
                defaultSort: getSort("SerialNumber") },
            { title: "First Name", field: "FirstName", editable: "never",
                defaultSort: getSort("FirstName"), customSort: (a, b) => null},
            { title: "Last Name", field: "LastName", editable: "never",
                defaultSort: getSort("LastName"), customSort: (a, b) => null}, 
            { title: "Company", field: "Company", editable: "never",
                defaultSort: getSort("Company"), customSort: (a, b) => null},
            { title: "E-Mail", field: "EMail", editable: "never",
                defaultSort: getSort("EMail"), customSort: (a, b) => null},
            {
              title: "Role",
              field: "Role",
              editable: "always",
              lookup: { Admin: "Admin", User: "User", Locked:"Locked" }, defaultSort: getSort("Role")
            }, 
            {
              title: "Validated",
              field: "Validated",
              editable: "always",
              sorting: false,
              lookup: { Yes: "Yes", No: "No" }
            }
          ]}
          editable={{
            onRowUpdate: newData =>
              editModel(newData,newData.ID,token, USERSURL),
            onRowDelete: oldData =>
              deleteModel(oldData.ID,token, USERSURL)
          }}
          data={query => {
              const effectivePage = isInit ? currentPage : query.page;
              setSearchText(query.search,true);
              setSortingColumn(query.orderBy && query.orderBy.field);
              setSortingDir(query.orderDirection);
              setCurrentPage(effectivePage);
              setIsInit(false);
              return fetchData(token, {...query,page: effectivePage}, USERSURL);
          }
          }
          options={{
            pageSize: rows,
            pageSizeOptions: itemsPerPage,
            actionsColumnIndex: 50,
            emptyRowsWhenPaging:false, 
              searchText:searchText
          }}
          onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
          actions={[
            (rowData) => ({
              icon: () => <AccountBoxIcon/>,
              onClick: (event, rowData) => {
                toggleModal()
                setRowUserId(rowData.ID)
              },
              tooltip: 'Edit profile',
            })
          ]}
          components={{
            EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />)},
            Row: props => { setIsTableEditing(props.hasAnyEditingRow); return (<MTableBodyRow {...props} />)},
            Pagination: props=>(<TablePagination {...props} className={isTableEditing ?classes.disabled:null} />) ,
            Toolbar: props => (
              <div>
                <div className={isTableEditing?classes.disabled:null}> 
                  <MTableToolbar {...props} /> 
                </div>
                <NAdminToolBar invisible={operation === "addUser"}>
                  {operation === "none" && 
                    <>
                      <NAdminActiveButton
                        startIcon={<AddIcon />}
                        onClick={() => operationChange("addUser")}>
                      New
                      </NAdminActiveButton>
                    </>
                  }
                  {operation === "addUser" && (
                    
                        <RegistrationForm
                          isAdmin={true}
                          onClose={() => {
                            operationChange("none");
                            refreshTable();
                            scrollTopFunction()
                          }}
                        />
                    
                  )}

                  <NModal showModal={showModal} updateModalState={toggleModal}>
                    <ProfileForm rowUserID={rowUserID} isPopUp={true} onClose={() => {toggleModal(); refreshTable()}}/>
                  </NModal>
                </NAdminToolBar>
              </div>
            )
          }} 
          title="Users"
        />
      </Grid>
    </Grid>
  );
}
