import React from "react";

import { Link } from "@material-ui/core";

import RouterLink from "../../routes/RouterLink";
import { useSelector } from "react-redux";
import { Deadline, calculateTimeLeft } from "./Deadline"
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { formatCurrency, priceEstimation } from "../../js/utils/formatter";
import SeparationCell from "./SeparationCell";

const useStyles = makeStyles((theme) => ({
    ProductCell: {
        position: "absolute",
        borderLeft: "solid 1px",
        borderColor: theme.ColorC1 + "22",
        color: theme.ColorC2,
        top: "16px",
        left: "0px",
        right: "32px",
        bottom: "16px",
    },
    ProductFrame: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "0px",
        left: theme.spacing(2),
        right: "0px",
        bottom: "0px",
    },
    ProductMedia: {
        flexBasis: "400px",
        flexGrow: 10,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "left center",
    },
    ProductInfo: {
        flexBasis: "140px",
        flexGrow: 0,
        paddingRight: "10px"
    },
    ProductLot: {
        fontFamily: theme.SecondaryFont,
        fontWeight: "bold",
        fontStyle: "normal",
        fontSize: "16px",
        marginTop: theme.spacing(2),
        color: theme.ColorC1,
        [theme.breakpoints.down('xs')]: {
            fontSize: "13px"
          }
    },
    ProductTitle: {
        fontFamily: theme.SecondaryFont,
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "18px",
        maxHeight: "36px",
        overflow:"hidden",
        [theme.breakpoints.down('xs')]: {
            fontSize: "13px"
          }
    },
    ProductSubtitle: {
        fontFamily: theme.SecondaryFont,
        fontSize: "13px",
        lineHeight: "18px",
        maxHeight: "36px",
        overflow:"hidden",
        [theme.breakpoints.down('xs')]: {
            fontSize: "10px",
            lineHeight: "14px",
            maxHeight: "14px"
        }
    },
    Fact: {
        display: "block",
        lineHeight: "20px",
        [theme.breakpoints.down('xs')]: {
            lineHeight: "14px"
        }
    },
    FactBold: {
        display: "block",
        lineHeight: "20px",
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            lineHeight: "14px"
        }
    },
    FactLabel: {
        display: "inline-block",
        fontSize: "13px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "10px"
          }
    },
    FactValue: {
        display: "inline-block",
        fontSize: "13px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "10px"
          }
    },
    HasEnded: {
        fontSize: "13px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "10px"
          }
    },
}));


const ProductCell = ({ 
    item, 
    index, 
    currentTime,
    totalWidth
}) => { 

   

    const classes = useStyles();
    const history = useHistory();
    const stateItem = useSelector((state) => item ? state.itemCache.items[item.ID]: null);
    if (item == null) {
        return null;
    }
    if(item.isSeparator){
        return (<SeparationCell item={item} index={index} currentTime={currentTime} totalWidth={totalWidth} />);
    }
    const currentBid = stateItem ? stateItem.current : null;
    const deadline = currentBid ? currentBid.DeadLine : null;
    const tooLate = calculateTimeLeft(deadline, currentTime) ? false : true; 
    const siteId = item.IsOnline ? "online" : "auctions"
    const isOnline = item.IsOnline;
    const displayCountdown = isOnline && deadline && !tooLate;
    const isGoingToBeSold = currentBid && currentBid.BidderRef; 
    const resultPrice = item.resultPrice ? item.resultPrice : (currentBid ? currentBid.Value : 0 ); 
    const isFinal = stateItem && stateItem.final;

    const url = item.Image ? `url(/Image/thumbnail/${item.Image})` : "none";

    return (
        <div className={classes.ProductCell}>
        <Link
            color="inherit"
            underline="none"
            component={RouterLink}
            onClick={() => {
            history.replace( {...history.location, state:{scrollback:window.scrollY}});
            }}
            to={{pathname:"/" + siteId + "/detail/" + item.AuctionID + "/product/" + item.ID, state:{scrollforward:window.scrollY}}}
        >
            <div className={classes.ProductFrame}>
            <div className={classes.ProductMedia} style={{ backgroundImage: url }} />
            <div className={classes.ProductInfo}>        
            <div className={classes.ProductLot}>{item.Order}</div>
            <div className={classes.ProductTitle}>{item.Title}</div>
            <div className={classes.ProductSubtitle}>{item.Subtitle}</div>
            {isOnline ? 
                /* ONLINE CELLS*/
                (tooLate ? 
                    (<div className={classes.Fact}>
                        {isFinal ? 
                        (isGoingToBeSold ?
                            <><div className={classes.FactLabel}>result:&nbsp;</div>
                                        <div className={classes.FactValue}>
                                                {formatCurrency(resultPrice)}
                                        </div></>
                        : <div className={classes.HasEnded}>the sale has ended</div> )
                        :
                         <div className={classes.HasEnded}>validating latest bids...</div> 
                        }
                    </div>)
                    :
                    (<>
                        <div className={classes.Fact}>
                            <div className={classes.FactLabel}>estimate:&nbsp;</div>
                            <div className={classes.FactValue}>
                                        {priceEstimation(item)}
                            </div>
                        </div>
                        {displayCountdown ? 
                            (<div className={classes.FactBold}>
                            <div className={classes.FactLabel}>time:&nbsp;</div>
                            <div className={classes.FactValue}>
                                {displayCountdown && (<Deadline deadline={deadline} currentTime={currentTime} />)}
                            </div>
                            </div>) 
                        :null}
                        <div className={classes.FactBold}>
                            <div className={classes.FactLabel}>{(stateItem.current.BidderRef === 0) ? "start:" : "current:"}&nbsp;</div>
                            <div className={classes.FactValue}>
                            {formatCurrency(stateItem.current.Value)} {(stateItem.current.BidderRef === 0) ? null : stateItem.current.BidCount ? "(" + stateItem.current.BidCount + ")" :"" }
                            </div>
                        </div>
                    </>) 
                )
                : 
                /* AUCTION CELLS */
                        (<>
                            <div className={item.FinalPrice ? classes.Fact : classes.FactBold}>
                            <div className={classes.FactValue}>
                                        {priceEstimation(item)}
                            </div>
                            </div>
                            {(item.FinalPrice) ?
                                
                                <div className={classes.FactBold}>
                                    <div className={classes.FactLabel}>result:&nbsp;</div>
                                    <div className={classes.FactValue}>
                                        {formatCurrency(item.FinalPrice)}
                                    </div>
                                </div>
                            : null}
                </>)
                }
            
                    </div>
                    </div>
        </Link>
        </div>
    );
    };

    export default ProductCell;