import React from 'react'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { editModel, addNewModel} from "../../js/utils/backend";
import { useSelector } from "react-redux"; 
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { CATALOGURL } from "../../js/constants/model_cst";
import { 
    VDatePicker,
    VDropzone, 
} from '../Widget/CustomVInputs'

import { useForm, FormContext } from 'react-hook-form';
import * as cst from '../../js/constants/miscellaneous_cst'

import {NBottomActiveButton, NBottomCancelButton} from '../Widget/NAdminCustomComponents';

const CatalogEditPanel = ({
    Data, 
    onClose, isNew}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange", 
        defaultValues: Data
    });

    const {
        handleSubmit
    } = methods;

    const localStyle = makeStylesGlobal(theme => ({}));
    
    const classes = localStyle();
    const token = useSelector(state => state.auth.token);


    const submitUpdate = data => { 
        const newData = {...Data, ...data}; 
        if(isNew)
        {
            addNewModel(newData, token, CATALOGURL)
            .then(onClose)
        }
        else
        {
            editModel(newData, Data.ID, token, CATALOGURL)
            .then(onClose)
        }
    }

    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)}>
                <Grid container> 
                    <Grid item sm={6} xs={12}>
                        <VDropzone
                            fieldName="CoverID"
                            label="Cover"
                            validation={{required: cst.msgImageRequired}}
                            fileType="img" 
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <VDropzone
                            fieldName="PdfID"
                            label="PDF"
                            validation={{required: cst.msgPDFRequired}}
                            fileType="pdf"
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <VDatePicker
                            fieldName="PublicationDate"
                            label="Publication Date"
                            fullWidth={true}
                            validation={{
                                required: cst.msgRequired,
                            }}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Box className={classes.RightEndAlign}>
                            <NBottomCancelButton 
                                variant="outlined" 
                                onClick={onClose}
                            >
                            Cancel
                            </NBottomCancelButton>
                            <NBottomActiveButton 
                                type="submit"
                            >
                            Save
                            </NBottomActiveButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    ); 
} 

export default CatalogEditPanel