import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { useForm, FormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import Axios from 'axios'

import { makeStylesGlobal } from "../../themes/GlobalTheme";

import { editModel, addNewModel } from "../../js/utils/backend";
import * as cst from "../../js/constants/miscellaneous_cst";


import { VAuctionChooser, VCheckBox } from "../Widget/CustomVInputs"

import {
  NBottomActiveButton,
  NBottomCancelButton
} from "../Widget/NAdminCustomComponents";

import {
  VTextField,
  VSelectInput,
  VNumeric,
  VUserChooser,
  VTextFieldMaskedInput,
  VDatePicker
} from "../Widget/CustomVInputs";

import { computeCountriesArray } from "../../js/utils/computedPackages";

export default function BillingEditPanel({
  Data,
  onClose,
  isNew,
  onUpdate,
  type
}) {
  const [currentUser, setCurrentUser] = useState(
    Data && Data.OriginalUser ? Data.OriginalUser : null
  );
  const [countriesList, setCountriesList] = useState([]);

  const [newInvoiceNumber, setNewInvoiceNumber] = useState(null)

  const token = useSelector(state => state.auth.token);

  useEffect(() => {
    const fetchBillingNumber = (token) => {
      var config = {
        headers: { Authorization: "Bearer " + token }
      };
      let url = type.nextnumber + "?category=P";
      Axios.get(url, config)
        .then(response => {
          setNewInvoiceNumber(response.data);
        })
    }
    isNew && fetchBillingNumber(token);
    setCountriesList(computeCountriesArray());
  }, [token, isNew, type]);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: isNew ? { ...Data, ...Data.IsArticle44 = false} : Data,
  });


  const {
    handleSubmit,
    setValue,
    watch,
    triggerValidation
  } = methods;
  const localStyle = makeStylesGlobal(theme => ({
    VCheckBoxAlign : {
      marginTop: "12px"
    }
  }));
  const classes = localStyle();




  function userToModelData(user) {
    return {
      ...user,
      ID: Data && Data.ID,
      UserID: user.ID,
      Name: makeName(user)
    };
  }

  function onUserSelected(user) {
    let dt = userToModelData(user);
    fillUserData(dt);
    setCurrentUser(user);
  }

  function fillUserData(data) {
    setValue("Name", data ? data.Name : "", true);
    setValue("AddressLine1", data ? data.AddressLine1 : "", true);
    setValue("AddressLine2", data ? data.AddressLine2 : "", true);
    setValue("PostCode", data ? data.PostCode : "", true);
    setValue("City", data ? data.City : "", true);
    setValue("Country", data ? data.Country : "", true);
    setValue("VATNumber", data ? data.VATNumber : null, true);
    isNew && setValue("Number", data ? newInvoiceNumber : "", true);
  }

  function onCloseAction(param) {
    onClose(param);
  }

  const submitUpdate = param => {
    let response;
    const newData = { ...Data, ...param, OriginalUser: null };
    if (isNew === true) {
      response = addNewModel(newData, token, type.modelUrl).then(onClose);
    } else {
      response = editModel(newData, Data.ID, token, type.modelUrl).then(onClose);
    }
    response.then(() => { onUpdate && onUpdate({ ...Data, ...param, OriginalUser: currentUser }); });
  };

  function makeName(data) {
    const first = data.FirstName ? data.FirstName : "";
    const last = data.LastName ? " " + data.LastName : "";
    const company = data.Company ? data.Company + " - " : "";
    return company + first + last;
  }

  function validationVATNumber(value) {
    if (watch('IsArticle44') === true) {
      if ((value === null) || (value === "")) {
        return cst.msgArt44Required
      } else if (value.includes("BE")) {
        return cst.msgArt44ButVATIsBE
      } else {
        return true
      }
    } else {return true}
  }

  function validationVATpercent(value) {
    if (watch('IsArticle44') === true) {
      if ((value !== 0 )){
        return cst.msgVATRate0
      } else {
        return true
      }
    } else {return true}
  }

  if (!Data && !isNew) return <div>wait</div>;
  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(submitUpdate)}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box>
                <VUserChooser
                  onUserSelected={onUserSelected}
                  value={currentUser}
                  label={type.userType}
                  fieldName={"UserID"}
                  validation={{ required: false }}
                  showRefresh={true}
                />
              </Box>
            </Grid>
          </Grid>

          <Box hidden={!currentUser}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <VSelectInput
                  label="Status"
                  fullWidth={true}
                  fieldName="Status"
                  validation={{ required: true }}
                  allvalues={type.status}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <VTextFieldMaskedInput
                  label="Number"
                  fieldName="Number"
                  validation={{
                    required: cst.msgRequired,
                    validate: value => {
                      if (value.includes("_")) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }}
                  formatChars={type.NumberChars}
                  mask={type.customMask}
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <VDatePicker
                  fieldName="Date"
                  label="Date"
                  validation={{
                    required: cst.msgRequired,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <VAuctionChooser
                  label="Related Auction"
                  fieldName="RelatedAuctionID"
                  fullWidth={true}
                  validation={{ required: false }}
                />
              </Grid>

              <Grid item xs={12}>
                <VTextField
                  label="Name"
                  fieldName="Name"
                  fullWidth={true}
                  validation={{ required: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <VTextField
                  label="Address"
                  fieldName="AddressLine1"
                  fullWidth={true}
                  validation={{ required: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <VTextField
                  label="Address complement"
                  fieldName="AddressLine2"
                  fullWidth={true}
                />
              </Grid>

              <Grid item sm={3} xs={6}>
                <VTextField
                  label="Postal Code"
                  fieldName="PostCode"
                  fullWidth={true}
                  validation={{ required: true }}
                />
              </Grid>

              <Grid item sm={4} xs={6}>
                <VTextField
                  label="City"
                  fieldName="City"
                  fullWidth={true}
                  validation={{ required: true }}
                />
              </Grid>

              <Grid item sm={5} xs={12}>
                <VSelectInput
                  label="Country"
                  fullWidth={true}
                  fieldName="Country"
                  validation={{ required: true }}
                  allvalues={countriesList}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} className={classes.VCheckBoxAlign}>
                  <VCheckBox
                    fieldName="IsArticle44"
                    label="VAT Article 44"
                />
                <VCheckBox
                    fieldName="IsWithVATonCommission"
                    label="With explicit VAT on Commission"
                  />
              </Grid>
              <Grid item xs={12} sm={6} md={3} >
                <VTextFieldMaskedInput
                  label="VAT Number"
                  fieldName="VATNumber"
                  fullWidth={true}
                  validation={{
                    validate: {
                      isArt44: value => validationVATNumber(value)
                    }
                  }}
                  onBlur={async () => {
                    triggerValidation('VATNumber');
                  }}
                  placeholder={(watch('IsArticle44') === true) ? "e.g. FR12345678901 " : "e.g. BE123456789"}
                  formatChars={cst.VATnumberChars}
                  mask={cst.VATnumberMask}
                  emptyMaskChar={""}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <VNumeric
                  label="VAT"
                  fieldName="VATRate"
                  fullWidth={true}
                  validation={{ 
                    validate: {
                      isArt44: value => validationVATpercent(value),
                    }
                  }} 
                  unit="%"
                  inputProps={{
                    style: { textAlign: "right" }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}  >
                <VNumeric
                  label={type.commission}
                  fieldName="BaseComissionPercent"
                  fullWidth={true}
                  validation={{ required: true }}
                  unit="%"
                  inputProps={{
                    style: { textAlign: "right" }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.RightEndAlign}>
            {onClose ? 
            <NBottomCancelButton variant="outlined" onClick={onCloseAction}>
              Cancel
            </NBottomCancelButton> 
            : null}
            {currentUser && (
              <NBottomActiveButton type="submit">Save</NBottomActiveButton>
            )}
          </Box>
        </form>
      </FormContext>
    </>
  );
}
