import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Dashboard from "../components/Admin/Dashboard/Dashboard";
import Users from "../components/Admin/Users";
import Faq from "../components/Admin/Faq";
import News from "../components/Admin/News";
import Auction from "../components/Admin/Auctions/Auction";
import Products from "../components/Admin/Products/Products";
import Billings from "../components/Admin/Billings/Billings";
import Mailing from "../components/Admin/Mailing";
import Parameters from "../components/Admin/Parameters";
import Catalog from "../components/Admin/Catalog";

import PhoneRequest from "../components/Admin/Auctions/PhoneRequest";
import AuctionWithProducts from "../components/Admin/Auctions/AuctionWithProducts"
import BillingWithProducts from "../components/Admin/Billings/BillingWithProducts";
import BulkEncode from "../components/Admin/Products/BulkEncode/BulkEncode";
import { invoice, settlement } from "../js/constants/miscellaneous_cst";
import AuctionReorder from "../components/Admin/Auctions/AuctionReorder";
import AuctionSections from "../components/Admin/Auctions/AuctionSections";
import ProfileForm from "../components/Profile/ProfileForm";
import AllBids from "../components/Admin/AllBids";
import Groups from "../components/Admin/Groups";
import { Helmet } from 'react-helmet';



export default function AdminRouter() {
        return (
                <>
                        <Helmet>
                                <title>Native Auctions - Admin</title>
                                <meta name="auth" content="auth" />
                        </Helmet>
                        <Switch>
                                <Route path="/admin/dashboard" component={Dashboard} />
                                <Route path="/admin/users" component={Users} />
                                <Route path="/admin/auctions" component={Auction} />
                                <Route path="/admin/faq" component={Faq} />
                                <Route path="/admin/news" component={News} />
                                <Route exact path="/admin/products" component={Products} />
                                <Route exact path="/admin/bids" component={AllBids} />

                                <Route exact path="/admin/auction/:auctionId/products/reorder" component={AuctionReorder} />
                                <Route exact path="/admin/auction/:auctionId/sections" component={AuctionSections} />
                                <Route exact path="/admin/groups/" component={Groups} />
                                <Route exact path="/admin/products/bulk" component={BulkEncode} />

                                <Route path="/admin/catalog" component={Catalog}/>
                                <Route path="/admin/mailing" component={Mailing} /> 
                                <Route path="/admin/parameters" component={Parameters} /> 

                                <Route exact path="/admin/auction/:auctionId/products" component={AuctionWithProducts} />
                                <Route exact path="/admin/auction/:auctionId/phonerequest" component={PhoneRequest} />s

                                <Route path="/admin/invoices" component={(props) => <Billings type={invoice} {...props} />} />
                                <Route path="/admin/settlements" component={(props) => <Billings type={settlement} {...props} />} />
                                
                                <Route path="/admin/invoice/:invoiceId" component={(props) => <BillingWithProducts type={invoice} {...props} />} />
                                <Route path="/admin/settlement/:settlementId" component={(props) => <BillingWithProducts type={settlement} {...props} />} />
                                <Route path="/admin/profile/:userId" component={ProfileForm} />
                                
                                <Redirect exact from="/admin" to="/admin/dashboard" />
                        </Switch>
                </>
        );
}
