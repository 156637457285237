import React, { useRef, useState } from "react";
import MaterialTable, { MTableToolbar, MTableBodyRow, MTableEditRow } from "material-table";
import { TablePagination } from '@material-ui/core';
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import {Grid, Paper} from "@material-ui/core";
import { useSelector } from "react-redux";
import { fetchData, deleteModel } from "../../js/utils/backend"
import GroupEditPanel from "../EditPanels/GroupEditPanel";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useReduxPrefsState } from "../../js/actions/prefs"
import * as cst from "../../js/constants/miscellaneous_cst";
import ProductsIcon from '@material-ui/icons/ShoppingBasketOutlined';

import { NAdminToolBar, NAdminActiveButton } from "../Widget/NAdminCustomComponents";
import { requestGROUP } from "../../js/constants/model_cst";

import { priceEstimation} from "../../js/utils/formatter";
import {useHistoryState} from "../../js/utils/customHooks";

const localStyle = makeStylesGlobal(theme => ({

}));

export default function Groups({history}) {
    const classes = localStyle();

    const token = useSelector(state => state.auth.token);


    //const [selectedRows, setSelectedRows] = useState([])
    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);

    const [displayEditPanel, setDisplayEditPanel] = useState(true);
    const [operation, setOperation] = useState("none");
    const [isTableEditing, setIsTableEditing] = useState(false);
    const [searchText, setSearchText] = useHistoryState("searchText","");
    const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn","Title");
    const [sortingDir, setSortingDir] = useHistoryState("sortingDir","asc");
    const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
    const [currentPage, setCurrentPage] = useHistoryState("currentPage",0);
    const [isInit,setIsInit] = useState(true);


    function refreshTable() {
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    const operationChange = newOperation => {
        switch (newOperation) {
            case "none":
                setDisplayEditPanel(true);
                break;
            case "new":
                break;
            default:
                return null;
        }
        setOperation(newOperation);
    };

    const tableRef = useRef();
    

    return (
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    detailPanel={
                        displayEditPanel ? 
                            rowData => {
                                return (
                                    <div>
                                        <GroupEditPanel
                                            Data={rowData}
                                            onClose={() => {
                                                refreshTable();
                                            }}
                                            isNew={false}
                                        />
                                    </div>
                                ) 
                            } : null
                    }
                    tableRef={tableRef}
                    columns={[
                        {   
                            title: "Title",
                            field: "Title",
                            editable: "never",
                            defaultSort: getSort("Title"), customSort: (a, b) => null
                        },
                        {
                            title: "Lots",
                            field: "Lots",
                            headerStyle: { textAlign: "right"},
                            cellStyle: { textAlign: "right"},
                            defaultSort: getSort("Lots")
                        },
                        {
                            title: "Estimated",
                            field: "EstimatedLow",
                            render: priceEstimation,
                            cellStyle: { width: "20%" },
                            defaultSort: getSort("EstimatedLow")
                        }
                    ]}
                    data={query => {
                        let fetchOptions = "&filter[status]=group"
                        const effectivePage = isInit ? currentPage : query.page;
                        setSearchText(query.search,true);
                        setSortingColumn(query.orderBy && query.orderBy.field);
                        setSortingDir(query.orderDirection);
                        setCurrentPage(effectivePage);
                        setIsInit(false)
                        return  fetchData(token, {...query,page: effectivePage}, requestGROUP, fetchOptions)
                    }}

                    options={{
                        pageSize: rows,
                        pageSizeOptions: cst.itemsPerPage,
                        emptyRowsWhenPaging:false,
                        actionsColumnIndex: 50,
                        searchText:searchText
                    }}

                    actions={[
                        (rowData) => (operation !== "none" ? null : {
                            icon: () => <ProductsIcon/>,
                            onClick: (event, rowData) => { history.push("/admin/auction/" + rowData.ID + "/products") },
                            tooltip: 'See items'
                        })
                    ]}
                    onChangeRowsPerPage={(nbRows) => setRows(nbRows)}

                    editable={{
                        onRowDelete: 
                            oldData => deleteModel(oldData.ID, token, requestGROUP)
                    }}

                    title="Groups"


                    components={{
                        Row: props => { setIsTableEditing(props.hasAnyEditingRow); return (<MTableBodyRow {...props} />) },
                        EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />) },
                        Pagination: props => (<TablePagination {...props} />),

                        Toolbar: props => (
                            <div>
                                <div className={isTableEditing ? classes.disabled : null}>
                                    <MTableToolbar {...props} />
                                </div>

                                <NAdminToolBar invisible={operation === "new"}>
                                    {operation === "none" && (
                                        <>
                                        <NAdminActiveButton
                                            startIcon={<AddIcon />}
                                            onClick={() => {
                                                operationChange("new");
                                            }}
                                            >
                                            New
                                        </NAdminActiveButton>
                                        </>
                                    )}

                                    {operation === "new" && (
                                    <Paper className={classes.NVisiblePaper}>
                                        <GroupEditPanel
                                            onClose={() => {
                                                operationChange("none");
                                                refreshTable();
                                            }}
                                            isNew={true}
                                        />
                                    </Paper>
                                    )}
                                </NAdminToolBar>
                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
    )
}