import React, { useRef, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useSelector } from "react-redux";
import { CATALOGURL } from "../../js/constants/model_cst";
import { fetchData, deleteModel } from "../../js/utils/backend"; 
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import { NAdminToolBar, NAdminActiveButton } from '../Widget/NAdminCustomComponents'
import CatalogEditPanel from "../EditPanels/CatalogEditPanel"
import { DateTimeRenderer, Thumbnail } from "../Widget/TableEditors"
import { scrollTopFunction } from "../../js/constants/functions_cst";
import * as cst from "../../js/constants/miscellaneous_cst"
import { useReduxPrefsState } from "../../js/actions/prefs"

const localStyle = makeStylesGlobal(theme => ({
}));

export default function Catalog() {
    const classes = localStyle();
    const token = useSelector(state => state.auth.token);

    const tableRef = useRef();

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
    const [displayEditPanel, setDisplayEditPanel] = useState(true)
    const [operation, setOperation] = useState("none");

    const operationChange = newOperation => 
    {
        switch(newOperation)
        {
            case "none":
                setDisplayEditPanel(true)
            break;
            case "new":
            break;
            default: return null;
        }
        setOperation(newOperation);
    }

    function refreshTable() { 
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    detailPanel={
                        displayEditPanel ? (rowData) =>
                        {
                            return (
                                <div>
                                    <CatalogEditPanel
                                        Data={rowData}
                                        onClose={refreshTable}
                                        isNew={false}
                                    />
                                </div>
                            );
                        } : null}
                    tableRef={tableRef}
                    columns={[
                        {
                            title: "Cover",
                            field: "CoverID",
                            cellStyle: { width: '10%', padding: 0 },
                            render: rowData => Thumbnail(80, rowData.CoverID)
                        },
                        {
                            title: "Publication Date",
                            field: "PublicationDate", 
                            defaultSort: "desc",
                            render: rowData => DateTimeRenderer(70, rowData.PublicationDate)
                        },
                    ]}
                    data={query => fetchData(token, query, CATALOGURL)}
                    options={{
                        sorting: false,
                        detailPanelType: 'single',
                        pageSize: rows,
                        pageSizeOptions: cst.itemsPerPage,
                        emptyRowsWhenPaging:false,
                        actionsColumnIndex: 50,
                    }}
                    editable={{
                        onRowDelete: oldData => deleteModel(oldData.ID, token, CATALOGURL)
                    }}
                    onChangeRowsPerPage={(nbRows) => setRows(nbRows)}
                    title="Catalog"
                    components={{
                        Toolbar: props => (
                            <div>
                                <div>   
                                    <MTableToolbar {...props} />
                                </div>
                                <div>
                                <NAdminToolBar invisible={operation==="new"}>
                                {operation === "none" && 
                                    <>
                                        <NAdminActiveButton
                                        startIcon={<AddIcon />}
                                        onClick={() => operationChange("new")}>
                                        New
                                        </NAdminActiveButton>
                                    </>
                                }
                                {operation === "new" && (
                                    <Paper className={classes.NVisiblePaper}>
                                        <CatalogEditPanel 
                                            onClose={() => {
                                                operationChange("none");
                                                refreshTable();
                                                scrollTopFunction();
                                            }} 
                                            isNew={true} 
                                        />
                                    </Paper>
                                )}
                                </NAdminToolBar>
                                </div>
                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
        </>
    );
}