import React, {useState} from "react";
import {NModal} from '../Widget/NAdminCustomComponents'
import ProfileForm from "../Profile/ProfileForm";


const UserModal = ({userId, isShown, onClose}) => {

  return (
  <NModal showModal={isShown} updateModalState={onClose}>
    <ProfileForm rowUserID={userId} isPopUp={true} onClose={onClose}/> 
  </NModal>);
};

export default UserModal;