import React from 'react';
import AuthRouter from './routes/AuthRouter';
import { ThemeProvider } from '@material-ui/core/styles';
import { GlobalTheme } from './themes/GlobalTheme';



import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';


// wrap AuthRouter in MuiPickerUtilsProvider https://material-ui-pickers.dev/getting-started/installation

const App = () => (
  <ThemeProvider theme={GlobalTheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        
        <AuthRouter/>
    </MuiPickersUtilsProvider>
  </ThemeProvider>);


export default App;