import React, { useState, useEffect } from "react";
import { Typography, Paper, Table, TableHead, TableRow, TableCell,TableBody,Link  } from "@material-ui/core";
import { makeStylesGlobal } from "../../../../themes/GlobalTheme";
import { useSelector } from "react-redux";
import { useDashboardState } from "../../../../js/actions/dashboard";
import { formatCurrency } from "../../../../js/utils/formatter"
import { APPCURRENCY } from '../../../../js/constants/miscellaneous_cst';
import ProductModal from "../../../Modals/ProductModal";
import UserModal from "../../../Modals/UserModal";
import { priceEstimation} from "../../../../js/utils/formatter";

const localStyle = makeStylesGlobal((theme) => ({
  WidgetContainer: {
    height: theme.NDashBoardWidgetHeight, //'200px',
  },
  ValueHeaderJustified: {
    textAlign: "right"
  },
  ValueJustified:{
    textAlign: "right"
  },
  Overflow:{
    overflow:"auto"
  },
  Final: {
    color: "#000",
    fontWeight: "bold"
  },
  NonFinal: {
    color: "#111"
  },
  IconTrend: {
    fontSize: "20px",
  }
}));

const BidView = ({ data, showUser, showProduct }) => {
  const classes = localStyle();
  const colorState = data.EstimatedLow > data.Value ? "#A00"
                : (data.EstimatedHigh < data.Value ? "#085" :
                "black");

  const date = data.Time.endsWith("Z") ? new Date(data.Time) : new Date(data.Time + "Z");
  return (
    <>
      <TableRow >
        <TableCell component="th" scope="row">
        <div className={data.IsFinal ? classes.Final : classes.NonFinal}>{date.toLocaleString('en-GB')}</div>
        </TableCell>
        <TableCell><div className={data.IsFinal ? classes.Final : classes.NonFinal}><Link onClick={showProduct}>{data.Product}</Link></div></TableCell>
        <TableCell><div className={data.IsFinal ? classes.Final : classes.NonFinal}>{priceEstimation(data)}</div></TableCell>
        <TableCell><div className={data.IsFinal ? classes.Final : classes.NonFinal}><Link onClick={showUser}>{data.BidderName}</Link></div></TableCell>
        <TableCell className={classes.ValueJustified}>
          <div className={data.IsFinal ? classes.Final : classes.NonFinal} style={{color: colorState}}>
            { formatCurrency(data.Value, APPCURRENCY)}
          </div>
        </TableCell>
        <TableCell className={classes.ValueJustified}>{ formatCurrency(data.MaxValue, APPCURRENCY)}</TableCell>
      </TableRow>
    </>
  );
};

export default function LiveBidsWidget() {
  const classes = localStyle();
  const allBids = useSelector((state) => state.itemCache.items.top100);
  const allBidders = useDashboardState("userlist", null);
  const allProducts = useDashboardState("productlist", null);
  const isReady = allBidders && allProducts && allBids;
  const [rowUserID, setRowUserId] = useState()
  const [rowProductID, setRowProductId ] = useState();

  const [showUserModal, updateShowUserModal] = useState(false)
  const [showProductModal, updateShowProductModal] = useState(false)

  const [aggregated, setAggregated] = useState(null);

  useEffect(() => {
    if (isReady) {
      let combined = allBids.map((t) => {
        const correspBidder = allBidders.find(
          (u) => u.BidderRef === t.BidderRef
        );
        const correspProduct = allProducts.find((u) => u.ID === t.AssignmentID);
        if(correspProduct) {
          return {
            ProductId: correspProduct.ProductId,
            BidderId: correspBidder && correspBidder.BidderId,
            BidderName: correspBidder ? correspBidder.BidderName : t.BidderRef,
            Product: correspProduct.Product,
            Time: t.Time,
            Value: t.Value,
            MaxValue: t.MaxValue,
            IsFinal: t.IsFinal,
            EstimatedLow: correspProduct.EstimatedLow,
            EstimatedHigh: correspProduct.EstimatedHigh
          };
        }
        return null;
      }).filter(t => t !== null);
      setAggregated(combined);
    }
  }, [allBids, isReady]);

  return (
    <>
      <ProductModal isShown={showProductModal} productId={rowProductID} onClose={() => updateShowProductModal(false)}/>
      <UserModal isShown={showUserModal} userId={rowUserID} onClose={() => updateShowUserModal(false)}/>
      <Paper className={classes.NPaperWidget}>
        <div className={classes.Overflow}>
        <Typography variant="h6">Live Bids</Typography>
        <Table
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell >Product</TableCell>
              <TableCell >Estimate</TableCell>
              <TableCell >Bidder</TableCell>
              <TableCell className={classes.ValueHeaderJustified}>Value</TableCell>
              <TableCell className={classes.ValueHeaderJustified}>Max Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aggregated &&
              aggregated.map((item, i) => {
                return <BidView key={i} data={item} showProduct={() => {setRowProductId(item.ProductId);updateShowProductModal(true);}} showUser={() => {setRowUserId(item.BidderId);updateShowUserModal(true);}}/>;
              })}
          </TableBody>
        </Table>
        </div>
      </Paper>
    </>
  );
}
