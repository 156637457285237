import React, {useState} from 'react'
import { Grid, Box } from '@material-ui/core'
import Dropzone from "./Dropzone"
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import DeletePicture from '@material-ui/icons/DeleteOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { Link } from "react-router-dom";


const MultiDropzone = ({
    value, 
    onChange,
    fileType,
}) => {
    
  const handleChangeDropzone = (index,tile) => {
    onChange(index,tile)
  }

  const localStyle = makeStylesGlobal(theme => ({

    DropzoneIconsGroup: {
      display: "flex", 
      marginTop: "-50px", 
      justifyContent: "space-around",
      alignItems: "center",
      opacity: 0,
      '&:hover': {
        opacity: 1,
      }
    },
    IconBackground: {
      backgroundColor: `rgba(255, 255, 255, 0.4)`, 
      '&:hover': {
        backgroundColor: `rgba(255, 255, 255, 0.8)`
      }
    },
    AddIconButton : {
      display: 'flex',
      marginTop: '-56px',
      marginRight: 'auto',
      marginBottom: '0px',
      marginLeft: 'auto',  
      width: '50%',
    },
    OrderingArrowBox : {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: -theme.spacing(3),
      marginLeft: -theme.spacing(1),
      marginTop: -theme.spacing(2),
      marginBottom: -theme.spacing(2),
    },
    ItemDropzone: {
      display: 'block', 
      flexGrow: 1
    }
  }));
  const classes = localStyle();

  const [refresh, setRefresh] = useState(false)
  function swapPictures(picturesArray, fromIndex, toIndex) {
    var element = picturesArray[fromIndex];
    picturesArray.splice(fromIndex, 1);
    picturesArray.splice(toIndex, 0, element);
  }
  let arrLength = value && value.length
  let isEndOfIndex = arrLength-1

  return (
    <>
      <Grid container spacing={2} sm={12} xs={8}> 
        {value && value.map((tile, index) => (
          <>
            <Grid item md={2} sm={4} xs={12} 
              key={index+"dropzone-tile"}
              container 
            >
              <Box className={classes.ItemDropzone}>
                <Dropzone  
                  isMulti={false}
                  value={tile}
                  onChange={
                    t => 
                    handleChangeDropzone(index,t)
                  }
                  fileType={fileType}
                />
                <Box className={classes.DropzoneIconsGroup}>
                  <IconButton 
                    onClick={t => handleChangeDropzone(index, null)}
                    classes={{root: classes.IconBackground}}
                  >
                      <DeletePicture/>
                  </IconButton>
                  <Link 
                    to={"/image/" + value[index]}
                    download 
                    target="_blank"
                    underline="none"
                  >
                    <IconButton
                    classes={{
                      root: classes.IconBackground
                    }}
                    >
                      <GetAppIcon/>
                    </IconButton>
                  </Link>
                </Box>
              </Box>
              
              {index !== isEndOfIndex ?
                <Box key={index+"arrows"} className={classes.OrderingArrowBox}>
                  <IconButton 
                    onClick={() => {
                      swapPictures(value, index, index+1) // right move in array, from index, to index+1
                      setRefresh(refresh => !refresh)
                    }}
                  >
                    <ArrowRightIcon fontSize="small"/>
                  </IconButton>
                  <IconButton 
                    onClick={() => {
                      swapPictures(value, index+1, index) // left move in array, from index+1, to index
                      setRefresh(refresh => !refresh)
                    }}
                  >
                    <ArrowLeftIcon fontSize="small"/>
                  </IconButton>
                </Box> : null
              }
            </Grid>
          </>
        ))}

        <Grid item md={2} sm={2} xs={12}>
          <Dropzone
            isMulti={true}
            onChange={t => 
              handleChangeDropzone(-1,t)}
            fileType={fileType}
          />
          <IconButton
            disabled
            className={classes.AddIconButton}
          >
            <AddCircleIcon fontSize="large"/>
          </IconButton>
        </Grid>
      </Grid>
    </>
  )
}

export default MultiDropzone