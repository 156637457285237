import React, { useState, useEffect } from 'react'
import RouterLink from '../../routes/RouterLink'
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import TermsConditionsModal from './TermsAndConditions/TermsConditionsModal'
import { VSelectInput, VCheckBox, VTextField, VTextFieldMaskedInput, VPhoneNumber } from '../Widget/CustomVInputs'
import { useForm, FormContext } from 'react-hook-form';
import Axios from 'axios';
import { authenticated } from '../../js/actions/auth';
import { useDispatch } from 'react-redux';
import { NError } from '../Widget/NativeStyleds';
import {phoneRegex, emailRegex, msgEmailInvalid, msgEmailNeeded, msgPasswordInvalid, msgMobileNumber, msgPasswordUnmatching, msgEmailUnmatching, passwordRegex} from '../../js/constants/miscellaneous_cst'
import {NBottomActiveButton, NDataLoading} from '../Widget/NAdminCustomComponents';
import * as cst from "../../js/constants/miscellaneous_cst"
import { computeCountriesArray } from '../../js/utils/computedPackages'
import { useSelector } from "react-redux";

const localStyle = makeStylesGlobal(theme => ({

}));

export function MobileNumberValidation (value) {
    if (value.match(phoneRegex)) {return true}
    else { return false}
}

const RegistrationForm = ({isAdmin, onClose}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            checkedTerms: false,
        }
    })
    const { watch, handleSubmit, triggerValidation } = methods;

    const token = useSelector(state => state.auth.token);


    const classes = localStyle();
    const dispatch = useDispatch();

    const [submitError, setSubmitError] = useState(null);
    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [countriesList, setCountriesList] = useState([])
    const [isFetching, setIsFetching] = useState(false);

    function TermsCaller() {
        return (<TermsConditionsModal/>)
    }

    useEffect(() => {
        setCountriesList(computeCountriesArray())
        setIsFetching(true)
    }, []);

    const onSubmit = data => {
        var url = `/user/register`
        Axios.post(url, data) 
            .then(response => {
                if (response.data.IsLogged === true) {
                    dispatch(authenticated(
                        response.data.IsValidated,
                        response.data.Token,
                        response.data.Role
                    ));
                    
                    setRedirectToLogin(true)
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    setSubmitError(error.response.data.message);
                } else {
                    setSubmitError("Impossible to register a new user at this time, please try again later.")
                }
            })
    }

    const onAdminSubmit = data => {
        var url = `/user/register`
        var config = {
            headers: { Authorization: "Bearer " + token }
        };
        Axios.post(url, data, config) 
        .then(onClose)
        .catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
                setSubmitError(error.response.data.message);
            } else {
                setSubmitError("Impossible to register a new user at this time, please try again later.")
            }
        })
    }

    if (redirectToLogin === true) {
        return <Redirect component={RouterLink} to="/login" />
    }
    

    if (!isFetching) {
        return <NDataLoading/>
    }

    function adminEmailValidation (value) {
        if (value === undefined) {return true} 
        else if (value === "") {return true} 
        else if (value && value.match(emailRegex)) {return true} 
        else {return msgEmailInvalid}
    }
    function adminEmailConfirm (value) {
        if (watch('EMail')) { 
            return (value === watch('EMail')) ? true : msgEmailUnmatching
        } 
    }
    function adminPasswordValidation (value) {
        if (value === undefined) {return true} 
        else if (value === "") {return true} 
        else if (value && value.match(passwordRegex) && watch('EMail')) {return true} 
        else if (!watch('EMail')) { return msgEmailNeeded}
        else {return msgPasswordInvalid}
    }
    function adminPasswordConfirm (value) {
        if (watch('Password')) {
            return (value === watch('Password')) ? true : msgPasswordInvalid
        } 
    }
    function adminMobileNumberValidation (value) {
        if (value === undefined) {return true} 
        else if (value === "+") {return true}
        else if (value && value.match(phoneRegex)) {return true}
        else {return false}
    }


    return (
        <FormContext {...methods}>
            <Grid 
                container={isAdmin ? false : true}
                direction={'row'} justify="center"
            >
                <Paper className={classes.NVisiblePaper}>

                    <form 
                        onSubmit={isAdmin ? handleSubmit(onAdminSubmit) : handleSubmit(onSubmit)}
                    >

                        {isAdmin ? null : <Grid container>
                            <Grid item xs={8}>
                                <Box className={classes.LeftEndAlign}>
                                        <Typography>Already have an account ?</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box className={classes.RightEndAlign}>
                                    <Button variant="outlined" component={RouterLink} to="/login">
                                        Sign in
                                        </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className={classes.LeftEndAlign} bgcolor="text.hint">
                                    <Typography>Become a Native member and start bidding.</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className={classes.LeftEndAlign}>
                                        <Typography>Create your account</Typography>
                                </Box>
                            </Grid>
                        </Grid>}

                        <Grid container spacing={3}>
                            <Grid item sm={3} xs={4} style={{ height: '1px' }}>
                                <VSelectInput
                                    label="Title"
                                    fullWidth={true}
                                    fieldName="Title"
                                    validation={{ required: true }}
                                    allvalues={cst.titleCategory}
                                />
                            </Grid>
                            <Grid item sm={4} xs={8}>
                                <VTextField
                                    label="First Name"
                                    fieldName="FirstName"
                                    validation={{ required: true }}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item sm={5} xs={12}>
                                <VTextField
                                    label="Last Name"
                                    fieldName="LastName"
                                    validation={{ required: true }}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} xs={12}>
                                <VTextField
                                    label="Company"
                                    fieldName="Company"
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <VTextField
                                    label="Email"
                                    fieldName="EMail"
                                    validation={!isAdmin ? {
                                        required: true, 
                                        pattern: {
                                            value: emailRegex,
                                            message: msgEmailInvalid
                                        }
                                    } : {
                                        required: false, 
                                        validate: (value) => adminEmailValidation(value)
                                    }}
                                    onBlur={async () => {
                                        triggerValidation("EMailConfirm");
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <VTextField
                                    label="Confirm E-mail"
                                    fieldName="EMailConfirm"
                                    validation={!isAdmin ? {
                                        required: true, 
                                        validate: (value) => { return value === watch('EMail') ? true : msgEmailUnmatching; },
                                        pattern: {}
                                    } : {
                                        required: false,
                                        pattern: {},
                                        validate: (value) => adminEmailConfirm(value)
                                    }}
                                    onBlur={async () => {
                                        triggerValidation("EMail");
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <VTextField
                                    label="Password"
                                    fieldName="Password"
                                    type="password"
                                    validation={!isAdmin ? {
                                        required: msgPasswordInvalid, 
                                        pattern: {
                                            value: passwordRegex,
                                            message: msgPasswordInvalid
                                        }
                                    }
                                        :
                                    {
                                        required: false,
                                        validate: (value) => adminPasswordValidation(value)
                                    }}
                                    onBlur={async () => {
                                        triggerValidation("ConfirmPassword");
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <VTextField
                                    label="Confirm Password"
                                    fieldName="ConfirmPassword"
                                    type="password"
                                    validation={!isAdmin ? {
                                        required: true, 
                                        validate: (value) => { return value === watch('Password') ? true : msgPasswordUnmatching; },
                                        pattern: {}
                                    }
                                        : 
                                    {
                                        required: false,
                                        validate: (value) => { return adminPasswordConfirm(value)} 
                                    }}
                                    onBlur={async () => {
                                        triggerValidation("Password");
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                {isAdmin ? 
                                    (<VTextFieldMaskedInput
                                        label="Mobile Number"
                                        fieldName="GSM"
                                        validation={{
                                            required: false,
                                            validate: (value) => { return adminMobileNumberValidation(value)}
                                        }}
                                        fullWidth={true}
                                        helperText={msgMobileNumber}
                                        placeholder={"e.g. +32498765432"}
                                        formatChars={cst.phoneNumberChars}
                                        mask={cst.phoneNumberMask}
                                        emptyMaskChar={""}
                                    />)
                                    :
                                    (<VPhoneNumber
                                        label="Mobile Number"
                                        fieldName="GSM"
                                        validation={{
                                            required: true,
                                            validate: (value) => {return MobileNumberValidation(value)},
                                        }}
                                        helperText={msgMobileNumber}
                                    />)
                                }
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <VTextFieldMaskedInput
                                    label="VAT Number"
                                    fieldName="VATNumber"
                                    validation={{
                                        required: false
                                    }}
                                    fullWidth={true}
                                    placeholder={"e.g. BE123456789"}
                                    formatChars={cst.VATnumberChars}
                                    mask={cst.VATnumberMask}
                                    emptyMaskChar={""}
                                />
                            </Grid>
                        </Grid>
                        

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <VTextField
                                    label="Address"
                                    fieldName="AddressLine1"
                                    validation={!isAdmin && {
                                        required: true, 
                                        pattern: {}
                                    }}
                                    fullWidth
                                    multiline
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VTextField
                                    label="Address complement"
                                    fieldName="AddressLine2"
                                    validation={!isAdmin && {
                                        required: false, 
                                        pattern: {}
                                    }}
                                    fullWidth
                                    multiline
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} >
                            <Grid item sm={3} xs={4}>
                                <VTextField
                                    label="Postal Code"
                                    fieldName="PostCode"
                                    validation={!isAdmin && { 
                                        required: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item sm={4} xs={8}>
                                <VTextField
                                    label="City"
                                    fieldName="City"
                                    validation={!isAdmin && { 
                                        required: true
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item sm={5} xs={12}>
                                <VSelectInput
                                    label="Country"
                                    fullWidth={true}
                                    fieldName="Country"
                                    validation={!isAdmin && { required: true }} 
                                    allvalues={countriesList}
                                />
                            </Grid>
                        </Grid>

                        {isAdmin ? null : <Grid container direction="row" spacing={3}> 
                            <Grid item xs={12} style={{ padding: 10 }}>
                                <VCheckBox
                                    fieldName="checkedTerms"
                                    validation={{ 
                                        required: 'To proceed, terms and conditions should be accepted',
                                    }}
                                    label={<TermsCaller/>} 
                                />
                            </Grid>
                        </Grid>}

                        <Grid item xs={12}>
                            {submitError ? <NError >{submitError}</NError> : null}
                        </Grid>

                        {isAdmin ?
                            <Grid item xs={12}>
                                <Box className={classes.RightEndAlign} >
                                    <NBottomActiveButton
                                        type="submit"
                                    >
                                        Create user
                                    </NBottomActiveButton>
                                </Box>
                            </Grid>

                            :

                            <Grid item xs={12}>
                                <Box className={classes.RightEndAlign} >
                                    <NBottomActiveButton
                                        variant="outlined"
                                        type="submit"
                                    >
                                        Create account
                                    </NBottomActiveButton>
                                </Box>
                            </Grid>
                        }
                    </form>
                </Paper>
            </Grid>
        </FormContext>
    )
    
}

export default RegistrationForm;