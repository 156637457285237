import React from "react";
import { AppBar, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NativeGeneric } from "../Widget/NativeStyleds";
import AccountMenu from "../Account/AccountMenu"
import { WarningTestWebsite } from "../Widget/Alert"
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  AppBar: {
    background: "#FFFA",
    padding: "8px 10px 3px 10px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.ColorC1,
    borderBottomWidth: "1px",
    color: theme.ColorC2,
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)"
  },
  Logo: {
    color: theme.ColorGold,
    fontFamily: theme.PrimaryFont,
    fontSize: "30px",
    lineHeight: "36px",
    marginRight: "30px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "26px",
    },
  },
  Mojo: {
    textTransform: "uppercase",
    fontSize: "18px",
    lineHeight: "36px",
    [theme.breakpoints.down('sm')]: {
      display:"none"
    },
  },
  Spacer: {
    flexGrow:1
  }
}));

export default function StoreAppBar() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar className={classes.AppBar} elevation={0}>
      <Grid item container alignItems="center">
        <Grid item className={classes.Logo} >
            <NativeGeneric  onClick={(s) =>history.push("upcoming")}/>
        </Grid>
        <Grid item className={classes.Mojo}>
          Art is where we belong
        </Grid>
        <WarningTestWebsite currentURL={window.location.href} />
        <Grid item className={classes.Spacer}/>
        <AccountMenu/>
      </Grid>
    </AppBar>
  );
}
