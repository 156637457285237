import React, { Fragment, useState } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { titleColor, formatDate } from "../../pdf_cst";
import { convertHtmlToString } from "../../../../js/utils/formatter"

const styles = StyleSheet.create({

    descriptionContainer : {
        textAlign: "right",
        marginTop: -110
    },

    informations: {
        marginTop: 8,
        marginBottom: 8,
        float: 'left',
        paddingLeft: 260
    }
})

const ListInformations = ({informations}) => {

    let htmlBlock = informations;
    
    return ( // dangerouslySetInnerHTML won't work in react-pdf since it requires to render a <div/> ... 
        <Fragment>
            <View style={styles.descriptionContainer}>
                <Text>
                    Brussels, {formatDate(new Date())}
                </Text>
                <Text style={styles.informations}>
                    {convertHtmlToString(htmlBlock)}
                </Text>
            </View>
        </Fragment>
    );
}

export default ListInformations 
