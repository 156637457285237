import * as actionTypes from "../constants/action_types";

const initialState = {
    isAuthenticated : false, 
    isValidated : false,
    since : new Date(),
    errorMessage : "",
    token: "",
    role: "",
    originalPath: "/"
};

export default function(state=initialState, action) {
    switch(action.type) {
      case actionTypes.RESETERROR: 
        return {
          ...state,
          errorMessage: ""
        };
      case actionTypes.AUTHENTICATED:
        return { 
          ...state, 
          isAuthenticated: true, 
          errorMessage: "", 
          isValidated: action.payload.validated, 
          since: new Date(),
          token: action.payload.token, 
          role : action.payload.role, 
          displayName : action.payload.displayName,
          bidderId : action.payload.bidderId,
          userId : action.payload.userId
        };
      case actionTypes.UNAUTHENTICATED:
        return { 
          ...state, 
          isAuthenticated: false, 
          errorMessage: "", 
          isValidated: false, 
          token: "",
          role: "",
          displayName: "",
          bidderId: null,
          userId: null
        };
      case actionTypes.AUTHENTICATION_ERROR:
        return { 
          ...state, 
          errorMessage: action.payload
        };
        case actionTypes.ENTERED:
          return {
            ...state,
            originalPath: action.payload
          }
      default:
        return state;
    }
  }