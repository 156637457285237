import React from "react";
import { useDashboardState } from "../../../../js/actions/dashboard";
import { Typography, Paper, Box } from "@material-ui/core";
import { makeStylesGlobal } from "../../../../themes/GlobalTheme";
import { formatCurrency } from "../../../../js/utils/formatter"
import { APPCURRENCY } from '../../../../js/constants/miscellaneous_cst';


const localStyle = makeStylesGlobal((theme) => ({
    WidgetContainer: {
        height: theme.NDashBoardChartHeight
    },
    ValueDisplay: {
        fontSize: theme.FontSizeDashboardValue,
        textAlign: 'center'
    },
    MidValueDisplay: {
        fontSize: "30px",
        textAlign: 'center'
    },
    CatDisplay: {
        paddingTop: "15px",
        fontSize: "15px",
        textAlign: 'center'
    },
}));

export default function AuctionProgressWidget() {
    const classes = localStyle();
    const totalAuctionValue = useDashboardState("totalvalue", 0);
    return (
        <Paper className={classes.NPaperWidget}>
            <Box className={classes.WidgetContainer}>

                <Typography variant="h6">Progress</Typography>
                <Typography className={classes.CatDisplay}>
                    Auction
                </Typography>
                <Typography className={classes.MidValueDisplay}>
                    {totalAuctionValue.Auction}
                </Typography>
                <Typography className={classes.CatDisplay}>
                    Total
                </Typography>
                <Typography className={classes.ValueDisplay}>
                    {formatCurrency(totalAuctionValue.Value, APPCURRENCY)}
                </Typography>
                <Typography className={classes.CatDisplay}>
                    Will sell
                </Typography>
                <Typography className={classes.ValueDisplay}>
                    {totalAuctionValue.WillSell} / {totalAuctionValue.Items}
                </Typography>
            </Box>
        </Paper>
    )
}