import React, {useState} from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux"; 
import { editModel, addNewModel} from "../../js/utils/backend";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { VTextFieldMaskedInput,VTextField, VPhoneNumber, VCheckBox } from '../Widget/CustomVInputs'
import { useForm, FormContext } from 'react-hook-form';
import * as cst from '../../js/constants/miscellaneous_cst';
import { PHONEREQUESTURL } from "../../js/constants/model_cst";
import { VUserChooser } from '../Widget/CustomVInputs';
import { phoneRegex } from '../../js/constants/miscellaneous_cst';

import {NBottomActiveButton, NBottomCancelButton} from '../Widget/NAdminCustomComponents';

function MobileNumberValidation (value) {
    if (!value || value.match(phoneRegex)) {return true}
    else { return false}
}

//We validate a string composed of numbers separated by commas, with no space, could end with a comma, could be empty
const commaSeparatedListWithNoSpace = /^([0-9]+,)*[0-9]*$/;

function LotListValidation(value) {
    console.log("Validation: " + value)
    if (value.match(commaSeparatedListWithNoSpace)) {
        return true;
    } else {
        return false;
    }
}

const PhoneRequestEditPanel = ({Data, onClose, isNew}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange", 
        defaultValues: Data
    });

    const {
        handleSubmit
    } = methods;

    const localStyle = makeStylesGlobal(theme => ({}));
    const classes = localStyle();

    const token = useSelector(state => state.auth.token);

    const submitUpdate = data => { 
        const newData = {...Data, ...data, OriginalUser: null}; 
        if(isNew)
        {
            addNewModel(newData, token, PHONEREQUESTURL)
            .then(onClose)
        }
        else
        {
            editModel(newData, Data.ID, token, PHONEREQUESTURL)
            .then(onClose)
        }
    }

    const userName = "OriginalUser";
    const [user] = useState(Data[userName]);
    
    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)}>
                <Grid container> 
                    {isNew ?
                        <Grid item xs={12}>
                            <VUserChooser
                                fieldName={"UserID"}
                                validation={{ required: true }}
                                label={"Client"}
                                value={user}
                                className={classes.userChooser}
                                showRefresh={false}
                            />
                        </Grid>
                        :
                        <><Grid item xs={12} sm={6}>
                            <VTextField
                                fieldName={"OriginalUser.GSM"}
                                label={"Official number from profile"}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth={true}
                            />
                        </Grid>
                            <Grid item xs={12} sm={6}>
                                <VTextFieldMaskedInput
                                    fieldName={"GSM"}
                                    label={"Preferred phone number"}
                                    validation={{
                                        required: false,
                                        validate: (value) => { return MobileNumberValidation(value) },
                                    }}
                                    formatChars={{ '9': '[0-9]', 'p': '[+0]' }}
                                    mask={"p9999999999999999999"}
                                    emptyMaskChar={""}
                                    className={classes.userChooser}
                                    helperText={"This is a secondary number for the call request."}
                                />
                            </Grid></>}
                    <Grid item container xs={12}>
                            <Grid item xs={4}>
                            <VCheckBox
                                fieldName={"FR"}
                                label={"FR"}
                             /></Grid>
                            <Grid item xs={4}>
                            <VCheckBox
                                fieldName={"EN"}
                                label={"EN"}
                            /></Grid>
                        <Grid item xs={4}>
                        <VCheckBox
                                fieldName={"NL"}
                                label={"NL"}
                            /></Grid>
                        </Grid>
                    <Grid item xs={12}>
                            <VTextFieldMaskedInput
                                fieldName={"LotsNumbers"}
                                label={"Lots"}
                            fullWidth={true}
                            placeholder={"e.g. 1,26,32"}
                            validation={{
                                required: true,
                                validate: (value) => { return LotListValidation(value) },
                            }}
                            formatChars={{ '9': '[0-9,]' }}
                            mask={"999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999"}
                            emptyMaskChar={""}
                        />
                        </Grid>
                    <Grid item sm={12} xs={12}>
                                <Box className={classes.RightEndAlign}>
                                    <NBottomCancelButton 
                                        variant="outlined" 
                                        onClick={onClose}
                                    >
                                    Cancel
                                    </NBottomCancelButton>
                                    <NBottomActiveButton 
                                        type="submit"
                                    >
                                    {isNew ? "Add": "Update"}
                                    </NBottomActiveButton>
                                </Box>
                            </Grid>
                </Grid>
            </form>
        </FormContext>
    ); 
} 

export default PhoneRequestEditPanel;