import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { useForm, FormContext } from 'react-hook-form';
import Axios from 'axios';
import { authenticated } from '../../js/actions/auth';
import { useDispatch } from 'react-redux';
import { NError } from '../Widget/NativeStyleds';
import { useSelector } from "react-redux";
import * as cst from '../../js/constants/miscellaneous_cst'

import { NBottomActiveButton, NDataLoading } from '../Widget/NAdminCustomComponents';
import { Typography } from '@material-ui/core';

import { VCheckBox } from '../Widget/CustomVInputs';

const MailingPreferences = ({ history }) => {

    const [originalData, setOriginalData] = useState({});

    const localStyle = makeStylesGlobal(theme => ({
    }));

    const classes = localStyle();

    const token = useSelector(state => state.auth.token);

    const [isFetching, setIsFetching] = useState(true);
    const [submitError, setSubmitError] = useState(null);
    const [hasChanged, setHasChanged] = useState(false);
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            "MailingCatalog": false, 
            "MailingList": false
        }
    })

    const { reset, handleSubmit} = methods;

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = () => {
            let url = cst.mailing;
            var config = {
                headers: { Authorization: "Bearer " + token }
            };
            Axios.get(url, config)
                .then(function (response) {
                    setOriginalData(response.data);
                    let mailingCatalog = response.data.MailingCatalog;
                    let mailingList = response.data.MailingList;
                    let temp1 = ( mailingCatalog === "OptedIn" || mailingCatalog === "Yes" );
                    let temp2 = ( mailingList === "OptedIn" || mailingList === "Yes" );
                    reset({MailingCatalog: temp1,MailingList: temp2});
                    setIsFetching(false);
                })

                .catch(error => {
                    if (error.response && error.response.data && error.response.data.message) {
                        setSubmitError(error.response.data.message);
                    } else {
                        setSubmitError("Impossible to fetch your profile account, please try again later.")
                    }
                })
        }
        fetchData();
    }, [token, reset]);

    const submitUpdate = data => {
        setHasChanged(false);
        data['MailingCatalog'] = data['MailingCatalog'] ? "OptedIn" : "OptedOut";
        data['MailingList'] = data['MailingList'] ? "OptedIn" : "OptedOut";
        const updatedMailPreferences = {...originalData, ...data };
        let config = {
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'

            }
        };
        let url = cst.mailing;

        Axios.put(url, updatedMailPreferences, config)
            .then(response => {
                if (response.data.IsLogged === true) {
                    dispatch(authenticated(
                        response.data.IsValidated,
                        response.data.Token,
                        response.data.Role
                    ));
                }
                //history.goBack();
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    setSubmitError(error.response.data.message);
                } else {
                    setSubmitError("Impossible to update your mail preferences, please try again later.")
                }
            })
    }

    if (isFetching) {
        return <NDataLoading/>
    }

    return (
        <FormContext {...methods}>
            <Grid container direction={'row'} justify="center">
                <Paper className={classes.NVisiblePaper}>
                    <form
                        onSubmit={handleSubmit(submitUpdate)}
                        onChange = {() => {setHasChanged(true)}}
                    >
                        <Grid container spacing={3}
                        >
                            <Grid item xs={12}>
                                <Typography>From time to time, Native will send informations to its members regarding its activities or future auctions. You can manage your notification preferences here.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <VCheckBox
                                    fieldName="MailingCatalog"
                                    label="Yes, I would like to receive the Catalog for future auctions."

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VCheckBox
                                    fieldName="MailingList"
                                    label="Yes, I would like to receive mailings from Native Auctions about future sales and events."
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {submitError ? <NError >{submitError}</NError> : null}
                        </Grid>
                        <Grid item xs={12}>
                            {hasChanged ?
                            <Box className={classes.RightEndAlign}>
                                <NBottomActiveButton
                                    type="submit"
                                >
                                    Update
                                    </NBottomActiveButton>
                            </Box>
                            :
                            <></>
                            }
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </FormContext>
    )
}

export default MailingPreferences;