import React, { useEffect, useState } from "react";

import Axios from "axios";
import { Grid, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  ToolbarPlaceHolder,
  ContentPlaceholder,
  FixedWidthStoreContainer,
} from "../../layouts/StoreLayout";
import MasonryListing from "../../components/Widget/MasonryListing";
import { makeStyles } from "@material-ui/core/styles";
import { itemsUpdated, serverTimed } from "../../js/actions/items";

import ProductCell from "../Widget/ProductCell"
import { Redirect } from "react-router-dom";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  BlockSubHeadline: {
    color: theme.ColorC2,
    textTransform: "uppercase",
    fontFamily: theme.SecondaryFont,
    fontSize: "110%",
    paddingBottom: "6px",
    borderBottom: "solid 1px",
    borderBottomColor: theme.ColorC1 + "22",
  },
  Headline: {
    fontSize: "200%",
    fontFamily: theme.PrimaryFont,
    color: theme.ColorC1,
    textTransform: "uppercase",
    paddingTop: "10px",
  },
  Section: {
    fontSize: "160%",
    color: theme.ColorC2,
    fontFamily: theme.PrimaryFont,
    fontStyle: "normal",
  },
  Intro: {
    marginTop: "20px",
    marginBottom: "20px",
    borderLeft: "solid 1px",
    borderColor: theme.ColorC1 + "22",
    color: theme.ColorC2,
    paddingLeft: theme.spacing(2),
  },
  Footer: {
    paddingBottom: "10px",
    borderTop: "solid 1px",
    borderTopColor: theme.ColorC2 + "22",
  },
  HtmlContent: {
    marginTop: "-20px",
  },
  BlockFooter: {
    color: theme.ColorC1,
  },
  MoreLabelFooter: {
    textTransform: "uppercase",
    lineHeight: "12px",
    fontSize: "14px",
    cursor: "pointer",
    textAlign: "left",
  },
}));

export default function Auction({ match }) {
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const auctionId = match.params.auctionId;
  const serverTimeDelta = useSelector((state) => state.itemCache.serverTimeDelta);
  const serverTime = () => {
    var t = new Date();
    return t.setSeconds(t.getSeconds() + serverTimeDelta);
  }

  const [showMore, setShowMore] = useState(false);
  const handleChange = () => {
    setShowMore((prev) => !prev);
  };

  

  const [currentTime, setCurrentTime] = useState(serverTime());

  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setCurrentTime(serverTime());
    }, 1000);
  });

  useEffect(() => {
    let url = `/view/auction/${auctionId}`;
    var config = {
      headers: { Authorization: "Bearer " + token },
    };
    Axios.get(url,config)
      .then(function (response) {
        const data = response.data;
        dispatch(itemsUpdated(data.Updates, false));
        const serverTime = data.ServerTime.endsWith("Z") ? new Date(data.ServerTime) : new Date(data.ServerTime + "Z");
        dispatch(serverTimed(serverTime, new Date()));
        setData(data);
      })
      .catch(function (error) { 
        setData(null)
        setShouldRedirect(true)
      });
  }, [auctionId, dispatch, token]);

  if (!data) {
    if (shouldRedirect) {
      return <Redirect to={"/upcoming"}/>
    }
    return <ContentPlaceholder />
  }



  return (
    <>
      <Helmet>
          <title>{data.Title}</title>
     </Helmet>

      <FixedWidthStoreContainer>
        <ToolbarPlaceHolder />
        <Grid container>
          <Grid item xs={12} className={classes.Headline}>
            {data.Title}
          </Grid>
          <Grid item xs={12} className={classes.BlockSubHeadline}>
            {`${data.IsOnline ? "Online" : "Brussels Auction"} - ${data.Date}`}
          </Grid>

          {!showMore && (
            <>
              <Grid item xs={12} className={classes.Intro}>
                <Box className={classes.HtmlContent}>
                  <div
                    className="styleContainer"
                    dangerouslySetInnerHTML={{ __html: data.ShortDescription }}
                  />
                  <div className={classes.BlockFooter}>
                    <div
                      className={classes.MoreLabelFooter}
                      onClick={handleChange}
                    >
                      {showMore ? "Less" : "More"}
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid item container>
                  <Grid item xs={12}>
                    <MasonryListing
                      data={{ items: data.Products, separators: data.Separators }}
                      component={ProductCell}
                      cellWidth={300} // aspect ratio full vertical 0.58 : 1
                      cellHeight={500}
                      itemProps={{
                        currentTime: currentTime
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.Footer} />
                </Grid>
              </Grid>
            </>
          )}

          {showMore && (
            <>
              <Grid item xs={12} className={classes.Intro}>
                <Box className={classes.HtmlContent}>
                  <div
                    className="styleContainer"
                    dangerouslySetInnerHTML={{ __html: data.ShortDescription }}
                  />
                  <div
                    className="styleContainer"
                    dangerouslySetInnerHTML={{ __html: data.Informations }}
                  />
                  <div className={classes.BlockFooter}>
                    <div
                      className={classes.MoreLabelFooter}
                      onClick={handleChange}
                    >
                      {showMore ? "Less" : "More"}
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid item container>
                  <Grid item xs={12}>
                    <MasonryListing
                      data={{ items: data.Products, separators: data.Separators }}
                      component={ProductCell}
                      cellWidth={300}
                      cellHeight={600}
                      itemProps={{
                        currentTime: currentTime
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.Footer} />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </FixedWidthStoreContainer>
    </>
  );
}
