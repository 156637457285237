import React, {useRef, useState} from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { BIDSURL } from "../../js/constants/model_cst";
import { fetchData } from "../../js/utils/backend"; 
import { useSelector } from "react-redux";
import { Thumbnail, DateTimeHourRenderer, CurrencyRenderer } from "../Widget/TableEditors"
import { useReduxPrefsState } from "../../js/actions/prefs";
import ProductsIcon from '@material-ui/icons/ShoppingBasketOutlined';
import { useHistory } from "react-router-dom";
import {NAdminToolBar, NAdminActiveButton, NModal} from '../Widget/NAdminCustomComponents'
import RefreshIcon from '@material-ui/icons/Refresh';
import UserProfileIcon from '@material-ui/icons/AccountBoxOutlined';
import CatalogIcon from '@material-ui/icons/MenuBook';
import * as cst from "../../js/constants/miscellaneous_cst";
import UserModal from "../Modals/UserModal";
import ProductModal from "../Modals/ProductModal";
import {useHistoryState} from "../../js/utils/customHooks";



export default function AllBids() {
    const token = useSelector(state => state.auth.token);
    const tableRef = useRef();


  const history = useHistory();
  function refreshTable() { 
    if (tableRef && tableRef.current) {
        tableRef.current.onQueryChange();
    }
}
  const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);

  const [rowUserID, setRowUserId] = useState()
  const [rowProductID, setRowProductId ] = useState();
    const [searchText, setSearchText] = useHistoryState("searchText","");
    const [currentPage, setCurrentPage] = useHistoryState("currentPage",0);
    const [sortingColumn, setSortingColumn] = useHistoryState("sortingColumn","Time");
    const [sortingDir, setSortingDir] = useHistoryState("sortingDir","desc");
    const getSort = (column) => sortingColumn === column ? sortingDir : undefined;
    const [isInit,setIsInit] = useState(true);
  

  const [showUserModal, updateShowUserModal] = useState(false)

  const [showProductModal, updateShowProductModal] = useState(false)

 

  return (
    <MaterialTable
      tableRef={tableRef}
      title = "Bids"
      onChangeRowsPerPage={(nbRows) => setRows(nbRows)}
      columns={[
        {
          title: "Time",  
          field: "Time",
          defaultSort: getSort("Time"),
          cellStyle: {width: '150px'},
          render: rowData => DateTimeHourRenderer(150, rowData.Time)
        },
        {
          title: "Image",
          field: "ImageID",
          sorting: false,
          cellStyle: { width: '100px', padding: 0 },
          render: rowData => Thumbnail(50, rowData.ImageID)
        },
        {
          title: "Auction",  
          field: "AuctionTitle",
          sorting: false
        },
        {
          title: "Product",
          field: "Title",
          sorting: false,
        },
        {
          title: "Bidder",
          field: "BidderName",
          sorting: false,
          render: rowData => rowData.BidderName + " (" + rowData.BidderRef + ")"
        },
        {
            title: "Bid",
            field: "Value",
            sorting: false,
            cellStyle: { width:'90px', textAlign: "right" },
            headerStyle: { textAlign: "right"},
            render: ({ Value }) => <>{CurrencyRenderer(Value)}</>
        },
        {
            title: "Maximum Bid",
            sorting: false,
            field: "MaxValue",
            headerStyle: { textAlign: "right"},
            cellStyle: {width:'140px', textAlign: "right" },
            render: ({ MaxValue }) => <>{CurrencyRenderer(MaxValue)}</>
        },
      ]}
      data={query => {
          const effectivePage = isInit ? currentPage : query.page;
          setSearchText(query.search,true);
          setSortingColumn(query.orderBy && query.orderBy.field);
          setSortingDir(query.orderDirection);
          setCurrentPage(effectivePage);
          setIsInit(false)
         return fetchData(token, {...query,page: effectivePage}, BIDSURL)
      }}
      options={{
        draggable: false,
        pageSize: rows,
        pageSizeOptions: cst.itemsPerPage,
        emptyRowsWhenPaging:false,
        actionsColumnIndex: 50,
          searchText:searchText
      }}
      actions={[
        {
          icon:  () =>  <><ProductsIcon/></>,
          onClick: (event, rowData) => {
            setRowProductId(rowData.ProductID)
            updateShowProductModal(true)
          },
          tooltip: 'See object profile'
        },
        {
          icon: () => <><UserProfileIcon/></>,
          onClick: (event, rowData) => { 
            setRowUserId(rowData.UserID)
            updateShowUserModal(true)
          },
          tooltip: 'See bidder profile'
        },
        {
          icon: () => <><CatalogIcon/></>,
          onClick: (event, rowData) => {history.push(rowData.URL)},
          tooltip: 'See object page'
        }
      ]}
      components={{
        Toolbar: props => (
          <div>
            <div>
            <MTableToolbar {...props} />
            </div>
            <div >
              <NAdminToolBar>

                <NAdminActiveButton
                  startIcon={<RefreshIcon />}
                  onClick={() => refreshTable()}>
                  Refresh
                </NAdminActiveButton>

                <UserModal userId={rowUserID} isShown={showUserModal} onClose= {() => {updateShowUserModal(false);refreshTable();}}/>
                <ProductModal productId={rowProductID} isShown={showProductModal} onClose= {() => {updateShowProductModal(false);refreshTable();}}/>
                
              </NAdminToolBar>
            </div>
          </div>
        )
      }}
    />
  );
}