import * as actionTypes from "../constants/action_types";

const initialState = {
    maintab: null,
    maintick: new Date(),
    secondarytick: new Date(),
};

export default function(state=initialState, action) { 
    switch(action.type) {
      case actionTypes.TABALIVE:
        if(action.payload === state.maintab)
        {
          return { 
            ...state, 
            maintick: new Date()
          };
        }
        else
        {
          const delayMain = (new Date(state.secondarytick).getTime() - new Date(state.maintick).getTime()) / 1000;
          if(delayMain > 7)
          {
            return { 
              ...state,
              maintick: new Date(),
              maintab: action.payload
            };
          }
          
          return { 
            ...state, 
            secondarytick: new Date()
          };
        }
      default:
        return state;
    }
  }