import React, { useRef, useState } from "react";
import MaterialTable, { MTableToolbar, MTableBodyRow, MTableEditRow } from "material-table";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import {NAdminToolBar, NAdminActiveButton, NAdminToolbarCancelButton} from '../Widget/NAdminCustomComponents'

import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { useSelector } from "react-redux";

import UpIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import DownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import ReorderIcon from '@material-ui/icons/UnfoldMore';
import AddIcon from "@material-ui/icons/AddCircleOutline";

import { fetchData, changeOrder, deleteModel } from "../../js/utils/backend";
import { MOVEUP, MOVEDOWN, FAQURL } from "../../js/constants/model_cst";
import {StoreRenderer} from "../Widget/TableEditors";
import FaqEditPanel from '../EditPanels/FaqEditPanel';
import { defaultPageSize, itemsPerPage } from "../../js/constants/miscellaneous_cst";
import { TablePagination } from "@material-ui/core";
import { useReduxPrefsState } from "../../js/actions/prefs";
import { scrollTopFunction } from "../../js/constants/functions_cst";

const localStyle = makeStylesGlobal(theme => ({
}));

export default function Faq() {

  const classes = localStyle();

  const token = useSelector(state => state.auth.token);

  const tableRef = useRef();
  const [rows, setRows] = useReduxPrefsState("nbRows", defaultPageSize);

  const [configActionOrder, setConfigActionOrder] = useState()
  const [configActionDelete, setConfigActionDelete] = useState(true)
  const [displayOrderColumn, setDisplayOrderColumn] = useState(false)
  const [displayEditPanel, setDisplayEditPanel] = useState(true)
  const [currentAddIndex, setCurrentAddIndex] = useState(1);
  const [isTableEditing, setIsTableEditing] = useState(false);
  const [operation, setOperation] = useState("none"); 

  function refreshTable() {
    if (tableRef && tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }

  const operationChange = newOperation =>
  {
    switch(newOperation)
    {
      case "none":
        setConfigActionOrder(false)
        setConfigActionDelete(true)
        setDisplayOrderColumn(false)
        setDisplayEditPanel(true)
        break;
      case "reorder":
        setConfigActionOrder(true)
        setConfigActionDelete(false)
        setDisplayOrderColumn(true)
        setDisplayEditPanel(false)
        tableRef.current.onSearchChange('')
        break;
      case "new":
        setConfigActionOrder(false)
        setConfigActionDelete(false)
        setDisplayOrderColumn(false)
        break;
      default: return null;
    }
    setOperation(newOperation);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialTable
          detailPanel={
            displayEditPanel ? rowData => {
            return (
              <div >
                <FaqEditPanel
                  Data={rowData}
                  onClose={refreshTable}
                  isNew={false}
                />
              </div>
            );
          }: null} 
          tableRef={tableRef}
          columns={[
            { 
              title: "Order",
              field: "Order",
              hidden: !displayOrderColumn
            },
            {
              title: "Title",
              field: "Title",
              sorting: false, 
              cellStyle: { width: "60%" }
            },
            {
              title: "Store",
              field: "Store",
              sorting: false,
              cellStyle: { width: '30%'},
              render: StoreRenderer
            }
          ]}
          data={query => 
            {
              setCurrentAddIndex(query.page * query.pageSize + 1); 
              return fetchData(token, {...query, orderBy: { field: "Order" }, orderDirection: "asc"}, FAQURL); 
            }
          }
          options={{
            draggable: false,
            detailPanelType: 'single',
            pageSize: rows,
            pageSizeOptions: itemsPerPage,
            actionsColumnIndex: 50,
            addRowPosition: "first",
            emptyRowsWhenPaging:false
          }}
          onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
          editable={{
            onRowDelete: configActionDelete ? oldData => deleteModel(oldData.ID, token, FAQURL) : null
          }}
          actions={[
            configActionOrder ? rowData => ({
              icon: () => <UpIcon />,
              tooltip: "Move up",
              hidden: rowData.isFirst,
              onClick: (event, rowData) => {
                changeOrder(FAQURL, token, rowData.ID, MOVEUP).then(() => {
                  tableRef.current.onQueryChange();
                });
              }
            }) : null,
            configActionOrder ? rowData => ({
              icon: () => <DownIcon />,
              tooltip: "Move down",
              hidden: rowData.isLast,
              onClick: (event, rowData) => {
                changeOrder(FAQURL, token, rowData.ID, MOVEDOWN).then(() => {
                  tableRef.current.onQueryChange();
                });
              }
            }) : null 
          ]}
          title="FAQ"
          components={{
            Row: props => { setIsTableEditing(props.hasAnyEditingRow); return (<MTableBodyRow {...props} />)},
            EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />)},
            Pagination: props=>(<TablePagination {...props} className={isTableEditing || operation === "reorder" ?classes.disabled:null} />) ,
            Toolbar: props => (
              <div>
                <div className={isTableEditing || operation === "reorder" ?classes.disabled:null}>
                <MTableToolbar {...props} />
                </div>
                <div className={isTableEditing?classes.disabled:null}>
                  <NAdminToolBar className={isTableEditing?classes.disabled:null}>
                    {operation === "none" && 
                      <>
                        <NAdminActiveButton
                          startIcon={<AddIcon />}
                          onClick={() => operationChange("new")}
                        >
                          New
                        </NAdminActiveButton>
                        
                        <NAdminActiveButton
                          startIcon={<ReorderIcon/>}
                          onClick={() => {
                            operationChange("reorder")
                            
                          }}
                        >
                          Reorder
                        </NAdminActiveButton>
                      </> 
                    }

                    {operation === "reorder" && 
                      <>
                        <NAdminToolbarCancelButton
                          onClick={() => {
                            operationChange("none")
                          }}                          
                        >
                          Done
                        </NAdminToolbarCancelButton>
                      </>
                    }
                  

                    {operation === "new" && (
                      <Paper className={classes.NVisiblePaper}>
                        <FaqEditPanel 
                          Data={{Order: currentAddIndex, Store: 3, Content: ''}} 
                          onClose={() => {
                            operationChange("none");
                            refreshTable();
                            scrollTopFunction();
                          }} 
                          isNew={true} 
                        />
                      </Paper>
                    )}

                  </NAdminToolBar>
                  </div>
              </div>
            )
          }}
        />
      </Grid>
    </Grid>
  );
}
