import React from 'react'
import { Page, Document, StyleSheet, View, Text} from '@react-pdf/renderer'
import { tableColumnStyle } from '../pdf_cst';
import PhoneRequestTable from '../ContentLayout/ObjectsListing/PhoneRequestTable';

const styles = StyleSheet.create(tableColumnStyle);

export default function ObjectsResultPDF ({data}) {

    const styles = StyleSheet.create({...tableColumnStyle,
        row: {
            flexDirection: 'row',
            marginBottom: 32
        },
        TitleContact : {
            flexDirection: 'column'

        },
        email : {
            textAlign: "center"
        }

    })

    return (
        <Document title={"PhoneRequests.pdf"}>
            <Page 
                size="A4" 
                style={styles.page}
            >
                <View>
                    <PhoneRequestTable items={data} />
                </View>
                <Text
                    fixed
                    style={styles.BottomEmail}
                >
                    www.native-auctions.com
                </Text>
                <Text 
                    fixed
                    style={styles.PageNumber} 
                    render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} 
                />
            </Page>
        </Document>
    )
}