import "babel-polyfill";
import * as actionTypes from "../constants/action_types";


export const emptyObjectCache = () => ({
  type: actionTypes.EMPTYOBJECTCACHE,
});

export const itemsUpdated = (value,isLive) => ({
  type: actionTypes.ITEMSUPDATED,
  payload: value,
  live: isLive
});

export const itemPending = (value) => ({
  type: actionTypes.ITEMPENDING,
  payload: value
});

export const followItem = (value) => ({
  type: actionTypes.FOLLOWITEM,
  payload: value
});

export const unfollowItem = (value) => ({
  type: actionTypes.UNFOLLOWITEM,
  payload: value
});

export const resetFollows = (value) => ({
  type: actionTypes.RESETFOLLOWS,
  payload: value
});

export const serverTimed = (serverTime, myTime) => ({
  type: actionTypes.SERVERTIMED,
  payload: {serverTime, myTime}
});





