 import * as cst from "../constants/miscellaneous_cst"
 import {APPCURRENCY} from "../constants/miscellaneous_cst";

export function formatCurrency(num, currency = cst.APPCURRENCY, decimals = 0, space = "\u202F") {
    if (num === undefined || num === null) return null;

    const spaced = num
      .toFixed(decimals)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1\u00A0")
      .replace(".",",");
    return currency + spaced;
}
  
export function formatCurrencyInvoice(num, currency = cst.APPCURRENCY, decimals = 0, space = "\u202F") {
  if (num === undefined || num === null) return null;

  const spaced = num
    .toFixed(decimals)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1\u00A0")
    .replace(".",",");
  return spaced + space + currency;
}

 export const priceEstimation = rowData => {
     if ( !rowData.EstimatedHigh && !rowData.EstimatedLow ) {
         return ""
     } else if ( rowData.EstimatedLow == null ) {
         return `${APPCURRENCY}` + formatCurrency(rowData.EstimatedHigh, "")
     } else if ( rowData.EstimatedHigh == null ) {
         return `${APPCURRENCY}` + formatCurrency(rowData.EstimatedLow, "")
     }else if(rowData.EstimatedHigh === rowData.EstimatedLow){
         return `${APPCURRENCY}` + formatCurrency(rowData.EstimatedLow, "")
     } else  {
         return `${APPCURRENCY}` + formatCurrency(rowData.EstimatedLow, "") + " - " + formatCurrency(rowData.EstimatedHigh, "")
     }
 }

  export function truncateString(str, num) {
    if (str.length <= num) {
      return str
    } 
    return str.slice(0, num) + "..."
  }


export function convertHtmlToString (htmlBlock) {
    let temp = document.createElement("div");
    temp.innerHTML= htmlBlock
    return temp.innerText
}