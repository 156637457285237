import { Grid, Typography, Modal, Dialog} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";


export const reactSelectStyles = {
  container: base => ({
      ...base,
      zIndex: 999,
      flex: 1,
  }),
  control: (base, state) => ({
      ...base,
      borderRadius: 0,
      borderColor: state.isFocused ? "rgba(0, 0, 0, 0.23)" : "rgba(0, 0, 0, 0.23)",
      boxShadow: 'none',
      "&:hover": {
          borderColor: state.isFocused ? "rgba(0, 0, 0, 0.23)" : "rgba(0, 0, 0, 0.23)"
      },
      background: 'transparent'
  })
};


export const NFullGridCentered = styled(({ ...other }) => (
  <Grid container spacing={0} alignItems="center" justify="center" {...other} />
))({
  minHeight: "100vh"
});

export const NFullGridFree = styled(({ ...other }) => (
  <Grid container spacing={0} {...other} />
))({
  width: "100%"
});

export const CenteredModal = styled(({...other}) => (
  <Modal {...other} />
))({

});


export const NError = styled(Typography)({ 
  color:"#FFFFFF",  
  fontWeight: "normal",
  border: "1px solid",
  borderColor: "#FFFFFF",
  backgroundColor: "#7A0000",
  padding: "3px",
  textAlign: "center"
});

export const NCorrect = styled(Typography)({
  color:"green", 
  border: "1px solid green",
  padding: "3px",
  textAlign: "center"
});


export const NContainer70 = styled('div')({
  backgroundColor: "red",
  width: "300px",
  height: "70vh"
});




// TYPOGRAPHY SYSTEM
export const NTypoPageHeader = styled(({ ...other }) => (
  <Typography variant="h3" paragraph={true} {...other} />
))({
});

export const NTypoMainTitle = styled(({ ...other }) => (
  <Typography variant="h4" paragraph={true} {...other} />
))({
});

export const NTypoLargeTitle = styled(({ ...other }) => (
  <Typography variant="h5" paragraph={true} {...other} />
))({
});

export const NTypoSmallTitle = styled(({ ...other }) => (
  <Typography variant="h6" paragraph={true} {...other} />
))({
});

export const NTypoSubMainTitle = styled(({ ...other }) => (
  <Typography variant="subtitle2" paragraph={true} {...other} />
))({
});

export const NTypoBodyContent = styled(({ ...other }) => (
  <Typography variant="body2" paragraph={true} {...other} />
))({
});

const Native = styled('div')({
  fontWeight: "500",
  display: "inline"
});

const Brand = styled('div')({
  fontWeight: "200",
  display: "inline"
});

export const NativeGeneric = styled(({ ...other }) => (
  <div {...other} >
    <Link to="/upcoming" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Native>Native</Native>
    </Link>
  </div>
))({
});

export const NativeAuctions = styled(({ ...other }) => (
  <div {...other} >
    <Link to="/upcoming" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Native>Native</Native>&nbsp;<Brand>Auctions</Brand>
    </Link>
  </div>
))({
});

export const NativeOnline = styled(({ ...other }) => (
  <div {...other} >
    <Link to="/upcoming" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Native>Native</Native>&nbsp;<Brand>Online</Brand>
    </Link>
  </div>
))({
});




