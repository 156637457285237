import React from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux"; 
import { editModel, addNewModel} from "../../js/utils/backend";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { NEWSURL } from "../../js/constants/model_cst";
import { VTextField, VDropzone, VMuiTextEditor, VDatePicker } from '../Widget/CustomVInputs'
import { useForm, FormContext } from 'react-hook-form';
import * as cst from '../../js/constants/miscellaneous_cst'

import {NBottomActiveButton, NBottomCancelButton} from '../Widget/NAdminCustomComponents';


const NewsEditPanel = ({Data, onClose, isNew}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange", 
        defaultValues: Data
    });

    const {
        handleSubmit
    } = methods;

    const localStyle = makeStylesGlobal(theme => ({}));
    const classes = localStyle();

    const token = useSelector(state => state.auth.token);

    const submitUpdate = data => { 
        const newData = {...Data, ...data}; 
        if(isNew)
        {
            addNewModel(newData, token, NEWSURL)
            .then(onClose)
        }
        else
        {
            editModel(newData, Data.ID, token, NEWSURL)
            .then(onClose)
        }
    }
    
    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)}>
                <Grid container>  
                    <Grid item sm={3} xs={12}>
                        <VDropzone
                            fieldName="ImageID"
                            label="Image"
                            validation={{required: cst.msgImageRequired}}
                            fileType="img" 
                        />
                    </Grid>
                    <Grid item sm={9} xs={12}> 
                        <Grid container>
                            <Grid item xs={12} >
                                <VTextField
                                    label="Title" 
                                    fieldName="Title"
                                    validation={{required: true}}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid sm={6} xs={12}>
                                <VTextField
                                    label="Intro"
                                    fieldName="Intro"
                                    validation={{required: true}}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid sm={6} xs={12}>
                                <VDatePicker
                                    label="Date"
                                    fieldName="PublicationDate"
                                    fullWidth={true}
                                    validation={{
                                        required: cst.msgRequired,
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <VMuiTextEditor
                                    fieldName="Content"
                                    label="Content"
                                    validation={{
                                        pattern: {
                                            value: cst.min12Chars,
                                            message: cst.msgContentRequired
                                        } 
                                    }}   
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box className={classes.RightEndAlign}>
                                    <NBottomCancelButton 
                                        variant="outlined" 
                                        onClick={onClose}
                                    >
                                    Cancel
                                    </NBottomCancelButton>
                                    <NBottomActiveButton 
                                        type="submit"
                                    >
                                    Save
                                    </NBottomActiveButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    ); 
} 

export default NewsEditPanel