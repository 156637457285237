import React, {useState} from 'react'
import RouterLink from '../../routes/RouterLink'
import Link from "@material-ui/core/Link";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Box, useTheme, Typography } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { Redirect } from 'react-router-dom';
import { NTypoLargeTitle, NFullGridCentered } from "../Widget/NativeStyleds"
import { VTextField } from '../Widget/CustomVInputs';
import { emailRegex, msgEmailInvalid } from '../../js/constants/miscellaneous_cst'
import { NError } from '../Widget/NativeStyleds';


import { useForm, FormContext } from 'react-hook-form';
import Axios from 'axios';

const localStyle = makeStylesGlobal(theme => ({

}));

const ResetPassword = () => { 

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    })
    const {handleSubmit} = methods;

    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [submitError, setSubmitError] = useState(null);

    const varUseTheme = useTheme().ColorBlue;
    const classes = localStyle();

    const onSubmit = data => {
        let url = `/user/lost`
        Axios.post(url, data) 
            .then(response => {      
                setRedirectToLogin(true)
            })
            .catch(error => {
                if(error.response && error.response.data && error.response.data.message) {
                    setSubmitError(error.response.data.message);
                } else {
                    setSubmitError("Impossible to reset password now, please try again later.")
                }
            })
    }

    if(redirectToLogin === true) {
        return <Redirect component={RouterLink} to="/login" />
    }

    return (
        <NFullGridCentered>
            <ThemeProvider
                    theme={theme =>
                    createMuiTheme({
                        ...theme,
                        ActiveColor: "white",
                        BackgroundColor: theme.ColorBlue
                    })
                    }
            >
                <Box
                    width={300}
                    boxShadow={3}
                    className={classes.NDropBox}
                    bgcolor={varUseTheme}
                >
                    <FormContext {...methods}>
                        <Grid container item spacing={1}>
                            <Grid item xs={12}>
                                <NTypoLargeTitle className={classes.NSecHeader}>Reset your password</NTypoLargeTitle>
                            </Grid>
                            <form onSubmit={handleSubmit(onSubmit)}> 
                                <Grid container spacing={1} >
                                    <Grid item xs={12}>
                                        <VTextField
                                            placeholder="E-mail"
                                            fieldName="Value"
                                            validation={{
                                                required: true,
                                                pattern: {
                                                    value: emailRegex,
                                                    message: msgEmailInvalid
                                                }}
                                            }
                                            autoComplete="off"
                                            className={classes.NTextField}
                                            InputProps={{
                                                className: classes.NTextFieldInput
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {submitError ? <NError >{submitError}</NError> : null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button 
                                            fullWidth={true} 
                                            className={classes.NButton}                                        
                                            type="submit"
                                        >
                                            Get new password by e-mail
                                        </Button>     
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">
                                            <Link component={RouterLink} to="/login" >Go back to login</Link>   
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </FormContext>
                </Box>
                
            </ThemeProvider>
        </NFullGridCentered>
    );
}

export default ResetPassword;