import React, {useEffect, useState} from "react";
import {NModal} from '../Widget/NAdminCustomComponents'
import ProductsEditPanel from "../EditPanels/ProductsEditPanel";
import { Paper } from "@material-ui/core";
import Axios from "axios";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { useSelector } from "react-redux";

const localStyle = makeStylesGlobal(theme => ({


}));


const ProductModal = ({productId, isShown, onClose}) => {
  const [productData, setProductData] = useState(undefined)
  const token = useSelector(state => state.auth.token);

  const classes = localStyle();

  useEffect(() => {
    const fetchProductData = (productId) => {  
      const url = '/product/'+productId
      const config = {headers: { Authorization: "Bearer " + token }};
      Axios.get(url, config)
        .then(response => {
          setProductData(response.data)
        })
    }
    if(isShown)
    {
      fetchProductData(productId);
    }
    else
    {
      setProductData(undefined)
    }
  }, [productId, isShown, token]);


  return (
    <NModal showModal={isShown} updateModalState={onClose} >
    <Paper className={classes.ScrollableModalPaper}>
    {productData ? 
      <ProductsEditPanel
          Data={productData}
          onClose={onClose}
          isNew={false}
          isPopUp={true}
        />
    : null}
    </Paper>
  </NModal>);
};

export default ProductModal;