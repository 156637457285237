import React from "react";
import {Grid} from "@material-ui/core";
import BiddingChartWidget from "./DashboardWidget/BiddingChartWidget";
import AuctionProgressValue from "./DashboardWidget/AuctionProgressWidget";
import LiveBidsWidget from "./DashboardWidget/LiveBidsWidget";
import ConnectedUsersWidget from "./DashboardWidget/ConnectedUsersWidget";

export default function Dashboard() {

  return (
    <>
      <Grid container justify="center" spacing={1}>
        <Grid item container xs={12} md={4} spacing={1} >
          <Grid item xs={12}>
            <AuctionProgressValue/>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={8} spacing={1}>
          <Grid item xs={12}>
            <BiddingChartWidget />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={4} spacing={1}>
          <Grid item xs={12}>
            <ConnectedUsersWidget />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={8} spacing={1}>
          <Grid item xs={12}>
            <LiveBidsWidget />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}