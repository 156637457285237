import * as actionTypes from "../constants/action_types";

const initialState = {
  items: [],
  follows: [],
  serverTimeDelta: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.EMPTYOBJECTCACHE: 
      return { ...state, items: [] };
    case actionTypes.ITEMSUPDATED:
      let newState = { ...state.items };

      let old100 = newState.top100 ? newState.top100 : [];
      let top100 = [...action.payload, ...old100];

      const distinct = (value,index,self) => {
        if(self[index].SerialNumber === 0)
          return false;
        return self.findIndex(t => t.SerialNumber === self[index].SerialNumber)===index;
      }
      top100 = top100.filter(distinct);
      top100 = top100.sort(function (a, b) {return b.SerialNumber - a.SerialNumber});
      top100 = top100.slice(0,100);

      action.payload.forEach((bid) => {
        const existingProduct = newState[bid.AssignmentID] ? newState[bid.AssignmentID] : { vbids: {} };
        const oldCurrent = (existingProduct.current && existingProduct.current.BidderRef !== 0) ? existingProduct.current : bid;

        let existingProductBids = (existingProduct.vbids && oldCurrent.BidderRef !== 0)
          ? Object.keys(existingProduct.vbids)
          .filter(key => existingProduct.vbids[key].BidderRef !== 0)
          .reduce((obj, key) => {
            obj[key] = existingProduct.vbids[key];
            return obj;
          }, {})
          : {};
        

        let newProductBids = null;

        if(!(existingProductBids[bid.Value] && existingProductBids[bid.Value].SerialNumber > bid.SerialNumber))
        {
          newProductBids = {
          ...existingProductBids,
          [bid.Value]: {
            Value: bid.Value,
            Time: bid.Time,
            BidderRef: bid.BidderRef,
            MaxValue: bid.MaxValue,
            SerialNumber: bid.SerialNumber,
            BidCount: bid.BidCount
          }
        };
      }
      else
      {
        newProductBids = {...existingProductBids};
      }
        const newProduct = {...existingProduct, 
          vbids: newProductBids,
          lastUpdate: new Date(),
          current: oldCurrent.Value > bid.Value ? oldCurrent : (oldCurrent.Value === bid.Value && oldCurrent.SerialNumber > bid.SerialNumber ? oldCurrent : bid ),
          pending: false,
          final: existingProduct.final || bid.IsFinal
        };

        const currentLiveSerial = newState.liveserial ? newState.liveserial : 0;
        const newLiveSerial = action.live ? (bid.SerialNumber > currentLiveSerial ? bid.SerialNumber : currentLiveSerial) : currentLiveSerial;
        newState = { ...newState, [bid.AssignmentID]: newProduct, liveserial: newLiveSerial, top100: top100 };
      });
      return { ...state, items: newState, };
    case actionTypes.ITEMPENDING:
      const existingProductBids = state.items[action.payload]
        ? state.items[action.payload]
        : state.items[action.payload];
      const newProductBids = { ...existingProductBids, pending: true };
      const merged = { ...state.items, [action.payload]: newProductBids };
      return { ...state, items: merged };
    case actionTypes.FOLLOWITEM:
      return { ...state, follows: [...state.follows, action.payload] };
    case actionTypes.UNFOLLOWITEM:
      let filteredFollows = [...state.follows ];
      filteredFollows = filteredFollows.filter(t => t!==action.payload);
      return { ...state, follows: filteredFollows };
    case actionTypes.RESETFOLLOWS:
      return { ...state, follows: action.payload };
    case actionTypes.SERVERTIMED:
      return { ...state, serverTimeDelta: Math.ceil((new Date(action.payload.serverTime).getTime())/1000 - (new Date(action.payload.myTime).getTime())/1000)+2 };
    default:
      return state;
  }
}
