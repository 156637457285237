import React from "react";
import { makeStyles, Box, Button, IconButton, Grid, Paper, Typography } from '@material-ui/core';
import CatalogIcon from '@material-ui/icons/MenuBook';
import EmailIcon from '@material-ui/icons/Email';
import PublicIcon from '@material-ui/icons/Public';
import { CenteredModal } from "../Widget/NativeStyleds";
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';




const localStyle = makeStyles(theme => ({
    NAdminWorkflow: {
        padding: "8px 20px",
        marginBottom: "12px",
        borderRadius: "0px"
    },
    NAdminToolBar: {
        backgroundColor: 'rgba(195, 206, 224, 0.3)',
        padding: "0px 12px",
        marginBottom: "5px"
    },
    NAdminToolBarInvisible: {
        padding: "12px",
        marginBottom: "5px"
    },
    NAdminFilterBar: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: 'rgba(195, 206, 224, 0.3)',
        padding: "0px 12px",
    },
    NAdminActiveButton: {
        margin: theme.ButtonSpacing,
    },
    NAdminCancelButton: {
        margin: theme.ButtonSpacing
    },
    NIconAndTypo: {
        display: 'inline-flex',
        margin: theme.ButtonSpacing,
        alignItems: 'center',
        boxSizing: 'inherit'
    },
    NAdminBottomButton: {
        marginLeft: theme.ButtonSpacing
    },
    NAdminLabeledButtonGroup: {
        display: 'inline-flex',
        marginRight: theme.ButtonDoubleSpacing,
    },
    CloseIconButton: {
        backgroundColor: `rgba(255, 255, 255, 0.4)`,
        '&:hover': {
            backgroundColor: `rgba(255, 255, 255, 0.7)`
        },
        display: "block",
        margin: "8px auto",
    },

    CenteredModal: {
        margin: "10px auto",
        maxWidth: "70%",
    },

    SmallCenteredModal: {
        margin: "8px auto",
        maxWidth: "570px",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "530px",
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: "260px",
        },
    }
}));


export const NModal = ({ children, showModal, updateModalState, size }) => {
    // give the two following const to the parent component ; with a slightly different variant if there're several modals in the same and single component (to avoid confusion) , cf. AvatarPicker.js : 
    //(1) const [showModal, updateShowModal] = useState(false)
    //(2) const toggleModal = () => updateShowModal(state => !state)

    const classes = localStyle();

    const small = size === "small"

    return (
        <>
            <CenteredModal
                className={clsx(classes.CenteredModal, {
                    [classes.SmallCenteredModal]: small,
                })}
                open={showModal} onClose={updateModalState} aria-labelledby="form-dialog-title"
            >
                <>
                    <CloseIconButton onClick={updateModalState} />
                    {children}
                </>
            </CenteredModal>
        </>
    )
}

export const CloseIconButton = ({ onClick }) => {
    const classes = localStyle();

    return (
        <IconButton className={classes.CloseIconButton} onClick={onClick} >
            <CloseIcon fontSize="small" />
        </IconButton>
    )
}


// admin toolbar components :  

export const NAdminLabeledButtonGroup = (props) => {
    const classes = localStyle();
    return (<Box className={classes.NAdminLabeledButtonGroup}>
        {props.children}
    </Box>)
}

export const NAdminToolBar = (props) => {
    const classes = localStyle();
    if (props.invisible)
    {
        return (<Box className={classes.NAdminToolBarInvisible}>
                    {props.children}
        </Box>)
    }
    return (<Box className={classes.NAdminToolBar}>
        <Grid container wrap="nowrap" alignItems="center">
            <Grid item>
                <div className={classes.NIconAndTypo}>
                    Actions
                </div>
            </Grid>
            <Grid item container alignItems="center">
                {props.children}
            </Grid>
        </Grid>
    </Box>)
}

export const NAdminWorkflowPanel = (props) => {
    const classes = localStyle();
    return (
        <Paper className={classes.NAdminWorkflow}>
                <Typography variant="h6">Workflows</Typography>
                {props.children}
        </Paper>
    )
}

export const NAdminFilterBar = (props) => {
    const classes = localStyle();
    return (<Box className={classes.NAdminFilterBar}>
       <Grid container wrap="nowrap" alignItems="center">
            <Grid item>
                <div className={classes.NIconAndTypo}>
                Filters&nbsp;&nbsp;
                </div>
            </Grid>
            <Grid item>{props.children}
            </Grid>
        </Grid>
    </Box>)
}

export const NAdminActiveButton = (props) => {

    const classes = localStyle();
    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.NAdminActiveButton}
            {...props}
        />
    )
}

export const NAdminToolbarCancelButton = (props) => {

    const classes = localStyle();
    return (
        <Button
            variant="outlined"
            color="default"
            className={classes.NAdminCancelButton}
            {...props}
        />
    )
}

// bottom buttons 

export const NBottomActiveButton = (props) => {
    const classes = localStyle();

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.NAdminBottomButton}
            {...props}
        />
    )
}

export const NBottomCancelButton = (props) => {
    const classes = localStyle();

    return (
        <Button
            variant="outlined"
            color="default"
            className={classes.NAdminBottomButton}
            {...props}
        />
    )
}

export const NCatalogAndTypo = (props) => {
    const classes = localStyle();
    return (
        <div className={classes.NIconAndTypo}>
            <CatalogIcon style={{ display: 'inherit', marginLeft: '-4 px', marginRight: '8px' }} />
            <div style={{ display: 'inherit' }}>Catalog</div>
        </div>
    )
}

export const NMailingAndTypo = (props) => {
    const classes = localStyle();
    return (
        <div className={classes.NIconAndTypo}>
            <EmailIcon style={{ display: 'inherit', marginLeft: '-4 px', marginRight: '8px' }} />
            <div style={{ display: 'inherit' }}>Mailing List</div>
        </div>
    )
}

export const NOnlineAndTypo = (props) => {
    const classes = localStyle();
    return (
        <div className={classes.NIconAndTypo}>
            <PublicIcon style={{ display: 'inherit', marginLeft: '-4 px', marginRight: '8px' }} />
            <div style={{ display: 'inherit' }}>Online</div>
        </div>
    )
}


// loading message 

export const NDataLoading = (props) => {
    const classes = localStyle();
    return (
        <div className={classes.NDataLoading}>
            Data is loading, please wait ...
        </div>
    )
}
