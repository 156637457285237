import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Box, Grid } from "@material-ui/core";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { useDispatch, useSelector } from 'react-redux';
import { checkCode, newCode, logout } from "../../js/actions/auth";
import { NError } from "../Widget/NativeStyleds";
import { useForm, FormContext } from 'react-hook-form';

import { MobileNumberValidation } from "./RegistrationForm";
import { VPhoneNumber } from "../Widget/CustomVInputs"
import Axios from 'axios';
import * as cst from '../../js/constants/miscellaneous_cst'
import { NBottomActiveButton } from "../Widget/NAdminCustomComponents"

import { authenticated } from '../../js/actions/auth';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const localStyle = makeStylesGlobal(theme => ({
  Spacing: {
    padding: "8px 12px"
  }
}));

export default function ValidationForm({ match }) {
  const token = useSelector(state => state.auth.token);
  const [validationCode, setValidationCode] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const [submitError, setSubmitError] = useState(null);

  const [userData, setUserData] = useState();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {userData}
  })

  const { handleSubmit, reset } = methods;

  const classes = localStyle();
  const dispatch = useDispatch();
  const error = useSelector(state => state.auth.errorMessage);

  const handleSubmitCode = () => {
    dispatch(checkCode(token, validationCode));
    setShowError(true);
  };

  const handleGetNewCode = () => {
    dispatch(newCode(token));
    setShowError(true);
  };

  const handleLogout = () => {
    dispatch(logout());
  };


  useEffect(() => {
    fetchUserProfile()
  }, [])

  const fetchUserProfile = () => {
    let url = cst.getUserProfile;
    let config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.get(url, config)
      .then(function (response) {
        setUserData(response.data)
        reset(response.data);
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          setSubmitError(error.response.data.message);
        } else {
          setSubmitError("Impossible to fetch you profile account, please try again later.")
        }
      })
  }
  
  const onSubmit = data => {
    const updatedUserMobileNumber = {...userData, ...data}
    let config = {
      headers: {
          Authorization: "Bearer " + token,
          'Content-Type': 'application/json'
      }
    };
    let url = cst.getUserProfile;

    Axios.put(url, updatedUserMobileNumber, config)
      .then(response => {
          if (response.data.IsLogged === true) {
              dispatch(authenticated(
                  response.data.IsValidated,
                  response.data.Token,
                  response.data.Role
              ));
          }
          handleClose()
          fetchUserProfile()

      })
      .catch(error => {
          if (error.response && error.response.data && error.response.data.message) {
              setSubmitError(error.response.data.message);
          } else {
              setSubmitError("Impossible to update your profile account, please try again later.")
          }
      })
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container item spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.NSecHeader}>Validate your account</Typography>
          <Typography gutterBottom display="block"  variant="subtitle2">
            We sent a verification code to the mobile number you entered ( {userData && userData.GSM} ). 
          </Typography>
          <Typography gutterBottom display="block"  variant="subtitle2">
            Please enter it below to activate your account.
          </Typography>
        </Grid>
        {
          showError && error ?
            <Grid item xs={12}>
              <NError>
                {error}
              </NError>
            </Grid>
            : null
        }
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            className={classes.NTextField}
            inputProps={{
              className: classes.NTextFieldInputHuge,
              style: {textAlign: 'center'}
            }}
            onChange={event => setValidationCode(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button className={classes.NButton} fullWidth={true} onClick={handleSubmitCode} >Activate</Button>
        </Grid>
        <Grid item container xs={12}
          justify="space-between" 
        >
          <Button  className={[classes.NButton, classes.Spacing]} type="button" onClick={handleGetNewCode} >Resend</Button>
          <Button  className={[classes.NButton, classes.Spacing]} type="button" onClick={handleClickOpen}>Change number</Button>
          <Button  className={[classes.NButton, classes.Spacing]} onClick={handleLogout}>Logout</Button>
        </Grid>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
          <FormContext {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
            >
            <DialogContent>
              <Grid item xs={12}>
                <VPhoneNumber
                  label="Mobile Number"
                  fieldName="GSM"
                  validation={{
                      required: true,
                      validate: (value) => {return MobileNumberValidation(value)}
                  }}
                  helperText={cst.msgMobileNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <Box 
                  className={classes.RightEndAlign} 
                >
                  <NBottomActiveButton
                    variant="outlined"
                    type="submit"
                  >
                    Update and resend code
                  </NBottomActiveButton>
                </Box>
              </Grid>
              </DialogContent>
            </form>
          </FormContext>
        </Dialog>
        
      </Grid>
    </React.Fragment>
  );
}
