import Axios from "axios";
import { invoice, settlement } from "../../js/constants/miscellaneous_cst";
import { fetchData } from "../../js/utils/backend"

export async function getInvoiceData(invoiceId) {
    let url = invoice.ownBills + "/" + invoiceId
    let response = await Axios.get(url);
    return response.data;
}


export async function getSettlementData(settlementId) {
    let url = settlement.ownBills + "/" + settlementId
    let response = await Axios.get(url);
    return response.data;
}

export async function getObjectsListingData(auctionId, token, noPremium) {
    const orderedQuery = "&orderBy=AuctionNumber&dir=asc"

    let fetchOptions = "&filter[auction]=" + auctionId + "&noPremium=" + (noPremium ? "true" : "false")
    let url = `/product/report?${fetchOptions}${orderedQuery}` 
    var config = {
        headers: { Authorization: "Bearer " + token }
    };
    let response = await Axios.get(url, config)
    return response.data
}

export async function getPhoneRequests(body, token) {
    let url = `/phonerequest/generateschedule` 
    var config = {
        headers: { Authorization: "Bearer " + token }
    };
    let response = await Axios.post(url, body, config)
    return response.data
}