
export const AUTHENTICATED = 'AUTHENTICATED';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const UNHELPFULTOKEN = 'UNHELPFULTOKEN';
export const ENTERED = 'ENTERED';
export const VARIABLESAVED = 'VARIABLESAVED';
export const ITEMSUPDATED = 'ITEMSUPDATED';
export const LIVEINDEX = 'LIVEINDEX';
export const ITEMPENDING = 'ITEMPENDING';
export const FOLLOWITEM = 'FOLLOWITEM';
export const UNFOLLOWITEM = 'UNFOLLOWITEM';
export const RESETFOLLOWS = 'RESETFOLLOWS';
export const DASHBOARDSAVED = 'DASHBOARDSAVED';
export const TABALIVE = 'TABALIVE';
export const EMPTYOBJECTCACHE = 'EMPTYOBJECTCACHE';
export const RESETERROR = 'RESETERROR';
export const SERVERTIMED = 'SERVERTIMED';