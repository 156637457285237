import React, { useState } from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux";
import { editModel, addNewModel } from "../../js/utils/backend";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { PRODUCTURL } from "../../js/constants/model_cst";
import {
    VTextField,
    VMuiTextEditor,
    VMultiDropzone,
    VUserChooser,
    VMultiLineEdit,
    VNumeric,
    VBidChooser
} from '../Widget/CustomVInputs'
import { useForm, FormContext } from 'react-hook-form';
import * as cst from '../../js/constants/miscellaneous_cst';
import { NBottomActiveButton, NBottomCancelButton } from '../Widget/NAdminCustomComponents';
import {ObjectHistory} from "../Admin/Products/ObjectHistory";


const localStyle = makeStylesGlobal(theme => ({
    userChooser: {
        margin: "8px",
        maxWidth: "80%"
    },

    UserProfileIcon : {
        display: "flex", 
        alignItems: "center"
    }
}));

const ProductsEditPanel = ({ onClose, isNew, Data, isAuctionBinded, isOnline, isGroup}) => {

    const sellerName = "OriginalSeller";
    const [seller] = useState(Data[sellerName]);
    const buyerName = "OriginalBuyer";
    const [buyer] = useState(Data[buyerName]);

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: Data
    });
    const {
        watch,
        triggerValidation,
        handleSubmit
    } = methods;

    
    const classes = localStyle();

    const token = useSelector(state => state.auth.token);

    const submitUpdate = data => {
        const newData = {
            ...Data, ...data
            , [sellerName]: null
        };
        if (isNew) {
            addNewModel(newData, token, PRODUCTURL)
                .then(onClose)
        }
        else {
            editModel(newData, Data.ID, token, PRODUCTURL)
                .then(onClose)
        }
    }


    return (
        <>
            <FormContext {...methods}>
                <form onSubmit={handleSubmit(submitUpdate)}>

                    <Grid container>
                        
                        <Grid item xs={12}>
                            <VUserChooser
                                fieldName={"SellerID"}
                                validation={{ required: true }}
                                label={"Seller"}
                                value={seller}
                                className={classes.userChooser}
                                showRefresh={false}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <VTextField
                                label="Title"
                                fieldName="Title"
                                validation={{ required: true }}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <VTextField
                                label="Subtitle"
                                fieldName="Subtitle"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <VMultiDropzone
                                label='Internal Images'
                                fieldName='InternalImagesID'
                                validation={{ required: false }}
                                fileType="img" 
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <VMultiDropzone
                                label='Public Images'
                                fieldName='OfficialImagesID'
                                validation={{ required: false }}
                                fileType="img"
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <VBidChooser
                                fieldName={"StartPrice"}
                                defaultValue={!Data["StartPrice"]? 0 : Data["StartPrice"]}
                                label={"Start Price"}
                                validation={{
                                    required: false,
                                }}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <VNumeric
                                label="Estimated Low"
                                fieldName="EstimatedLow"
                                integer
                                unit={cst.APPCURRENCY}
                                validation={{
                                    required: false,
                                    validate: (value) => {
                                        if ((!value || value === '') || (!watch('EstimatedHigh') || watch('EstimatedHigh') === '')) {
                                            return true
                                        } else {
                                            return ((watch('EstimatedLow') <= watch('EstimatedHigh')) ? true : cst.msgEstimatedLowInvalid);
                                        }
                                    }
                                }}
                                onBlur={async () => {
                                    triggerValidation("EstimatedHigh");
                                }}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <VNumeric
                                label="Estimated High"
                                fieldName="EstimatedHigh"
                                integer
                                unit={cst.APPCURRENCY   }
                                validation={{
                                    required: false,
                                    validate: (value) => {
                                        if ((!value || value === '') || (!watch('EstimatedLow') || watch('EstimatedLow') === '')) {
                                            return true
                                        } else {
                                            return ((watch('EstimatedHigh') >= watch('EstimatedLow')) ? true : cst.msgEstimatedHighInvalid);
                                        }
                                    }
                                }}
                                onBlur={async () => {
                                    triggerValidation("EstimatedLow");
                                }}
                                fullWidth={true}
                            />
                        </Grid>

                        {isAuctionBinded && !isGroup ? // shown only in AuctionWithProducts view
                            <>
                            <Grid item sm={4} xs={12}>
                                <VUserChooser
                                fieldName={"BuyerID"}
                                validation={{ required: false }}
                                label={"Buyer"}
                                value={buyer}
                                className={classes.userChooser}
                                showRefresh={false}
                                />
                            </Grid> 
                            <Grid item sm={4} xs={12}>
                                <VNumeric
                                    label="Hammer Price"
                                    fieldName="FinalPrice"
                                    integer
                                    unit={cst.APPCURRENCY   }
                                    validation={{
                                        required: false,
                                    }}
                                    fullWidth={true}
                                />
                                </Grid>
                                {isOnline && Data.DisplayFinalPrice ? null : 
                                    <Grid item sm={4} xs={12}>
                                    <VNumeric
                                        label="Displayed Result Price (with Premium)"
                                        fieldName="DisplayFinalPrice"
                                            integer
                                            disabled={true}
                                        unit={cst.APPCURRENCY   }
                                        validation={{
                                            required: false,
                                        }}
                                        fullWidth={true}
                                    />
                                    </Grid>    
                                }
                            </> : null
                        }

                        <Grid item sm={12} xs={12}>
                            <VMuiTextEditor
                                fieldName="Description"
                                label="Description"
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <VMultiLineEdit
                                label="Internal Notes"
                                fieldName="InternalNotes"
                                validation={{required: false}}
                            />
                        </Grid>
                        {isNew ? null : <Grid item sm={12} xs={12}>
                            <ObjectHistory 
                                ID={Data && Data.ID}
                            />
                        </Grid>}
                        <Grid item sm={12} xs={12}>
                            <Box className={classes.RightEndAlign}>
                                <NBottomCancelButton
                                    onClick={onClose}
                                >
                                    Cancel
                                </NBottomCancelButton>
                                <NBottomActiveButton
                                    type="submit"
                                >
                                    Save
                                </NBottomActiveButton>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </FormContext>
        </>
    )
}

export default ProductsEditPanel;