import React from 'react';
import ReactDOM from 'react-dom';
import ReactAutocomplete from 'ron-react-autocomplete';
require('./ron-react-autocomplete.css');

function doSearch(options,searchterm,onSearchComplete) 
  {
    const clean = searchterm.toLowerCase().replace(" ","");
    const results = options.filter(t=>t.Name.toLowerCase().replace(" ","").includes(clean));
    onSearchComplete(null, results);
  }
  
export default class SellerEditor extends React.Component {
      
  handleChange = () => {
    this.props.onCommit();
  };


  getValue = () => {
    let value;
    const updated = {};
    if (this.hasResults() && this.isFocusedOnSuggestion()) {
      value = this.getLabel(this.autoComplete.state.focusedValue);
      value = this.constuctValueFromParams(this.autoComplete.state.focusedValue, ["ID","Name"]);
    } else {
      value = "";
    }

    updated[this.props.column.key] = value;
    return updated;
  };

  getEditorDisplayValue = () => {
    const displayValue = { title: '' };
    const { value } = this.props;
    displayValue.title = value && value.toString().split('|').slice(1).join(" ");
    return displayValue;
  };

  getInputNode = () => {
    return ReactDOM.findDOMNode(this).getElementsByTagName('input')[0];
  };

  getLabel = (item) => {
    return item.Name
  };

  hasResults = () => {
    return this.autoComplete.state.results.length > 0;
  };

  isFocusedOnSuggestion = () => {
    const autoComplete = this.autoComplete;
    return autoComplete.state.focusedValue != null;
  };

  constuctValueFromParams = (obj, props) => {
    if (!props) {
      return '';
    }

    const ret = [];
    for (let i = 0, ii = props.length; i < ii; i++) {
      ret.push(obj[props[i]]);
    }
    return ret.join('|');
  };

  setAutocompleteRef = (autoComplete) => {
    this.autoComplete = autoComplete;
  };

  render() {
    return (<div height={this.props.height} onKeyDown={this.props.onKeyDown}>
      <ReactAutocomplete search={doSearch} ref={this.setAutocompleteRef} label={t=>t.Name} onChange={this.handleChange} onFocus={this.props.onFocus} resultIdentifier="ID" options={this.props.options} value={this.getEditorDisplayValue()} />
      </div>);
  }
}
