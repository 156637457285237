import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import { createStateSyncMiddleware, withReduxStateSync } from 'redux-state-sync';
import signalRMiddleware from './signalRMiddleWare';
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import rootReducer from "../reducers";

const persistConfig = {
    key: 'native',
    storage,
  }

const syncConfig = {
  blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
  channel: 'native_state_sync',
}

const middlewares = [thunk,createStateSyncMiddleware(syncConfig), signalRMiddleware];
   
const persistedReducer = persistReducer(persistConfig, withReduxStateSync(rootReducer));

export const store = createStore(persistedReducer, compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
);

export const persistor = persistStore(store);
