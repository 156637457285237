import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ProductsChooser from '../Admin/Products/ProductsChooser'
import AddIcon from "@material-ui/icons/AddCircleOutline";


import {NAdminActiveButton, NBottomCancelButton} from './NAdminCustomComponents'

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const DialogProductChooser = ({choosenProduct, addExtra, products}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <NAdminActiveButton 
                onClick={handleClickOpen}
                startIcon={<AddIcon />}

            >
                Add Object
            </NAdminActiveButton>
            <Dialog 
                onClose={handleClose} 
                aria-labelledby="customized-dialog-title" 
                open={open}
                fullWidth={true} 
                maxWidth={'lg'}
            >
                <DialogContent dividers>
                    <ProductsChooser 
                        choosenProduct={choosenProduct}
                        handleClose={handleClose}
                    />
                </DialogContent>
                <DialogActions>
                    <NBottomCancelButton 
                        onClick={handleClose}
                    >
                        Cancel
                    </NBottomCancelButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

