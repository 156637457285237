import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { titleColor } from '../../pdf_cst';
const styles = StyleSheet.create({
    titleContainer: {
        marginTop: 24,
    },
    reportTitle: {
        color: titleColor,
        fontFamily: "BauerBodoni",
        fontSize: 40,
    },
});

const Title = ({ title }) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
);

export default Title