import React, { useState } from "react";
import { makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";

import Collapse from '@material-ui/core/Collapse'; 
import clsx from 'clsx';

const localStyle = makeStyles(theme => ({
    displayText: {
        //"-webkit-mask-image": "none"

    },
    hideText: {
        "-webkit-mask-image": "-webkit-gradient(linear, left 60%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))" // to 100% = less faded - to 0% = more faded
    },
    BlockFooter: {
        color: theme.ColorC1,
    },
    LabelFooter: {
        textTransform: "uppercase",
        marginTop: theme.spacing(3),
        lineHeight: "12px",
        fontSize: "14px",
        cursor: "pointer",
        textAlign: "left"
    },

}));

export const CollapsibleFadedContent = ({ children, fixedHeight }) => {

    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleChange = () => {
        setIsCollapsed((prev) => !prev);
    };

    const classes = localStyle();

    return (
        <>
            <Collapse
                in={isCollapsed}
                collapsedHeight={fixedHeight}
                className={clsx({
                    [classes.hideText]: !isCollapsed,
                    [classes.displayText]: isCollapsed,
                })}
            >
                {children}
            </Collapse>
            <div className={classes.BlockFooter}>
                <div className={classes.LabelFooter} onClick={handleChange}>
                    {isCollapsed ? 'Less' : 'More'}
                </div>
            </div>
        </>
    )
}


export const NBottomActiveButton = (props) => {
    const classes = localStyle();

    return (
        <Button 
            variant="contained"
            color="primary"
            className={classes.NAdminBottomButton}
            {...props} 
        />
    )
}

export const NBottomCancelButton = (props) => {
    const classes = localStyle();

    return (
        <Button 
            variant="outlined"
            color="default"
            className={classes.NAdminBottomButton}
            {...props} 
        />
    )
}